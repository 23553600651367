import { isProduction } from 'utils'
import { useMediaQuery } from 'react-responsive'

import ContentLoader from 'react-content-loader'

import { colors } from 'styles/variables'

const props = {
    title: 'Caricamento...',
    backgroundColor: colors.border,
    foregroundColor: isProduction ? colors.borderSecondary : undefined,
    // set animation only in production because SVG animations are really CPU intensive and slow down development machines
    animate: isProduction
}

export function ContainerLoader() {
    return(
        <ContentLoader viewBox="0 0 820 540" uniqueKey="content_loader" className="mx-6 mt-[65px]" {...props}>
            <rect rx="4" ry="4" y="15" width="300" height="25"/>
            <rect y="60" rx="4" ry="4" width="100%" height="420"></rect>
        </ContentLoader>
    )
}

export function SidebarMenuLoader() {
    return(
        <ContentLoader viewBox="0 0 245 1400" uniqueKey="sidebarmenu_loader" className="mt-2" {...props}>
            <circle cx="120" cy="70" r="60"/>
            <circle cx="120" cy="250" r="60"/>
            <circle cx="120" cy="430" r="60"/>
            <circle cx="120" cy="610" r="60"/>
            <circle cx="120" cy="790" r="60"/>
            <circle cx="120" cy="970" r="60"/>
            <circle cx="120" cy="1150" r="60"/>
            <circle cx="120" cy="1330" r="60"/>
        </ContentLoader>
    )
}

export function SidebarBottomLoader() {
    return(
        <ContentLoader viewBox="0 0 245 350" uniqueKey="sidebarbottom_loader" {...props}>
            <circle cx="120" cy="70" r="60"/>
            <circle cx="120" cy="250" r="60"/>
        </ContentLoader>
    )
}

export function LiveOrdersListLoader() {
    return(
        <ContentLoader viewBox="0 0 245 390" uniqueKey="liveorderslist_loader" {...props}>
            <rect rx="4" ry="4" x="10" y="10" width="50%" height="10"/>
            <rect rx="4" ry="4" x="10" y="28" width="40%" height="8"/>
            <rect rx="4" ry="4" x="10" y="46" width="30%" height="8"/>
            <rect rx="4" ry="4" x="10" y="64" width="70%" height="8"/>
            <rect rx="4" ry="4" x="10" y="82" width="20%" height="8"/>
            <rect y="100" width="100%" height=".5"/>
            <rect rx="4" ry="4" x="10" y="110" width="50%" height="10"/>
            <rect rx="4" ry="4" x="10" y="128" width="40%" height="8"/>
            <rect rx="4" ry="4" x="10" y="146" width="30%" height="8"/>
            <rect rx="4" ry="4" x="10" y="164" width="70%" height="8"/>
            <rect rx="4" ry="4" x="10" y="182" width="20%" height="8"/>
            <rect y="200" width="100%" height=".5"/>
            <rect rx="4" ry="4" x="10" y="210" width="50%" height="10"/>
            <rect rx="4" ry="4" x="10" y="228" width="40%" height="8"/>
            <rect rx="4" ry="4" x="10" y="246" width="30%" height="8"/>
            <rect rx="4" ry="4" x="10" y="264" width="70%" height="8"/>
            <rect rx="4" ry="4" x="10" y="282" width="20%" height="8"/>
            <rect y="300" width="100%" height=".5"/>
            <rect rx="4" ry="4" x="10" y="310" width="50%" height="10"/>
            <rect rx="4" ry="4" x="10" y="328" width="40%" height="8"/>
            <rect rx="4" ry="4" x="10" y="346" width="30%" height="8"/>
            <rect rx="4" ry="4" x="10" y="364" width="70%" height="8"/>
            <rect rx="4" ry="4" x="10" y="382" width="20%" height="8"/>
        </ContentLoader>
    )
}

export function TableLoader() {
    return(
        <ContentLoader viewBox="0 0 1000 240" uniqueKey="table_loader" className="border rounded-lg" {...props}>
            <rect width="100%" height="10"/>
            <rect x="48" y="55" rx="4" ry="4" width="194" height="10"/>
            <rect x="305" y="55" rx="4" ry="4" width="299" height="10"/>
            <rect x="661" y="55" rx="4" ry="4" width="141" height="10"/>
            <rect x="842" y="55" rx="4" ry="4" width="120" height="10"/>
            <rect y="80" width="100%" height="1"/>
            <rect x="48" y="95" rx="4" ry="4" width="194" height="10"/>
            <rect x="305" y="95" rx="4" ry="4" width="299" height="10"/>
            <rect x="661" y="95" rx="4" ry="4" width="141" height="10"/>
            <rect x="842" y="95" rx="4" ry="4" width="120" height="10"/>
            <rect y="120" width="100%" height="1"/>
            <rect x="48" y="135" rx="4" ry="4" width="194" height="10"/>
            <rect x="305" y="135" rx="4" ry="4" width="299" height="10"/>
            <rect x="661" y="135" rx="4" ry="4" width="141" height="10"/>
            <rect x="842" y="135" rx="4" ry="4" width="120" height="10"/>
            <rect y="160" width="100%" height="1"/>
            <rect x="48" y="175" rx="4" ry="4" width="194" height="10"/>
            <rect x="305" y="175" rx="4" ry="4" width="299" height="10"/>
            <rect x="661" y="175" rx="4" ry="4" width="141" height="10"/>
            <rect x="842" y="175" rx="4" ry="4" width="120" height="10"/>
            <rect y="200" width="100%" height="1"/>
            <rect x="48" y="215" rx="4" ry="4" width="194" height="10"/>
            <rect x="305" y="215" rx="4" ry="4" width="299" height="10"/>
            <rect x="661" y="215" rx="4" ry="4" width="141" height="10"/>
            <rect x="842" y="215" rx="4" ry="4" width="120" height="10"/>
            <rect y="30" width="100%" height="10"/>
            <rect y="10" width="68" height="25"/>
            <rect x="222" y="10" width="149" height="25"/>
            <rect x="540" y="10" width="137" height="25"/>
            <rect x="795" y="10" width="72" height="25"/>
            <rect x="930" y="10" width="72" height="25"/>       
        </ContentLoader>
    )
}

export function MonthlyBillingLoader() {
    const internalProps = {viewBox: '0 0 1000 250', className: 'flex-auto'}

    return(
        <>
            <ContentLoader uniqueKey="monthly_billing_loader_1" {...props} {...internalProps}>
                <rect x="240" y="80" rx="8" ry="8" width="500" height="40"/>
                <rect x="330" y="150" rx="8" ry="8" width="300" height="25"/>
            </ContentLoader>
            <ContentLoader uniqueKey="monthly_billing_loader_2" {...props} {...internalProps}>
                <rect x="240" y="80" rx="8" ry="8" width="500" height="40"/>
                <rect x="330" y="150" rx="8" ry="8" width="300" height="25"/>
            </ContentLoader>
            <ContentLoader uniqueKey="monthly_billing_loader_3" {...props} {...internalProps}>
                <rect x="240" y="80" rx="8" ry="8" width="500" height="40"/>
                <rect x="330" y="150" rx="8" ry="8" width="300" height="25"/>
            </ContentLoader>
            <ContentLoader uniqueKey="monthly_billing_loader_4" {...props} {...internalProps}>
                <rect x="240" y="80" rx="8" ry="8" width="500" height="40"/>
                <rect x="330" y="150" rx="8" ry="8" width="300" height="25"/>
            </ContentLoader>
        </>
    )
}

export function AnalyticsLoader() {
    const isMobile = useMediaQuery({query: '(max-width: 958px)'})

    return(
        <ContentLoader uniqueKey="items_loader" viewBox="0 0 1000 500" className="w-full" {...props}>
            <rect rx="4" ry="4" width={isMobile ? '100%' : '50%'}height="86"/>
            <rect rx="4" ry="4" y="115" width="100%" height="380"/>
        </ContentLoader>
    )
}

export function ItemsLoader() {
    return(
        <ContentLoader uniqueKey="analytics_loader" viewBox="0 0 1000 800" {...props}>
            <rect rx="4" ry="4" width="100%" height="80"/>
            <rect rx="4" ry="4" y="100" width="100%" height="80"/>
            <rect rx="4" ry="4" y="200" width="100%" height="80"/>
            <rect rx="4" ry="4" y="300" width="100%" height="80"/>
            <rect rx="4" ry="4" y="400" width="100%" height="80"/>
            <rect rx="4" ry="4" y="500" width="100%" height="80"/>
            <rect rx="4" ry="4" y="600" width="100%" height="80"/>
            <rect rx="4" ry="4" y="700" width="100%" height="80"/>
        </ContentLoader>
    )
}

export function BookingsCardLoader() {
    return(
        <>
            <ContentLoader uniqueKey="bookings_card_loader1" viewBox="0 0 1000 450" {...props}>
                <rect x="0" y="0" rx="8" ry="8" width="1000" height="450"/>           
            </ContentLoader>
            <ContentLoader uniqueKey="bookings_card_loader2" viewBox="0 0 1000 450" {...props}>
                <rect x="0" y="0" rx="8" ry="8" width="1000" height="450"/>           
            </ContentLoader>
            <ContentLoader uniqueKey="bookings_card_loader3" viewBox="0 0 1000 450" {...props}>
                <rect x="0" y="0" rx="8" ry="8" width="1000" height="450"/>           
            </ContentLoader>
            <ContentLoader uniqueKey="bookings_card_loader4" viewBox="0 0 1000 450" {...props}>
                <rect x="0" y="0" rx="8" ry="8" width="1000" height="450"/>           
            </ContentLoader>
            <ContentLoader uniqueKey="bookings_card_loader5" viewBox="0 0 1000 450" {...props}>
                <rect x="0" y="0" rx="8" ry="8" width="1000" height="450"/>           
            </ContentLoader>
            <ContentLoader uniqueKey="bookings_card_loader6" viewBox="0 0 1000 450" {...props}>
                <rect x="0" y="0" rx="8" ry="8" width="1000" height="450"/>           
            </ContentLoader>
        </>
    )
}