import { createContext } from 'react'

import { OrdersContextStateInterface } from 'types'

export const initialOrdersContext = {
    isPayMode: false,
}

const OrdersContext = createContext<OrdersContextStateInterface>({
    ...initialOrdersContext,
    updateOrdersContext: () => {return () => {/**/}}
})

export default OrdersContext