import useSWR from 'swr'
import Router from 'next/router'

import { AreaListType, Experience, Fiscale, GlobalContextStateOptional, Mutate, Shop, ShopEditorType, Structure, TaxButton, TokenType} from 'types'
import request from './request'
import { addNotification, getQueryParams, transformToQueryParams } from 'utils'
import { ParsedQuery } from 'query-string'
import { dequal } from 'dequal'

export function getEventsList(eventId: string, shouldDoRequest?: boolean) {
    const { data, error, mutate } = useSWR<any, { error: string }>(() => shouldDoRequest ? `/experience/list/${eventId}/` : null)

    return { events: data as any, isLoading: !data && !error, error, mutate }
}

export function getStructures(brandId: string, shouldDoRequest?: boolean) {
    const { data, error, mutate } = useSWR<any, { error: string }>(() => shouldDoRequest ? `/structure/list/${brandId}/` : null)

    return { structures: data as any, isLoading: !data && !error, error, mutate }
}

export function getAreaList(brandId: string, structureId?: string, shouldDoRequest?: boolean) {
    const queryParams = { structureId }
    const { data, error, mutate } = useSWR<any, { error: string }>(() => shouldDoRequest ? `/structure/area/list/${brandId}${transformToQueryParams('string', {...queryParams, ...getQueryParams() as ParsedQuery})}` : null)

    return { areaList: data as any, isLoading: !data && !error, error, mutate }
}

export function getSingleEvent(eventId: string, shouldDoRequest?: boolean) {
    const { data, error, mutate } =  useSWR<Experience, {error: string}>(() => shouldDoRequest ? `/experience/info/${eventId}` : null)

    return {event: data as Experience, isLoading: !data && !error, error, mutate}
}

export function createEvent(brandId: string, event: Experience) {
    const { query: { id }, push } = Router

    return new Promise<void>((resolve, reject) => {
        request<{ _id: string }>('post', '/experience/create', {...event, brandId})
            .then(() => {
                push(`/brand/${id}/manage/events`)
                addNotification('Evento creato con successo', 'success')
                return resolve()
            })
            .catch(() => {
                addNotification('Ops, si è verificato un errore.', 'danger')
                return reject()
            })
    })
}

export function editEvent(experienceId: string | undefined, event: Experience, mutate?: Mutate<Experience>) {
    return new Promise<void>((resolve, reject) => {
        request('post', '/experience/edit', { ...event, experienceId})
            .then(() => {
                addNotification('Modifica effettuata con successo', 'success')
                return resolve()
            })
            .catch(() => {
                addNotification('Si è verificato un errore, riprova', 'danger')
                return reject()
            })
            .finally(() => (mutate && mutate()))
    })
}

export function deleteEvent(experienceId: string) {
    const { query: { id }, push } = Router

    return new Promise((resolve, reject) => {
        request('post', '/experience/delete', {experienceId})
            .then(() => push(`/brand/${id}/manage/events/`))
            .catch(() => {
                addNotification('Si è verificato un errore, riprova', 'danger')
                return reject()
            })
    })
}

export function getTokenList(brandId: string, shouldDoRequest?: boolean) {
    const { data, error, mutate } = useSWR<any, { error: string }>(() => shouldDoRequest ? `/payment-token/list/${brandId}/` : null)

    return { tokenList: data as any, isLoading: !data && !error, error, mutate }
}

export function createToken(brandId: string, event: TokenType) {
    const { query: { id }, push } = Router

    return new Promise<void>((resolve, reject) => {
        request<{ _id: string }>('post', '/payment-token/create', {...event, brandId})
            .then(() => {
                push(`/brand/${id}/events/manage/token`)
                addNotification('Token creato con successo', 'success')
                return resolve()
            })
            .catch(() => {
                addNotification('Ops, si è verificato un errore.', 'danger')
                return reject()
            })
    })
}

export function getSingleArea(areaId: string, shouldDoRequest?: boolean) {
    const { data, error, mutate } =  useSWR<Structure, {error: string}>(() => shouldDoRequest ? `/structure/info/${areaId}` : null)

    return {area: data as Structure, isLoading: !data && !error, error, mutate}
}

export function createStructure(brandId: string, structure: Structure) {
    const { query: { id }, push } = Router

    return new Promise<void>((resolve, reject) => {
        request<{ _id: string }>('post', '/structure/create', {...structure, brandId})
            .then(() => {
                push(`/brand/${id}/events/manage/area`)
                addNotification('Struttura creata con successo', 'success')
                return resolve()
            })
            .catch(() => {
                addNotification('Ops, si è verificato un errore.', 'danger')
                return reject()
            })
    })
}

export function editStructure(structureId: string | undefined, structure: Structure, areaList: AreaListType, mutate?: Mutate<Structure>) {
    return new Promise<void>((resolve, reject) => {
        request('post', '/structure/edit', { ...structure, structureId, experienceId: structure.experience})   
            .then(() => {
                if(structure.areaList && structure.areaList.length > 0) {
                    Promise.all(structure.areaList?.map((single, index) => (
                        single.areaId === 'new' ? request('post', '/structure/area/create', {...single, structureId}) : request('post', '/structure/area/edit', {...single, structureId, areaId: single._id})
                    )))
                    .then(() => (
                        addNotification('Modifica aree effettuata con successo', 'success')
                    ))
                }
                addNotification('Modifica struttura effettuata con successo', 'success')
                return resolve()
            })
            .catch(() => {
                addNotification('Si è verificato un errore, riprova', 'danger')
                return reject()
            })
            .finally(() => mutate && mutate())
    })
}

export function deleteStructure(structureId: string) {
    const { query: { id }, push } = Router

    return new Promise((resolve, reject) => {
        request('post', '/structure/delete', {structureId})
            .then(() => push(`/brand/${id}/manage/events/area`))
            .catch(() => {
                addNotification('Si è verificato un errore, riprova', 'danger')
                return reject()
            })
    })
}