import cn from 'classnames'

import { WithChildren } from 'types'

type Props = {
    className?: string
}

export default function ModalBody({className, children}: WithChildren<Props>) {
    return(
        <div className={cn('p-5 overflow-y-auto', className)}>
            {children}
        </div>
    )
}