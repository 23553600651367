import { getQueryParams, getDatesFromRange, transformToQueryParams, numberFormatter, getCurrency, downloadElement, formatDate } from 'utils'
import { differenceInCalendarDays } from 'date-fns'

import { AnalyticsStatus, Currencies, SelledProducts, Timeframe, GenericStatistics, ApiStatistics, CashDeskOrderStats, AnalyticsStatusV2, Closure } from 'types'
import { ParsedQuery } from 'query-string'
import request from 'api/request'
import useSWR from 'swr'


export function getCleanQueryParams(timeframe?: string, calculateDistance?: boolean, disableShopFilter?: boolean) {
    let queryParams: AnalyticsStatus = getQueryParams() as ParsedQuery
    if (queryParams.timeframe !== 'custom') {
        const [start, end] = getDatesFromRange(queryParams.timeframe as Timeframe)
        queryParams = { ...queryParams, start, end }
    }

    if (timeframe) {
        queryParams.timeframe = timeframe
    }

    if (queryParams.shopId && !disableShopFilter) {
        queryParams.brandId = undefined
    }

    if (calculateDistance) {
        const daysDistance = differenceInCalendarDays(new Date(queryParams.end), new Date(queryParams.start))

        if (daysDistance > 59) {
            queryParams.timeframe = 'month'
        } else if (daysDistance < 2) {
            queryParams.timeframe = 'hours'
        }
    }

    return queryParams
}

function getCleanQueryParams2() {
    let queryParams: AnalyticsStatusV2 = getQueryParams() as ParsedQuery
    if (queryParams.timespan === 'custom') {
        queryParams.timespan = undefined
    }

    if (queryParams.dateFrom) {
        queryParams.dateFrom = new Date(queryParams.dateFrom).getTime()
    }

    if (queryParams.dateTo) {
        queryParams.dateTo = new Date(queryParams.dateTo).getTime()
    }

    return queryParams
}

function cleanData(data?: ApiStatistics[]) {
    let statistics: GenericStatistics[] | undefined = undefined
    const fallBackDate = new Date()

    if (data) {
        statistics = data.map(single => {
            let date = new Date(`${single.x.year || fallBackDate.getFullYear()}/${(single.x.month || fallBackDate.getMonth()) + 1}/${single.x.day || fallBackDate.getDay()}`)
            if (single.x.hours) {
                date.setHours(single.x.hours, 0, 0, 0)
            }

            return { date: date.getTime(), element: single.y }
        })
        statistics.sort((a, b) => a.date - b.date)
    }

    return statistics
}

export function getOrderStatistics(brandId: string, mode: 'revenue' | 'count', config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams('date', true)
    const { data, error, mutate } = useSWR<ApiStatistics[], { error: string }>(`/statistics/orders${transformToQueryParams('string', { brandId, mode, ...queryParams, ...config })}`)
    const statistics = cleanData(data)

    return { statistics, isLoading: !data && !statistics && !error, error, mutate }
}

export function getExperienceStatistics(brandId: string, mode: 'amount' | 'count') {
    const queryParams = getCleanQueryParams('date', true)
    const { data, error, mutate } = useSWR<ApiStatistics[], { error: string }>(`/statistics/experience-recharge${transformToQueryParams('string', { brandId, mode, ...queryParams })}`)
    const statistics = cleanData(data)

    return { statistics, isLoading: !data && !statistics && !error, error, mutate }
}

export function getProductsStatistics(brandId: string, mode: 'revenue' | 'count') {
    const queryParams = getCleanQueryParams()
    const { data, error, mutate } = useSWR<{ x: string; y: number, z: string }[], { error: string }>(() => (queryParams.start || queryParams.end) ? `/statistics/products${transformToQueryParams('string', { brandId, mode, ...queryParams })}` : null)

    return { products: data, isLoading: !data && !error, error, mutate }
}

export function getStatistics(brandId: string, config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/mock${transformToQueryParams('string', { brandId, ...queryParams, ...config })}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getHomeStatistics(brandId: string, shopIds?: string[], config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/home${transformToQueryParams('string', { brandId, ...queryParams, ...config })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getDeliveryStatistics(brandId: string, shopIds?: string[], config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/delivery${transformToQueryParams('string', { brandId, ...queryParams, ...config })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getCashdeskStatistics(brandId: string, shopIds?: string[], config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/cashdesk${transformToQueryParams('string', { brandId, ...queryParams, ...config })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getTablesStatistics(brandId: string, shopIds?: string[], config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/tables${transformToQueryParams('string', { brandId, ...queryParams, ...config })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getTakeawayStatistics(brandId: string, shopIds?: string[], config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/takeaway${transformToQueryParams('string', { brandId, ...queryParams, ...config })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getExpressStatistics(brandId: string, shopIds?: string[], config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/express${transformToQueryParams('string', { brandId, ...queryParams, ...config })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getUsersStatistics(brandId: string) {
    const queryParams = getCleanQueryParams('date', true, true)
    const { data, error, mutate } = useSWR<ApiStatistics[], { error: string }>(`/statistics/users${transformToQueryParams('string', { brandId, ...queryParams })}`)
    const statistics = cleanData(data)

    return { statistics, isLoading: !data && !error && !statistics, error, mutate }
}

export function getTipsStatistics(brandId: string, mode: 'revenue' | 'count', config?: { [key: string]: string }) {
    const queryParams = getCleanQueryParams('date', true)
    const { data, error, mutate } = useSWR<ApiStatistics[], { error: string }>(`/statistics/tips${transformToQueryParams('string', { brandId, mode, ...queryParams, ...config })}`)
    const statistics = cleanData(data)

    return { statistics, isLoading: !data && !statistics && !error, error, mutate }
}

export function getCustomerStatistics(brandId: string, customerId: string, shopIds?: string[]) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/customer/${customerId}${transformToQueryParams('string', { brandId, ...queryParams })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getCustomers(brandId: string, limit: number, skip?: number, search?: string) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/chart/customers/${transformToQueryParams('string', {
        brandId,
        limit,
        skip,
        ...queryParams,
    })}${search ? '&match.name|surname.regex=' + search : ''}`)
    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function useRecentFiscalClosuresChart(params: Record<string, string | number>) {
    const queryParams = getCleanQueryParams2()
    const query = transformToQueryParams("string", { ...params, ...queryParams })
    const { data, error, mutate } = useSWR(`/statistics/v2/chart/recent-fiscal-closures${query}`)
    let closures: Closure[] = []
    let nClosures: number = 0

    if (data?.charts && data?.charts[0] && data?.charts[0].metrics && data?.charts[0].metrics[0] && data?.charts[0].metrics[0].data) {
        const metricData = data?.charts[0].metrics[0].data
        closures = metricData.rows.map((row: any) => {
            return {
                amount: row.amount,
                created: row.created,
                printerId: row.printerId,
                printerName: row.printerName,
                nReceipts: row.nReceipts,
                netAmount: row.netAmount,
                vat0: row.vat0,
                vat4: row.vat4,
                vat5: row.vat5,
                vat10: row.vat10,
                vat22: row.vat22,
            }
        })
        nClosures = metricData.nRows
    }

    return { closures, nClosures, isLoading: !data && !data && !error, error, mutate }
}

export function downloadFiscalityReport(brandId: string) {
    return new Promise<void>((resolve, reject) => {
        const queryParams = getCleanQueryParams2()
        const query = transformToQueryParams("string", { brandId, ...queryParams })

        request('get', `/statistics/v2/report/recent-fiscal-closures${query}`, undefined, undefined, undefined, 'blob')
            .then(data => {
                const date = new Date()
                downloadElement(window.URL.createObjectURL(data as any), `easylivery-fiscality-${formatDate(date, 'dd-MM-yyyy HH-mm')}.xlsx`)
                return resolve()
            })
            .catch(() => reject())
    })
}

export function getChart(brandId: string, chartId: string, granularity?: number, limit?: number, skip?: number, channel?: string, search?: string, shopIds?: string[]) {
    const queryParams = getCleanQueryParams2()
    const { data, error, mutate } = useSWR(`/statistics/v2/chart/${chartId}${transformToQueryParams('string',
        {
            brandId,
            granularity,
            limit,
            skip,
            channel,
            ...queryParams
        })}${search ? search : ''}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getChannelChart(brandId: string, granularity: number, shopIds?: string[]) {
    let queryParams: AnalyticsStatusV2 = getQueryParams() as ParsedQuery
    queryParams.channel && (queryParams.channel = undefined)
    queryParams.timespan === 'custom' && (queryParams.timespan = undefined)

    const { data, error, mutate } = useSWR(`/statistics/v2/chart/revenue-by-channel${transformToQueryParams('string', { brandId, granularity, ...queryParams })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`)

    return { data, isLoading: !data && !data && !error, error, mutate }
}

export function getChartReport(brandId: string, chartId: string, channel?: string, granularity?: number, limit?: number, skip?: number, shopIds?: string[],) {
    return new Promise<void>((resolve, reject) => {
        const queryParams = getCleanQueryParams2() as ParsedQuery

        request('get', `/statistics/v2/report/${chartId}${transformToQueryParams('string',
            {
                brandId,
                channel,
                granularity,
                limit,
                skip,
                ...queryParams,
            })}${shopIds && !queryParams.shopIds ? '&shopIds=' + [shopIds] : ''}`, undefined, undefined, undefined, 'blob')
            .then(data => {
                const date = new Date()
                downloadElement(window.URL.createObjectURL(data as any), `easylivery-orders-${formatDate(date, 'dd-MM-yyyy HH-mm')}.xlsx`)
                return resolve()
            })
            .catch(() => reject())
    })
}

export function getSelledProducts(brandId: string, currency?: Currencies) {
    const { products: productsCount, isLoading: isLoadingProductsCount, error: errorCount } = getProductsStatistics(brandId, 'count')
    const { products: productsRevenue, isLoading: isLoadingProductsRevenue, error: errorRevenue } = getProductsStatistics(brandId, 'revenue')
    const selledProducts: SelledProducts[] = []

    if (productsCount && productsRevenue) {
        productsCount.map(count => {
            const revenue = productsRevenue.find(revenue => revenue.x === count.x)
            if (revenue) {
                selledProducts.push({
                    name: count.x,
                    count: numberFormatter(count.y),
                    revenue: `${numberFormatter(revenue.y, undefined, true)}${getCurrency(currency)}`,
                    untouchedRevenue: revenue.y,
                    category: revenue.z
                })
            }
        })
        selledProducts.sort((a, b) => parseInt(b.count) - parseInt(a.count))
    }

    return { selledProducts, isLoading: isLoadingProductsCount || isLoadingProductsRevenue, error: errorCount || errorRevenue }
}

export function getCashDeskDailyOrders(brandId: string, shopId?: string) {
    const clearQueryParams = getCleanQueryParams(undefined, true, true)
    const queryParams = { ...clearQueryParams, shopId: clearQueryParams.shopId || shopId, brandId: (clearQueryParams.shopId || shopId) ? undefined : brandId, mode: 'count', timeframe: 'date', payed: 'payed' }
    const { data, error } = useSWR<CashDeskOrderStats[]>(`/statistics/calculate-orders${transformToQueryParams('string', { ...queryParams })}`)

    return { orders: data, isLoading: !data && !error }
}