import { createContext } from 'react'

import { GlobalContextStateInterface } from 'types'

export const initialGlobalContextState = {authenticated: null, userType: null, redirect: null, id: '', email: '', name: '', surname: '', brands: [], shops: [], addons: [], deviceUUID: null, allOrdersIsMutating: false, operator: undefined, hideSidebar: true, appUpdate: {available: false, isCritical: false}}

const GlobalContext = createContext<GlobalContextStateInterface>({
    ...initialGlobalContextState,
    updateGlobalContext: () => {return () => {/**/}}
})

export default GlobalContext