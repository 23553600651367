import { formatDate } from 'utils'
import { useMemo } from 'react'
import { useBrand } from 'utils/hooks'
import { useTranslate } from 'utils/hooks'
import cn from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'components/link'

import { Order, OrderV2 } from "types"

import { faQrcode, faPersonBiking, faTable } from '@fortawesome/pro-regular-svg-icons'

type Props = {
    single: Order | OrderV2
    close: (state: boolean) => void
}

export default function SingleNotification({ single, close }: Props) {
    const { t } = useTranslate()
    const { id } = useBrand()
    const { orderType, url } = useMemo(() => {
        let orderType = ''
        let url = ''

        if (single.orderType === 'delivery') {
            if (single.deliveryInfo.homeDelivery) {
                url = `/brand/${id}/orders/delivery?selectedOrder=${single._id}`
                orderType = t('Delivery')
            } else {
                url = `/brand/${id}/orders/takeaway?selectedOrder=${single._id}`
                orderType = t('Takeaway')
            }
        } else if (single.orderType === 'express') {
            orderType = t('Ordina e Paga')
            url = `/brand/${id}/orders/express?selectedOrder=${single._id}`
        } else if (single.orderType === 'table' && (single?.saloonInfo && single?.saloonInfo.askBill)) {
            orderType = t('Sala')
            url = `/brand/${id}/hall-manager/order/${single._id}/?displayType=askBill`
        } else if (single.orderType === 'table') {
            orderType = t('Sala')
            url = `/brand/${id}/hall-manager/order/${single._id}`
        } else if (single.orderType === 'cashDesk') {
            orderType = t('Cassa')
            url = `/brand/${id}/orders/cashdesk?selectedOrder=${single._id}`
        } else if (single.orderType === 'experience') {
            orderType = t('Evento')
            url = `/brand/${id}/orders/single/${single._id}`
        }

        if (['delivery', 'express'].indexOf(single.orderType) > -1 && single.status === 'canceled') {
            url = `/brand/${id}/orders/single/${single._id}`
        }

        if (single.status === 'canceled') {
            orderType = t('Annullato')
            url = `/brand/${id}/orders/single/${single._id}`
        }

        return { orderType, url }
    }, [single])

    const formattedDate = new Date(single.created).toLocaleString([], {
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    })

    const saloons = useMemo(() => single.orderType === 'table' ? [...new Set((single as OrderV2).saloonInfo?.saloonElement?.map(element => element.saloon?.name)) as any].join(', ') : '', [single])
    const labelAskBill = single?.payed === "payed" ? t("Pagato conto ordine") : t('Richiesta conto ordine')

    return (
        <Link href={url} className="flex flex-row justify-between cursor-pointer hover:bg-gray-200">
            <div className="flex flex-row gap-4 md:mx-5 my-3 items-center">
                <div className={cn('w-10 h-10 md:h-16 md:w-16 flex rounded-full items-center justify-center', { 'bg-primary': single.status !== 'canceled', 'bg-red-600': single.status === 'canceled' })}>
                    <FontAwesomeIcon size='lg' icon={(single.orderType === 'express') ? faQrcode : single.orderType === 'table' ? faTable : faPersonBiking} className="text-white" />
                </div>
                <div className="flex flex-col justify-center">
                    <p className="font-medium text-xl">{orderType}</p>
                    <div className="flex flex-row gap-1">
                        {single.orderType === 'table' && single.orderOuting.length > 0 && (single as Order).cart.reduce((acc, item) => acc + (!item.isCover && item.status !== 'waiting' ? 1 : 0), 0) > 0 ?
                            <p className="font-medium text-gray-500 truncate">{single.status !== 'canceled' && t('Nuova uscita')}</p> :
                            single.orderType === 'table' && !single.deliveryInfo.watched && single?.saloonInfo && single?.saloonInfo.askBill ?
                                <p className="font-medium text-gray-500 truncate">{single.status !== 'canceled' && labelAskBill}</p> :
                                <p className="font-medium text-gray-500 truncate">{single.status !== 'canceled' && t('Nuovo ordine')}</p>
                        }
                        <p className="uppercase font-medium text-gray-500 truncate">{single.orderType === 'table' ? `${saloons} - ${(single as OrderV2).saloonInfo?.saloonElement?.map(element => element.name)?.join(', ') || (single as OrderV2).saloonInfo?.saloonElementName}` : `#${single.code}`}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-center justify-end gap-2 sm:mr-5">
                <p className="text-xs text-gray-500">{formattedDate}</p>
                <div className={cn('h-10 w-2 rounded-lg my-3', { 'bg-blue-500': single.status !== 'canceled', 'bg-red-600': single.status === 'canceled' })} />
            </div>
        </Link>
    )
}