import { addNotification, getRealCartItemPrice, isCapacitor, sumAllPaymentAmount } from 'utils'
import { taxType } from './data'

import { t } from 'i18next'
import { Cart, CashDeskCartV2, CashDeskPaymentModeV2, CustomPayment, FiscalPrinterDailyClose, Mutate, Order, OrderTaxation, OrderV2, Payment, PaymentAmount, Piva, Printer, PrinterError, PrinterResponse, ValidTax } from 'types'

export function connectFiscalPrinter(printer: Printer) {
    // @ts-ignore
    createPrinter({
        "idPrinter": printer._id,
        "name": printer.name,
        "model": 21,
        "remote": true,
        "ip": printer.ip,
        "port": typeof printer.port === 'string' ? printer.port.toString() : printer.port,
        "ssl": printer.ssl,
        "mode": 1,
        "width": 0,
        "printMode": {
          "fis": 1,
          "list": 0,
          "nfis": 1,
          "text": 0
        },
        "departments": printer.departments
    }, '/js/printers')
}

type PrintPaymentParams = {
    order: Order,
    payment: Payment,
    deviceUUID: string,
    fiscalPrinter: Printer,
    successCallback: (res?: PrinterResponse) => void,
    errorCallback: Function
}

export async function printPayment(params: PrintPaymentParams) {
    if (params.order.status === "canceled") return await fiscalPrint('delete', params.deviceUUID, params.order, {paymentId: params.payment._id, paymentAmount: params.payment.paymentAmount, paymentMode: params.order.paymentMode, shares: params.payment.shares}, undefined, params.fiscalPrinter, params.successCallback, params.errorCallback)
    return await fiscalPrint('create', params.deviceUUID, params.order, {paymentId: params.payment._id, paymentAmount: params.payment.paymentAmount, paymentMode: params.order.paymentMode, shares: params.payment.shares}, undefined, params.fiscalPrinter, params.successCallback, params.errorCallback)
}

export async function sendPrintJob(type: 'create' | 'delete' | 'reprint' | 'invoice' | 'nonFiscal' | 'courtesy', deviceUUID: string | null, order: Order | OrderV2, payment?: {paymentId: string, paymentAmount: PaymentAmount, customPayments?: CustomPayment[], paymentMode: CashDeskPaymentModeV2, taxValue?: ValidTax, causal?: string, pivaInfo?: Piva, items?: CashDeskCartV2[], shares?: number[], cartItems?: string[]}, fiscale?: OrderTaxation, selectedPrinter?: Printer, successCallback?: (res?: PrinterResponse) => void, errorCallback?: Function, mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{order: Order;}>, paymentId?: string) {
    const printer = selectedPrinter || order.shop.printers.find(singlePrinter => singlePrinter.isFiscal && singlePrinter.devices.find(device => device.uuid === deviceUUID))
    if(type === 'create' || type === 'invoice' || type === 'nonFiscal') {
        const discount = order ?
        order.promoType === 'fixedPlus' || order.promoType === 'percentagePlus'
        ? Math.abs(order.promoPrice) * -1
        : order.promoPrice
        : 0
        
        const articles = (!payment || (payment && (payment?.paymentMode === 'total' || payment.paymentMode === undefined))) ? order.cart.map(cartItem => ({description: cartItem.itemName, price: getRealCartItemPrice(cartItem, order.shop._id), vat: taxType.numbers[cartItem.itemTax || '10'], quantity: 1}))
        : payment.paymentMode === 'items' ?
        payment.items !== undefined  ?
        payment.items.map(cartItem => ({description: cartItem.itemName, price: getRealCartItemPrice(cartItem, order.shop._id), vat: taxType.numbers[cartItem.itemTax || '10'], quantity: 1}))
        : (order.cart as unknown as CashDeskCartV2[]).filter(item => item.payment?._id === payment.paymentId).map(cartItem => ({description: cartItem.itemName, price: getRealCartItemPrice(cartItem, order.shop._id), vat: taxType.numbers[cartItem.itemTax || '10'], quantity: 1}))
        :
        payment.paymentMode === 'share' ?
        payment.customPayments ? 
        payment.shares?.map((share, shareIndex) => ({description: payment.causal ? `${payment.causal} ${shareIndex + 1}` : `${t('Quota')} ${shareIndex + 1}`, price: share, vat: taxType.numbers[payment.taxValue || '10'], quantity: 1})) || []
        :
        [{description: payment.causal ? `${payment.causal} 1` : `${t(`Quota`)} 1`, price: sumAllPaymentAmount(payment.paymentAmount), vat: taxType.numbers[payment.taxValue || '10'], quantity: 1}]
        :
        payment.paymentMode === 'amount' ?
        payment.customPayments ?
        payment.customPayments?.map((customPayment, customPaymentIndex) => ({description: payment.causal ? `${payment.causal} ${customPaymentIndex + 1}` : `${t('Quota')} ${customPaymentIndex + 1}`, price: customPayment.price, vat: taxType.numbers[payment.taxValue || '10'], quantity: 1})) || []
        :
        [{description: payment.causal ? `${payment.causal} 1` : `${t(`Quota`)} 1`, price: sumAllPaymentAmount(payment.paymentAmount), vat: taxType.numbers[payment.taxValue || '10'], quantity: 1}]
        : 
        []
        
        if(order.deliveryInfo.homeDelivery && order.deliveryPrice > 0) {
            articles.push({description: 'Consegna', price: order.deliveryPrice, vat: 22, quantity: 1})
        }
        
        if (order.servicePrice > 0) {
            articles.push({description: 'Costo di servizio', price: order.servicePrice, vat: 22, quantity: 1})
        }
        
        
        const paymentMethods: {method: string; value: number}[] = [] 
        
        if((payment || order)?.paymentAmount?.cash > 0) {
            paymentMethods.push({method: 'contanti', value: (payment || order)?.paymentAmount.cash})
        }
        if((payment || order)?.paymentAmount?.bancomat > 0) {
            paymentMethods.push({method: 'bancomat', value: (payment || order)?.paymentAmount.bancomat})
        }
        if((payment || order)?.paymentAmount?.creditCard > 0 || (payment || order)?.paymentAmount?.applePay > 0 || (payment || order)?.paymentAmount?.googlePay > 0 || (payment || order)?.paymentAmount?.paypal > 0 || (payment || order)?.paymentAmount?.stripe > 0 || (payment || order)?.paymentAmount?.token > 0 || (payment || order)?.paymentAmount?.postePay > 0) {
            paymentMethods.push({method: 'carta', value: ((payment || order)?.paymentAmount?.creditCard || 0) + ((payment || order)?.paymentAmount?.applePay || 0) + ((payment || order)?.paymentAmount?.googlePay || 0) + ((payment || order)?.paymentAmount?.paypal || 0) + ((payment || order)?.paymentAmount?.stripe || 0) || ((payment || order)?.paymentAmount?.token || 0) + ((payment || order)?.paymentAmount?.postePay || 0)})
        }
        if((payment || order)?.paymentAmount?.ticket > 0) {
            paymentMethods.push({method: 'ticket', value: (payment || order)?.paymentAmount.ticket})
        }
        if((payment || order)?.paymentAmount?.sepa > 0) {
            paymentMethods.push({method: 'bonifico', value: (payment || order)?.paymentAmount.sepa})
        }
        if((payment || order)?.paymentAmount?.notCollected > 0) {
            paymentMethods.push({method: 'non_riscosso', value: (payment || order)?.paymentAmount.notCollected})
        }

        let customerEmail = undefined
        if(order.deliveryInfo?.orderUserInfo?.email && order.deliveryInfo?.orderUserInfo?.email !== 'undefined') {
            customerEmail = order.deliveryInfo?.orderUserInfo?.email
        } else if(order.user?.email && order.user?.email !== 'undefined') {
            customerEmail = order.user.email
        }
        
        // @ts-ignore
        window[`world_printer_${printer._id}`].print({
            "printMode": (type === 'invoice' || type === 'nonFiscal') ? "nfis" : "fis",
            paymentMethods,
            articles,
            "deposits": [],
            discount,
            "notes": `\n--------------------------------------\n${order.shop?.name?.replace(/[^a-zA-Z0-9 ]/g, '')}\n${order.orderType !== 'table' ? `ORDINE: ${order.code?.toLocaleUpperCase()}` : `TAVOLO: ${isOrderV2(order) ? order.saloonInfo.saloonElementName : order.deliveryInfo.tableName}`}${order.pickupMode ? `\nOPZIONE: ${order.pickupMode}` : ''}${order.notes && order.notes.length > 0 ? `\nNOTE: ${order.notes}` : ''}`,
            "notesFontSize": 3,
            "timestamp": Date.now(),
            "codeType": "",
            "codeText": "",
            "text": "",
            "lotteryCode": "",
            "singleUseVouchers": [],
            "vatCode": payment?.pivaInfo?.vat,
            "vatName": payment?.pivaInfo?.name,
            "vatAddress": payment?.pivaInfo?.address?.street,
            "vatCity": payment?.pivaInfo?.address?.city,
            "vatProvince": payment?.pivaInfo?.address?.province,
            "vatCap": payment?.pivaInfo?.address?.cap,
            "vatPec": payment?.pivaInfo?.pec,
            "vatUniqueCode": payment?.pivaInfo?.uniqueCode,
            "eticket": printer?.isETicketEnabled ? {customerEmail} : undefined
        },
        (res: PrinterResponse) => {
            if(printer!.supportDrawer && printer!.openDrawerAfterPayment) {
                openFiscalPrintDrawer(printer!)
            }
            
            if(successCallback) {
                successCallback(res)
            }
        },
        (error: PrinterError) => {
            let errorMsg = ""
            switch (error.code) {
                case "EPTR_REC_EMPTY":
                    errorMsg = "Si è verificato un errore, sportello stampante aperto o carta esaurita"
                    break
                default:
                    errorMsg = "Si è verificato un errore con la stampa fiscale, riprova"
            }
            
            if(errorCallback) {
                errorCallback(error)
            }
            addNotification(errorMsg, "danger")
        })
    } else if(type === 'delete') {
        const fiscaleDate = fiscale?.created ? new Date(fiscale?.created) : null
        if(fiscaleDate) {
            // @ts-ignore
            window[`world_printer_${printer._id}`].receiptCancellationAutomatic(fiscale.progressivo, fiscaleDate.getFullYear(), `${fiscaleDate.getMonth() + 1}`.padStart(2, '0'), `${fiscaleDate.getDate()}`.padStart(2, '0'),
            () => {
                if(successCallback) {
                    successCallback()
                }
            })
        } else {
            addNotification('Si è verificato un errore con la stampa fiscale, riprova', 'danger')
        }
    } else if(type === 'courtesy') {// @ts-ignore
        window[`world_printer_${printer._id}`].print({
            "printMode": 'nfis',
            "paymentMethods": [],
            "articles": order.cart.map(cartItem => ({description: cartItem.itemName, price: getRealCartItemPrice(cartItem, order.shop._id), vat: taxType.numbers[cartItem.itemTax], quantity: 1})),
            "deposits": [],
            "discount": 0,
            "notes": `\n--------------------------------------\n${order.shop?.name?.replace(/[^a-zA-Z0-9 ]/g, '')}\nORDINE: ${order.orderType !== 'table' ? `${order.code?.toLocaleUpperCase()}` : order.deliveryInfo.tableName}${order.pickupMode ? `\nOPZIONE: ${order.pickupMode}` : ''}${order.notes && order.notes.length > 0 ? `\nNOTE: ${order.notes}` : ''}`,
            "notesFontSize": 3,
            "timestamp": Date.now(),
            "codeType": "",
            "codeText": "",
            "text": "",
            "lotteryCode": "",
            "singleUseVouchers": [],
            "courtesy": true
        },
        (res: PrinterResponse) => {
            if(successCallback) {
                successCallback(res)
            }
        },
        () => {
            if(errorCallback) {
                errorCallback()
            } else {
                addNotification('Si è verificato un errore con la stampa di cortesia, riprova', 'danger')
            }
        })
    } else if(type === 'reprint') {
        // non funziona
        const fiscaleDate = fiscale?.created ? new Date(fiscale.created) : null
        if(fiscaleDate) {
            // @ts-ignore
            window[`world_printer_${printer._id}`].printReceiptByNumber(fiscale.progressivo.split('-')[1], fiscaleDate.getFullYear(), `${fiscaleDate.getMonth() + 1}`.padStart(2, '0'), `${fiscaleDate.getDate()}`.padStart(2, '0'), () => { })
        }
    }
}

async function checkIsPrinterAvailable(printer: Printer) {
    let attempt = 0
    while (attempt <= 5) {
        // @ts-ignore 
        const connected = window[`world_printer_${printer._id}`] !== undefined
        if (!connected) {
            attempt++
            await new Promise<void>((resolve) => setTimeout(async () => resolve(), 500 * attempt))
            connectFiscalPrinter(printer)
        } else {
            return true
        }
    }

    return false
}

export async function fiscalPrint(type: 'create' | 'delete' | 'reprint' | 'invoice' | 'nonFiscal' | 'courtesy', deviceUUID: string | null, order: Order | OrderV2, payment?: {paymentId: string, paymentAmount: PaymentAmount, customPayments?: CustomPayment[], paymentMode: CashDeskPaymentModeV2, taxValue?: ValidTax, causal?: string, pivaInfo?: Piva, items?: CashDeskCartV2[], shares?: number[]}, fiscale?: OrderTaxation, selectedPrinter?: Printer, successCallback?: (res?: PrinterResponse) => void, errorCallback?: Function, mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{order: Order;}>, paymentId?: string) {
    if(isCapacitor && order) {
        const printer = selectedPrinter || order.shop.printers.find(singlePrinter => singlePrinter.isFiscal && singlePrinter.devices.find(device => device.uuid === deviceUUID))
        if(printer) {
            // @ts-ignore  
            if(window[`world_printer_${printer._id}`] === undefined) {
                connectFiscalPrinter(printer)
            }

            const connected = await checkIsPrinterAvailable(printer)

            if (connected) await sendPrintJob(type, deviceUUID, order, payment, fiscale, selectedPrinter, successCallback, errorCallback, mutate, paymentId)
        }
    }
}

function isOrderV2(obj: any): obj is OrderV2 {
    return 'saloonInfo' in obj
}

export function dailyFiscalPrinterClose(printer: Printer, callback?: (res: FiscalPrinterDailyClose) => void, errorCallBack?: Function, retryCount?: number) {
    if(printer && isCapacitor) {
        // @ts-ignore
        if(window[`world_printer_${printer._id}`]) {
            // @ts-ignore
            window[`world_printer_${printer._id}`].execDailyReport()
            // @ts-ignore
            window[`world_printer_${printer._id}`].execDailyClosure(callback)
        } else if(!retryCount || (retryCount && retryCount < 3)) {
            connectFiscalPrinter(printer)
            setTimeout(() => dailyFiscalPrinterClose(printer, callback, errorCallBack, retryCount ? retryCount + 1 : 1), 100)
        } else if(errorCallBack) {
            errorCallBack()
        } else {
            addNotification('Si è verificato un errore con la chiusura fiscale, riprova', 'danger')
        }
    }
}

export function openFiscalPrintDrawer(printer: Printer, retryCount?: number) {
    if(printer && isCapacitor) {
        // @ts-ignore
        if(window[`world_printer_${printer._id}`]) {
            // @ts-ignore
            window[`world_printer_${printer._id}`].openDrawer()
        } else if(!retryCount || (retryCount && retryCount < 3)) {
            connectFiscalPrinter(printer)
            setTimeout(() => openFiscalPrintDrawer(printer, retryCount ? retryCount + 1 : 1), 100)
        } else {
            addNotification(`Si è verificato un errore con l'apertura del cassetto, riprova`, 'danger')
        }
    }
}