import { getToken } from 'api/authentication'

export default async function request<T = {}>(method: 'get' | 'post' | 'put' | 'delete', url: string, body?: any, file?: boolean, disableDefaultApiHost?: boolean, responseMethod?: 'json' | 'blob' | 'text') {
    let headers: any = !disableDefaultApiHost ? {Authorization: `Bearer ${await getToken()}`} : {}
    if(!file) {
        // @ts-ignore
        headers['Content-Type'] = 'application/json'
    }

    return new Promise<T>((resolve, reject) => {
        fetch(!disableDefaultApiHost ? process.env.NEXT_PUBLIC_API_HOST + url : url, {method, headers, body: !file ? JSON.stringify(body) : body})
        .then(async res => {
            let data;
            switch(responseMethod) {
                case 'blob': data = await res.blob(); break;
                case 'text': data = await res.text(); break;
                default: data = await res.json();
            }

            if (!res.ok) {
                if(data?.error === 'Unauthorized') {
                    dispatchEvent(new Event("unauthorizedLogout"))
                } else {
                    return reject(data)
                }
            }

            return resolve(data)
        })
        .catch(err => reject(err))
    })
}

export async function fetcher(url: string, disableDefaultApiHost?: boolean) {
    const headers: any = !disableDefaultApiHost ? {Authorization: `Bearer ${await getToken()}`} : {}
    const res = await fetch(!disableDefaultApiHost ? process.env.NEXT_PUBLIC_API_HOST + url : url, {headers})

    if(!res.ok) {
        const error: any = new Error('API error')
        error.info = await res.json()

        if(error.info?.error === 'Unauthorized') {
            dispatchEvent(new Event("unauthorizedLogout"))
        }

        error.status = res.status
        throw error
    }

    return await res.json()
}