import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'
import { addNotificationDeviceToken } from 'api/notifications'
import { removeFromStorage, getFromStorage, isElectron, saveToStorage } from 'utils'
import { Capacitor } from '@capacitor/core'
import { PushNotifications, Token } from '@capacitor/push-notifications'

let serviceWorker: ServiceWorkerRegistration | null = null

export async function getServiceWorker() {
    await navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
    .then(registration => {
        if(registration) {
            serviceWorker = registration
        }
    })
}

export async function getNotificationToken(noElectron?: boolean) {
    return await (isElectron && !noElectron ? getFromStorage('electronNotificationToken') : getFromStorage('notificationToken'))
}

export async function setNotificationToken(token: string) {
    await saveToStorage('notificationToken', token)
}

export async function removeNotificationToken() {
    await removeFromStorage('notificationToken')
}

export async function activateNotifications() {
    if(isElectron && await getNotificationToken()) {
        const electronNotificationToken = await getNotificationToken()
        if(electronNotificationToken && electronNotificationToken !== await getNotificationToken(true)) {
            await registerNotificationToken(electronNotificationToken)
        }
    } else if(Capacitor.isNativePlatform()) {
        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
              PushNotifications.register()
            }
        })
    } else if(firebase.messaging.isSupported()) {
        if(!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
                authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
                databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
                projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
                storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
                measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
            })
        }
    
        const messaging = firebase.messaging()
        
        if(messaging) {
            messaging.getToken({vapidKey: process.env.NEXT_PUBLIC_FIREBASE_WEB_PUSH_CERT})
            .then(async token => await registerNotificationToken(token))
            .catch(() => console.warn('Unable to get permission to notify'))
            
            messaging.onMessage(async payload => {
                if(!serviceWorker) {
                    await getServiceWorker()
                }

                // @ts-ignore
                serviceWorker?.showNotification(payload.data.title, {body: payload.data.body, timestamp: Date.now(), icon: payload.data.icon, silent: false, data: payload.data.link, requireInteraction: true})
            })
        }
    }
}

if(Capacitor.isNativePlatform()) {
    PushNotifications.addListener('registration', async (token: Token) => {
        await registerNotificationToken(token.value)
    });
    
    // PushNotifications.addListener('pushNotificationReceived',(notification: PushNotificationSchema) => {
    //     alert('Push received: ' + JSON.stringify(notification));
    // });

    // PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
    //     alert('Push action performed: ' + JSON.stringify(notification));
    // });
}

export async function registerNotificationToken(token: string) {
    if(token !== await getNotificationToken(true)) {
        addNotificationDeviceToken(token)
        .then(async () => await setNotificationToken(token))
        .catch(() => console.error('Error uploading notification device token'))
    }
}