import cn from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { WithChildren } from 'types'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { MouseEventHandler } from 'react'

const sizes = {
    small: 'py-1 px-2.5 text-sm',
    medium: 'py-1.5 px-3 text-sm',
    normal: 'py-1.5 px-4',
    big: 'py-4 px-6 text-md',
    none: ''
}

const loadingSizes = {
    small: 'text-md',
    medium: 'text-md',
    normal: 'text-lg',
    big: 'text-xl',
    none: 'text-base'
}

const colors = {
    none: 'bg-white text-standard hover:text-gray-500 focus:border-primary focus:ring-primary-lighter',
    primary: 'bg-primary text-white hover:bg-primary-dark border-primary hover:border-primary-dark focus:border-primary-dark focus:border-primary-dark focus:ring-primary-lighter active:bg-primary-dark',
    red: 'bg-red-600 text-white border-red-600 focus:ring-red-300 focus:border-red-800 hover:bg-red-800 hover:border-red-800',
    green: 'bg-green-600 text-white border-green-600 focus:ring-green-300 focus:border-green-600 hover:bg-green-600 hover:border-green-600'
}

type Props = {
    type?: 'submit' | 'reset' | 'button'
    color?: 'none' | 'primary' | 'red' | 'green'
    size?: 'small' | 'medium' | 'normal' | 'big' | 'none'
    disabled?: boolean
    loading?: boolean
    className?: string
    noRounded?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    noBorder?: boolean
}

export default function Button({children, type = 'button', color = 'none', size = 'normal', noRounded, disabled, loading, className, onClick, noBorder}: WithChildren<Props>) {
    return(
        <button disabled={disabled || loading} type={type} className={cn('focus:outline-none focus:ring transition', {'border': !noBorder}, {[sizes[size]]: size}, {[colors[color]]: color}, {'rounded-lg': !noRounded}, {'opacity-25 cursor-not-allowed': disabled}, {'bg-gray-300 border-gray-300': disabled && color === 'none'}, className)} onClick={onClick}>
            {loading && <FontAwesomeIcon icon={faSpinnerThird} className={cn('mr-1 animate-spin', {[loadingSizes[size]]: size})}/>} {children}
        </button>
    )
}