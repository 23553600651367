import cn from 'classnames'

import { ErrorMessage as DefaultErrorMessage } from 'formik'

type Props = {
    name?: string
    overrideError?: string
    className?: string
}

export default function ErrorMessage({name, overrideError, className}: Props) {
    return !overrideError && name ? (
        <DefaultErrorMessage name={name}>
            {message => message !== 'required' && <p className={cn('mt-1 text-red-600 text-xs', className)}>{message}</p>}
        </DefaultErrorMessage>
    ) : overrideError && typeof overrideError === 'string' ? (
        <p className={cn('mt-1 text-red-600 text-xs', className)}>{overrideError}</p>
    ) : null
}