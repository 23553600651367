import cn from 'classnames'
import { useMemo } from 'react'
import { useTranslate } from 'utils/hooks'

import { Listbox } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field } from 'formik'
import ErrorMessage from 'components/field/errorMessage'

import { ValueLabel, WithChildren } from 'types'
import { FieldAttributes, FieldProps } from 'formik'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export interface SelectProps extends FieldAttributes<any> {
    overrideValue?: string
    valueLabel?: string | React.ReactNode
    options: ValueLabel[]
    disabled?: boolean
    label?: string
    placeholder?: string
    noOptions?: string
    className?: string
    disableFormik?: boolean
    onChange?: (option: any) => void
    name?: string
    value?: string
    onBlur?: () => void
    hasError?: boolean
    containerClassName?: string
    wrapperClassName?: string
    optionClassName?: string
    position?: 'up' | 'down'
    disableShowSelected?: boolean
}

export default function Select({disableFormik, options, overrideValue, onChange, ...props}: SelectProps) {
    const memoOptions = useMemo(() => options, [options])

    return !disableFormik ? (
        <Field {...props}>
            {({form: { setFieldValue, setFieldTouched, submitCount }, field: { value, name }, meta: { error, touched }}: FieldProps<any>) => {
                const hasError = error && (touched || submitCount > 0) 

                return(
                    <SelectComponent {...props} options={memoOptions} value={value} onChange={option => onChange ? onChange(option) : setFieldValue(name, option.value)} onBlur={() => setFieldTouched(name, true, false)} hasError={hasError as boolean}/>        
                )
            }}
        </Field>
    ) : (
        <SelectComponent {...props} onChange={onChange} value={overrideValue} options={memoOptions}/>        
    )
}

function SelectComponent({name, value, onChange, onBlur, hasError, label, valueLabel, disabled, disableShowSelected, options, position = 'down', placeholder = 'Seleziona', noOptions = 'Nessuna opzione', className, containerClassName, wrapperClassName, optionClassName}: SelectProps) {
    const { t } = useTranslate()
    return(
        <Listbox as="div" className={cn('space-y-1 ', containerClassName)} value={value} onChange={(option: any) => onChange && onChange(option)} onBlur={() => onBlur && onBlur()}>
            {({open}) => (
                <>
                    {label && <Listbox.Label className="label">{label}</Listbox.Label>}
                    <div className={cn('relative', wrapperClassName)}>
                        <span className="inline-block w-full rounded-lg">
                            {/* @ts-ignore */}
                            <Listbox.Button disabled={disabled} className={cn('bg-white cursor-pointer relative w-full text-sm rounded-lg border pl-3 pr-10 py-0.5 leading-8 text-left focus:outline-none  transition focus:ring', {'bg-gray-100 cursor-not-allowed': disabled}, {' focus:border-primary focus:ring-primary-lighter': !hasError}, {'border-red-600 focus:ring-red-300': hasError}, className)}>
                                <span className={cn('block truncate', {'text-gray-400': !valueLabel && !value, 'text-red-600 opacity-50': hasError})}>{valueLabel || value || t(placeholder)}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor" >
                                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                {hasError && (
                                    <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="h-4 fa-w-18 text-red-600"/>
                                    </div> 
                                )}
                            </Listbox.Button>
                        </span>
                        {open && !disabled && (
                            <div className={cn('absolute z-10 w-full border rounded-lg bg-white', {'bottom-11': position === 'up', 'mt-1': position === 'down'})}>
                                <Listbox.Options static className="max-h-60 rounded-lg py-1 text-base leading-6 overflow-auto focus:outline-none">
                                    {options && options.length > 0 ? (
                                        options.map((option, index) => (
                                            <Listbox.Option key={option.value || index} value={option as any}>
                                                {({selected, active}) => (
                                                    <div className={cn('cursor-pointer text-sm select-none focus:outline-none relative py-2 px-3', {'text-white bg-primary': active, 'text-standard': !active})}>
                                                        <span className={cn('block truncate', {'pl-6': !disableShowSelected, 'font-semibold': selected, 'font-normal': !selected}, optionClassName)}>{option.label}</span>
                                                        {selected && !disableShowSelected && (
                                                            <span className={cn('absolute inset-y-0 left-0 flex items-center pl-2', {'text-white': active, 'text-primary': !active})}>
                                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </Listbox.Option>
                                        ))
                                    ) : (
                                        <p className="text-center  py-6">{t(noOptions)}</p>
                                    )}
                                </Listbox.Options>
                            </div>
                        )}
                        {name && <ErrorMessage name={name}/>}
                    </div>
                </>
            )}
        </Listbox>
    )
}

export interface SelectWithChildrenProps {
    value: string | undefined
    label?: string
    placeholder?: string
    className?: string
    optionsClassName?: string
    containerClassName?: string
    wrapperClassName?: string
    position?: 'up' | 'down'
    disabled?: boolean
    error?: string | string[]
}

export function SelectWithChildren({value, label, position = 'down', placeholder = 'Seleziona', className, containerClassName, wrapperClassName, disabled, children, optionsClassName, error}: WithChildren<SelectWithChildrenProps>) {
    const hasError = error !== undefined
    const { t } = useTranslate()
    return(
        <Listbox as="div" className={cn('space-y-1', containerClassName)} value={value} onChange={() => {}}>
            {({open}) => (
                <>
                    {label && <Listbox.Label className="label">{label}</Listbox.Label>}
                    <div className={cn('relative', wrapperClassName)}>
                        <span className="inline-block w-full rounded-lg">
                            {/* @ts-ignore */}
                            <Listbox.Button disabled={disabled} className={cn('cursor-pointer relative w-full text-sm rounded-lg border pl-3 pr-10 py-0.5 leading-8 text-left focus:outline-none  transition focus:ring focus:border-primary focus:ring-primary-lighter', {'bg-gray-100 cursor-not-allowed': disabled, 'border-red-600 focus:ring-red-300': hasError}, className)}>
                                <span className={cn('block truncate', {'text-gray-400': !value})}>{value || t(placeholder)}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor" >
                                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                {hasError && (
                                    <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="h-4 fa-w-18 text-red-600"/>
                                    </div> 
                                )}
                            </Listbox.Button>
                        </span>
                        {open && !disabled && (
                            <div className={cn('absolute z-10 w-full border rounded-lg bg-white', {'bottom-11': position === 'up', 'mt-1': position === 'down'})}>
                                <Listbox.Options static className={cn('max-h-60 rounded-lg py-1 text-base leading-6 overflow-auto focus:outline-none', optionsClassName)}>
                                    {children}
                                </Listbox.Options>
                            </div>
                        )}
                        {hasError && <ErrorMessage overrideError={error as string}/>}
                    </div>
                </>
            )}
        </Listbox>
    )
}