import request from 'api/request'
import { addNotification } from 'utils'

import { Notification } from 'types'

export function sendNotification(brandId: string, notification: Notification) {
    return new Promise<void>((resolve, reject) => {
        request('post', `/notification/create`, {...notification, status: 'sent', brandId})
        .then(() => resolve())
        .catch(() => {
            addNotification('Si è verificato un errore, riprova', 'danger')
            return reject()
        })
    })
}

export function addNotificationDeviceToken(token: string) {
    return new Promise<void>((resolve, reject) => {
        request('post', '/user/add-device-token', {deviceToken: token})
        .then(() => resolve())
        .catch(() => reject())
    })
}