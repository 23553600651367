import qs from "query-string";
import { useShops } from "utils/hooks";

import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslate } from 'utils/hooks';
import { transformToQueryParams } from 'utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button';
import Menu from 'components/menu';
import { Form, Formik } from 'formik';

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { SelectWithChildren } from "components/field/select";
import { CheckboxArray } from "components/field/checkbox";

export interface OperatorFilterShopsProps {
    size?: 'small' | 'medium' | 'normal' | 'big'
    className?: string
    menuClassName?: string
    containerMenuClassName?: string
}


export default function OperatorFilterShops({ className, containerMenuClassName, menuClassName, size = "medium" }: OperatorFilterShopsProps) {
    const { shops: allShops } = useShops()
    const { shops: queryShops } = qs.parse(location.search)
    const shops: string[] = queryShops ? (Array.isArray(queryShops) ? queryShops as string[] : [queryShops]) : allShops.map(item => item._id)

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
    const { t } = useTranslate()

    const shopOptions = useMemo(() => allShops.map(single => ({ value: single._id, label: single.name })), [shops])
    const populatedSelectedShops = useMemo(() => shops.map(single => shopOptions.find(singleShop => singleShop.value === (typeof single === 'string' ? single : single))?.label).join(', '), [shops])

    const menuContent = (
        <Formik initialValues={{ shops }} onSubmit={values => applyFilter(values)}>
            {({ setValues, errors, }) => (
                <Form className="pb-2 divide-y outline-none focus:outline-none divide-gray-300">
                    <div className="bg-gray-100 p-2 flex justify-between rounded-t-lg">
                        <Button size="small" onClick={() => setValues({ shops: [] })}>{t('Reset')}</Button>
                        <Button size="small" type="submit" color="primary">{t('Applica')}</Button>
                    </div>
                    <div className="px-2 pt-2 space-y-2">
                        <SelectWithChildren error={errors.shops} label={t("Negozi")} value={populatedSelectedShops} optionsClassName="divide-y">
                            <CheckboxArray name="shops" options={shopOptions} className="px-2 py-2" />
                        </SelectWithChildren>
                    </div>
                </Form>
            )}
        </Formik>
    )



    function applyFilter(values: any) {
        transformToQueryParams('redirect', values)
        setMenuIsOpen(false)
    }

    return (
        <Menu list={[{ children: menuContent }]} width="w-64" menuState={[menuIsOpen, setMenuIsOpen]} className={menuClassName} containerClassName={containerMenuClassName}>
            <Button color="primary" size={size} className={cn('flex items-center', className)} onClick={() => setMenuIsOpen(open => !open)}>
                <FontAwesomeIcon icon={faFilter} className="mr-2 text-md" />
                {t('Filtra')}
            </Button>
        </Menu>)

}
