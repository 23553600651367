import { checkShopManager, useAddon } from "./hooks"
import { faTable } from '@fortawesome/pro-regular-svg-icons'
import { Shop } from "types"
import i18n from './language'
import { useContext, useMemo } from "react"
import GlobalContext from "context"
import { getQueryParams } from 'utils'
import { ParsedQuery } from 'query-string'

export function createOrdersMenuWithAddon(id: string){
    const { deviceUUID, updateGlobalContext } = useContext(GlobalContext)
    const hasDeliveryAddon = useAddon('delivery').hasAddon
    const hasOrderAndPay = useAddon('order-pay').hasAddon
    const { shops } = checkShopManager()
    const hasEcommerce = shops.filter(single => single.shopType === 'ecommerce').length > 0
    const hasShopFiscal = shops.filter(single => single.addonFiscale !== undefined || single.printers.find(printer => printer.isFiscal && printer.devices.find(device => device.uuid === deviceUUID)) !== undefined).length > 0

    if(hasDeliveryAddon && hasOrderAndPay){
        if(hasEcommerce && hasShopFiscal){
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if(hasShopFiscal) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if(hasEcommerce) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            },{
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        }
    } else if(hasDeliveryAddon){
        if(hasEcommerce && hasShopFiscal){
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            },{
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if (hasShopFiscal) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if (hasEcommerce) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Delivery',
                href: `/brand/${id}/orders/delivery`
            }, {
                label: 'Takeaway',
                href: `/brand/${id}/orders/takeaway`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        }
    } else if(hasOrderAndPay){
        if(hasEcommerce && hasShopFiscal){
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if(hasShopFiscal) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if(hasEcommerce) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Ordina e Paga',
                href: `/brand/${id}/orders/express`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        }
    } else {
        if(hasEcommerce && hasShopFiscal){
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if(hasShopFiscal) {
            return [{
                label: 'Tutti',
                href: `/brand/${id}/orders/all`
            }, {
                label: 'Cassa',
                href: `/brand/${id}/orders/cashdesk`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else if(hasEcommerce) {
            return [{
                label: 'Ecommerce',
                href: `/brand/${id}/orders/ecommerce`
            }, {
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        } else {
            return [{
                label: 'Archivio',
                href: `/brand/${id}/orders/archive`
            }]
        }
    }
}

export function showSaloon(){
    const hasOrderTable = useAddon('table-order').hasAddon
    const hasTableBooking = useAddon('table-booking').hasAddon
     if(hasOrderTable || hasTableBooking){
        return true
     } else {
        return false
     }
}

export function showMap() {
    const queryParams = getQueryParams() as ParsedQuery
    const { shops } = checkShopManager()
    const enableAskBill = shops.filter(shop => shop._id === queryParams.shopId)[0]?.tables?.enableAskBill

    return enableAskBill
}

export function showManage(){
    const hasDeliveryAddon = useAddon('delivery').hasAddon
    const hasOrderAndPay = useAddon('order-pay').hasAddon
    const hasOrderTable = useAddon('table-order').hasAddon
    const hasTableBooking = useAddon('table-booking').hasAddon
     if(hasOrderTable || hasTableBooking || hasDeliveryAddon || hasOrderAndPay){
        return true
     } else {
        return false
     }
}

export function showManageCheckWarehouse(isShopManager: boolean, id: string, shops: Shop[] | undefined){
    const hasWarehouse = useAddon('warehouse').hasAddon
    const hasExperiences = useAddon('experience').hasAddon

    return [{
        label: 'Prodotti',
        href: `/brand/${id}/manage/products`
    }, hasWarehouse ? {
        label: 'Magazzino',
        href: `/brand/${id}/manage/warehouse`
    } : null,
    {
        label: shops && shops.length > 0 ? 'Negozi' : 'Crea negozio',
        href: shops && shops.length > 0 ? `/brand/${id}/manage/shops` : `/brand/${id}/manage/shops/create`
    }, hasExperiences ? {
        label: 'Eventi',
        href: `/brand/${id}/manage/events`
    } : null,
    !isShopManager ? {
        label: 'Team',
        href: `/brand/${id}/manage/team`
    }: null, 
    {
        label: 'Stampanti',
        href: `/brand/${id}/manage/printers`
    },
    {
        label: 'Fatturazione',
        href: `/brand/${id}/manage/invoicing`
    }]
}

export function showStats(isShopManager: boolean){
    const hasDeliveryAddon = useAddon('delivery').hasAddon
    const hasOrderAndPay = useAddon('order-pay').hasAddon
    const hasOrderTable = useAddon('table-order').hasAddon
    const hasTableBooking = useAddon('table-booking').hasAddon
    const hasFiscale = useAddon('fiscale').hasAddon
     
    if(hasOrderTable || hasTableBooking || hasDeliveryAddon || hasOrderAndPay){
        if(hasFiscale){
            return true
        } else {
            if(isShopManager){
                return false
            } else {
                return true
            }
        }
     } else {
        return false
     }
}

export function showStatsCheckFiscale(id: string, isShopManager: boolean){
    const hasFiscale = useAddon('fiscale').hasAddon
    
    if(hasFiscale){
        if(isShopManager){
            return [{
                label: 'Fiscale',
                href: `/brand/${id}/analytics/fiscality`
            }]
        } else {
            return [{
                label: 'Prodotti',
                href: `/brand/${id}/analytics/products`
            }, {
                label: 'Fatturato',
                href: `/brand/${id}/analytics/revenue`
            }, {
                label: 'Fiscale',
                href: `/brand/${id}/analytics/fiscality`
            }, {
                label: 'Ordini',
                href: `/brand/${id}/analytics/orders`
            }, {
                label: 'Utenti',
                href: `/brand/${id}/analytics/customers`
            }]
        }
    } else {
        return [{
            label: 'Prodotti',
            href: `/brand/${id}/analytics/products`
        }, {
            label: 'Fatturato',
            href: `/brand/${id}/analytics/revenue`
        }, {
            label: 'Ordini',
            href: `/brand/${id}/analytics/orders`
        }, {
            label: 'Utenti',
            href: `/brand/${id}/analytics/customers`
        }]
    }
}

export function showMarketing(){
    const hasDeliveryAddon = useAddon('delivery').hasAddon
    const hasOrderAndPay = useAddon('order-pay').hasAddon
    const hasOrderTable = useAddon('table-order').hasAddon
    const hasTableBooking = useAddon('table-booking').hasAddon
     if(hasOrderTable || hasTableBooking || hasDeliveryAddon || hasOrderAndPay){
        return true
     } else {
        return false
     }
}

export function showMarketingTab(id: string){
    const hasDeliveryAddon = useAddon('delivery').hasAddon
    
     if(hasDeliveryAddon){
        return [{
            label: 'App/Web',
            href: `/brand/${id}/marketing/app-web`
        }, {
            label: 'Promozioni',
            href: `/brand/${id}/marketing/promos`
        }, {
            label: 'Clienti',
            href: `/brand/${id}/marketing/customers`
        }]
     } else {
        return [{
            label: 'Promozioni',
            href: `/brand/${id}/marketing/promos`
        }, {
            label: 'Clienti',
            href: `/brand/${id}/marketing/customers`
        }]
     }
}