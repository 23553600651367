import { createContext } from 'react'

import { CashDeskContextV2StateInterface, CashDeskModeV2, CashDeskPaymentModeV2 } from 'types'

export const initiCashDeskDiscount = {
    type: null,
    notes: "",
    amount: 0
}

export const initialCashDeskContextStateV2 = {
    shopId: null, 
    isPayingMode: false,
    isCartViewMode: false,
    mode: 'items' as CashDeskModeV2, 
    categoryId: null, 
    draftOrders: [], 
    notes: '', 
    paymentMode: 'total' as CashDeskPaymentModeV2, 
    itemToConfigure: null, 
    cart: [],
    discount: {...initiCashDeskDiscount},
    pickupMode: undefined,
    shares: [
        {name: "Quota", price: 0, selected: false, isPayed: false, hasToBePayed: false},
        {name: "Quota", price: 0, selected: false, isPayed: false, hasToBePayed: false},
    ],
    customPayments: [],
    order: null,
    orderNumber: 0,
    tax: "10",
    updateTableOrder: false
}

const CashDeskContextV2 = createContext<CashDeskContextV2StateInterface>({
    ...initialCashDeskContextStateV2,
    addToCart: () => {return () => {/**/}},
    updateCashDeskContext: () => {return () => {/**/}}
})

export default CashDeskContextV2