import { useMediaQuery } from "react-responsive"
import { Order } from "types"

import { ModalHeader, ModalBody, ModalFooter } from 'components/modal'
import Modal from 'components/modal'
import Button from 'components/button'
import NotificationsLayout from 'components/notifications/notificationsLayout'
import { IconButton } from '@easylivery/components'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

type Props = {
    orders: Order[] | undefined
    onCloseRequest: () => void
}

export default function MobileNotifications({orders, onCloseRequest}: Props){
    const isMobile = useMediaQuery({query: '(max-width: 958px)'})
    
    return(
        <Modal width={958} isOpen={isMobile} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
            <ModalHeader title="Notifiche" className="flex items-center justify-between">
                <IconButton onClick={() => onCloseRequest()}>
                    <HighlightOffIcon className="!text-3xl"/>
                </IconButton>
            </ModalHeader>
            <ModalBody>
                <NotificationsLayout close={onCloseRequest} orders={orders}/>
            </ModalBody>
        </Modal>
    )
}