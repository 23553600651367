import DefaultModal from 'react-modal'
import ModalHeader from './_partials/modalHeader'
import ModalBody from './_partials/modalBody'
import ModalFooter from './_partials/modalFooter'

import { WithChildren } from 'types'
import { Props as ModalProps } from 'react-modal'

interface Props extends WithChildren<ModalProps> {
    width?: number
}

export default function Modal({children, width = 640, ...props}: Props) {
    DefaultModal.setAppElement('#__next')
    
    return(
        <DefaultModal shouldFocusAfterRender={false} shouldReturnFocusAfterClose={false} overlayClassName="fixed z-40 flex items-center justify-center overflow-hidden top-0 bottom-0 right-0 left-0" className="border sm:rounded-lg flex flex-col overflow-hidden relative bg-white" {...props}>
            {children}
            <style jsx global>{`
                .ReactModal__Overlay {background: rgba(0, 0, 0, 0.75) !important;}
                .ReactModal__Overlay * :focus {outline: 0;}
                .ReactModal__Body--open #__next {filter: blur(4px);}
                .ReactModal__Content {
                    width: ${width}px;
                    max-height: calc(100vh - 40px);
                }
                @media(max-width: ${width}px) {
                    .ReactModal__Content {
                        width: 100%;
                        max-height: 100%;
                        height: 100% !important;
                    }
                }
            `}</style>
        </DefaultModal>
    )
}

export { ModalHeader, ModalBody, ModalFooter }