import cn from 'classnames'
import Button from 'components/button'
import { useTranslate } from 'utils/hooks'

import { WithChildren } from 'types'

type Props = {
    title: string
    disableDefaultStyle?: boolean
    titleClassName?: string
    className?: string
    rightDisable?: boolean
    rightState?: boolean
    onRightClick?: () => void
    qrButton?: boolean
    onQrClick?: () => void
    selectPeopleTable?: boolean
}

export default function ModalHeader({title, disableDefaultStyle, titleClassName, className, children, rightDisable, rightState, onRightClick, qrButton, onQrClick, selectPeopleTable}: WithChildren<Props>) {
    const { t } = useTranslate()
    
    return(
        <div className={cn({'border-b p-5': !disableDefaultStyle}, {'flex flex-row justify-between': rightDisable}, className)}>
            {title && <h3 className={cn({'p-0 leading-5': !disableDefaultStyle, 'px-4 pt-3 pb-1': disableDefaultStyle}, titleClassName)}>{title}</h3>}
            {selectPeopleTable && <h5 className={cn('text-gray-400 mt-2 -mb-7 font-normal', titleClassName)}>{t('Seleziona i coperti')}</h5>}
            <div className="flex flex-row gap-4">
                {qrButton && <Button onClick={onQrClick} color="primary" className={cn({'-mt-2': !disableDefaultStyle, 'px-4 pt-3 pb-1': disableDefaultStyle}, titleClassName)}>QR</Button>}
                {rightDisable && <Button onClick={onRightClick} color={rightState ? "red" : "green"} className={cn({'-mt-2': !disableDefaultStyle, 'px-4 pt-3 pb-1': disableDefaultStyle}, titleClassName)}>{rightState ? t('Disattiva') : t('Attiva')}</Button>}
            </div>
            {children}
        </div>
    )
}