import React, { useContext, useMemo, useState } from 'react'
import { useAddon, useBrand, useShops } from 'utils/hooks'
import { useRouter } from 'next/router'
import { fallbackImage } from 'utils'
import { getMenuOptions } from 'utils/data'
import { logout } from 'api/authentication'

import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'components/link'
import Select from 'components/field/select'
import { NewFeature } from 'components/badge'

import GlobalContext from 'context'

import { MenuOption } from 'types'

import { faBars, faSignOut, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useTranslate } from 'utils/hooks'

export default function MobileMenu() {
    const { asPath } = useRouter()
    const { userType, brands, updateGlobalContext } = useContext(GlobalContext)
    const { shops } = useShops()
    const { brand, id } = useBrand()
    const { t } = useTranslate()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const brandOptions = useMemo(() => {
        const options = brands.map((single, index) => ({
            value: single._id,
            label: (
                <Link href={`/brand/${index}`} className="flex items-center space-x-2 text-standard hover:text-standard focus:no-underline">
                    <img className="bg-gray-200 w-5 h-5 rounded-full flex-shrink-0" src={fallbackImage(single.logo)} alt={single.name}/>
                    <span>{single.name}</span>
                </Link>
            )
        }))

        if(userType !== 'shopManager') {
            options.push({value: 'new', label: <Link className="text-standard hover:text-standard font-medium flex items-center focus:no-underline" href={`/brand/${brands.length}/setup`}><div className="w-5 h-5 text-center rounded-full bg-gray-200 mr-2">+</div> {t('Nuovo brand')}</Link>})
        }

        return options
    }, [brands])
    const options = useMemo(() => {
        return getMenuOptions(id, userType === 'shopManager', brand, shops, true).map((option, index) => option !== null && (!brand || canDisplayOption(option)) ? (
            <React.Fragment key={index}>
                <Link onClick={() => !option.isMobile ? setIsOpen(false) : undefined} href={option.href} exact={option.exact} activeClassName="bg-gray-200 font-medium" className="focus:no-underline group flex justify-between my-1 sm:my-0 text-standard hover:text-standard items-center p-1.5 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition">
                    <div className="flex items-center">
                        <div className="ml-0.5 mr-2 -mt-0.5">{option.icon}</div>
                        {t(option.label)}
                    </div>
                    {option.newFeature && <NewFeature/>}
                </Link>
                {asPath.toString().indexOf(option.href) > -1 && option.subPages.length > 0 && (
                    <ul className="pl-9 space-y-1">
                        {option.subPages.map((subOption, subIndex) => subOption && (
                            <li className="text-sm" key={subIndex}>
                                {subOption.isExternal ? (
                                    <a target="_blank" className="focus:no-underline group flex justify-between my-1 sm:my-0 text-standard hover:text-standard items-center p-1.5 py-1 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition" href={subOption.href}>{t(subOption.label)}</a>
                                ) : (
                                    <Link onClick={() => setIsOpen(false)} activeClassName="bg-gray-200 font-medium" className="focus:no-underline group flex justify-between my-1 sm:my-0 text-standard hover:text-standard items-center p-1.5 py-1 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition" href={subOption.href}>{t(subOption.label)}</Link>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </React.Fragment>
        ) : null)
    }, [id, brand, asPath])

    const brandSelect = (
        <Select position="up" className="bg-white" valueLabel={brand ? (
            <div className="flex items-center space-x-3">
                <img src={fallbackImage(brand.logo)} className="bg-gray-200 w-5 h-5 rounded-full flex-shrink-0"/>
                <p className="truncate">{brand.name}</p>
            </div>
        ) : 'Nuovo brand'} overrideValue={brand?._id} disableFormik options={brandOptions}/>
    )

    function canDisplayOption(option: MenuOption) {
        const { showOnlyWhen, permission } = option!
        const optionsAllowed = shops.length > 0 ? ['shops'] : []

        if (useAddon('cashDesk').hasAddon) {
            optionsAllowed.push('cashDesk')
        }
        
        if(useAddon('table-order').hasAddon) {
            optionsAllowed.push('table-order')
        }

        return (option!.showOnlyWhen.length === 0 || optionsAllowed.some(a => showOnlyWhen.indexOf(a) > -1)) && permission.indexOf(userType) > -1
    }
    
    return(
        <>
            <Button className="!py-1 !border-0 block 2sm:hidden" onClick={() => setIsOpen(oldState => !oldState)}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="!text-xl mt-1"/>
            </Button>
            {isOpen && (
                <div className="w-full h-(screen-layout-mobile) absolute bg-white top-[53.5px] p-4 z-10 -left-[8px]">
                    {options}
                    <div className="pt-4">
                        <label className="label">{t('Cambia brand')}</label>
                        {brandSelect}
                        <div className="focus:no-underline group cursor-pointer flex my-1 sm:my-0 space-x-2 mt-4 text-standard hover:text-standard items-center p-1.5 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition" onClick={() => logout(updateGlobalContext)}>
                            <FontAwesomeIcon icon={faSignOut} className="ml-1 mr-2 fa-w-18 h-4.5"/>
                            <p>{t('Logout')}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}