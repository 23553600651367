import { createContext } from 'react'

import { HallManagerContextState, HallManagerContextStateInterface, HallManagerMode } from 'types'

export const initialHallManagerContextState = {shopId: null, saloonId: null, saloons: [], mode: 'card' as HallManagerMode, filters: {name: '', orderAndPay: false, covers: -1, status: []} } as HallManagerContextState

const HallManagerContext = createContext<HallManagerContextStateInterface>({
    ...initialHallManagerContextState,
    updateHallManagerContext: () => {return () => {/**/}}
})

export default HallManagerContext