import { formatDate, getQueryParams, isAndroid, isAndroidOrIos, isCapacitor, isDevelopment, isIos, isStaging } from "utils";
import { subMonths, subWeeks } from "date-fns";
import { ParsedQuery } from "query-string";
import { createOrdersMenuWithAddon, showManageCheckWarehouse, showMarketingTab, showSaloon, showStats, showStatsCheckFiscale, showMap } from "./addon";
import { Brand, Category, Promo, Item, ItemType, Shop, Table, Card, CreditCardsType, MenuOption, AnalyticsStatus, AllergensData, GoogleMapsLibraries, Banner, TeamMember, GlobalContextStateOptional, ComponentCategory, Component, ComponentType, StocksStatus, Supplier, Saloon, EnableTableDataNew, HallStatus, Week, Printer, OperatorOrderStatus, OperatorOrderType, OperatorPermission, UserType, Experience, TicketType, AccessoryBulk, TokenType, Structure, AreaListType, AnalyticsStatusV2 } from "types";

import { colors } from "styles/variables";
import { PHASE_PRODUCTION_BUILD } from "next/dist/shared/lib/constants";
import { useRouter } from "next/router";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import TableRestaurantOutlinedIcon from "@mui/icons-material/TableRestaurantOutlined";

export const fallbackImage = "https://cdn.easylivery.it/fallback.jpg";

export const defaultShop = { value: "all", label: "Tutti i negozi" };

export const deliveryMethods = {
  valueLabel: [
    {
      value: "local",
      label: "Proprietario",
    },
    {
      value: "glovo",
      label: "Glovo",
    },
    {
      value: "poony",
      label: "Poony",
    },
  ],
  key: {
    local: "Proprietario",
    glovo: "Glovo",
    poony: "Poony",
  },
};

export const orderType = {
  valueLabel: [
    {
      value: "cashDesk",
      label: "Cassa",
    },
    {
      value: "ecommerce",
      label: "Ecommerce",
    },
    {
      value: "delivery",
      label: "Delivery",
    },
    {
      value: "takeAway",
      label: "Take Away",
    },
    {
      value: "table",
      label: "Tavolo",
    },
    {
      value: "express",
      label: "Ordina e Paga",
    },
    {
      value: "experience",
      label: "Evento",
    },
  ],
  key: {
    cashDesk: "Cassa",
    ecommerce: "Ecommerce",
    delivery: "Delivery",
    takeAway: "Take Away",
    table: "Tavolo",
    express: "Ordina e Paga",
    experience: "Evento",
  },
};

export const orderStatuses = {
  valueLabel: [
    {
      value: "all",
      label: "Tutti",
    },
    {
      value: "pending",
      label: "In attesa",
    },
    {
      value: "confirmed",
      label: "Confermato",
    },
    {
      value: "ready",
      label: "Pronto",
    },
    {
      value: "onDelivery",
      label: "In consegna",
    },
    {
      value: "delivered",
      label: "Consegnato",
    },
    {
      value: "canceled",
      label: "Annullato",
    },
    {
      value: "open",
      label: "Aperto",
    },
    {
      value: "closed",
      label: "Completato",
    },
  ],
  key: {
    pending: "In attesa",
    confirmed: "Confermato",
    ready: "Pronto",
    onDelivery: "In consegna",
    delivered: "Consegnato",
    canceled: "Annullato",
    open: "Aperto",
    payed: "Completato",
    closed: "Completato",
  },
  manageStatus: {
    valueLabel: [
      {
        value: "pending",
        label: "In attesa",
      },
      {
        value: "confirmed",
        label: "Da preparare",
      },
      {
        value: "ready",
        label: "Pronti",
      },
      {
        value: "onDelivery",
        label: "In consegna",
      },
    ],
    key: {
      pending: "In attesa",
      confirmed: "Da preparare",
      ready: "Pronti",
      onDelivery: "In consegna",
      delivered: "Consegnati",
      canceled: "Annullati",
      open: "Aperto",
      payed: "Completati",
      closed: "Completati",
    },
    list: ["pending", "confirmed", "onDelivery", "ready", "open"],
  },
  express: {
    valueLabel: [
      {
        value: "confirmed",
        label: "Da preparare",
      },
      {
        value: "ready",
        label: "Pronti",
      },
    ],
    key: {
      confirmed: "Da preparare",
      ready: "Pronti",
    },
    list: ["pending", "ready"],
  },
  cashDesk: {
    valueLabel: [
      {
        value: "confirmed",
        label: "Da preparare",
      },
      {
        value: "ready",
        label: "Pronti",
      },
    ],
    key: {
      confirmed: "Da preparare",
      ready: "Pronti",
    },
    list: ["confirmed", "ready"],
  },
  initialStatus: {
    valueLabel: [
      {
        value: "pending",
        label: "In attesa",
      },
      {
        value: "confirmed",
        label: "Confermato",
      },
    ],
  },
  filters: {
    valueLabel: [
      {
        value: "open",
        label: "Aperto",
      },
      {
        value: "pending",
        label: "In attesa",
      },
      {
        value: "confirmed",
        label: "Da preparare",
      },
      {
        value: "ready",
        label: "Pronti",
      },
      {
        value: "onDelivery",
        label: "In consegna",
      },
      {
        value: "allCompleted",
        label: "Consegnati/Completati",
      },
      {
        value: "canceled",
        label: "Annullati",
      },
    ],
    key: {
      pending: "In attesa",
      confirmed: "Da preparare",
      ready: "Pronti",
      onDelivery: "In consegna",
      allCompleted: "Consegnati/Completati",
      canceled: "Annullati",
      open: "Aperto",
    },
  },
};

export const currenciesList = {
  key: {
    EUR: "Euro (€)",
    GBP: "Sterlina (£)",
    USD: "Dollaro ($)",
  },
  symbol: {
    "€": "EUR",
    $: "USD",
    "£": "GBP",
  },
  text: {
    EUR: "€",
    USD: "$",
    GBP: "£",
  },
  valueLabel: [
    { label: "Euro (€)", value: "EUR" },
    { label: "Sterlina (£)", value: "GBP" },
    { label: "Dollaro ($)", value: "USD" },
  ],
};

export const fiscalTypes = {
  key: {
    true: "Fiscale",
    false: "Non fiscale",
  },
  valueLabel: [
    { label: "Fiscale", value: "true" },
    { label: "Non fiscale", value: "false" },
  ],
};

export const paymentTypes = {
  key: {
    stripe: "Stripe",
    paypal: "PayPal",
    onDelivery: "Alla consegna", //TODO(Paolo): capire perchè alla consegna è un metodo di pagamento, alla consegna pagherò sicuramente in contanti no?
    applePay: "Apple Pay",
    ticket: "Ticket",
    googlePay: "Google Pay",
    cash: "Contanti",
    bancomat: "Bancomat",
    creditCard: "Carta di credito",
    postePay: "PostePay",
  },
  valueLabel: [
    { label: "Stripe", value: "stripe" },
    { label: "Contanti", value: "cash" },
    { label: "Bancomat", value: "bancomat" },
    { label: "Carta di credito", value: "creditCard" },
    { label: "Ticket", value: "ticket" },
    { label: "Apple Pay", value: "applePay" },
    { label: "Google Pay", value: "googlePay" },
    { label: "PayPal", value: "paypal" },
    { label: "PostePay", value: "postePay" },
  ],
};

export const channelTypes = {
  key: {
    cashDesk: "Cassa",
    ecommerce: "Ecommerce",
    delivery: "Delivery",
    takeaway: "Take Away",
    table: "Tavolo",
    express: "Ordina e Paga",
    experience: "Evento",
    notCollected: "Non riscosso",
    bancomat: "Bancomat",
    creditCard: "Carta di credito",
    stripePos: "Pos stripe",
    ticket: "Ticket",
    cash: "Contanti",
    sepa: "Bonifico",
    stripe: "Stripe",
    token: "Token",
  },
  valueLabel: [
    { value: "cashDesk", label: "Cassa" },
    { value: "ecommerce", label: "Ecommerce" },
    { value: "delivery", label: "Delivery" },
    { value: "takeaway", label: "Take Away" },
    { value: "table", label: "Tavolo" },
    { value: "express", label: "Ordina e Paga" },
    { value: "experience", label: "Evento" },
    { value: "notCollected", label: "Non riscosso" },
    { value: "bancomat", label: "Bancomat" },
    { value: "creditCard", label: "Carta di credito" },
    { value: "stripePos", label: "Pos stripe" },
    { value: "ticket", label: "Ticket" },
    { value: "cash", label: "Contanti" },
    { value: "sepa", label: "Bonifico" },
    { value: "stripe", label: "Stripe" },
    { value: "token", label: "Token" },
  ],
};

export const experiencePaymentTypes = {
  key: {
    cash: "Contanti",
    creditCard: "Carta di credito",
  },
  valueLabel: [
    { label: "Contanti", value: "cash" },
    { label: "Carta di credito", value: "creditCard" },
  ],
};

export const transactionTypes = {
  key: {
    recharge: "Ricarica utente",
    rechargeNFC: "Ricarica operatore",
  },
  valueLabel: [
    { label: "Ricarica utente", value: "recharge" },
    { label: "Ricarica operatore", value: "rechargeNFC" },
  ],
};

export const saloonType = {
  key: {
    all: "Tutte",
    internal: "Sale interne",
    external: "Sale esterne",
  },
  valueLabel: [
    { label: "Tutte", value: "all" },
    { label: "Sale interne", value: "internal" },
    { label: "Sale esterne", value: "external" },
  ],
};

export const simpleSaloonType = {
  key: {
    internal: "Sala interna",
    external: "Sala esterna",
  },
  valueLabel: [
    { label: "Sala interna", value: "internal" },
    { label: "Sala esterna", value: "external" },
  ],
};

export function initCategory(position: number): Category {
  return {
    _id: "",
    name: "",
    image: undefined,
    categoryType: ["base"],
    open: {
      enabled: true,
      from: "",
      to: "",
    },
    items: [],
    position,
    otherLanguages: {
      en: {
        name: "",
      },
    },
    groups: [],
  };
}

export function initComponentCategory(position: number): ComponentCategory {
  return {
    _id: "",
    name: "",
    image: undefined,
    categoryType: ["base"],
    open: {
      enabled: true,
      from: "",
      to: "",
    },
    components: [],
    position,
    otherLanguages: {
      en: {
        name: "",
      },
    },
    groups: [],
  };
}

export function initComponent(type: ComponentType, componentCategory: string, position?: number | null, initial?: boolean): Component {
  return {
    _id: "",
    name: "",
    description: "",
    position: position || 0,
    initial: initial || false,
    unit: "pz",
    price: undefined,
    componentType: type,
    componentCategory,
    additionalComponentCategories: [],
    externalRef: "",
    otherLanguages: {
      en: {
        name: "",
        description: "",
      },
    },
    components: [],
    allergens: [],
  };
}

export function initSupplier(brand: string) {
  return {
    _id: "",
    piva: {
      brand: brand,
      codiceFiscale: "",
      denominazione: "",
      uniqueCode: "",
      pec: "",
      _id: "",
      idIva: {
        codicePaese: "IT",
        partitaIva: "",
      },
      indirizzo: {
        indirizzo: "",
        cap: "",
        comune: "",
        provincia: "",
      },
    },
    brand: brand,
    type: "piva",
    name: "",
    email: "",
    iban: "",
    shops: [],
  };
}

export function setTable(table: Table, shop: string) {
  return {
    _id: table._id,
    enabled: table.enabled,
    shop,
    name: table.name,
    seats: {
      max: table.seats.max,
      min: table.seats.min,
    },
    saloon: table.saloon,
    bookingTable: table.bookingTable,
    expressCheckout: table.expressCheckout,
    orderTable: table.orderTable,
    color: table.color,
  };
}

export function setSaloon(saloon: Saloon, shop: string) {
  return {
    _id: saloon._id,
    enabled: saloon.enabled,
    shop,
    name: saloon.name,
    indoor: saloon.indoor,
  };
}

export function setSupplier(supplierInfo: Supplier) {
  return {
    _id: supplierInfo._id,
    piva: {
      brand: supplierInfo.piva?.brand,
      codiceFiscale: supplierInfo.piva?.codiceFiscale,
      denominazione: supplierInfo.piva?.denominazione,
      uniqueCode: supplierInfo.piva?.uniqueCode,
      pec: supplierInfo.piva?.pec,
      _id: supplierInfo.piva?._id,
      idIva: {
        codicePaese: "IT",
        partitaIva: supplierInfo.piva?.idIva.partitaIva,
      },
      indirizzo: {
        indirizzo: supplierInfo.piva?.indirizzo.indirizzo,
        cap: supplierInfo.piva?.indirizzo.cap,
        comune: supplierInfo.piva?.indirizzo.comune,
        provincia: supplierInfo.piva?.indirizzo.provincia,
      },
    },
    brand: supplierInfo.brand,
    type: supplierInfo.type,
    name: supplierInfo.name,
    email: supplierInfo.email,
    iban: supplierInfo.iban,
    shops: supplierInfo.shops,
  };
}

export function initLoad(brand: string) {
  return {
    _id: "",
    component: "",
    brand: brand,
    shop: "",
    loadType: "",
    supplierId: "",
    quantity: undefined || 0,
    unitPrice: undefined || 0,
    additionPrice: undefined || 0,
    ivaPrice: undefined || 0,
    iva: "",
    cause: "",
    notes: "",
    barcode: "",
    orderNumber: "",
    documentNumber: "",
    documentType: "",
    // HACCP
    productionDate: undefined,
    expirationDate: undefined,
    lotNumber: "",
    deliveryDate: undefined,
    deliveryHour: undefined,
    deliveryTemperature: undefined,
    deliveryConditions: "",
    specificationsCompliance: false,
    expireDateVerification: false,
    deliveryConditionsVerification: false,
    accepted: false,
    rejectReason: "",
    signature: "",
  };
}

export function initVisibility(brandId: string) {
  return {
    _id: "",
    brandId: brandId,
    mode: "",
    categories: undefined,
    items: undefined,
    filterType: [],
  };
}

export function initAccount() {
  return {
    _id: "",
    language: "it",
    fuso: "Europe/Rome",
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    oldEmail: "",
    email: "",
  };
}

export function initItem(type: ItemType, shops: Shop[], category: string, position?: number | null, initial?: boolean): Item {
  return {
    _id: "",
    itemName: "",
    name: "",
    category,
    additionalCategories: [],
    description: "",
    image: undefined,
    price: type === "modular-flex" ? 0 : undefined,
    itemPrice: undefined,
    externalRef: "",
    itemType: type,
    availableConfigurations: [],
    selectableItems:
      type.indexOf("modular") > -1
        ? {
            items: [],
            limit: 1,
            minPrice: type === "modular-static" ? undefined : 1,
          }
        : {
            items: [],
            limit: undefined,
            minPrice: undefined,
          },
    shops: shops.map((single) => ({ _id: single._id, quantity: -1, price: "", maxOrder: -1 })),
    filterType: ["delivery", "digitalMenu", "express", "table", "takeAway"],
    delay: {
      value: 0,
      unit: "hours",
    },
    initial: initial || false,
    position: position || 0,
    allergens: [],
    components: [],
    productType: "simpleProduct",
    ingredients: [],
    newIngredient: {
      value: "",
      otherLanguages: {
        en: {
          value: "",
        },
      },
    },
    otherLanguages: {
      en: {
        name: "",
        description: "",
      },
    },
    fiscality: {
      cashDeskService: "10",
      tableService: "10",
      delivery: "10",
      takeAway: "10",
    },
    rawMaterialCheck: false,
    adultCheck: false,
  };
}

export const daysOfTheWeek = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"];

export function initPrinter(owner: string, brand: string): Printer {
  return {
    _id: "new",
    name: "",
    owner,
    brand,
    shops: [],
    categories: [],
    categoriesToReprint: [],
    ip: "",
    port: 8008,
    password: "",
    productsTextDimension: 1,
    customProductsTextDimension: 1,
    shopTextDimension: 1,
    hideOrderDetails: false,
    printFakeFiscalReceipt: false,
    printNonFiscalAfterFiscal: false,
    printNonFiscal: false,
    printFiscalLikeReceipt: false,
    splitCustomLinkedItems: false,
    printRecapOnreprint: false,
    isFiscal: false,
    isETicketEnabled: false,
    supportDrawer: false,
    openDrawerAfterPayment: false,
    devices: [],
    departments: [22, 10, 4, 0],
    ssl: false,
  };
}

export function initShop(email?: string, brand?: string): Shop {
  return {
    _id: "new",
    name: "",
    description: "",
    email: email || "",
    phone: "",
    address: "",
    hourInterval: 0,
    minPrice: 0,
    tablePrice: 0,
    currency: "EUR",
    availableCaps: [],
    deliveryMethod: "local",
    deliveryType: "multipleRange",
    enableForItems: false,
    billing: {
      piva: "",
      denominazione: "",
      indirizzo: "",
      numeroCivico: "",
      cap: "",
      comune: "",
      provincia: "",
      nazione: "",
      fullAddress: "",
    },
    qr: {
      _id: "",
      brand: "",
      code: "",
      qrType: null,
      shop: "",
      table: "",
    },
    qrMenu: {
      _id: "",
      brand: "",
      code: "",
      qrType: null,
      shop: "",
      table: "",
    },
    rangeDeliveryPrice: {
      singlePrice: 2,
      singleRange: 10,
      singleRiderTime: 20,
      availableRanges: [
        {
          range: 10,
          price: 2,
          minPrice: 0,
          riderTime: 20,
          cap: "",
        },
      ],
    },
    placeId: "",
    cashDesk: {
      pickupModeValues: [],
      handleOrder: false,
      roundDiscount: false,
    },
    open: {
      isOpen: true,
      week: [
        {
          _id: "sun",
          enabled: false,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
        {
          _id: "mon",
          enabled: true,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
        {
          _id: "tue",
          enabled: false,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
        {
          _id: "wed",
          enabled: false,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
        {
          _id: "thu",
          enabled: false,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
        {
          _id: "fri",
          enabled: false,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
        {
          _id: "sat",
          enabled: false,
          hours: [{ from: "00:00", to: "23:59", interval: 10, maxOrders: 10, selectedMode: ["delivery", "express", "table", "takeAway"], maxShow: -1 }],
        },
      ],
      closedDays: [],
    },
    openTable: {
      isOpen: false,
      bookingType: "seats",
      week: [
        {
          _id: "sun",
          enabled: false,
          hours: [],
        },
        {
          _id: "mon",
          enabled: true,
          hours: [{ from: "12:00", to: "15:00", maxSeats: 50 }],
        },
        {
          _id: "tue",
          enabled: false,
          hours: [],
        },
        {
          _id: "wed",
          enabled: false,
          hours: [],
        },
        {
          _id: "thu",
          enabled: false,
          hours: [],
        },
        {
          _id: "fri",
          enabled: false,
          hours: [],
        },
        {
          _id: "sat",
          enabled: false,
          hours: [],
        },
      ],
    },
    areaDeliveryPrice: [],
    undoTime: 0,
    capsDeliveryPrice: [],
    printers: [],
    initialStatus: "pending",
    tables: {
      enabled: false,
      enableAskBill: false,
      enableTip: false,
      minCancelTime: 60,
      avgBookDuration: 30,
      aggregation: {
        enabled: false,
        time: 5,
      },
      paymentOptions: ["full", "split-bill-equal", "split-bill-custom", "split-bill-items"],
      deliveryMode: "self",
      paymentTime: "before",
      smsNotification: false,
      minPrice: 0,
      enableOrdering: true,
      paymentMethod: ["stripe"],
      enableStripeEmail: false,
      serviceCost: {
        enabled: false,
        price: 0,
        options: [],
      },
    },
    express: {
      enabled: false,
      enableTip: false,
      deliveryMode: "self",
      smsNotification: false,
      enablePickupMode: false,
      pickupModeValues: [],
      minPrice: 0,
      paymentMethod: ["stripe"],
      qrCheck: false,
      enableStripeEmail: false,
      enableUserNamePrompt: false,
      hideFromBrandPage: false,
      serviceCost: {
        enabled: false,
        price: 0,
      },
      orderTimer: {
        enabled: false,
        timer: 0,
      },
      enableOrderTracking: true,
      enableRequestEmail: false,
      showShopTakeawayName: false,
      textToShowInsteadOfTracking: "",
      enableOrderMonitorText: false,
      orderMonitorText: "",
      initialStatus: "confirmed",
      selfWithConfirmTitle: "",
      selfWithConfirmDescription: "",
      disableDefaultOpenAccordion: false
    },
    applicationConf: {
      availableModes: ["delivery", "takeAway"],
      availableTime: ["scheduled", "asap"],
      availablePayment: ["onDelivery"],
      availableBooking: false,
      availableTable: false,
      availableDigitalMenu: false,
      availableDays: ["today", "tomorrow"],
      availableTablePayment: ["onDelivery"],
      deliveryConf: {
        availablePayment: ["onDelivery", "stripe", "paypal"],
        availableTime: ["scheduled", "asap"],
        availableDays: ["today", "tomorrow"],
        minPrice: 0,
        calendarRange: 0,
      },
      takeAwayConf: {
        availablePayment: ["onDelivery", "stripe", "paypal"],
        availableTime: ["scheduled", "asap"],
        availableDays: ["today", "tomorrow"],
        minPrice: 0,
        calendarRange: 0,
      },
    },
    shopType: "shop",
    brand: brand || "",
    useGlovoPrice: false,
    otherLanguages: {
      en: {
        name: "",
        description: "",
        selfWithConfirmTitle: "",
        selfWithConfirmDescription: "",
      },
    },
  };
}

export function initTable(shop: string): Table {
  return {
    _id: "new",
    enabled: true,
    shop,
    name: "",
    qr: {
      _id: "",
      brand: "",
      shop: "",
      code: "",
      qrType: null,
      table: "",
    },
    seats: {
      max: null,
      min: null,
    },
    saloon: "",
    bookingTable: true,
    expressCheckout: true,
    orderTable: true,
    color: "#177BBF",
  };
}

export function initSaloon(shop: string): Saloon {
  return {
    _id: "new",
    shop,
    enabled: true,
    name: "",
    indoor: true,
  };
}

export function initFiscaleMail() {
  return {
    email: "",
  };
}

export function initOpenTable(shop: string, tableId: string, min: number): EnableTableDataNew {
  return {
    _id: "new",
    shop,
    tableId,
    people: min,
  };
}

export function initPromo(brand: string, shops: Shop[]): Promo {
  return {
    _id: "new",
    brandId: brand,
    brand,
    promoType: undefined,
    name: "",
    description: "",
    code: undefined,
    shops: shops.map((shop) => shop._id),
    discountType: undefined,
    amount: undefined,
    amountLimit: undefined,
    filterSelected: "none",
    items: [],
    categories: [],
    requirementSelected: undefined,
    minimumAmount: undefined,
    minimumItems: undefined,
    maxUsagePerUser: 1,
    maxUsageGlobal: -1,
    orderType: [],
    dateFilterSelected: "none",
    startDate: undefined,
    endDate: undefined,
    banner: false,
    enabled: true,
    usageCount: 0,
    otherLanguages: {
      en: {
        description: "",
      },
    },
  };
}

export function initEvent(brand: string): Experience {
  return {
    experienceId: "new",
    brandId: brand,
    from: "",
    to: "",
    shops: [],
    name: "",
    description: "",
    website: "",
    siaeCode: "",
    fromHour: "",
    toHour: "",
    maxPeople: 0,
    _id: "new",
  };
}

export function initTicketType(brand: string): TicketType {
  return {
    _id: "new",
    brand: brand,
    name: "",
    description: "",
    price: undefined,
    itemType: "ticket",
    shops: [],
    digitalItemInfo: {
      endless: false,
      fromDate: "",
      toDate: "",
      maxUsage: undefined,
      burnOnExit: false,
    },
    fiscality: {
      cashDeskService: "N4",
      tableService: "N4",
      delivery: "N4",
      takeAway: "N4",
    },
  };
}

export function initAccessoryBulk(brand: string): AccessoryBulk {
  return {
    _id: "new",
    brand: brand,
    brandId: brand,
    tag: "",
    amountToCreate: undefined,
    digitalItemsToAssign: [],
  };
}

export function initToken(brand: string): TokenType {
  return {
    _id: "new",
    brand: brand,
    name: "",
    validFrom: "",
    validTo: "",
    valueOfOneToken: undefined,
    shops: [],
  };
}

export function initBanner(brand: string, shops: Shop[]): Banner {
  return {
    _id: "new",
    bannerType: "base",
    brand,
    brandId: brand,
    title: "",
    description: "",
    shops: shops.map((shop) => shop._id),
    startDate: undefined,
    endDate: undefined,
    image: "",
    enabled: true,
    otherLanguages: {
      en: {
        title: "",
        description: "",
      },
    },
  };
}

export const initEnableTable = {
  rid: "",
  shop: null,
  table: null,
  people: undefined,
};

export const initNotification = {
  title: "",
  body: "",
};

export function getMenuOptions(id: string, isShopManager: boolean, brand?: Brand, shops?: Shop[], isMobile?: boolean): MenuOption[] {
  const brandHasBeenDisabled = useRouter().asPath === `/brand/${id}/settings/general/disabled/`;

  return brand
    ? [
        {
          label: "Home",
          icon: <HomeOutlinedIcon />,
          href: `/brand/${id}/home/`,
          permission: ["merchant", "shopManager"],
          showOnlyWhen: ["shops"],
          exact: true,
          subPages: [],
        },
        {
          label: "Ordini",
          icon: <ReceiptLongOutlinedIcon />,
          href: `/brand/${id}/orders/`,
          permission: ["merchant", "shopManager"],
          showOnlyWhen: ["shops"],
          subPages: createOrdersMenuWithAddon(id),
        },
        {
          label: "Cassa",
          icon: <KeyboardAltOutlinedIcon />,
          href: `/brand/${id}/cashdesk/receipt`,
          permission: ["merchant", "shopManager"],
          showOnlyWhen: ["cashDesk"],
          subPages: [
            {
              label: "Scontrino",
              href: `/brand/${id}/cashdesk/receipt/`,
            },
            {
              label: "Archivio",
              href: `/brand/${id}/cashdesk/archive/`,
            },
          ],
        },
        showSaloon()
          ? {
              label: "Sala",
              icon: <TableRestaurantOutlinedIcon />,
              href: `/brand/${id}/hall-manager/`,
              permission: ["merchant", "shopManager"],
              showOnlyWhen: ["shops", "table-order"],
              subPages: [
                {
                  label: "Tutti",
                  href: `/brand/${id}/hall-manager/card/`,
                },
                {
                  label: "Archivio",
                  href: `/brand/${id}/hall-manager/archive/`,
                },
              ],
            }
          : null,
        shops?.length === 0 && !brandHasBeenDisabled
          ? {
              label: "Crea negozio",
              icon: <ShoppingBagOutlinedIcon />,
              href: `/brand/${id}/manage/shops/create/`,
              permission: ["merchant"],
              showOnlyWhen: [],
              subPages: [],
            }
          : null,
        {
          label: "Gestione",
          icon: <Inventory2OutlinedIcon />,
          href: `/brand/${id}/manage/`,
          permission: ["merchant", "shopManager"],
          showOnlyWhen: ["shops"],
          subPages: showManageCheckWarehouse(isShopManager, id, shops),
        },
        {
          label: "Statistiche",
          icon: <InsertChartOutlinedIcon />,
          href: `/brand/${id}/analytics`,
          permission: ["merchant", "shopManager"],
          showOnlyWhen: ["shops"],
          subPages: [],
        },
        {
          label: "Marketing",
          icon: <FilterAltOutlinedIcon />,
          href: `/brand/${id}/marketing/`,
          permission: ["merchant"],
          showOnlyWhen: ["shops"],
          subPages: showMarketingTab(id),
        },
        isAndroidOrIos || isMobile
          ? {
              label: "Impostazioni",
              icon: <SettingsOutlinedIcon />,
              href: `/brand/${id}/settings/`,
              permission: ["merchant", "shopManager"],
              showOnlyWhen: ["shops"],
              isMobile: isMobile || isCapacitor,
              subPages: [],
            }
          : null,
      ]
    : [
        {
          label: "Informazioni",
          icon: <HomeOutlinedIcon />,
          href: `/brand/${id}/setup/general/`,
          permission: [],
          showOnlyWhen: [],
          subPages: [],
        },
        {
          label: "Abbonamento",
          icon: <CreditCardOutlinedIcon />,
          href: `/brand/${id}/setup/subscription/`,
          permission: [],
          showOnlyWhen: [],
          subPages: [],
        },
      ];
}
export const initBrand: Brand = {
  _id: "new",
  name: "",
  desc: "",
  appDesc: "",
  appLayout: "list",
  color: colors.secondary,
  products: [],
  created: "",
  domain: undefined,
  brandLocalization: "it",
  defaultTimezone: "Europe/Rome",
  billing: {
    companyName: "",
    vat: "",
    fullAddress: "",
    uniqueCode: "",
    pec: "",
    additionalShopCost: 10,
    paymentMethod: undefined,
    billingType: "orders",
  },
  googleAnalytics: [],
  facebookPixel: [],
  customNotification: {
    confirmed: {
      title: "Ordine confermato",
      body: "Il locale sta preparando il tuo ordine",
      otherLanguages: {
        en: {
          title: "Order confirmed",
          body: "The business is preparing your order",
        },
      },
    },
    readyDelivery: {
      title: "Ordine pronto",
      body: "Il tuo ordine è stato preparato",
      otherLanguages: {
        en: {
          title: "Order ready",
          body: "Your order has been prepared",
        },
      },
    },
    readyTakeAway: {
      title: "Ordine pronto",
      body: "Il tuo ordine è pronto per il ritiro",
      otherLanguages: {
        en: {
          title: "Order ready",
          body: "Your order is ready for collection",
        },
      },
    },
    onDelivery: {
      title: "Ordine in consegna",
      body: "Il rider consegnerà presto il tuo ordine",
      otherLanguages: {
        en: {
          title: "Order on delivery",
          body: ` rider's on the way`,
        },
      },
    },
    deliveredDelivery: {
      title: "Ordine consegnato",
      body: "Il tuo ordine è stato consegnato",
      otherLanguages: {
        en: {
          title: "Order delivered",
          body: "Your order has been successfully delivered",
        },
      },
    },
    deliveredTakeAway: {
      title: "Ordine ritirato",
      body: "Il tuo ordine è stato ritirato",
      otherLanguages: {
        en: {
          title: "Order withdrawn",
          body: "Your order has been successfully withdrawn",
        },
      },
    },
    canceled: {
      title: "Ordine cancellato",
      body: "Siamo spiacenti, il tuo ordine è stato cancellato",
      otherLanguages: {
        en: {
          title: "Order canceled",
          body: "Sorry, your order has been canceled",
        },
      },
    },
    changeTime: {
      title: "Orario ordine modificato",
      body: `L'orario di consegna del tuo ordine è stato aggiornato`,
      otherLanguages: {
        en: {
          title: "Order time modified",
          body: "Your order delivery time has been modified",
        },
      },
    },
    tableConfirm: {
      title: "Ordine confermato",
      body: "Il tuo ordine è stata confermato e verrà servito il prima possibile",
      otherLanguages: {
        en: {
          title: "Order confirmed",
          body: "Your order has been confirmed and will be delivered to you as soon as possible",
        },
      },
    },
  },
  otherLanguages: {
    en: {
      name: "",
      desc: "",
      appDesc: "",
    },
  },
  owner: "",
  requiredBirthday: false,
  requiredLogin: false,
  requiredKyc: false,
  expressShowBottomBar: false,
};

export const initCard: Card = {
  brand: "",
  name: "",
  holder: "",
  fourDigits: "",
  cardNumber: "",
  expireDate: "",
  cvc: "",
};

export function initBankAccount(brand?: Brand) {
  return {
    holder: brand?.billing.bankAccount?.holder || "",
    email: brand?.billing.bankAccount?.email || "",
    iban: brand?.billing.bankAccount?.iban || "",
  };
}

export const creditCards = {
  "American Express": "/images/cards/amex.png",
  cartes_bancaires: "/images/cards/generic_card.png",
  diners_club: "/images/cards/diners.png",
  discover: "/images/cards/discover.png",
  jcb: "/images/cards/jcb.png",
  MasterCard: "/images/cards/mastercard.png",
  Visa: "/images/cards/visa.png",
  unionpay: "/images/cards/generic_card.png",
};

export function getCreditCardLogo(card?: CreditCardsType) {
  return creditCards[card!] || "/images/cards/generic_card.png";
}

export function initTeamMember(): TeamMember {
  return {
    _id: "new",
    name: "",
    surname: "",
    email: "",
    password: "",
    passwordConfirm: "",
    shop: [],
    type: undefined,
    orderStatus: [],
    orderType: [],
    permissions: [],
    services: [],
    openCartByDefault: true,
  };
}

export const riderVehicles = {
  valueLabel: [
    {
      label: "Bicicletta",
      value: "bike",
    },
    {
      label: "Scooter",
      value: "motorbike",
    },
    {
      label: "Auto",
      value: "car",
    },
    {
      label: "Monopattino",
      value: "scooter",
    },
  ],
  key: {
    bike: "Bicicletta",
    motorbike: "Scooter",
    car: "Auto",
    scooter: "Monopattino",
  },
};

export const initSignup = {
  email: "",
  name: "",
  surname: "",
  password: "",
  passwordConfirm: "",
  sellerRID: "",
};

export const signupDescriptions = [
  {
    title: "Registrazione facile e veloce",
    description: "Per creare un account, inserisci nome, cognome e il tuo indirizzo email.",
  },
  {
    title: "Inizia a vendere online",
    description: "Con Easylivery la tua app sarà disponibile per il download su iOS & Android.",
  },
  {
    title: "Monitora la tua attività",
    description: "Gestisci gli ordini in un'unica dashboard, monitorali dalla preparazione alla consegna.",
  },
  {
    title: "Aumenta le vendite",
    description: "Velocizza il tuo processo di ordinazione, crea e fidelizza il database dei tuoi clienti.",
  },
];

export const newItemOptions = [
  {
    title: "Normale",
    subtitle: "Questo prodotto è semplice, senza configurazioni",
    type: "base",
  },
  {
    title: "Configurabile",
    subtitle: "Questo prodotto ha più opzioni come ingredienti, formati oppure opzioni varie",
    type: "custom",
  },
  {
    title: "Lista prodotti con prezzo fisso",
    subtitle: "Aggiungi più prodotti e crea un menu con un prezzo fisso",
    type: "modular-static",
  },
  {
    title: "Lista prodotti con prezzo variabile",
    subtitle: "Aggiungi più prodotti e crea un menu con un prezzo variabile in base ai prodotti aggiunti",
    type: "modular-flex",
  },
  {
    title: "Copia prodotto",
    subtitle: "Copia le informazioni da prodotto esistente velocizzando l'operazione di caricamento",
    type: "copy",
  },
  {
    title: "Pacchetti di token",
    subtitle: "Aggiungi pacchetti di token utilizzabili durante gli eventi",
    type: "token-bundle",
  },
  {
    title: "Item digitale",
    subtitle: "Questo è un item digitale come parcheggio, wifi, ecc...",
    type: "digital-item",
  },
];

export const statusColors: { [key: string]: string } = { open: "bg-yellow-400 text-white", pending: "bg-yellow-400 text-white", ready: "bg-gray-500 text-white", onDelivery: "bg-purple-500 text-white", confirmed: "bg-primary text-white", delivered: "bg-green-500 text-white", closed: "bg-green-500 text-white", canceled: "bg-red-600 text-white" };

export const currentCostsLabel = {
  additionalShopCost: "Negozi",
  amount: "Totale IVA escl.",
  baseFee: "Base",
  shops: "Negozi aggiuntivi",
  ordersPrice: "Costo ordini",
  number: "Numero Ordini",
};

export const currentCostsLabelNew = {
  number: "Numero Ordini",
  ordersPrice: "Costo ordini",
};

export const analyticsDateFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

export const apiDateFormat = "yyyy-MM-dd";

export const apiDateFormatWithHours = "yyyy-MM-dd'T'HH:mm:ssxxx";

export const nuvolaDateFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

export function initAnalytics(): AnalyticsStatus {
  const queryParams = getQueryParams() as ParsedQuery;
  if (queryParams.start && queryParams.end) {
    queryParams.timeframe = "custom";
  }

  const init = {
    start: formatDate(subWeeks(new Date(), 1), analyticsDateFormat),
    end: formatDate(new Date(), analyticsDateFormat),
    shopId: undefined,
    timeframe: "week",
    ...queryParams,
  };

  return init;
}

export function initAnalyticsV2(): AnalyticsStatusV2 {
  const queryParams = getQueryParams() as ParsedQuery;

  const init = {
    dateFrom: formatDate(subWeeks(new Date(), 1), analyticsDateFormat),
    dateTo: formatDate(new Date(), analyticsDateFormat),
    shopId: undefined,
    timespan: "month",
    channel: undefined,
    ...queryParams,
  };

  return init;
}

export function initCustomer(): AnalyticsStatusV2 {
  const queryParams = getQueryParams() as ParsedQuery;

  const init = {
    dateFrom: formatDate(subMonths(new Date(), 1), analyticsDateFormat),
    dateTo: formatDate(new Date(), analyticsDateFormat),
    timespan: "year",
    ...queryParams,
  };

  return init;
}

export function initHall(shopId: string): HallStatus {
  const queryParams = getQueryParams() as ParsedQuery;

  const init = {
    shopId: shopId,
    indoor: undefined,
    saloonId: undefined,
    ...queryParams,
  };

  return init;
}

export function initStocks(): StocksStatus {
  const queryParams = getQueryParams() as ParsedQuery;
  if (queryParams.start && queryParams.end) {
    queryParams.timeframe = "custom";
  }

  const init = {
    start: formatDate(subWeeks(new Date(), 1), analyticsDateFormat),
    end: formatDate(new Date(), analyticsDateFormat),
    shopId: undefined,
    timeframe: "week",
    ...queryParams,
  };

  return init;
}

export function initNuvola(): AnalyticsStatus {
  const queryParams = getQueryParams() as ParsedQuery;
  if (queryParams.start && queryParams.end) {
    queryParams.timeframe = "custom";
  }

  const init = {
    from: formatDate(subWeeks(new Date(), 1), nuvolaDateFormat),
    to: formatDate(new Date(), nuvolaDateFormat),
    shop: undefined,
    timeframe: "today",
    ...queryParams,
  };

  return init;
}

export const timeframeOptions = {
  object: {
    today: "Oggi",
    yesterday: "Ieri",
    week: "Ultima settimana",
    month: "Ultimo mese",
    year: "Ultimo anno",
    custom: "Personalizzato",
  },
  array: [
    {
      value: "today",
      label: "Oggi",
    },
    {
      value: "yesterday",
      label: "Ieri",
    },
    {
      value: "week",
      label: "Ultima settimana",
    },
    {
      value: "month",
      label: "Ultimo mese",
    },
    {
      value: "year",
      label: "Ultimo anno",
    },
    {
      value: "custom",
      label: "Personalizzato",
    },
  ],
};

export const timeframeOptionsV2 = {
  object: {
    today: "Oggi",
    yesterday: "Ieri",
    week: "Ultima settimana",
    month: "Ultimo mese",
    year: "Ultimo anno",
    custom: "Personalizzato",
  },
  array: [
    {
      value: "today",
      label: "Oggi",
    },
    {
      value: "yesterday",
      label: "Ieri",
    },
    {
      value: "week",
      label: "Ultima settimana",
    },
    {
      value: "month",
      label: "Ultimo mese",
    },
    {
      value: "year",
      label: "Ultimo anno",
    },
    {
      value: "custom",
      label: "Personalizzato",
    },
  ],
};

export const ordersMyPresets = {
  all: "all",
  delivery: "liveDelivery",
  takeaway: "liveTakeAway",
  tables: "liveTable",
  ecommerce: undefined,
  archive: undefined,
  express: "liveExpress",
  cashdesk: "liveCashDesk",
};

export const liveOrdersPageTitles = {
  all: "Tutti",
  delivery: "Delivery",
  takeaway: "Takeaway",
  tables: "Tavoli",
  ecommerce: "Ecommerce",
  archive: "Archivio",
  express: "Ordina e Paga",
  cashdesk: "Cassa",
};

export const shopTypeOptions = {
  object: {
    shop: "Punto vendita",
    ecommerce: "Ecommerce",
    pickup: "Punto di ritiro",
  },
  array: [
    {
      value: "shop",
      label: "Punto vendita",
    },
    {
      value: "ecommerce",
      label: "Ecommerce",
    },
    {
      value: "pickup",
      label: "Punto di ritiro",
    },
  ],
};

export const promoTypes = {
  code: "Codice sconto",
  category: "Sconto categorie",
  item: "Sconto prodotti",
  shipment: "Consegna gratuita",
};

export const bannerTypes = {
  key: {
    base: "Solo immagine",
    item: "Prodotto",
    category: "Categoria",
    url: "Link esterno",
  },
  valueLabel: [
    {
      value: "base",
      label: "Solo immagine",
    },
    {
      value: "item",
      label: "Prodotto",
    },
    {
      value: "category",
      label: "Categoria",
    },
    {
      value: "url",
      label: "Link esterno",
    },
  ],
};

export const DeliveryMode = {
  key: {
    self: "Ritiro",
    waiter: "Consegna al tavolo",
    selfWithConfirm: "Ritiro con conferma",
  },
  valueLabel: [
    {
      value: "self",
      label: "Ritiro",
    },
    {
      value: "waiter",
      label: "Consegna al tavolo",
    },
    {
      value: "selfWithConfirm",
      label: "Ritiro con conferma",
    },
  ],
};

export const PaymentTime = {
  key: {
    before: "Pagamento anticipato",
    after: "Pagamento posticipato",
  },
  valueLabel: [
    {
      value: "before",
      label: "Pagamento anticipato",
    },
    {
      value: "after",
      label: "Pagamento posticipato",
    },
  ],
};

export const subscriptionPoints = ["App iOS & Android", "Dashboard gestionale", "Prenotazioni", "Notifiche e promozioni", "Un punto vendita incluso", "Costi server inclusi"];

export const subscriptionExtraCosts = [
  {
    name: "Ordine",
    price: "0,50€",
  },
  {
    name: "Ordine al tavolo",
    price: "0,25€",
  },
  {
    name: "Punto vendita aggiuntivo",
    price: "10€ /mese",
  },
];

export const allergensList = {
  key: {
    gluten: "Glutine",
    celery: "Sedano",
    treeNuts: "Frutta a guscio",
    fish: "Pesce",
    soy: "Soia",
    sesame: "Sesamo",
    peanuts: "Arachidi e derivati",
    crustaceans: "Crostacei",
    eggs: "Uova e derivati",
    molluscs: "Molluschi",
    milk: "Latte e derivati",
    mustard: "Senape",
    sulphurDioxide: "Anidride solforosa e solfiti",
    lupin: "Lupini",
  },
  valueLabel: [
    {
      value: "gluten",
      label: "Glutine",
      description: "Cereali, grano, segale, orzo, avena, farro, kamut, inclusi ibridati derivati",
    },
    {
      value: "celery",
      label: "Sedano",
      description: "Sia in pezzi che all'interno di preparati per zuppe, salse e concentrati vegetali",
    },
    {
      value: "treeNuts",
      label: "Frutta a guscio",
      description: "Mandorle, nocciole, noci comuni, noci di acagiù, noci pecan, anacardi e pistacchi",
    },
    {
      value: "fish",
      label: "Pesce",
      description: "Prodotti alimentari in cui è presente il pesce, anche se in piccole percentuali",
    },
    {
      value: "soy",
      label: "Soia",
      description: "Prodotti dericati come: latte di soia, tofu, spaghetti di soia e simili",
    },
    {
      value: "sesame",
      label: "Sesamo",
      description: "Semi interi usati per il pane, farine anche se lo contengono in minima percentuale",
    },
    {
      value: "peanuts",
      label: "Arachidi e derivati",
      description: "Snack confezionati, creme e condimenti in cui vi sia anche in piccole dosi",
    },
    {
      value: "crustaceans",
      label: "Crostacei",
      description: "Marini e d'acqua dolce: gamberi, scampi, aragoste, granchi e simili",
    },
    {
      value: "eggs",
      label: "Uova e derivati",
      description: "Uova e prodotti che lo contengono come: maionese, emulsionanti, pasta all'uovo",
    },
    {
      value: "molluscs",
      label: "Molluschi",
      description: "Canestrello, cannolicchio, capasanta, cozza, ostrica, patella, vongola, tellina, ecc...",
    },
    {
      value: "milk",
      label: "Latte e derivati",
      description: "Ogni prodotto in cui viene usato il latte: yogurt, biscotti, torte, gelato e creme varie",
    },
    {
      value: "mustard",
      label: "Senape",
      description: "Si può trovare nelle salse e nei condimenti, specie nella mostarda",
    },
    {
      value: "sulphurDioxide",
      label: "Anidride solforosa e solfiti",
      description: "Cibi sott'aceto, sott'olio e in salamoia, marmellate, funghi secchi, conserve, ecc...",
    },
    {
      value: "lupin",
      label: "Lupini",
      description: "Presenti in cibi vegan sottoforma di: arrosti, salamini, farine e similari",
    },
  ] as AllergensData[],
};

export const colorsList = ["#000000", "#800000", "#9A6324", "#808000", "#469990", "#000075", "#f58231", "#ffe119", "#3cb44b", "#42d4f4", "#4363d8", "#911eb4", "#f032e6"];

export const gMapsLibraries: GoogleMapsLibraries[] = ["places", "drawing"];

export const ecommerceHours: Week[] = [
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
  {
    enabled: true,
    hours: [{ from: "00:00", to: "23:59", interval: 30, maxOrders: 10000000, maxShow: -1, selectedMode: ["delivery", "takeAway", "express", "table"] }],
  },
];

export const bookingType = {
  valueLabel: [
    {
      value: "seats",
      label: "Gestione coperti",
    },
    {
      value: "table",
      label: "Gestione tavoli",
    },
  ],
  key: {
    seats: "Gestione coperti",
    table: "Gestione tavoli",
  },
};

export function initTaxation(shopId: string) {
  return {
    shopId,
    cf: "",
    password: "",
    pin: "",
    piva: "",
    denominazione: "",
    indirizzo: "",
    numeroCivico: "",
    cap: "",
    comune: "",
    provincia: "",
    nazione: "",
    defAliquotaIVA: "22",
  };
}

export const taxType = {
  strings: {
    "Rep. 1": "10",
    "Rep. 2": "22",
    "Rep. 3": "4",
    "Esente Iva": "N4",
    "4": "4",
    "5": "5",
    "10": "10",
    "22": "22",
    N4: "N4",
  },
  numbers: {
    "Rep. 1": 10,
    "Rep. 2": 22,
    "Rep. 3": 4,
    "Esente Iva": 0,
    "4": 4,
    "5": 5,
    "10": 10,
    "22": 22,
    N4: 0,
  },
};

export const discounts = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

export const loadTypes = {
  key: {
    load: "Carico",
    unload: "Scarico",
  },
  valueLabel: [
    {
      value: "load",
      label: "Carico",
    },
    {
      value: "unload",
      label: "Scarico",
    },
  ],
};

export const conditionsTypes = {
  key: {
    bad: "Scarse",
    good: "Buone",
    optimal: "Ottime",
  },
  valueLabel: [
    {
      value: "bad",
      label: "Scarse",
    },
    {
      value: "good",
      label: "Buone",
    },
    {
      value: "optimal",
      label: "Ottime",
    },
  ],
};

export const acceptedTypes = {
  key: {
    accepted: "Accettato",
    rejected: "Rifiutato",
  },
  valueLabel: [
    {
      value: "accepted",
      label: "Accettato",
    },
    {
      value: "rejected",
      label: "Rifiutato",
    },
  ],
};

export const componentTypes = {
  key: {
    base: "Semplice",
    complex: "Distinta Base",
  },
  valueLabel: [
    {
      value: "base",
      label: "Semplice",
    },
    {
      value: "complex",
      label: "Distinta Base",
    },
  ],
};

export const loadingCausal = {
  key: {
    purchase: "Acquisto",
    return: "Reso",
    replacement: "Sostituzione",
    production: "Produzione",
    transfer: "Trasferimento",
  },
  valueLabel: [
    {
      value: "purchase",
      label: "Acquisto",
    },
    {
      value: "return",
      label: "Reso",
    },
    {
      value: "replacement",
      label: "Sostituzione",
    },
    {
      value: "production",
      label: "Produzione",
    },
    {
      value: "transfer",
      label: "Trasferimento",
    },
  ],
};

export const unitType = {
  key: {
    kg: "Chilogrammi",
    pz: "Pezzi",
    l: "Litri",
  },
  valueLabel: [
    {
      value: "kg",
      label: "Chilogrammi",
    },
    {
      value: "pz",
      label: "Pezzi",
    },
    {
      value: "l",
      label: "Litri",
    },
  ],
};

export const unloadingCausal = {
  key: {
    sale: "Vendita",
    gift: "Omaggio",
    internal_use: "Uso interno",
    damage: "Danneggiamento",
    theft: "Furto",
    waste: "Scarto",
    return_to_supplier: "Reso a fornitore",
    shift: "Spostamento",
    replacement: "Sostituzione",
  },
  valueLabel: [
    {
      value: "sale",
      label: "Vendita",
    },
    {
      value: "gift",
      label: "Omaggio",
    },
    {
      value: "internal_use",
      label: "Uso interno",
    },
    {
      value: "damage",
      label: "Danneggiamento",
    },
    {
      value: "theft",
      label: "Furto",
    },
    {
      value: "return_to_supplier",
      label: "Reso a fornitore",
    },
    {
      value: "shift",
      label: "Spostamento",
    },
    {
      value: "waste",
      label: "Scarto",
    },
    {
      value: "replacement",
      label: "Sostituzione",
    },
  ],
};

export const taxTypes = {
  key: {
    "4": "4%",
    "10": "10%",
    "22": "22%",
    N4: "Esente Iva",
  },
  valueLabel: [
    {
      value: "4",
      label: "4%",
    },
    {
      value: "10",
      label: "10%",
    },
    {
      value: "22",
      label: "22%",
    },
    {
      value: "N4",
      label: "Esente Iva",
    },
  ],
};

export const teamMembersTypes = {
  key: {
    manager: "Manager",
    rider: "Rider",
    waiter: "Cameriere",
    operator: "Operatore",
  },
  valueLabel: [
    {
      value: "manager",
      label: "Manager",
    },
    {
      value: "rider",
      label: "Rider",
    },
    {
      value: "waiter",
      label: "Cameriere",
    },
    {
      value: "operator",
      label: "Operatore",
    },
  ],
};

export const riderVehiclesTypes = {
  key: {
    bike: "Bicicletta",
    motorbike: "Moto",
    car: "Macchina",
    scooter: "Scooter",
  },
  valueLabel: [
    {
      value: "bike",
      label: "Bicicletta",
    },
    {
      value: "motorbike",
      label: "Moto",
    },
    {
      value: "car",
      label: "Macchina",
    },
    {
      value: "scooter",
      label: "Scooter",
    },
  ],
};

export const operatorServicesTypes = {
  key: {
    orders: "Ordini",
    cashDesk: "Cassa",
  },
  valueLabel: [
    {
      value: "orders",
      label: "Ordini",
    },
    {
      value: "cashDesk",
      label: "Cassa",
    },
  ],
};
export const operatorOrderStatusTypes = {
  key: {
    [OperatorOrderStatus.Pending]: "In Attesa",
    [OperatorOrderStatus.Confirmed]: "Da Preparare",
    [OperatorOrderStatus.Ready]: "Pronti",
    [OperatorOrderStatus.Delivering]: "In Consegna",
  },
  valueLabel: [
    {
      value: OperatorOrderStatus.Pending,
      label: "In Attesa",
    },
    {
      value: OperatorOrderStatus.Confirmed,
      label: "Da Preparare",
    },
    {
      value: OperatorOrderStatus.Ready,
      label: "Pronti",
    },
    {
      value: OperatorOrderStatus.Delivering,
      label: "In Consegna",
    },
  ],
};
export const operatorOrderTypes = {
  key: {
    [OperatorOrderType.Delivery]: "Delivery",
    [OperatorOrderType.Takeaway]: "Takeaway",
    [OperatorOrderType.OrderAndPay]: "Ordina e Paga",
    [OperatorOrderType.CashDesk]: "Cassa",
  },
  valueLabel: [
    {
      value: OperatorOrderType.OrderAndPay,
      label: "Ordina e Paga",
    },
    {
      value: OperatorOrderType.Takeaway,
      label: "Takeaway",
    },
    {
      value: OperatorOrderType.Delivery,
      label: "Delivery",
    },
    {
      value: OperatorOrderType.CashDesk,
      label: "Cassa",
    },
  ],
};
export const operatorPermissionsTypes = {
  key: {
    [OperatorPermission.OrderCancel]: "Cancella Ordine",
    [OperatorPermission.OrderRefund]: "Rimborso Ordine",
  },
  valueLabel: [
    {
      value: OperatorPermission.OrderCancel,
      label: "Cancella Ordine",
    },
    {
      value: OperatorPermission.OrderRefund,
      label: "Rimborso Ordine",
    },
  ],
};

export const riderAssignTypes = {
  key: {
    manual: "Manuale",
    auto: "Automatica",
  },
  valueLabel: [
    {
      value: "manual",
      label: "Manuale",
    },
    {
      value: "auto",
      label: "Automatica",
    },
  ],
};

export const payPalStripeIntegrationOptions = {
  valueLabel: [
    {
      value: "brand",
      label: "Brand",
    },
    {
      value: "shop",
      label: "Negozio",
    },
  ],
  key: {
    brand: "Brand",
    shop: "Negozio",
  },
};

export const hoursRange: { [key: number]: string } = {
  0: "00:00",
  0.5: "00:30",
  1: "01:00",
  1.5: "01:30",
  2: "02:00",
  2.5: "02:30",
  3: "03:00",
  3.5: "03:30",
  4: "04:00",
  4.5: "04:30",
  5: "05:00",
  5.5: "05:30",
  6: "06:00",
  6.5: "06:30",
  7: "07:00",
  7.5: "07:30",
  8: "08:00",
  8.5: "08:30",
  9: "09:00",
  9.5: "09:30",
  10: "10:00",
  10.5: "10:30",
  11: "11:00",
  11.5: "11:30",
  12: "12:00",
  12.5: "12:30",
  13: "13:00",
  13.5: "13:30",
  14: "14:00",
  14.5: "14:30",
  15: "15:00",
  15.5: "15:30",
  16: "16:30",
  16.5: "16:30",
  17: "17:00",
  17.5: "17:30",
  18: "18:00",
  18.5: "18:30",
  19: "19:00",
  19.5: "19:30",
  20: "20:00",
  20.5: "20:30",
  21: "21:00",
  21.5: "21:30",
  22: "22:00",
  22.5: "22:30",
  23: "23:00",
  23.5: "23:30",
  24: "23:59",
};

export const warehouseType = {
  valueLabel: [
    {
      value: "simpleProduct",
      label: "Prodotto Semplice",
    },
    {
      value: "billMaterials",
      label: "Distinta Base",
    },
  ],
  key: {
    simpleProduct: "Prodotto Semplice",
    billMaterials: "Distinta Base",
  },
};

export const visibilityEnableItemOptions = [
  {
    title: "Abilità tutti i prodotti",
    subtitle: "Ti permette di abilitare tutti i prodotti in una o più sezioni, esempio Order& Pay, Delivery, Take Away.",
    type: "all",
  },
  {
    title: "Abilità i prodotti in una o più categorie",
    subtitle: "Ti permette di abilitare i prodotti una o più categorie in una o più sezioni, esempio Order& Pay, Delivery, Take Away.",
    type: "categories",
  },
  {
    title: "Abilita uno o più prodotti",
    subtitle: "Ti permette di abilitare i prodotti in una o più sezioni, esempio Order& Pay, Delivery, Take Away.",
    type: "items",
  },
];

export const visibilityDisableItemOptions = [
  {
    title: "Disabilita tutti i prodotti ",
    subtitle: "Ti permette di disabilitare tutti i prodotti in una o più sezioni, esempio Order& Pay, Delivery, Take Away.",
    type: "all",
  },
  {
    title: "Disabilita i prodotti in una o più categorie",
    subtitle: "Ti permette di disabilitare i prodotti una o più categorie in una o più sezioni, esempio Order& Pay, Delivery, Take Away.",
    type: "categories",
  },
  {
    title: "Disabilita uno o più prodotti ",
    subtitle: "Ti permette di disabilitare i prodotti in una o più sezioni, esempio Order& Pay, Delivery, Take Away.",
    type: "items",
  },
];

export const notificationsElement = {
  key: {
    all: "Tutti",
    delivery: "Delivery",
    takeAway: "Takeaway",
    express: "Ordina e Paga",
    table: "Tavoli",
    canceled: "Annullati",
  },
  valueLabel: [
    {
      value: "all",
      label: "Tutti",
    },
    {
      value: "delivery",
      label: "Delivery",
    },
    {
      value: "takeAway",
      label: "Takeaway",
    },
    {
      value: "express",
      label: "Ordina e Paga",
    },
    {
      value: "table",
      label: "Tavoli",
    },
    {
      value: "canceled",
      label: "Annullati",
    },
  ],
};

export const languageTypes = {
  key: {
    base: "Italiano",
    raw: "Inglese",
  },
  valueLabel: [
    {
      value: "it",
      label: "Italiano",
    },
    {
      value: "en",
      label: "Inglese",
    },
  ],
};
export const kpiType = [
  {
    label: "Delivery",
    value: "delivery",
  },
  {
    label: "Takeaway",
    value: "takeAway",
  },
  {
    label: "Ordina e Paga",
    value: "express",
  },
  {
    label: "Ecommerce",
    value: "ecommerce",
  },
];

export const timezones: string[] = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  " America/Knox_IN",
  " America/Kralendijk",
  " America/La_Paz",
  " America/Lima",
  " America/Los_Angeles",
  " America/Louisville",
  " America/Lower_Princes",
  " America/Maceio",
  " America/Managua",
  " America/Manaus",
  " America/Marigot",
  " America/Martinique",
  " America/Matamoros",
  " America/Mazatlan",
  " America/Mendoza",
  " America/Menominee",
  " America/Merida",
  " America/Metlakatla",
  " America/Mexico_City",
  " America/Miquelon",
  " America/Moncton",
  " America/Monterrey",
  " America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

export const isBuilding = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;

export const billingAddons = [
  {
    value: "delivery",
    label: "Delivery/Takeaway",
  },
  {
    value: "table-order",
    label: "Ordini al Tavolo",
  },
  {
    value: "order-pay",
    label: "Ordina e Paga",
  },
  {
    value: "digital-menu",
    label: "Menu digitale",
  },
  {
    value: "cashDesk",
    label: "Cassa",
  },
  // {
  //   value: 'aruba-invoice',
  //   label: 'Fatturazione'
  // },
  // {
  //   value: 'experience',
  //   label: 'Eventi'
  // }
];

export const hoursAddons = [
  {
    value: "delivery",
    label: "Delivery",
  },
  {
    value: "takeAway",
    label: "Takeaway",
  },
  {
    value: "table",
    label: "Tavoli",
  },
  {
    value: "express",
    label: "Ordina e Paga",
  },
];

export const shopAddons = [
  {
    value: "delivery",
    label: "Delivery",
  },
  {
    value: "takeAway",
    label: "Takeaway",
  },
  {
    value: "table-order",
    label: "Tavolo",
  },
  {
    value: "order-pay",
    label: "Ordina e Paga",
  },
];

export const stripeCategoryCode = [
  {
    label: "A/C, Refrigeration Repair",
    code: 7623,
  },
  {
    label: "Accounting/Bookkeeping Services",
    code: 8931,
  },
  {
    label: "Advertising Services",
    code: 7311,
  },
  {
    label: "Agricultural Cooperative",
    code: "0763",
  },
  {
    label: "Airlines, Air Carriers",
    code: 4511,
  },
  {
    label: "Airports, Flying Fields",
    code: 4582,
  },
  {
    label: "Ambulance Services",
    code: 4119,
  },
  {
    label: "Amusement Parks/Carnivals",
    code: 7996,
  },
  {
    label: "Antique Reproductions",
    code: 5937,
  },
  {
    label: "Antique Shops",
    code: 5932,
  },
  {
    label: "Aquariums",
    code: 7998,
  },
  {
    label: "Architectural/Surveying Services",
    code: 8911,
  },
  {
    label: "Art Dealers and Galleries",
    code: 5971,
  },
  {
    label: "Artists Supply and Craft Shops",
    code: 5970,
  },
  {
    label: "Auto Body Repair Shops",
    code: 7531,
  },
  {
    label: "Auto Paint Shops",
    code: 7535,
  },
  {
    label: "Auto Service Shops",
    code: 7538,
  },
  {
    label: "Auto and Home Supply Stores",
    code: 5531,
  },
  {
    label: "Automated Cash Disburse",
    code: 6011,
  },
  {
    label: "Automated Fuel Dispensers",
    code: 5542,
  },
  {
    label: "Automobile Associations",
    code: 8675,
  },
  {
    label: "Automotive Parts and Accessories Stores",
    code: 5533,
  },
  {
    label: "Automotive Tire Stores",
    code: 5532,
  },
  {
    label: "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)",
    code: 9223,
  },
  {
    label: "Bakeries",
    code: 5462,
  },
  {
    label: "Bands, Orchestras",
    code: 7929,
  },
  {
    label: "Barber and Beauty Shops",
    code: 7230,
  },
  {
    label: "Betting/Casino Gambling",
    code: 7995,
  },
  {
    label: "Bicycle Shops",
    code: 5940,
  },
  {
    label: "Billiard/Pool Establishments",
    code: 7932,
  },
  {
    label: "Boat Dealers",
    code: 5551,
  },
  {
    label: "Boat Rentals and Leases",
    code: 4457,
  },
  {
    label: "Book Stores",
    code: 5942,
  },
  {
    label: "Books, Periodicals, and Newspapers",
    code: 5192,
  },
  {
    label: "Bowling Alleys",
    code: 7933,
  },
  {
    label: "Bus Lines",
    code: 4131,
  },
  {
    label: "Business/Secretarial Schools",
    code: 8244,
  },
  {
    label: "Buying/Shopping Services",
    code: 7278,
  },
  {
    label: "Cable, Satellite, and Other Pay Television and Radio",
    code: 4899,
  },
  {
    label: "Camera and Photographic Supply Stores",
    code: 5946,
  },
  {
    label: "Candy, Nut, and Confectionery Stores",
    code: 5441,
  },
  {
    label: "Car Rental Agencies",
    code: 7512,
  },
  {
    label: "Car Washes",
    code: 7542,
  },
  {
    label: "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing",
    code: 5511,
  },
  {
    label: "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing",
    code: 5521,
  },
  {
    label: "Carpentry Services",
    code: 1750,
  },
  {
    label: "Carpet/Upholstery Cleaning",
    code: 7217,
  },
  {
    label: "Caterers",
    code: 5811,
  },
  {
    label: "Charitable and Social Service Organizations - Fundraising",
    code: 8398,
  },
  {
    label: "Chemicals and Allied Products (Not Elsewhere Classified)",
    code: 5169,
  },
  {
    label: "Child Care Services",
    code: 8351,
  },
  {
    label: "Childrens and Infants Wear Stores",
    code: 5641,
  },
  {
    label: "Chiropodists, Podiatrists",
    code: 8049,
  },
  {
    label: "Chiropractors",
    code: 8041,
  },
  {
    label: "Cigar Stores and Stands",
    code: 5993,
  },
  {
    label: "Civic, Social, Fraternal Associations",
    code: 8641,
  },
  {
    label: "Cleaning and Maintenance",
    code: 7349,
  },
  {
    label: "Clothing Rental",
    code: 7296,
  },
  {
    label: "Colleges, Universities",
    code: 8220,
  },
  {
    label: "Commercial Equipment (Not Elsewhere Classified)",
    code: 5046,
  },
  {
    label: "Commercial Footwear",
    code: 5139,
  },
  {
    label: "Commercial Photography, Art and Graphics",
    code: 7333,
  },
  {
    label: "Commuter Transport, Ferries",
    code: 4111,
  },
  {
    label: "Computer Network Services",
    code: 4816,
  },
  {
    label: "Computer Programming",
    code: 7372,
  },
  {
    label: "Computer Repair",
    code: 7379,
  },
  {
    label: "Computer Software Stores",
    code: 5734,
  },
  {
    label: "Computers, Peripherals, and Software",
    code: 5045,
  },
  {
    label: "Concrete Work Services",
    code: 1771,
  },
  {
    label: "Construction Materials (Not Elsewhere Classified)",
    code: 5039,
  },
  {
    label: "Consulting, Public Relations",
    code: 7392,
  },
  {
    label: "Correspondence Schools",
    code: 8241,
  },
  {
    label: "Cosmetic Stores",
    code: 5977,
  },
  {
    label: "Counseling Services",
    code: 7277,
  },
  {
    label: "Country Clubs",
    code: 7997,
  },
  {
    label: "Courier Services",
    code: 4215,
  },
  {
    label: "Court Costs, Including Alimony and Child Support - Courts of Law",
    code: 9211,
  },
  {
    label: "Credit Reporting Agencies",
    code: 7321,
  },
  {
    label: "Cruise Lines",
    code: 4411,
  },
  {
    label: "Dairy Products Stores",
    code: 5451,
  },
  {
    label: "Dance Hall, Studios, Schools",
    code: 7911,
  },
  {
    label: "Dating/Escort Services",
    code: 7273,
  },
  {
    label: "Dentists, Orthodontists",
    code: 8021,
  },
  {
    label: "Department Stores",
    code: 5311,
  },
  {
    label: "Detective Agencies",
    code: 7393,
  },
  {
    label: "Digital Goods Media – Books, Movies, Music",
    code: 5815,
  },
  {
    label: "Digital Goods – Applications (Excludes Games)",
    code: 5817,
  },
  {
    label: "Digital Goods – Games",
    code: 5816,
  },
  {
    label: "Digital Goods – Large Digital Goods Merchant",
    code: 5818,
  },
  {
    label: "Direct Marketing - Catalog Merchant",
    code: 5964,
  },
  {
    label: "Direct Marketing - Combination Catalog and Retail Merchant",
    code: 5965,
  },
  {
    label: "Direct Marketing - Inbound Telemarketing",
    code: 5967,
  },
  {
    label: "Direct Marketing - Insurance Services",
    code: 5960,
  },
  {
    label: "Direct Marketing - Other",
    code: 5969,
  },
  {
    label: "Direct Marketing - Outbound Telemarketing",
    code: 5966,
  },
  {
    label: "Direct Marketing - Subscription",
    code: 5968,
  },
  {
    label: "Direct Marketing - Travel",
    code: 5962,
  },
  {
    label: "Discount Stores",
    code: 5310,
  },
  {
    label: "Doctors",
    code: 8011,
  },
  {
    label: "Door-To-Door Sales",
    code: 5963,
  },
  {
    label: "Drapery, Window Covering, and Upholstery Stores",
    code: 5714,
  },
  {
    label: "Drinking Places",
    code: 5813,
  },
  {
    label: "Drug Stores and Pharmacies",
    code: 5912,
  },
  {
    label: "Drugs, Drug Proprietaries, and Druggist Sundries",
    code: 5122,
  },
  {
    label: "Dry Cleaners",
    code: 7216,
  },
  {
    label: "Durable Goods (Not Elsewhere Classified)",
    code: 5099,
  },
  {
    label: "Duty Free Stores",
    code: 5309,
  },
  {
    label: "Eating Places, Restaurants",
    code: 5812,
  },
  {
    label: "Educational Services",
    code: 8299,
  },
  {
    label: "Electric Razor Stores",
    code: 5997,
  },
  {
    label: "Electrical Parts and Equipment",
    code: 5065,
  },
  {
    label: "Electrical Services",
    code: 1731,
  },
  {
    label: "Electronics Repair Shops",
    code: 7622,
  },
  {
    label: "Electronics Stores",
    code: 5732,
  },
  {
    label: "Elementary, Secondary Schools",
    code: 8211,
  },
  {
    label: "Employment/Temp Agencies",
    code: 7361,
  },
  {
    label: "Equipment Rental",
    code: 7394,
  },
  {
    label: "Exterminating Services",
    code: 7342,
  },
  {
    label: "Family Clothing Stores",
    code: 5651,
  },
  {
    label: "Fast Food Restaurants",
    code: 5814,
  },
  {
    label: "Financial Institutions",
    code: 6012,
  },
  {
    label: "Fines - Government Administrative Entities",
    code: 9222,
  },
  {
    label: "Fireplace, Fireplace Screens, and Accessories Stores",
    code: 5718,
  },
  {
    label: "Floor Covering Stores",
    code: 5713,
  },
  {
    label: "Florists",
    code: 5992,
  },
  {
    label: "Florists Supplies, Nursery Stock, and Flowers",
    code: 5193,
  },
  {
    label: "Freezer and Locker Meat Provisioners",
    code: 5422,
  },
  {
    label: "Fuel Dealers (Non Automotive)",
    code: 5983,
  },
  {
    label: "Funeral Services, Crematories",
    code: 7261,
  },
  {
    label: "Furniture Repair, Refinishing",
    code: 7641,
  },
  {
    label: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances",
    code: 5712,
  },
  {
    label: "Furriers and Fur Shops",
    code: 5681,
  },
  {
    label: "General Services",
    code: 1520,
  },
  {
    label: "Gift, Card, Novelty, and Souvenir Shops",
    code: 5947,
  },
  {
    label: "Glass, Paint, and Wallpaper Stores",
    code: 5231,
  },
  {
    label: "Glassware, Crystal Stores",
    code: 5950,
  },
  {
    label: "Golf Courses - Public",
    code: 7992,
  },
  {
    label: "Government Services (Not Elsewhere Classified)",
    code: 9399,
  },
  {
    label: "Grocery Stores, Supermarkets",
    code: 5411,
  },
  {
    label: "Hardware Stores",
    code: 5251,
  },
  {
    label: "Hardware, Equipment, and Supplies",
    code: 5072,
  },
  {
    label: "Health and Beauty Spas",
    code: 7298,
  },
  {
    label: "Hearing Aids Sales and Supplies",
    code: 5975,
  },
  {
    label: "Heating, Plumbing, A/C",
    code: 1711,
  },
  {
    label: "Hobby, Toy, and Game Shops",
    code: 5945,
  },
  {
    label: "Home Supply Warehouse Stores",
    code: 5200,
  },
  {
    label: "Hospitals",
    code: 8062,
  },
  {
    label: "Hotels, Motels, and Resorts",
    code: 7011,
  },
  {
    label: "Household Appliance Stores",
    code: 5722,
  },
  {
    label: "Industrial Supplies (Not Elsewhere Classified)",
    code: 5085,
  },
  {
    label: "Information Retrieval Services",
    code: 7375,
  },
  {
    label: "Insurance - Default",
    code: 6399,
  },
  {
    label: "Insurance Underwriting, Premiums",
    code: 6300,
  },
  {
    label: "Intra-Company Purchases",
    code: 9950,
  },
  {
    label: "Jewelry Stores, Watches, Clocks, and Silverware Stores",
    code: 5944,
  },
  {
    label: "Landscaping Services",
    code: "0780",
  },
  {
    label: "Laundries",
    code: 7211,
  },
  {
    label: "Laundry, Cleaning Services",
    code: 7210,
  },
  {
    label: "Legal Services, Attorneys",
    code: 8111,
  },
  {
    label: "Luggage and Leather Goods Stores",
    code: 5948,
  },
  {
    label: "Lumber, Building Materials Stores",
    code: 5211,
  },
  {
    label: "Manual Cash Disburse",
    code: 6010,
  },
  {
    label: "Marinas, Service and Supplies",
    code: 4468,
  },
  {
    label: "Masonry, Stonework, and Plaster",
    code: 1740,
  },
  {
    label: "Massage Parlors",
    code: 7297,
  },
  {
    label: "Medical Services",
    code: 8099,
  },
  {
    label: "Medical and Dental Labs",
    code: 8071,
  },
  {
    label: "Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies",
    code: 5047,
  },
  {
    label: "Membership Organizations",
    code: 8699,
  },
  {
    label: "Mens and Boys Clothing and Accessories Stores",
    code: 5611,
  },
  {
    label: "Mens, Womens Clothing Stores",
    code: 5691,
  },
  {
    label: "Metal Service Centers",
    code: 5051,
  },
  {
    label: "Miscellaneous Apparel and Accessory Shops",
    code: 5699,
  },
  {
    label: "Miscellaneous Auto Dealers",
    code: 5599,
  },
  {
    label: "Miscellaneous Business Services",
    code: 7399,
  },
  {
    label: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
    code: 5499,
  },
  {
    label: "Miscellaneous General Merchandise",
    code: 5399,
  },
  {
    label: "Miscellaneous General Services",
    code: 7299,
  },
  {
    label: "Miscellaneous Home Furnishing Specialty Stores",
    code: 5719,
  },
  {
    label: "Miscellaneous Publishing and Printing",
    code: 2741,
  },
  {
    label: "Miscellaneous Recreation Services",
    code: 7999,
  },
  {
    label: "Miscellaneous Repair Shops",
    code: 7699,
  },
  {
    label: "Miscellaneous Specialty Retail",
    code: 5999,
  },
  {
    label: "Mobile Home Dealers",
    code: 5271,
  },
  {
    label: "Motion Picture Theaters",
    code: 7832,
  },
  {
    label: "Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services",
    code: 4214,
  },
  {
    label: "Motor Homes Dealers",
    code: 5592,
  },
  {
    label: "Motor Vehicle Supplies and New Parts",
    code: 5013,
  },
  {
    label: "Motorcycle Shops and Dealers",
    code: 5571,
  },
  {
    label: "Motorcycle Shops, Dealers",
    code: 5561,
  },
  {
    label: "Music Stores-Musical Instruments, Pianos, and Sheet Music",
    code: 5733,
  },
  {
    label: "News Dealers and Newsstands",
    code: 5994,
  },
  {
    label: "Non-FI, Money Orders",
    code: 6051,
  },
  {
    label: "Non-FI, Stored Value Card Purchase/Load",
    code: 6540,
  },
  {
    label: "Nondurable Goods (Not Elsewhere Classified)",
    code: 5199,
  },
  {
    label: "Nurseries, Lawn and Garden Supply Stores",
    code: 5261,
  },
  {
    label: "Nursing/Personal Care",
    code: 8050,
  },
  {
    label: "Office and Commercial Furniture",
    code: 5021,
  },
  {
    label: "Opticians, Eyeglasses",
    code: 8043,
  },
  {
    label: "Optometrists, Ophthalmologist",
    code: 8042,
  },
  {
    label: "Orthopedic Goods - Prosthetic Devices",
    code: 5976,
  },
  {
    label: "Osteopaths",
    code: 8031,
  },
  {
    label: "Package Stores-Beer, Wine, and Liquor",
    code: 5921,
  },
  {
    label: "Paints, Varnishes, and Supplies",
    code: 5198,
  },
  {
    label: "Parking Lots, Garages",
    code: 7523,
  },
  {
    label: "Passenger Railways",
    code: 4112,
  },
  {
    label: "Pawn Shops",
    code: 5933,
  },
  {
    label: "Pet Shops, Pet Food, and Supplies",
    code: 5995,
  },
  {
    label: "Petroleum and Petroleum Products",
    code: 5172,
  },
  {
    label: "Photo Developing",
    code: 7395,
  },
  {
    label: "Photographic Studios",
    code: 7221,
  },
  {
    label: "Photographic, Photocopy, Microfilm Equipment, and Supplies",
    code: 5044,
  },
  {
    label: "Picture/Video Production",
    code: 7829,
  },
  {
    label: "Piece Goods, Notions, and Other Dry Goods",
    code: 5131,
  },
  {
    label: "Plumbing, Heating Equipment, and Supplies",
    code: 5074,
  },
  {
    label: "Political Organizations",
    code: 8651,
  },
  {
    label: "Postal Services - Government Only",
    code: 9402,
  },
  {
    label: "Precious Stones and Metals, Watches and Jewelry",
    code: 5094,
  },
  {
    label: "Professional Services",
    code: 8999,
  },
  {
    label: "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage",
    code: 4225,
  },
  {
    label: "Quick Copy, Repro, and Blueprint",
    code: 7338,
  },
  {
    label: "Railroads",
    code: 4011,
  },
  {
    label: "Real Estate Agents and Managers - Rentals",
    code: 6513,
  },
  {
    label: "Record Stores",
    code: 5735,
  },
  {
    label: "Recreational Vehicle Rentals",
    code: 7519,
  },
  {
    label: "Religious Goods Stores",
    code: 5973,
  },
  {
    label: "Religious Organizations",
    code: 8661,
  },
  {
    label: "Roofing/Siding, Sheet Metal",
    code: 1761,
  },
  {
    label: "Secretarial Support Services",
    code: 7339,
  },
  {
    label: "Security Brokers/Dealers",
    code: 6211,
  },
  {
    label: "Service Stations",
    code: 5541,
  },
  {
    label: "Sewing, Needlework, Fabric, and Piece Goods Stores",
    code: 5949,
  },
  {
    label: "Shoe Repair/Hat Cleaning",
    code: 7251,
  },
  {
    label: "Shoe Stores",
    code: 5661,
  },
  {
    label: "Small Appliance Repair",
    code: 7629,
  },
  {
    label: "Snowmobile Dealers",
    code: 5598,
  },
  {
    label: "Special Trade Services",
    code: 1799,
  },
  {
    label: "Specialty Cleaning",
    code: 2842,
  },
  {
    label: "Sporting Goods Stores",
    code: 5941,
  },
  {
    label: "Sporting/Recreation Camps",
    code: 7032,
  },
  {
    label: "Sports Clubs/Fields",
    code: 7941,
  },
  {
    label: "Sports and Riding Apparel Stores",
    code: 5655,
  },
  {
    label: "Stamp and Coin Stores",
    code: 5972,
  },
  {
    label: "Stationary, Office Supplies, Printing and Writing Paper",
    code: 5111,
  },
  {
    label: "Stationery Stores, Office, and School Supply Stores",
    code: 5943,
  },
  {
    label: "Swimming Pools Sales",
    code: 5996,
  },
  {
    label: "TUI Travel - Germany",
    code: 4723,
  },
  {
    label: "Tailors, Alterations",
    code: 5697,
  },
  {
    label: "Tax Payments - Government Agencies",
    code: 9311,
  },
  {
    label: "Tax Preparation Services",
    code: 7276,
  },
  {
    label: "Taxicabs/Limousines",
    code: 4121,
  },
  {
    label: "Telecommunication Equipment and Telephone Sales",
    code: 4812,
  },
  {
    label: "Telecommunication Services",
    code: 4814,
  },
  {
    label: "Telegraph Services",
    code: 4821,
  },
  {
    label: "Tent and Awning Shops",
    code: 5998,
  },
  {
    label: "Testing Laboratories",
    code: 8734,
  },
  {
    label: "Theatrical Ticket Agencies",
    code: 7922,
  },
  {
    label: "Timeshares",
    code: 7012,
  },
  {
    label: "Tire Retreading and Repair",
    code: 7534,
  },
  {
    label: "Tolls/Bridge Fees",
    code: 4784,
  },
  {
    label: "Tourist Attractions and Exhibits",
    code: 7991,
  },
  {
    label: "Towing Services",
    code: 7549,
  },
  {
    label: "Trailer Parks, Campgrounds",
    code: 7033,
  },
  {
    label: "Transportation Services (Not Elsewhere Classified)",
    code: 4789,
  },
  {
    label: "Travel Agencies, Tour Operators",
    code: 4722,
  },
  {
    label: "Truck StopIteration",
    code: 7511,
  },
  {
    label: "Truck/Utility Trailer Rentals",
    code: 7513,
  },
  {
    label: "Typesetting, Plate Making, and Related Services",
    code: 2791,
  },
  {
    label: "Typewriter Stores",
    code: 5978,
  },
  {
    label: "U.S. Federal Government Agencies or Departments",
    code: 9405,
  },
  {
    label: "Uniforms, Commercial Clothing",
    code: 5137,
  },
  {
    label: "Used Merchandise and Secondhand Stores",
    code: 5931,
  },
  {
    label: "Utilities",
    code: 4900,
  },
  {
    label: "Variety Stores",
    code: 5331,
  },
  {
    label: "Veterinary Services",
    code: "0742",
  },
  {
    label: "Video Amusement Game Supplies",
    code: 7993,
  },
  {
    label: "Video Game Arcades",
    code: 7994,
  },
  {
    label: "Video Tape Rental Stores",
    code: 7841,
  },
  {
    label: "Vocational/Trade Schools",
    code: 8249,
  },
  {
    label: "Watch/Jewelry Repair",
    code: 7631,
  },
  {
    label: "Welding Repair",
    code: 7692,
  },
  {
    label: "Wholesale Clubs",
    code: 5300,
  },
  {
    label: "Wig and Toupee Stores",
    code: 5698,
  },
  {
    label: "Wires, Money Orders",
    code: 4829,
  },
  {
    label: "Womens Accessory and Specialty Shops",
    code: 5631,
  },
  {
    label: "Womens Ready-To-Wear Stores",
    code: 5621,
  },
  {
    label: "Wrecking and Salvage Yards",
    code: 5935,
  },
];

export function initArea(brand: string): Structure {
  return {
    _id: "new",
    name: "",
    description: "",
    brandId: "",
    experienceId: "",
  };
}

export function initAreaList(): AreaListType {
  return {
    areaId: "new",
    name: "",
    description: "",
    color: "",
    type: "",
    shops: [],
    maxPeople: 0,
    itemsToAccess: [],
    structureId: "",
  };
}

export const PAYMENT_METHODS = {
  CASH: "cash",
  BANCOMAT: "bancomat",
  CREDIT_CARD: "creditCard",
  TICKET: "ticket",
  SEPA: "sepa",
  NOT_COLLECTED: "notCollected",
};
