import cn from 'classnames'

import { Field, FieldArray } from 'formik'

import { FieldAttributes, FieldProps } from 'formik'
import { ValueLabel } from 'types'
import { useTranslate } from 'utils/hooks'

interface CheckboxProps extends FieldAttributes<any> {
    overrideValue?: boolean
    disableFormik?: boolean
    label?: string | React.ReactNode
    className?: string
    wrapperClassName?: string
    labelClassName?: string
}

function CheckboxComponent({hasError, className, label, labelClassName, ...props}: CheckboxProps) {
    return(
        <>
            <input {...props} onClick={() => props.onClick && props.onClick()} id={props.name} type="checkbox" className={cn('form-checkbox h-4 w-4 cursor-pointer transition rounded', {'text-primary border focus:ring-primary-lighter': !hasError}, {'text-red-600 border-red-600 focus:ring-red-300': hasError}, className)}/>
            <label htmlFor={props.name} className={cn('ml-2 block text-sm leading-5 cursor-pointer', labelClassName)}>{label}</label>
        </>
    )
}

export default function Checkbox({overrideValue, wrapperClassName = 'items-center', disableFormik, ...props}: CheckboxProps) {
    return(
        <div className={cn('flex', wrapperClassName)}>
            {!disableFormik ? (
                <Field {...props}>
                    {({field, meta: { error, touched }, form: { submitCount }}: FieldProps<any>) => {
                        const hasError = error && (touched || submitCount > 0)
                        const checked = overrideValue !== undefined ? overrideValue : field.value

                        return(
                            <CheckboxComponent {...props} {...field} defaultChecked={checked} hasError={hasError}/>
                        )
                    }}
                </Field>
            ) : (
                <CheckboxComponent {...props} defaultChecked={overrideValue}/>
            )}
            
        </div>
    )
}

interface CheckboxArrayProps extends Omit<CheckboxProps, 'label'> {
    options: ValueLabel<string>[]
    hideError?: boolean
}

export function CheckboxArray({name, options, className, labelClassName, hideError, ...props}: CheckboxArrayProps) {
    const { t } = useTranslate()
    return(
        <FieldArray name={name} {...props} validateOnChange>
            {({form: { getFieldMeta, setFieldTouched }, push, remove }) => {    
                const { value, error } = getFieldMeta<string[]>(name)
                
                function getOptionIndex(option: string) {
                    return value.indexOf(option)
                }

                return(
                    <>
                        {options.map(option => (
                            <div className={cn('flex items-center', className)} key={option.value} onBlur={() => setFieldTouched(name, true)}>
                                <input id={option.value} type="checkbox" className="form-checkbox h-4 w-4 focus:ring cursor-pointer transition rounded text-primary border"
                                    checked={value?.includes(option.value!)} defaultChecked={value?.includes(option.value!)}
                                    onChange={({ target: { checked } }) => checked ? push(option.value) : remove(getOptionIndex(option.value!))}
                                    disabled={option.disabled} />
                                <label htmlFor={option.value} className={cn('ml-2 block leading-5 cursor-pointer', labelClassName)}>{option.label}</label>
                            </div>
                        ))}
                        {!hideError && error && (
                            <div className="relative border-none">
                                <p className="mt-2 pb-4 text-red-600 text-xs absolute">{error === 'required' ? t('Seleziona almeno un opzione') : error}</p>
                            </div>
                        )}
                    </>
                )
            }}
        </FieldArray>
    )
}