import { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { useClickOutside } from 'utils/hooks'
import { useTranslate } from 'utils/hooks'

import { Menu as DefaultMenu } from '@headlessui/react'
import Link from 'components/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { MenuItems, WithChildren } from 'types'

type Props = {
    list?: MenuItems[]
    content?: React.ReactNode
    width?: string
    menuState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    className?: string
    menuClassName?: string
    containerClassName?: string
}

export default function Menu({children, content, list, width = 'w-56', menuState, className, menuClassName, containerClassName}: WithChildren<Props>) {
    const { t } = useTranslate()
    const [isOpen, setMenuOpen] = menuState || [null, null]
    const overrideMenuDefaultLogic = menuState !== undefined && setMenuOpen !== undefined
    const [ref, clickedOutside] = useClickOutside()
    const items = useMemo(() => list, [list])

    useEffect(() => {
        if(overrideMenuDefaultLogic && clickedOutside && isOpen) {
            setMenuOpen!(false)
        }
    }, [clickedOutside])

    return(
        <DefaultMenu>
            <DefaultMenu.Button as="span" className="focus:outline-none appearance-none outline-none">
                {children}
            </DefaultMenu.Button>
            {(!overrideMenuDefaultLogic || (overrideMenuDefaultLogic && isOpen)) && (
                <div className={cn('relative', containerClassName)}>
                    <DefaultMenu.Items static={overrideMenuDefaultLogic} className={cn('absolute z-50 top-3 right-0 origin-top-right rounded-lg outline-none focus:outline-none', {'bg-white border divide-y divide-gray-300': !content}, className, width)}>
                        <div ref={ref} className={cn('outline-none focus:outline-none', menuClassName)}>
                            {items ? items.map((option, index) => option !== null && (
                                <DefaultMenu.Item key={index}>
                                    {({active}) => {
                                        const completeClassName = cn('flex cursor-pointer text-sm px-4 py-2 w-full leading-5 text-left focus:outline-none', {'!bg-gray-100': active, '!rounded-b-lg': active && items.length - 1 === index, '!rounded-t-lg': active && index === 0}, option.className)
                                        const content = (
                                            <>
                                                {option.icon && <FontAwesomeIcon icon={option.icon} className={cn('mr-2 mt-0.5', option.iconClassName)}/>}
                                                {typeof option.name === 'string' ? t(option.name) : option.name}
                                            </>
                                        )
                                        
                                        return option.children ? option.children : (
                                            option.url?.externalHref ? (
                                                <a className={completeClassName} href={option.url.externalHref} target="_blank">{content}</a>
                                            ) : option.url?.href ? (
                                                <Link className={cn(completeClassName, 'hover:!bg-gray-100', option.linkHoverClassName)} href={option.url.href}>{content}</Link>
                                            ) : option.url?.onClick ? (
                                                <div className={completeClassName} onClick={() => option.url?.onClick && option.url?.onClick()}>{content}</div>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    }}
                                </DefaultMenu.Item>
                            )) : content}
                        </div>
                    </DefaultMenu.Items>
                </div>
            )}
        </DefaultMenu>
    )
}