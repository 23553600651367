import qs from "query-string";
import useSWR, { KeyedMutator, MutatorCallback, MutatorOptions } from "swr";
import request from "api/request";
import {
  addNotification,
  downloadElement,
  formatDate,
  getQueryParams,
  getTotalMinutesFromString,
  havePrinter,
  transformToQueryParams,
  usePrinter,
} from "utils";
import { ordersMyPresets } from "utils/data";
import { fiscalPrint } from "utils/fiscalPrinter";
import { useContext } from "react";

import GlobalContext from "context";

import { ParsedQuery } from "query-string";
import {
  Cart,
  CartItemConfiguration,
  CartToEdit,
  FiscaleType,
  Item,
  ItemTax,
  LiveOrdersType,
  Mutate,
  Order,
  OrderStatus,
  PaginatedData,
  Shop,
  CreatePayment,
  UpdatePayment,
  OrderV2,
  ValidTax,
  OrderTaxation,
  SaveFiscaleRes,
  PaymentAmount,
  Piva,
  PrintJobStatus,
} from "types";
import { epsonPrint } from "utils/printer";
import { Payment, AnalyticsStatusV2 } from "types";
import { addFiscalPrintJob, addNonFiscalPrintJob } from "./printers";

export function generateOrderConfiguration(cart: Cart[]) {
  const newCart = [...cart];
  return newCart.map((item) => ({ ...item, configuration: fixCartItemConfiguration(item.configuration as CartItemConfiguration[]) }));
}

export function generateCartWithLessCover(cart: Cart[]) {
  const oldCart = generateOrderConfiguration(cart);
  const fixedCart = oldCart.map((single) => ({
    item: single.item?._id,
    itemName: single.itemName,
    itemDesc: single.itemDesc,
    itemPrice: single.itemPrice,
    itemTax: single.itemTax,
    itemTaxPrice: single.itemTaxPrice,
    itemPromo: single.itemPromo,
    orderNumber: single.orderNumber,
    configuration: single.configuration,
    _id: single._id,
    selectedItems: single.selectedItems as Item[],
    isCover: single.isCover,
  }));
  const oldCartWithoutCover = fixedCart.filter((single) => !single.isCover);
  const oldCartWithOnlyCover = fixedCart.filter((single) => single.isCover);
  const newOnlyCover = oldCartWithOnlyCover.splice(0, oldCartWithOnlyCover.length - 1);
  return [...oldCartWithoutCover, ...newOnlyCover];
}

export function generateCartWithMoreCover(cart: Cart[]) {
  const oldCart = generateOrderConfiguration(cart);
  const fixedCart = oldCart.map((single) => ({
    item: single.item?._id,
    itemName: single.itemName,
    itemDesc: single.itemDesc,
    itemPrice: single.itemPrice,
    itemTax: single.itemTax,
    itemTaxPrice: single.itemTaxPrice,
    itemPromo: single.itemPromo,
    orderNumber: single.orderNumber,
    configuration: single.configuration,
    _id: single._id,
    selectedItems: single.selectedItems as Item[],
    isCover: single.isCover,
  }));
  const oldCartWithoutCover = fixedCart.filter((single) => !single.isCover);
  const oldCartWithOnlyCover = fixedCart.filter((single) => single.isCover);
  const newItemCover = oldCartWithOnlyCover[oldCartWithOnlyCover.length - 1];
  const { _id, ...newFinalItemCover } = newItemCover;

  const newOnlyCover = [...oldCartWithOnlyCover, newFinalItemCover];
  return [...oldCartWithoutCover, ...newOnlyCover];
}

export function generateCartWithOnlyCover(cart: Cart[]) {
  const oldCart = generateOrderConfiguration(cart);
  const fixedCart = oldCart.map((single) => ({
    item: single.item?._id,
    itemName: single.itemName,
    itemDesc: single.itemDesc,
    itemPrice: single.itemPrice,
    itemTax: single.itemTax,
    itemTaxPrice: single.itemTaxPrice,
    itemPromo: single.itemPromo,
    orderNumber: single.orderNumber,
    configuration: single.configuration,
    _id: single._id,
    selectedItems: single.selectedItems as Item[],
    isCover: single.isCover,
  }));
  const oldCartWithOnlyCover = fixedCart.filter((single) => single.item === undefined && single.isCover === true);

  return [...oldCartWithOnlyCover];
}

export function generateCartWithKeypadItem(cart: Cart[], iva: ItemTax, name: string, price: number, quantity: number) {
  if (quantity === 1) {
    const oldCart = generateOrderConfiguration(cart);
    const fixedCart = oldCart.map((single) => ({
      item: single.item?._id,
      itemName: single.itemName,
      itemDesc: single.itemDesc,
      itemPrice: single.itemPrice,
      itemTax: single.itemTax,
      itemTaxPrice: single.itemTaxPrice,
      itemPromo: single.itemPromo,
      orderNumber: single.orderNumber,
      configuration: single.configuration,
      _id: single._id,
      selectedItems: single.selectedItems as Item[],
      isCover: single.isCover,
    }));
    const oldCartWithoutCover = fixedCart.filter((single) => single.item !== undefined);
    const oldCartWithOnlyCover = fixedCart.filter((single) => single.item === undefined);
    const newItemCover = oldCartWithOnlyCover[oldCartWithOnlyCover.length - 1];
    const { _id, ...newFinalItemCover } = newItemCover;
    newFinalItemCover.isCover = false;
    newFinalItemCover.itemName = name;
    newFinalItemCover.itemPrice = price;
    newFinalItemCover.itemTax = iva;
    newFinalItemCover.itemTaxPrice = (price * Number(iva)) / 100;

    const newOnlyCover = [...oldCartWithOnlyCover, newFinalItemCover];
    return [...oldCartWithoutCover, ...newOnlyCover];
  } else {
    const oldCart = generateOrderConfiguration(cart);
    const fixedCart = oldCart.map((single) => ({
      item: single.item?._id,
      itemName: single.itemName,
      itemDesc: single.itemDesc,
      itemPrice: single.itemPrice,
      itemTax: single.itemTax,
      itemTaxPrice: single.itemTaxPrice,
      itemPromo: single.itemPromo,
      orderNumber: single.orderNumber,
      configuration: single.configuration,
      _id: single._id,
      selectedItems: single.selectedItems as Item[],
      isCover: single.isCover,
    }));
    const oldCartWithoutCover = fixedCart.filter((single) => single.item !== undefined);
    const oldCartWithOnlyCover = fixedCart.filter((single) => single.item === undefined);

    let newKeypadCart = [];

    for (let i = 0; i < quantity; i++) {
      const newItemCover = oldCartWithOnlyCover[oldCartWithOnlyCover.length - 1];
      const { _id, ...newFinalItemCover } = newItemCover;
      newFinalItemCover.isCover = false;
      newFinalItemCover.itemName = name;
      newFinalItemCover.itemPrice = price;
      newFinalItemCover.itemTax = iva;
      newFinalItemCover.itemTaxPrice = (price * Number(iva)) / 100;
      newKeypadCart[newKeypadCart.length] = newFinalItemCover;
    }
    const newOnlyCover = [...oldCartWithOnlyCover, ...newKeypadCart];

    return [...oldCartWithoutCover, ...newOnlyCover];
  }
}

export function reorderOrder(cart: Cart[]) {
  const listOfCover = cart?.filter((single) => single.isCover === true);
  const listOfItem = cart?.filter((single) => !single.isCover);
  if (listOfItem !== undefined) {
    const finalArray = [...listOfItem, ...listOfCover];
    return finalArray;
  }
  return cart;
}

export function fixCartItemConfiguration(configuration: CartItemConfiguration[]) {
  const newConfiguration = configuration.map((single) => {
    // @ts-ignore
    single.price = 0;
    // @ts-ignore
    single.values = single.values.map((value) => {
      /**
       * The mutate function used in the editItemCartOrder may modifiy the order with an array of strings inside the configuration
       * This check will prevent the api request to error since the values required from the api must be an array of string
       *
       * This will prevent an error that occurs when an user clicks really fast on new cart items, when a configurable item is inside the cart
       */
      if (typeof value === "string") {
        return value;
      }

      // @ts-ignore
      return value?.value;
    });

    return single;
  });

  return newConfiguration;
}

function getCleanQueryParams() {
  let queryParams: any = getQueryParams() as ParsedQuery;

  if (queryParams.from) {
    queryParams.from = undefined;
  }

  if (queryParams.shopIds) {
    queryParams.shopId = queryParams.shopIds;
  }

  return queryParams;
}

export function getOrders<T>(brandId: string, type: LiveOrdersType, shopId?: string, search?: string, indoor?: boolean, userId?: string) {
  const queryParams = { preset: ordersMyPresets[type], type: type === "ecommerce" ? "ecommerce" : undefined, shopId, search, indoor, userId };
  const { data, error, mutate } = useSWR<T>(`/order/${brandId}/my${transformToQueryParams("string", { ...queryParams, ...getCleanQueryParams() })}`, {
    refreshInterval: 8000,
    refreshWhenHidden: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateOnMount: true,
  });

  return { orders: data, isLoading: !data && !error, error, mutate };
}

export function getAllOrders(brandId: string, filterByShops?: boolean, shops?: Shop[], type?: LiveOrdersType, disableShopIdQueryParam?: boolean) {
  const { updateGlobalContext } = useContext(GlobalContext);
  const shopId =
    filterByShops && shops
      ? shops.map((single) => single._id).join()
      : !disableShopIdQueryParam
      ? ({ ...(getQueryParams() as ParsedQuery) }?.shopId as string | undefined) || undefined
      : undefined;
  const { data, isValidating, error, mutate } = useSWR<Order[]>(
    brandId ? `/order/${brandId}/my${transformToQueryParams("string", { preset: "liveAll", shopId })}` : null,
    { refreshInterval: 10000, refreshWhenHidden: true, revalidateOnFocus: true, revalidateOnReconnect: true, revalidateOnMount: true }
  );
  let dataToReturn: Order[] = [];

  if (data) {
    if (type === "delivery") {
      dataToReturn = data.filter((order) => order.status !== "canceled" && order.orderType === "delivery" && order.deliveryInfo.homeDelivery);
    } else if (type === "takeaway") {
      dataToReturn = data.filter((order) => order.status !== "canceled" && order.orderType === "delivery" && !order.deliveryInfo.homeDelivery);
    } else if (type === "express") {
      dataToReturn = data.filter((order) => order.status !== "canceled" && order.orderType === "express");
    } else if (type === "cashdesk") {
      dataToReturn = data.filter((order) => order.status !== "delivered" && order.orderType === "cashDesk");
    } else {
      dataToReturn = data;
    }
  }

  function customMutate(data?: Order[] | Promise<Order[]> | MutatorCallback<Order[]>, opts?: boolean | MutatorOptions<Order[]>) {
    updateGlobalContext({ allOrdersIsMutating: true });

    return mutate(data ? data : (oldData: Order[] | undefined) => oldData, opts).finally(() => updateGlobalContext({ allOrdersIsMutating: false }));
  }

  return {
    orders: dataToReturn,
    allOrders: data,
    notWatchedOrders: dataToReturn.filter((order) => !order.deliveryInfo.watched),
    isValidating,
    isLoading: !data && !error,
    error,
    mutate: customMutate,
  };
}

export function getTypeOrders<T>(brandId: string, type: string, shopId?: string, search?: string, indoor?: boolean) {
  const queryParams = { type, shopId, search, indoor };
  const { data, error, mutate } = useSWR<T>(
    `/order/${brandId}/my${transformToQueryParams("string", { ...queryParams, ...(getQueryParams() as ParsedQuery), indoor })}`,
    { refreshInterval: 10000, refreshWhenHidden: true }
  );

  return { orders: data, isLoading: !data && !error, error, mutate };
}

export function getSingleOrder(brandId: string, orderId: string) {
  const { data, error, mutate } = useSWR<Order>(`/order/${brandId}/info/${orderId}`);

  return { order: data, isLoading: !data && !error, error, mutate };
}

export function updateOrderStatus(
  orderId: string,
  status: OrderStatus,
  rider?: string | null,
  isSingleOrderPage?: boolean,
  mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{ order: Order }>,
  selectedOrder?: Order,
  deviceUUID?: string | null,
  t?: Function,
  brandName?: string
) {
  if (mutate && status !== "canceled") {
    if (isSingleOrderPage) {
      (mutate as Mutate<Order>)((order) => ({ ...order!, mutateFix: Math.random(), status }), false);
    } else {
      (mutate as Mutate<Order[]>)((orders) => {
        const newOrders = [...orders!];
        const orderIndex = newOrders.findIndex((order) => order._id === orderId);
        if (orderIndex > -1) {
          if (status !== "payed") {
            newOrders[orderIndex].status = status;
          }
          newOrders[orderIndex].mutateFix = Math.random();
        }

        return newOrders;
      }, false);
    }
  }

  request("post", `/order/edit`, { order: orderId, status, rider })
    .then(() => {
      if (status === "canceled" && selectedOrder && deviceUUID && t) {
        const clonedOrder = JSON.parse(JSON.stringify(selectedOrder)) as Order;
        clonedOrder.status = "canceled";

        if (
          clonedOrder.fiscale[0] &&
          clonedOrder.payment &&
          clonedOrder.payment[0] &&
          (clonedOrder.payment[0]._id || typeof clonedOrder.payment[0] === "string")
        ) {
          fiscalPrint("delete", deviceUUID, clonedOrder, undefined, clonedOrder.fiscale[0], undefined, () =>
            saveFiscale(
              "annullo",
              clonedOrder,
              clonedOrder.fiscale[0].progressivo,
              clonedOrder.payment![0]?._id || (clonedOrder.payment![0] as unknown as string),
              undefined,
              undefined,
              mutate
            )
          );
        }

        if (clonedOrder.payed === "payed" || clonedOrder.printed.nonFiscal) {
          epsonPrint([clonedOrder], t, undefined, undefined, brandName || "", undefined, undefined, undefined, undefined, deviceUUID, mutate);
        }
      }
    })
    .catch(() => addNotification("Si è verificato un errore, riprova", "danger"))
    .finally(() => mutate && mutate());
}

export function updateOrderPayedButNotStatus(
  selectedOrder: Order,
  pivaInfo: Piva,
  paymentAmount: PaymentAmount,
  deviceUUID: string | null,
  status: OrderStatus,
  t: Function,
  brandName: string,
  rider?: string | null,
  isSingleOrderPage?: boolean,
  mutate?: Mutate<Order> | Mutate<Order[]>,
  printNonFiscal?: boolean,
  printFiscal?: boolean
) {
  return new Promise((resolve, reject) => {
    if (mutate) {
      if (isSingleOrderPage) {
        (mutate as Mutate<Order>)(
          (order) => ({ ...order!, mutateFix: Math.random(), paymentAmount, status: status !== "payed" ? status : selectedOrder.status }),
          false
        );
      } else {
        (mutate as Mutate<Order[]>)((orders) => {
          const newOrders = [...orders!];
          const orderIndex = newOrders.findIndex((order) => order._id === selectedOrder._id);
          if (orderIndex > -1) {
            newOrders[orderIndex].mutateFix = Math.random();
            if (status !== "payed") {
              newOrders[orderIndex].status = status;
            }
            newOrders[orderIndex].paymentAmount = paymentAmount;
          }

          return newOrders;
        }, false);
      }
    }

    request("post", `/order/edit`, { order: selectedOrder._id, status, rider, paymentAmount, pivaInfo })
      .then((res) => {
        const fiscalPrinter = usePrinter("fiscal", selectedOrder.shop.printers, deviceUUID);

        if (fiscalPrinter && printFiscal) {
          fiscalPrint(
            "create",
            deviceUUID,
            selectedOrder,
            undefined,
            undefined,
            undefined,
            (res) =>
              saveFiscale(
                "commerciale",
                selectedOrder,
                res!.receiptNumber,
                selectedOrder.payment ? selectedOrder.payment[0]?._id || (selectedOrder.payment[0] as unknown as string) || undefined : undefined,
                (savedFiscale) => {
                  const newOrder = (savedFiscale?.order as OrderV2) || selectedOrder;

                  if (printNonFiscal) {
                    epsonPrint([newOrder], t, undefined, undefined, brandName, undefined, undefined, undefined, undefined, deviceUUID, mutate);
                  }
                },
                true,
                mutate
              ),
            () => {
              if (printNonFiscal) {
                epsonPrint([selectedOrder], t, undefined, undefined, brandName, undefined, undefined, undefined, undefined, deviceUUID, mutate);
              }
            }
          );
        } else if (printNonFiscal) {
          epsonPrint([selectedOrder], t, undefined, undefined, brandName, undefined, undefined, undefined, undefined, deviceUUID, mutate);
        }

        resolve(res);
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function changeOrderHour(
  order: Order,
  newHour: string,
  setChangeHour: React.Dispatch<React.SetStateAction<boolean>>,
  isSingleOrderPage?: boolean,
  mutate?: Mutate<Order> | Mutate<Order[]>
) {
  setChangeHour(false);
  const newDate = new Date(order.deliveryInfo.date);
  newDate.setHours(0);
  newDate.setMinutes(getTotalMinutesFromString(newHour));

  if (mutate) {
    if (isSingleOrderPage) {
      (mutate as Mutate<Order>)((order) => ({ ...order!, deliveryInfo: { ...order!.deliveryInfo, date: newDate }, mutateFix: Math.random() }), false);
    } else {
      (mutate as Mutate<Order[]>)((orders) => {
        const newOrders = [...orders!];
        const orderIndex = newOrders.findIndex((order) => order._id === order._id);
        if (orderIndex > -1) {
          newOrders[orderIndex].deliveryInfo.date = newDate;
          newOrders[orderIndex].mutateFix = Math.random();
        }

        return newOrders;
      }, false);
    }
  }

  request("post", `/order/edit`, { order: order._id, status: order.status, dateChange: newDate })
    .catch(() => {
      setChangeHour(true);
      addNotification("Si è verificato un errore, riprova", "danger");
    })
    .finally(() => mutate && mutate());
}

export function doChangeTablePeople(
  order: Order,
  newTablePeople: number,
  setChangeTablePeople: React.Dispatch<React.SetStateAction<boolean>>,
  isSingleOrderPage?: boolean,
  mutate?: Mutate<Order> | Mutate<Order[]>
) {
  setChangeTablePeople(false);

  if (mutate) {
    if (isSingleOrderPage) {
      (mutate as Mutate<Order>)(
        (order) => ({ ...order!, deliveryInfo: { ...order!.deliveryInfo, tablePeople: newTablePeople }, mutateFix: Math.random() }),
        false
      );
    } else {
      (mutate as Mutate<Order[]>)((orders) => {
        const newOrders = [...orders!];
        const orderIndex = newOrders.findIndex((order) => order._id === order._id);
        if (orderIndex > -1) {
          newOrders[orderIndex].deliveryInfo.tablePeople = newTablePeople;
          newOrders[orderIndex].mutateFix = Math.random();
        }

        return newOrders;
      }, false);
    }
  }

  request("post", `/table/order/my-order-edit`, { orderId: order._id, people: newTablePeople })
    .catch(() => {
      setChangeTablePeople(false);
      addNotification("Si è verificato un errore, riprova", "danger");
    })
    .finally(() => mutate && mutate());
}

export function closeTableOrder(orderId: string, shopId: string, isSingleOrderPage?: boolean, mutate?: Mutate<Order> | Mutate<Order[]>) {
  return new Promise<void>((resolve, reject) => {
    if (mutate) {
      if (isSingleOrderPage) {
        (mutate as Mutate<Order>)((order) => ({ ...order!, mutateFix: Math.random(), status: "closed" as OrderStatus }), false);
      } else {
        (mutate as Mutate<Order[]>)((orders) => {
          const newOrders = [...orders!];
          const orderIndex = newOrders.findIndex((order) => order._id === orderId);
          if (orderIndex > -1) {
            newOrders[orderIndex].status = "closed";
            newOrders[orderIndex].mutateFix = Math.random();
          }

          return newOrders;
        }, false);
      }
    }

    request("post", `/table/order/close`, { orderId, shopId })
      .then(() => resolve())
      .catch(() => reject())
      .finally(() => mutate && mutate());
  });
}

export function updateOrderToWatched<T = Order>(
  brandId: string,
  orderId: string,
  shopId: string,
  mutate?: Mutate<T> | Mutate<T[]> | KeyedMutator<{ order: T }>,
  isSingleOrderPage?: boolean
) {
  if (mutate) {
    if (isSingleOrderPage) {
      (mutate as Mutate<Order>)((order) => ({ ...order!, deliveryInfo: { ...order!.deliveryInfo, watched: true } }), false);
    } else {
      (mutate as Mutate<Order[]>)((orders) => {
        const newOrders = [...orders!];
        const orderIndex = newOrders.findIndex((order) => order._id === orderId);
        if (orderIndex > -1) {
          newOrders[orderIndex].deliveryInfo.watched = true;
          newOrders[orderIndex].mutateFix = Math.random();
        }

        return newOrders;
      }, false);
    }
  }

  request("post", "/order/watch", { orderId, shopId })
    .catch(() => {})
    .finally(() => mutate && mutate());
}

export function removeTableOrderItem(orderId: string, cartItem: Cart, mutate?: Mutate<Order> | Mutate<Order[]>) {
  return new Promise<void>((resolve, reject) => {
    request("post", "/table/order/remove-items", { orderId, cart: [cartItem] })
      .then(() => {
        addNotification(`Il prodotto è stato rimosso dall'ordine`, "success");
        return resolve();
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        return reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function downloadOrdersReport(brandId: string, isCashDesk?: boolean, userId?: string) {
  return new Promise<void>((resolve, reject) => {
    const queryParams = getQueryParams() as ParsedQuery;

    request(
      "get",
      `/order/${brandId}/download-orders-report${transformToQueryParams("string", {
        ...queryParams,
        userId,
        type: isCashDesk ? "cashDesk" : queryParams?.type,
      })}`,
      undefined,
      undefined,
      undefined,
      "blob"
    )
      .then((data) => {
        const date = new Date();
        downloadElement(window.URL.createObjectURL(data as any), `easylivery-orders-${formatDate(date, "dd-MM-yyyy HH-mm")}.xlsx`);
        return resolve();
      })
      .catch(() => reject());
  });
}

export function getAllNonFiscalOrder(brandId: string, shopId: string) {
  return new Promise<OrderV2[]>((resolve, reject) => {
    request<OrderV2[]>("get", `/order/${brandId}/get-non-fiscal-orders${transformToQueryParams("string", { shopId })}`)
      .then((data) => {
        return resolve(data);
      })
      .catch(() => reject());
  });
}

export function downloadCSVReport(brandId: string) {
  return new Promise<void>((resolve, reject) => {
    const queryParams = getQueryParams() as ParsedQuery;

    request("get", `/order/confirmed-csv/${brandId}${transformToQueryParams("string", { ...queryParams })}`, undefined, undefined, undefined, "blob")
      .then((data) => {
        const date = new Date();
        downloadElement(window.URL.createObjectURL(data as any), `easylivery-orders-${formatDate(date, "dd-MM-yyyy HH-mm")}.csv`);
        return resolve();
      })
      .catch(() => reject());
  });
}

export function editItemCartOrder(orderId: string, cart: Cart[], mutate?: Mutate<{ order: Order }>, promoPrice?: number, promoType?: "fixed" | "percentage") {
  return new Promise<void>((resolve, reject) => {
    if (mutate) {
      mutate((old) => {
        if (old) {
          const newPrice = cart.reduce((a, b) => +a + +b.itemPrice, 0);
          return { order: { ...old.order, cart: cart, price: newPrice } };
        }
      }, false);
    }

    const filteredCart = cart.map((single) => ({
      item: single.item?._id,
      itemName: single.itemName,
      itemDesc: single.itemDesc,
      itemPrice: single.itemPrice,
      itemTax: single.itemTax,
      itemTaxPrice: single.itemTaxPrice,
      itemPromo: single.itemPromo,
      orderNumber: single.orderNumber,
      configuration: single.configuration,
      _id: single._id ? single._id : undefined,
      selectedItems: single.selectedItems as Item[],
      isCover: single.isCover,
      status: single.status,
    }));

    request("post", `/table/order/my-order-edit`, { orderId, cart: filteredCart, promoPrice, promoType })
      .then(() => {
        return resolve();
      })
      .catch((err) => {
        addNotification("Si è verificato un errore, riprova", "danger");
        return reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function editItemCartWithKeypad(
  orderId: string,
  cart: CartToEdit[],
  tax: string,
  name: string,
  price: number,
  quantity: number,
  mutate?: Mutate<{ order: Order }>
) {
  return new Promise<void>((resolve, reject) => {
    if (mutate) {
      mutate((old) => {
        if (old) {
          if (quantity === 1) {
            const newItem: Cart = JSON.parse(JSON.stringify(old.order.cart[old.order.cart.length - 1]));
            newItem.itemName = name;
            newItem.isCover = false;
            newItem.item = undefined as any;
            const newPrice = cart.reduce((a, b) => +a + +b.itemPrice, 0);
            return { order: { ...old.order, cart: [...old.order.cart, newItem], price: newPrice } };
          } else {
            const newKeypadArray = [];
            for (let i = 0; i < quantity; i++) {
              const newItem: Cart = JSON.parse(JSON.stringify(old.order.cart[old.order.cart.length - 1]));
              newItem.itemName = name;
              newItem.isCover = false;
              newItem.item = undefined as any;
              newKeypadArray[newKeypadArray.length] = newItem;
            }
            const newPrice = cart.reduce((a, b) => +a + +b.itemPrice, 0);
            return { order: { ...old.order, cart: [...old.order.cart, ...newKeypadArray], price: newPrice } };
          }
        }
      }, false);
    }

    request("post", `/table/order/my-order-edit`, { orderId, cart, promoPrice: 0, promoType: "fixed" })
      .then(() => {
        return resolve();
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        return reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function editPromoPriceOnOrder(
  orderId: string,
  cart: CartToEdit[],
  promoPrice: number,
  promoType: "fixed" | "percentage",
  mutate?: Mutate<{ order: Order }>
) {
  return new Promise<void>((resolve, reject) => {
    if (mutate) {
      mutate((old) => {
        if (old) {
          const newPrice = cart.reduce((a, b) => +a + +b.itemPrice, 0);
          return { order: { ...old.order, promoPrice: promoPrice, price: newPrice } };
        }
      }, false);
    }

    request("post", `/table/order/my-order-edit`, { orderId, cart, promoPrice, promoType })
      .then(() => {
        return resolve();
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        return reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function editPeopleOnOrder(orderId: string, people: number, cart: CartToEdit[], mutate?: Mutate<{ order: Order }>) {
  return new Promise<void>((resolve, reject) => {
    if (mutate) {
      mutate((old) => {
        if (old) {
          const newPrice = cart.reduce((a, b) => +a + +b.itemPrice, 0);
          return {
            order: {
              ...old.order,
              deliveryInfo: { ...old.order.deliveryInfo, tablePeople: people },
              deliveryPrice: (old.order.deliveryPrice / old.order.deliveryInfo.tablePeople) * people,
              price: newPrice,
            },
          };
        }
      }, false);
    }

    const getCoverInCart = cart.filter((single) => single.isCover).length;

    request("post", `/table/order/my-order-edit`, { orderId, people: getCoverInCart, cart })
      .then(() => {
        return resolve();
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        return reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function saveFiscale(
  documentType: FiscaleType,
  order: Order | OrderV2,
  progressivo: string,
  paymentId?: string,
  callback?: (res?: SaveFiscaleRes) => void,
  shouldReturnRes?: boolean,
  mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{ order: Order }> | Mutate<{ order: OrderV2 }>
) {
  request<{ newFiscale: OrderTaxation; order: Order | OrderV2 }>("post", "/fiscale/save-fiscale", {
    documentType,
    orderId: order._id,
    shopId: typeof order.shop === "object" ? order.shop._id : order.shop,
    progressivo,
    paymentId,
  })
    .then((res) => callback && callback(shouldReturnRes ? res : undefined))
    .catch(() => {
      if (callback) {
        callback();
      }

      addNotification("Si è verificato un errore, riprova", "danger");
    })
    .finally(() => {
      if (mutate) {
        mutate();
      }
    });
}

export function refundOrder(orderId: string, paymentId: string, amount: number, mutate?: Mutate<Order> | Mutate<Order[]> | undefined) {
  return new Promise<void>((resolve, reject) => {
    request("post", `/order/refund`, { orderId, paymentId, amount })
      .then(() => {
        return resolve();
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        return reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function getOrderMy<Preset extends "liveExpress" | "liveDeliveryTakeaway" | "liveEcommerce" | "liveTable" | undefined = undefined>(
  brandId: string,
  shopId: string,
  filters: { status?: Order["status"]; preset?: Preset } = {}
) {
  const params = qs.stringify({ ...filters, shopId });

  const { data, error, mutate } = useSWR<Preset extends undefined ? PaginatedData<Order[]> : Order[]>(`/order/${brandId}/my/?${params}`, {
    refreshInterval: 10000,
  });

  return { data, error, mutate, isLoading: typeof data === "undefined" && typeof error === "undefined" };
}

export function setPrintedOrders(
  printType: "nonFiscal" | "fiscal",
  ordersIds: string[],
  mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{ order: Order }> | Mutate<{ order: OrderV2 }>
) {
  return new Promise<void>((resolve) => {
    if (ordersIds.length > 0) {
      request("post", "/printer/order/set-printed", { printType, ordersIds })
        .then(() => resolve())
        .catch(() => resolve())
        .finally(() => {
          if (mutate) {
            mutate();
          }
        });
    } else {
      if (mutate) {
        mutate();
      }

      return resolve();
    }
  });
}

export function setPrintedPayment(paymentsIds: string[], mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{ order: Order }>) {
  return new Promise<void>((resolve) => {
    if (paymentsIds.length > 0) {
      request("post", "/printer/order/set-printed", { paymentsIds })
        .then(() => resolve())
        .catch(() => resolve())
        .finally(() => {
          if (mutate) {
            mutate();
          }
        });
    } else {
      if (mutate) {
        mutate();
      }

      return resolve();
    }
  });
}

function notifyOrderPayed(order: OrderV2) {
  if (order?.payed === "partial") {
    addNotification("Hai generato correttamente il pagamento parziale", "success");
  } else if (order?.payed === "payed") {
    addNotification("Hai completato il pagamento", "success");
  } else {
    addNotification("Hai generato correttamente il pagamento", "success");
  }
}

export function createPayment({
  orderId,
  shopId,
  price,
  taxPrice,
  taxValue,
  paymentAmount,
  paymentMode,
  shareCount,
  shareTax,
  shares,
  items,
  pivaInfo,
  causal,
  printType,
  deviceUUID,
  customPayments,
  mutate,
  t,
  courtesyReceipts,
  printNonFiscal,
  billy
}: CreatePayment) {
  return new Promise((resolve, reject) => {
    request<{ order: OrderV2; payment: Payment; taxValue?: ValidTax; fiscale: OrderTaxation }>("post", "/order/payment/create", {
      orderId,
      shopId,
      price,
      paymentAmount,
      paymentMode,
      courtesyReceipts,
      shareCount,
      taxValue,
      shares,
      items: items?.map((item) => item._id),
      pivaInfo,
      causal,
      taxPrice,
      shareTax,
    })
      .then(({ order, payment, fiscale, taxValue: newTaxValue }) => {
        const paymentId = payment._id;
        let orderToReturn = order;

        if (printType && deviceUUID || billy?.hasAddon) {
          const fiscalPrinter = usePrinter("fiscal", orderToReturn?.shop.printers || [], deviceUUID);
          const nonFiscalPrinterIds = orderToReturn.shop.printers.filter((printer) => !printer.isFiscal).map((printer) => printer._id);
          const nonFiscalFakePrinter = usePrinter("nonFiscal", orderToReturn?.shop.printers || [], deviceUUID, undefined, true);

          if(billy?.hasAddon && fiscale && nonFiscalFakePrinter) {
            addNonFiscalPrintJob({
              id: `courtesy-${order._id}`,
              orderId: order._id,
              status: PrintJobStatus.QUEUE,
              orderStatus: order.status as any,
              priority: 100,
              printerIds: [nonFiscalFakePrinter?._id],
              type: "receipt",
              rawOrderJson: JSON.stringify(order),
              printFakeFiscalReceipt: true,
              fiscale
            });
          } if (printType === "nonFiscal" && nonFiscalFakePrinter) {
            addNonFiscalPrintJob({
              id: `courtesy-${order._id}`,
              orderId: order._id,
              status: PrintJobStatus.QUEUE,
              orderStatus: order.status as any,
              priority: 100,
              printerIds: [nonFiscalFakePrinter?._id],
              type: "receipt",
              rawOrderJson: JSON.stringify(order),
              printFakeFiscalReceipt: true
            });

            if (printNonFiscal && nonFiscalPrinterIds.length > 0) {
              addNonFiscalPrintJob({
                id: `${order._id}`,
                orderId: order._id,
                status: PrintJobStatus.QUEUE,
                orderStatus: order.status as any,
                priority: 100,
                printerIds: nonFiscalPrinterIds,
                type: "order",
                rawOrderJson: JSON.stringify(order),
              });
            }
            // epsonPrint([order], t, undefined, undefined, undefined, 'receipt', undefined, undefined, undefined, deviceUUID, mutate, true, paymentId)
            // if(printNonFiscal) {
            //     setTimeout(() => epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate), 500)
            // }
            notifyOrderPayed(orderToReturn);
            resolve({ order: orderToReturn, paymentId });
          } else if (fiscalPrinter) {
            const printerIds = order.shop.printers
              .filter((printer) => printer.isFiscal && printer.devices.findIndex((device) => device.uuid === deviceUUID) > -1)
              .map((printer) => printer._id);
            addFiscalPrintJob({
              id: paymentId,
              orderId: order._id,
              status: PrintJobStatus.QUEUE,
              priority: 100,
              orderStatus: order.status as any,
              printerIds,
              rawOrderJson: JSON.stringify(order),
              courtesyReceipts,
            });

            if (fiscalPrinter?.printNonFiscalAfterFiscal || printNonFiscal) {
              addNonFiscalPrintJob({
                id: `${orderId}-0`,
                orderId,
                status: PrintJobStatus.QUEUE,
                priority: 100,
                orderStatus: order.status as any,
                type: "order",
                printerIds: nonFiscalPrinterIds,
                rawOrderJson: JSON.stringify(order),
              });
            }

            notifyOrderPayed(orderToReturn);
            resolve({ order: orderToReturn, paymentId });
            // fiscalPrint(printType === 'fiscal' ? 'create' : 'invoice', deviceUUID, order, {paymentId, paymentAmount, customPayments, paymentMode, causal, items, shares, taxValue: newTaxValue || taxValue, pivaInfo}, undefined, undefined, (res) => {
            //     saveFiscale(printType === 'fiscal' ? 'commerciale' : 'nonFiscalInvoice', order, res!.receiptNumber, paymentId, (saveFiscale) => {
            //         if(saveFiscale?.order) {
            //             orderToReturn = saveFiscale.order as OrderV2
            //         }

            //         // temp salone del risparmio
            //         if(courtesyReceipts && courtesyReceipts > 0) {
            //             epsonPrint([order], t, undefined, undefined, undefined, 'receipt', saveFiscale?.newFiscale, undefined, undefined, deviceUUID, mutate, true, undefined, undefined, undefined, undefined, undefined, true)
            //         }

            // if(fiscalPrinter?.printNonFiscalAfterFiscal) {
            //     epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            // } else if (printNonFiscal) {
            //     epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            // }

            //         // if(printType === 'fiscal' && courtesyReceipts && courtesyReceipts > 0) {
            //         //     Array.from({length: courtesyReceipts}, () => fiscalPrint('courtesy', deviceUUID, order, {paymentId, paymentAmount, customPayments, paymentMode, causal, items, shares, taxValue: newTaxValue || taxValue, pivaInfo}))
            //         // }

            //         notifyOrderPayed(orderToReturn)
            //         resolve({order: orderToReturn, paymentId})
            //     }, true, mutate)
            // }, () => {
            //     if(fiscalPrinter?.printNonFiscalAfterFiscal && printNonFiscal) {
            //         epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            //     } else if (printNonFiscal) {
            //         epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            //     }

            //     notifyOrderPayed(orderToReturn)
            //     resolve({order: orderToReturn, paymentId})
            // })
          } else {
            notifyOrderPayed(orderToReturn);
            resolve({ order: orderToReturn, paymentId });
          }
        } else {
          notifyOrderPayed(orderToReturn);
          resolve({ order: orderToReturn, paymentId });
        }
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function updatePayment({
  orderId,
  shopId,
  price,
  taxValue,
  paymentAmount,
  paymentMode,
  shareCount,
  shares,
  items,
  pivaInfo,
  causal,
  printType,
  deviceUUID,
  customPayments,
  mutate,
  paymentId,
  t,
  courtesyReceipts,
  printNonFiscal,
  billy
}: UpdatePayment) {
  return new Promise((resolve, reject) => {
    request<{ order: OrderV2; payment: Payment; taxValue?: ValidTax, fiscale: OrderTaxation }>("post", "/order/payment/update", {
      orderId,
      paymentId,
      shopId,
      price,
      courtesyReceipts,
      paymentAmount,
      paymentMode,
      shareCount,
      shares,
      items: items?.map((item) => item._id),
      pivaInfo,
      causal,
    })
      .then(({ order, payment, fiscale, taxValue: newTaxValue }) => {
        const paymentId = payment._id;
        let orderToReturn = order;

        if (printType && deviceUUID) {
          const fiscalPrinter = usePrinter("fiscal", orderToReturn?.shop.printers || [], deviceUUID);
          const nonFiscalPrinterIds = order.shop.printers.filter((printer) => !printer.isFiscal).map((printer) => printer._id);
          const nonFiscalFakePrinter = usePrinter("nonFiscal", orderToReturn?.shop.printers || [], deviceUUID, undefined, true);

          if(billy?.hasAddon && fiscale && nonFiscalFakePrinter) {
            addNonFiscalPrintJob({
              id: `courtesy-${order._id}`,
              orderId: order._id,
              status: PrintJobStatus.QUEUE,
              orderStatus: order.status as any,
              priority: 100,
              printerIds: [nonFiscalFakePrinter?._id],
              type: "receipt",
              rawOrderJson: JSON.stringify(order),
              printFakeFiscalReceipt: true,
              fiscale
            });
          } else if (printType === "nonFiscal" && nonFiscalFakePrinter) {
            addNonFiscalPrintJob({
              id: `courtesy-${order._id}`,
              orderId: order._id,
              status: PrintJobStatus.QUEUE,
              orderStatus: order.status as any,
              priority: 100,
              printerIds: [nonFiscalFakePrinter?._id],
              type: "receipt",
              rawOrderJson: JSON.stringify(order),
              printFakeFiscalReceipt: true,
            });

            if (printNonFiscal && nonFiscalPrinterIds.length > 0) {
              addNonFiscalPrintJob({
                id: `${order._id}`,
                orderId: order._id,
                status: PrintJobStatus.QUEUE,
                orderStatus: order.status as any,
                priority: 100,
                printerIds: nonFiscalPrinterIds,
                type: "order",
                rawOrderJson: JSON.stringify(order),
              });
            }
            // epsonPrint([order], t, undefined, undefined, undefined, 'receipt', undefined, undefined, undefined, deviceUUID, mutate, true, paymentId)
            // if(printNonFiscal) {
            //     setTimeout(() => epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate), 500)
            // }
            notifyOrderPayed(orderToReturn);
            resolve({ order: orderToReturn, paymentId });
          } else if (fiscalPrinter) {
            const printerIds = order.shop.printers
              .filter((printer) => printer.isFiscal && printer.devices.findIndex((device) => device.uuid === deviceUUID) > -1)
              .map((printer) => printer._id);
            addFiscalPrintJob({
              id: paymentId,
              orderId: order._id,
              status: PrintJobStatus.QUEUE,
              priority: 100,
              orderStatus: order.status as any,
              printerIds,
              rawOrderJson: JSON.stringify(order),
              courtesyReceipts,
            });

            if (fiscalPrinter?.printNonFiscalAfterFiscal || printNonFiscal) {
              addNonFiscalPrintJob({
                id: `${orderId}-0`,
                orderId,
                status: PrintJobStatus.QUEUE,
                priority: 100,
                orderStatus: order.status as any,
                printerIds: nonFiscalPrinterIds,
                rawOrderJson: JSON.stringify(order),
              });
            }

            notifyOrderPayed(orderToReturn);
            resolve({ order: orderToReturn, paymentId });
            // fiscalPrint(printType === 'fiscal' ? 'create' : 'invoice', deviceUUID, order, {paymentId, paymentAmount, customPayments, paymentMode, causal, items, shares, taxValue: newTaxValue || taxValue, pivaInfo}, undefined, undefined, (res) => {
            //     saveFiscale(printType === 'fiscal' ? 'commerciale' : 'nonFiscalInvoice', order, res!.receiptNumber, paymentId, (saveFiscale) => {
            //         if(saveFiscale?.order) {
            //             orderToReturn = saveFiscale.order as OrderV2
            //         }

            //         // temp salone del risparmio
            //         if(courtesyReceipts && courtesyReceipts > 0) {
            //             epsonPrint([order], t, undefined, undefined, undefined, 'receipt', saveFiscale?.newFiscale, undefined, undefined, deviceUUID, mutate, true, undefined, undefined, undefined, undefined, undefined, true)
            //         }

            // if(fiscalPrinter?.printNonFiscalAfterFiscal) {
            //     epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            // } else if (printNonFiscal) {
            //     epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            // }

            //         // if(printType === 'fiscal' && courtesyReceipts && courtesyReceipts > 0) {
            //         //     Array.from({length: courtesyReceipts}, () => fiscalPrint('courtesy', deviceUUID, order, {paymentId, paymentAmount, customPayments, paymentMode, causal, items, shares, taxValue: newTaxValue || taxValue, pivaInfo}))
            //         // }

            //         notifyOrderPayed(orderToReturn)
            //         resolve({order: orderToReturn, paymentId})
            //     }, true, mutate)
            // }, () => {
            //     if(fiscalPrinter?.printNonFiscalAfterFiscal && printNonFiscal) {
            //         epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            //     } else if (printNonFiscal) {
            //         epsonPrint([order], t, undefined, undefined, undefined, 'order', undefined, undefined, undefined, deviceUUID, mutate)
            //     }

            //     notifyOrderPayed(orderToReturn)
            //     resolve({order: orderToReturn, paymentId})
            // })
          } else {
            notifyOrderPayed(orderToReturn);
            resolve({ order: orderToReturn, paymentId });
          }
        } else {
          resolve({ order: orderToReturn, paymentId });
        }
      })
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function deletePayment({ shopId, orderId, paymentId, mutate }: { shopId: string; orderId: string; paymentId: string; mutate?: () => void }) {
  return new Promise((resolve, reject) => {
    request("post", "/order/payment/delete", { shopId, orderId, paymentId })
      .then((res) => resolve(res))
      .catch(() => {
        addNotification("Si è verificato un errore, riprova", "danger");
        reject();
      })
      .finally(() => mutate && mutate());
  });
}

export function unsetPickup(orderId: string, shopId: string, mutate?: () => void) {
  request("post", "/order/unset-pickup", { shopId, orderId })
    .then(() => {})
    .catch(() => addNotification("Si è verificato un errore, riprova", "danger"))
    .finally(() => mutate && mutate());
}
