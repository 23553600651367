import cn from 'classnames'
import { useRouter } from 'next/router'

import NextLink from 'next/link'

import { WithChildren } from 'types'
import { LinkProps } from 'next/link'

interface Props extends LinkProps {
    onClick?: () => void
    className?: string
    activeClassName?: string
    exact?: boolean
    noUnderline?: boolean
}

export default function Link({children, href, onClick, className, activeClassName, noUnderline, exact, ...props}: WithChildren<Props>) {
    const { asPath } = useRouter()
    const isActive = exact ? asPath === href.toString() : asPath.toString().indexOf(href.toString()) > -1

    return(
        <NextLink href={href} {...props} onClick={onClick} className={cn('hover:text-primary-dark focus:outline-none  transition', className, {'focus:no-underline': !noUnderline}, {[activeClassName || '']: isActive})}>
            {children}
        </NextLink>
    )
}