import Head from 'next/head'
import { isElectron } from 'utils'

import { colors } from 'styles/variables'

type Props = {
    title?: string
}

export default function Seo({title}: Props) {
    return(
        <Head>
            <title>{title && !isElectron ? `${title} - Dashboard Easylivery` : 'Dashboard Easylivery'}</title>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
            <link rel="apple-touch-icon" sizes="57x57" href="/images/favicons/apple-icon-57x57.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/images/favicons/apple-icon-60x60.png"/>
            <link rel="apple-touch-icon" sizes="72x72" href="/images/favicons/apple-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="76x76" href="/images/favicons/apple-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="114x114" href="/images/favicons/apple-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="120x120" href="/images/favicons/apple-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="144x144" href="/images/favicons/apple-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="152x152" href="/images/favicons/apple-icon-152x152.png"/>
            <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-icon-180x180.png"/>
            <link rel="icon" type="image/png" sizes="192x192"  href="/images/favicons/android-icon-192x192.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="96x96" href="/images/favicons/favicon-96x96.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png"/>
            <meta name="msapplication-TileColor" content={colors.secondary}/>
            <meta name="msapplication-TileImage" content="/images/favicons/ms-icon-144x144.png"/>
        </Head>
    )
}
