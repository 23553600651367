import '@shopify/polaris-viz/build/esm/styles.css'
import { useState, useEffect, useMemo } from 'react'
import { checkAuthenticationAndGetData } from 'api/authentication'
import { initSentry, isAndroidOrIos, loadAndSetCrispData, isProduction, redirect, setSentryExtras, stripTrailingSlash, isElectron, updateFullScreenToStorage, getFromStorage } from 'utils'
import { fetcher } from 'api/request'
import { useRouter } from 'next/router'
import { logout } from 'api/authentication'
import { SWRConfig } from 'swr'
import { activateNotifications } from 'utils/notifications'
import { withProfiler } from '@sentry/react'
import { checkNewAppVersion } from 'api/generic'
import { KeepAwake } from '@capacitor-community/keep-awake'
import { StatusBar } from '@capacitor/status-bar'
import { CapacitorUpdater, DownloadEvent } from '@capgo/capacitor-updater'
import 'utils/language'
// @ts-ignore
import 'utils/printer_includes_es6.js'

import Layout from 'components/layout'
import Seo from 'components/seo'
import Toast from 'components/toast'

import GlobalContext, { initialGlobalContextState } from 'context'

import { AppProps } from 'next/app'
import { GlobalContextState, GlobalContextStateOptional } from 'types'
import { EasyliveryThemeProvider } from '@easylivery/theme'

import 'styles/tailwind.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { theme } from 'styles/theme'

function App({Component, pageProps}: AppProps) {
	const { query: { id }, events } = useRouter()
	const [globalContext, setGlobalContext] = useState<GlobalContextState>({...initialGlobalContextState})
	const isLoading = useMemo(() => globalContext.authenticated === null || globalContext.redirect !== 'done', [globalContext.authenticated, globalContext.redirect])
	// const { version, critical } = checkNewAppVersion()
	checkNewAppVersion()
	
	function updateGlobalContext(newState: GlobalContextStateOptional) {
		setGlobalContext(oldState => ({...oldState, ...newState}))
	}
	
	function checkRedirect(route: string) {
		const currentRoute = stripTrailingSlash(route)
		const routeToRedirect = stripTrailingSlash(globalContext.redirect as string)
		
		if(currentRoute === routeToRedirect) {
			updateGlobalContext({redirect: 'done'})
			events.off('routeChangeComplete', checkRedirect)
		}
	}
	
	useEffect(() => {
		initSentry()
		
		checkAuthenticationAndGetData()
		.then(data => updateGlobalContext({...data}))
		.catch(err => updateGlobalContext(err))

		window.addEventListener("unauthorizedLogout", async () => await logout(updateGlobalContext))
		window.addEventListener('resize', updateFullScreenToStorage)
	
		return () => {
			window.removeEventListener("unauthorizedLogout", async () => await logout(updateGlobalContext))
			window.removeEventListener("resize", updateFullScreenToStorage)
		}
	}, [])

	useEffect(() => {
		if(isAndroidOrIos) {
			if(isProduction) {
				CapacitorUpdater.notifyAppReady()
			}
      
			// if(isProduction && version !== null && version !== undefined) {
			// 	CapacitorUpdater.notifyAppReady()

			// 	CapacitorUpdater.addListener('download', (info: DownloadEvent) => {
			// 		if(info.bundle.status === 'success') {
			// 			updateGlobalContext({appUpdate: {available: true, isCritical: critical || false}})
			// 		}
			// 	})
			// }

			StatusBar.hide()

			KeepAwake.isSupported()
			.then(async ({isSupported}) => {
				if(isSupported) {
					await KeepAwake.keepAwake()
				}
			})
		}
	// }, [isAndroidOrIos, isProduction, version, critical])
	}, [isAndroidOrIos, isProduction])

	useEffect(() => {
		if(globalContext.authenticated) {
			activateNotifications()
			setSentryExtras({id: globalContext.id, email: globalContext.email})
			loadAndSetCrispData(globalContext, id ? Number(id) : undefined)

			if(isElectron) {
				// window.addEventListener('notificationClick', value => {})
				getFromStorage('isFullscreen').then(isFullscreen => {
					if(isFullscreen === 'true') {
						// @ts-ignore
						window.electron.enableFullScreen()
					}
				})
			}
		}
	}, [globalContext.authenticated])

	useEffect(() => {
		if(globalContext.redirect !== null && globalContext.redirect !== 'done') {
			redirect(globalContext.redirect)
			events.on('routeChangeComplete', checkRedirect)
		}
	}, [globalContext.redirect])

	return (		
		<EasyliveryThemeProvider theme={theme}>
			<GlobalContext.Provider value={{...globalContext, updateGlobalContext}}>
				<SWRConfig value={{fetcher}}>
					<Seo/>
					<Layout showLoader={isLoading}>
						{!isLoading && (
							<Component {...pageProps}/>
						)}
					</Layout>
					<Toast/>
				</SWRConfig>
			</GlobalContext.Provider>
		</EasyliveryThemeProvider>
	)
}

export default withProfiler(App)