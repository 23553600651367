import Router from 'next/router'
import { format, parse, subYears, subDays, subMonths, subWeeks, setHours, setMinutes, setSeconds, intervalToDuration } from 'date-fns'
import { it } from 'date-fns/locale'
import qs from 'query-string'
import { analyticsDateFormat, currenciesList, paymentTypes } from 'utils/data'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import toast from 'react-hot-toast'
import packageInfo from '../../package.json'
import { colors } from 'styles/variables'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import UAParser from 'ua-parser-js'
import { utcToZonedTime } from 'date-fns-tz'

import { Cart, CartConfiguration, CartItemWithQuantity, CashDeskCartV2, Category, Currencies, GlobalContextState, Item, Order, PaymentAmount, PaymentTypesKey, Printer, ReadableCurrencies, Shop, Timeframe } from 'types'
import { User } from '@sentry/react'
import { Toast } from 'react-hot-toast'
import { ChatboxColors, Crisp } from 'crisp-sdk-web'
import { itIT } from '@mui/x-data-grid-pro'

export function redirect(href: string, options?: {}) {
    if (href.indexOf('http') > -1) {
        return window.location.assign(href)
    } else {
        Router.push(href, options)
    }
}

export const NODE_ENV = process.env.NODE_ENV
export const NODE_ENV_DEV = NODE_ENV === 'development'
export const isProduction = process.env.NEXT_PUBLIC_INTERNAL_ENV === 'production'
export const isStaging = process.env.NEXT_PUBLIC_INTERNAL_ENV === 'staging'
export const isDevelopment = process.env.NEXT_PUBLIC_INTERNAL_ENV === 'development'
export const isClient = typeof window !== 'undefined'
export const isElectron = isClient && (window as any).CapacitorCustomPlatform?.name === 'electron'
export const isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios'
export const isAndroid = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android'
export const isAndroidOrIos = isIos || isAndroid
export const isCapacitor = isElectron || isAndroidOrIos || isDevelopment || isStaging || NODE_ENV_DEV

export function isIOSDevice() {
    if(isClient) {
        const ua = new UAParser()

        return ['iPad'].indexOf(ua.getDevice()?.model || '') > -1
    } else {
        return false
    }
}

export const isDarkMode = isClient ? window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches : false

export function formatDate(date: string | Date | number, dateFormat?: string, convertToUtc?: boolean) {
    let parsedDate = date instanceof Date ? date : new Date(date)

    if(convertToUtc) {
        parsedDate = utcToZonedTime(parsedDate, 'UTC')
    }

    return format(parsedDate, dateFormat || 'dd/MM/yyyy', { locale: it })
}

export function formatDistance(date: string | Date) {
    const parsedDate = date instanceof Date ? date : new Date(date)
    const formattedDate = intervalToDuration({ start: parsedDate as Date, end: new Date })
    const days = formattedDate.days! > 0 ? formattedDate.days + ' d' : ''
    const hours = formattedDate.hours! < 10 ? "0" + formattedDate.hours : formattedDate.hours
    const minutes = formattedDate.minutes! < 10 ? "0" + formattedDate.minutes : formattedDate.minutes

  if (formattedDate.days! > 0) {
        return days
    } else {
        return hours + ':' + minutes
    }
}

export function numberFormatter(value: number, noComma?: boolean, minDigits?: boolean) {
    return !noComma ? value?.toLocaleString('it-IT', { minimumFractionDigits: minDigits ? 2 : undefined, maximumFractionDigits: 2 }) : value.toFixed()
}

export function addNotification(message: string, type: 'success' | 'danger', options?: Partial<Pick<Toast, "id" | "icon" | "duration" | "ariaProps" | "className" | "style" | "position" | "iconTheme">>) {
    switch (type) {
        case 'success':
            toast.success(message, options)
            break
        case 'danger':
            toast.error(message, { ...options, style: { backgroundColor: colors.red, color: '#fff' } })
            break
        default: toast.success(message, options)
    }
}

export function range(from: number, to: number, step?: number) {
    let i = from
    const range = []

    while (i <= to) {
        range.push(i)
        i += step || 1
    }

    return range
}

export function removeQueryParamsFromUrl(url?: string) {
    return url?.split(/[?#]/)[0]
}

export function getQueryParams(mode: 'object' | 'string' | undefined = 'object', removeQuestionMark?: boolean) {
    return isClient ? mode === 'object' ? qs.parse(location.search) : !removeQuestionMark ? location.search : location.search.replace(/\?/g, '') : {}
}

export function transformToQueryParams(mode: 'redirect' | 'string', object: Record<string, any>) {
    if (object) {
        Object.keys(object).map(key => {
            if (object[key] === undefined || object[key] === null || object[key].length === 0) {
                delete object[key]
            }
        })

        const query = qs.stringify(object)
        if (mode === 'redirect') {
            return Router.push(Router.pathname, { pathname: removeQueryParamsFromUrl(Router.asPath), query }, { shallow: true })
        } else {
            return query.length > 0 ? `?${query}` : ''
        }
    }

    return ''
}

export function getCurrency(currency?: Currencies): ReadableCurrencies {
    return currenciesList.text[currency || 'EUR'] as ReadableCurrencies
}

export function fallbackValue(value: any, fallbackValue: string | number) {
    if (!value) {
        return fallbackValue
    }

    return value
}

export function fallbackImage(image?: string | null) {
    return image || 'https://cdn.easylivery.it/fallback.jpg'
}

export function getTotalMinutesFromString(time: string) {
    const parsed = parse(time, 'HH:mm', new Date(), { locale: it })

    return (parsed.getHours() * 60) + parsed.getMinutes()
}

export function getTimeRanges(interval: number, startTime?: number | string, endTime?: number | string, isFirstRange?: boolean) {
    const isFinalRange = endTime === '23:59'

    if (startTime && typeof startTime === 'string' && startTime.length > 0) {
        startTime = getTotalMinutesFromString(startTime)

        if (isFirstRange) {
            startTime = startTime + interval
        }
    }
    if (endTime && typeof endTime === 'string' && endTime.length > 0) {
        endTime = getTotalMinutesFromString(endTime) + (interval)
    }

    const ranges = []
    const date = new Date()
    const end = endTime ? (endTime as number) : 24 * 60
    let minutes = startTime ? (startTime as number) : 0

    for (minutes; minutes < end; minutes = minutes + interval) {
        date.setHours(0)
        date.setMinutes(minutes)
        ranges.push(date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', hour12: false }))
    }


    return (!endTime || isFinalRange) ? [...ranges, '23:59'] : ranges
}

export function stripTrailingSlash(url?: string) {
    return url?.replace(/\/$/, '') || ''
}

export function getLanguage() {
    const lang = (navigator.languages && navigator.languages[0]) || navigator.language || 'en';

    return lang.split('-')[0] || lang
}

export function initSentry() {
    Sentry.init({
        enabled: isProduction,
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.85,
        release: packageInfo.version,
        autoSessionTracking: true,
        environment: process.env.NEXT_PUBLIC_INTERNAL_ENV
    })
}

export function setSentryExtras(user: User) {
    Sentry.configureScope(scope => {
        scope.setUser(user)
    })
}

export function initCopyProduct(item: Item, category?: string) {
    item._id = ''
    item.name = ''
    item.copyProduct = false
    item.initial = false
    if(item.otherLanguages) {
        item.otherLanguages.en.name = ''
        item.otherLanguages.en.description = ''
    }

    if (category) {
        item.category = category
    }

    return item
}

export function getDatesFromRange(range: Timeframe) {
    const week = subWeeks(new Date(), 1)
    let dates = [week, new Date()]

    switch (range) {
        case 'today':
            dates[0] = new Date()
            break
        case 'yesterday':
            dates = [subDays(new Date(), 1), subDays(new Date().setHours(23,59,59,0), 1)]
            break
        case 'week':
            dates[0] = week
            break
        case 'month':
            dates[0] = subMonths(new Date(), 1)
            break
        case 'year':
            dates[0] = subYears(new Date(), 1)
            break
        case 'custom':
            dates[0] = week
            break
        default: null
    }

    if(range !== 'custom') {
        dates[0] = setHours(dates[0], 0)
        dates[0] = setMinutes(dates[0], 0)
        dates[0] = setSeconds(dates[0], 0)
    }

    return [formatDate(dates[0], analyticsDateFormat), formatDate(dates[1], analyticsDateFormat)]
}

export function loadScript(src: string, id: string) {
    return new Promise<void>(resolve => {
        if (!document.getElementById(id)) {
            const script = document.createElement('script')
            script.src = src
            script.id = id
            document.body.appendChild(script)
            script.onload = () => resolve()
        } else {
            return resolve()
        }
    })
}

export function getImageUrl(image?: File | string | null) {
    if (image) {
        return image instanceof File ? URL.createObjectURL(image) : image
    }
}

export function downloadElement(href: string, name: string) {
    const link = document.createElement('a')
    link.download = name
    link.style.display = 'none'
    link.href = href
    document.body.append(link)
    link.click()
    link.remove()
}

export function calculatePercentage(value: number, percentage: number) {
    return (percentage / 100) * value
}

export function calculateNetPrice(grossPrice: number, taxAmount: number) {
    return grossPrice / (1 + taxAmount / 100)
}

export function calculateTaxPrice(grossPrice: number, taxAmount: number) {
    return calculateNetPrice(grossPrice, taxAmount) * taxAmount / 100
}

export async function getFromStorage(key: string) {
    const { value } = isClient ? await Preferences.get({key}) : { value: null }

    return value || localStorage.getItem(key)
}

export async function saveToStorage(key: string, value: string) {
    await Preferences.set({key, value})
}

export async function removeFromStorage(key: string) {
    await Preferences.remove({key})
}

export function fixShopWeekHours(shop: Shop){
    if(shop) {
        const fixedWeek = shop.open.week.map(week => {
            const fixedHours = week.hours.map(hour => {
                if(!hour.maxShow){
                    hour.maxShow = -1
                }
                if(hour.selectedMode.length === 0){
                    hour.selectedMode = ['delivery', 'takeAway', 'express', 'table']
                }
                return hour
            })
            
            return {...week, hours: fixedHours}
        })
        
        return {...shop, open: { ...shop.open, week: fixedWeek} } as Shop
    }

    return shop
}

/**
 * Used with selects , returns a comma separated string with the value of the label
 * @param formikFieldValue 
 * @param options 
 */
export function getCommaSeparatedValue<T = any>(formikFieldValue : T[] | undefined = [], options : { label : string , value : T }[]) : string{
    let text = ""

    for(const element of formikFieldValue){
        const foundItem = options.find(option => option.value === element)
        
        if(foundItem){
            
            if(text !== ""){
                text += ", "
            }

            text += foundItem.label
        }
    }
    
    return text
}

export function havePrinter(type: 'fiscal' | 'nonFiscal', printers: Printer[], deviceUUID: string | null, notFiscalPrint?: boolean) {
    return isCapacitor && printers.find(printer => (type === 'fiscal' ? printer.isFiscal : !printer.isFiscal) && (notFiscalPrint ? printer.printFiscalLikeReceipt : true) && printer.devices.find(device => device.uuid === deviceUUID)) !== undefined
}

export function usePrinter(type: 'fiscal' | 'nonFiscal', printers: Printer[], deviceUUID: string | null | undefined, notFiscalPrint?: boolean, printFakeFiscalReceipt?: boolean) {
    return isCapacitor ? printers.find(printer => (type === 'fiscal' ? printer.isFiscal : !printer.isFiscal) && (notFiscalPrint ? printer.printFiscalLikeReceipt : true) && (printFakeFiscalReceipt ? printer.printFakeFiscalReceipt : true) && printer.devices?.find(device => device.uuid === deviceUUID)) : undefined
}

export function getProductItemQuantityArray(cart: Cart[]) {
    let products: CartItemWithQuantity[] = []
    for (const singleCart of cart) {
        const cartItem = singleCart.item
        
        const indexOfItem = products.findIndex(mapItem => mapItem.item?._id === cartItem?._id && singleCart.configuration.length === 0 && mapItem.cart.configuration.length === 0)
        
        if (indexOfItem === -1) {
            products.push({ item: cartItem, quantity: 1, cart: singleCart, isCover: singleCart.isCover })
        } else {
            if (products[indexOfItem].item?.itemType === "custom" && products[indexOfItem].cart.configuration.length !== 0) {
                products.push({ item: cartItem, quantity: 1, cart: singleCart, isCover: singleCart.isCover })
            } else {
                products[indexOfItem].quantity++
            }
        }
    }

    return products
}

export function clearOrderData(order: Order) {
    const clonedOrder = JSON.parse(JSON.stringify(order)) as Order
    // @ts-ignore
    delete clonedOrder.annulloFiscale
    // @ts-ignore
    delete clonedOrder.fiscale
    // @ts-ignore
    delete clonedOrder.rider
    delete clonedOrder.mutateFix
    // @ts-ignore
    delete clonedOrder.payed
    if (clonedOrder.status !== 'canceled') {
        // @ts-ignore
        delete clonedOrder.status
    }
    // @ts-ignore
    delete clonedOrder.deliveryInfo.lastWatch
    // @ts-ignore
    delete clonedOrder.deliveryInfo.watched
    // @ts-ignore
    delete clonedOrder.consenses

    const tempCart: Cart[] = []
    clonedOrder.cart.map(single => {
        delete single.item?.integrations

        if (!single.isCover) {
            tempCart.push(single)
        }
    })
    clonedOrder.cart = tempCart

    return clonedOrder
}

export function getRealCartItemPrice(cartItem: CashDeskCartV2 | Cart, shopId: string) {
    if(cartItem.itemPromo > 0) {
        if(cartItem.itemPriceBeforePromo !== undefined) {
            return cartItem.itemPriceBeforePromo
        } else if (cartItem.item) {
            const cartItemShopPrice = cartItem.item?.shops?.find(shop => shop._id === shopId && shop.price !== undefined && shop.price !== null)?.price
            
            if(cartItemShopPrice !== undefined && cartItemShopPrice !== null) {
                return cartItemShopPrice
            }
            
            return cartItem.itemPrice ?? cartItem.item.price
        }
    }
    
    return cartItem.itemPrice
}

export function generatePaymentAmountText(paymentAmount: PaymentAmount, t: Function) {
    const paymentsList = Object.entries(paymentAmount).filter(([_, value]) => value > 0).map(([key, _]) => paymentTypes.key[key as PaymentTypesKey])
    
    return paymentsList.length > 0 ? paymentsList.join(", ") : t('Da pagare')
}

export function sumAllPaymentAmount(paymentAmount: PaymentAmount) {
    return Object.values(paymentAmount).reduce((acc, curr) => acc + curr, 0)
}

export function removeParam(queryParams : any, paramToDelete: string) {
    const transformQuery = transformToQueryParams('string', queryParams) as string
    const params = transformQuery.split('&')
    const result = []

    for (let i = 0; i < params.length; i++) {
      const param = params[i].split('=')
      const nameParam = param[0]
  
      if (nameParam !== paramToDelete) {
        result.push(params[i])
      }
    }
    return result.join('&')
}

export async function loadAndSetCrispData(globalContext: GlobalContextState, id?: number) {
    Crisp.configure(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID!, {autoload: true, lockFullview: isAndroidOrIos, sessionMerge: true})
    Crisp.setColorTheme(ChatboxColors.LightBlue)
    Crisp.setTokenId(globalContext.deviceUUID! + globalContext.email)
    Crisp.user.setEmail(globalContext.email)
    Crisp.user.setNickname(`${globalContext.name} ${globalContext.surname}`)
    Crisp.session.setData({
        currentAppVersion: await getFromStorage('lastUpdatedVersion'),
        isApp: isCapacitor,
        platform: isCapacitor ? isAndroidOrIos ? isIos ? 'iOS' : 'Android' : 'Electron' : 'Web',
        userType: globalContext.userType,
        brand: id ?  globalContext.brands[id].name || undefined : undefined
    })
    Crisp.chat.hide()
    Crisp.chat.onChatOpened(() => Crisp.chat.show())
    Crisp.chat.onChatClosed(() => Crisp.chat.hide())
}

export function updateFullScreenToStorage() {
    if(isElectron) {
        // @ts-ignore
        window.electron.getFullScreenState().then(async isFullscreen => {
            await saveToStorage('isFullscreen', isFullscreen)
        })
    }
}

export function checkInvoicingIsDisabled(obj: { [key: string]: number }): boolean {
    let count = 0;
    for (const key in obj) {
      if (obj[key] > 0) {
        count++;
        if (count >= 2) {
          return true;
        }
      }
    }
    return false;
}

export const removeQueryParam = (keyToRemove: string) => {
    const updatedParams = []
    const queryParamString = getQueryParams('string') as string
    
    if (!queryParamString || queryParamString.trim() === "") {
        return queryParamString
    }

    const queryParams = queryParamString.split("&")

    for (const param of queryParams) {
        const [key, value] = param.split("=")

        if (key !== keyToRemove) {
            updatedParams.push(`${key}=${value}`)
        }
    }

    const updatedQueryParamString = updatedParams.join("&")

    return updatedQueryParamString
}

export const getLanguageTable = () => {
    return getLanguage() === 'it' ? itIT.components.MuiDataGrid.defaultProps.localeText : undefined
}

export function getOrderItemCategories(order: Order) {
    const categoriesIds = []
    for (const cartItem of order.cart) {
        if (cartItem.isCover) continue

        const categoryId = cartItem.category !== undefined ? cartItem.category._id : cartItem?.item?.category as string
        categoriesIds.push(categoryId)

        if(cartItem.configuration && cartItem.configuration.length > 0) {
            for(const configuration of cartItem.configuration as CartConfiguration[]) {
                if (!configuration || !configuration.values) continue

                for (const value of configuration.values) categoriesIds.push((value?.linkedItem?.category as Category)?._id)
            }
        }
    }

    return new Set(categoriesIds)
}
