import React, { useContext, useMemo } from 'react'
import { useAddon, useBrand, useShops } from 'utils/hooks'
import { getMenuOptions } from 'utils/data'
import { useRouter } from 'next/router'
import { useTranslate } from 'utils/hooks'
import { Navbar, type NavbarElements } from '@easylivery/components'

import GlobalContext from 'context'

import { MenuOption } from 'types'

export default function AppNavBar() {
    const { userType } = useContext(GlobalContext)
    const { asPath, push } = useRouter()
    const { shops } = useShops()
    const { brand, id } = useBrand()
    const { t } = useTranslate()

    const options = useMemo(() => {
        return getMenuOptions(id, userType === 'shopManager', brand, shops).map((option, index) =>  option !== null && (!brand || canDisplayOption(option)) ? (
            {
                label: t(option.label),
                value: option.href,
                icon: option.icon
            }
        ) : null).filter(e => e !== null)
    }, [id, brand, asPath]) as NavbarElements[]

    function canDisplayOption(option: MenuOption) {
        const { showOnlyWhen, permission } = option!
        const optionsAllowed = shops.length > 0 ? ['shops'] : []

        if (useAddon('cashDesk').hasAddon) {
            optionsAllowed.push('cashDesk')
        }
        
        if(useAddon('table-order').hasAddon) {
            optionsAllowed.push('table-order')
        }
 
        return (option!.showOnlyWhen.length === 0 || optionsAllowed.filter(a => showOnlyWhen.indexOf(a) > -1).length === showOnlyWhen.length) && permission.indexOf(userType) > -1
    }

    return(
        <div className="border-t fixed bottom-0 bg-white w-full z-50">
            <Navbar elements={options} value={asPath} onChange={route => push(route)} path={asPath}/>
        </div>
    )
}