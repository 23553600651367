import { getEventsList } from "api/events";
import { saveFiscale } from "api/orders";
import { addFiscalPrintJob, addNonFiscalPrintJob, getPrinters } from "api/printers";
import GlobalContext from "context";
import { default as CashDeskContext, default as CashDeskContextV2 } from "context/cashDeskContext";
import CurrentPaymentContext from "context/currentPayment";
import HallManagerContext from "context/hallManagerContext";
import OrdersContext from "context/ordersContext";
import Heap from "heap-js";
import { useRouter } from "next/router";
import qs from "query-string";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddonName,
  CartConfigurationValue,
  Category,
  Item,
  Order,
  PrintJob,
  PrintJobStatus,
  Printer,
  PrinterError,
  PrinterOrderStatus,
  PrinterOrderTypes,
  PrinterResponse,
} from "types";
import { useInterval } from "usehooks-ts";
import { getOrderItemCategories, isClient } from "utils";
import { printPayment } from "./fiscalPrinter";
import { getCurrentLanguageValue } from "./language/utils";
import { printOuting } from "./printer";
import request from "api/request";

export function useBrand() {
  const {
    query: { id },
    asPath,
  } = useRouter();
  const brandIndex = id || (asPath.match(new RegExp(/\/brand\/[0-9]\//)) || [])[0]?.split("/")[2];
  const { brands } = useContext(GlobalContext);
  const brand = brands[Number(brandIndex)];

  return { brand, id: brandIndex as string };
}

export function useShops() {
  const { shops: allShops } = useContext(GlobalContext);
  const { brand } = useBrand();
  const shops = useMemo(() => allShops.filter((single) => single.brand === brand?._id), [allShops, brand]);

  return { shops };
}

export function useEvents() {
  const { brand } = useBrand();
  const { events } = getEventsList(brand._id, true);

  return { events };
}

export function checkShopManager() {
  const { userType, shops } = useContext(GlobalContext);

  return { isShopManager: userType === "shopManager", shops };
}

export function useAddon(addonName: AddonName, shop?: string | null, skipBrandOrShopCheck?: boolean) {
  const { addons } = useContext(GlobalContext);
  const { brand } = useBrand();

  const addon = addons.find((addon) => addon.name === addonName && (skipBrandOrShopCheck ? true : shop ? addon.shop === shop : addon.brand === brand?._id));

  return { hasAddon: addon !== undefined, addon };
}

export function useClickOutside(): [React.Ref<HTMLDivElement>, boolean] {
  const ref = useRef<HTMLDivElement>(null);
  const [clickedOutside, setClickedOutside] = useState<boolean>(false);

  function handleEvent(e: PointerEvent | MouseEvent | TouchEvent) {
    if (ref?.current?.contains(e.target as Node)) {
      setClickedOutside(false);
    } else {
      setClickedOutside(true);
    }
  }

  useEffect(() => {
    if (window.PointerEvent) {
      document.addEventListener("pointerdown", handleEvent);
    } else {
      document.addEventListener("mousedown", handleEvent);
      document.addEventListener("touchstart", handleEvent);
    }

    return () => {
      if (window.PointerEvent) {
        document.removeEventListener("pointerdown", handleEvent);
      } else {
        document.removeEventListener("mousedown", handleEvent);
        document.removeEventListener("touchstart", handleEvent);
      }
    };
  }, []);

  return [ref, clickedOutside];
}

export function useCashDeskContext() {
  const context = useContext(CashDeskContext);

  return context;
}

export function useCashDeskContextV2() {
  const context = useContext(CashDeskContextV2);

  return context;
}

export function useCurrentPaymentContext() {
  const context = useContext(CurrentPaymentContext);

  return context;
}

export function useOrdersContext() {
  const context = useContext(OrdersContext);

  return context;
}

export function useHallManagerContext() {
  const context = useContext(HallManagerContext);

  return context;
}

export function useFilterCategoriesByShopId({
  categories,
  shopId,
  searchText,
}: {
  categories: Category[] | undefined;
  shopId: string | null;
  searchText?: string;
}) {
  const categoriesEnabled: Category[] = [];

  categories?.forEach((category) => {
    if (category.open.enabled) {
      const newCategory: Category = { ...category, items: [] as Item[] };
      category.items.forEach((item) => {
        const foundItem = item.shops.find(
          (itemShop) => itemShop._id === shopId && itemShop.quantity !== undefined && (itemShop.quantity > 0 || itemShop.quantity === -1)
        );
        const isItemSoldOut = item.shops.some((itemShop) => itemShop._id === shopId && itemShop.quantity !== undefined && itemShop.quantity === 0);
        if (foundItem !== undefined && item.filterType.length > 0 && item.filterType.includes("cashDesk")) {
          newCategory.items.push({ ...item, price: (foundItem.price as number) || item.price });
        } else if (isItemSoldOut && item.filterType.length > 0 && item.filterType.includes("cashDesk")) {
          newCategory.items.push({ ...item, isSoldOut: true });
        }
      });

      if (newCategory.items.length > 0) {
        categoriesEnabled.push(newCategory);
      }
    }
  });

  if (!!searchText?.length && categoriesEnabled && !!categoriesEnabled.length) {
    const clonedCategoriesFiltered: Category[] = [];

    categoriesEnabled.forEach((category) => {
      const copiedCategory: Category = { ...category, items: [] as Item[] };
      category.items.forEach((item) => {
        if (
          getCurrentLanguageValue(item.otherLanguages, "name")?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          copiedCategory.items.push(item);
        }
      });

      if (copiedCategory.items.length > 0) {
        clonedCategoriesFiltered.push(copiedCategory);
      }
    });

    return clonedCategoriesFiltered;
  } else {
    return categoriesEnabled;
  }
}

export function useOperator() {
  const { operator } = useContext(GlobalContext);

  return operator;
}

export function useGetSelectedShops() {
  const { shops: allShops } = useShops();
  const { shops: queryShops } = qs.parse(location.search);

  const shops: string[] | undefined = useMemo(() => {
    if (!isClient) return undefined;

    if (queryShops) {
      if (Array.isArray(queryShops)) {
        return queryShops as string[];
      }

      return [queryShops];
    }

    return allShops.map((item) => item._id);
  }, [queryShops, allShops]);

  return shops;
}

export function useGetElementSize(elementName: string) {
  const [sizes, setSizes] = useState<{ width: number; height: number } | null>(null);
  const element = document.querySelector(elementName);

  if (!sizes && element) getSizes();

  function getSizes() {
    if (element) {
      const { width, height } = element.getBoundingClientRect();

      setSizes({ width, height });
    }
  }

  useEffect(() => {
    if (element) {
      window.addEventListener("resize", getSizes);
    }

    return () => {
      if (element) {
        window.removeEventListener("resize", getSizes);
      }
    };
  }, [element]);

  return sizes;
}

export function usePageUsableHeight() {
  const sizes = useGetElementSize("#layout-wrapper");
  const bannerAdminTokenCountdown = document.querySelector("#banner-admin-token-countdown");
  const bannerAppUpdateAvailable = document.querySelector("#banner-app-update-available");
  const sizeToSubtract =
    !bannerAdminTokenCountdown && !bannerAppUpdateAvailable
      ? 85
      : (bannerAdminTokenCountdown && !bannerAppUpdateAvailable) || (!bannerAdminTokenCountdown && bannerAppUpdateAvailable)
      ? 113
      : bannerAdminTokenCountdown && bannerAppUpdateAvailable
      ? 141
      : 0;

  return sizes?.height !== undefined ? sizes.height - sizeToSubtract : null;
}

export function useTranslate() {
  const { t: typeUnsafeTranslate } = useTranslation();

  function translateTypeSafe(key: string) {
    return typeUnsafeTranslate(key) ?? key;
  }

  return { t: translateTypeSafe };
}

export function useCountdown(endDate: Date) {
  const calculateTimeLeft = () => {
    let diff = +new Date(endDate) - +new Date();
    let timeLeft = { hours: "0", minutes: "0", seconds: "0" };

    if (diff > 0) {
      timeLeft = {
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24).toString(),
        minutes: Math.floor((diff / 1000 / 60) % 60)
          .toString()
          .padStart(2, "0"),
        seconds: Math.floor((diff / 1000) % 60)
          .toString()
          .padStart(2, "0"),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return timeLeft;
}

type Params = { orders: Order[]; printTimeoutMs?: number };
export function useFiscalPrinterQueue({ orders, printTimeoutMs }: Params) {
  const { brand } = useBrand();
  const { deviceUUID } = useContext(GlobalContext);
  const { isShopManager, shops: shopManagerShops } = checkShopManager();
  const printers =
    getPrinters(brand?._id, isShopManager ? shopManagerShops.map((shop) => shop._id).join(",") : undefined, brand !== undefined).printers?.data.filter(
      (printer) => printer.isFiscal
    ) || [];
  const nonFiscalprinters = getPrinters(brand?._id, isShopManager ? shopManagerShops.map((shop) => shop._id).join(",") : undefined, brand !== undefined).printers?.data.filter(
    (printer) => !printer.isFiscal
  ) || []

  printTimeoutMs = printTimeoutMs ?? 3000;
  if (typeof window === "undefined") return;

  if (window.printerJobs === undefined) window.printerJobs = new Map<string, PrintJob>();
  if (window.fiscalPrioQ === undefined)
    window.fiscalPrioQ = new Heap<string>((j1, j2) => (window.printerJobs.get(j2)?.priority || 0) - (window.printerJobs.get(j1)?.priority || 0));
  const prioQ = window.fiscalPrioQ;

  const getPrintJobs = (order: Order) => {
    const jobs: PrintJob[] = [];
    const printablePayments = order.payment?.filter((payment) => !payment.fiscale);
    if (!printablePayments) return jobs;

    const printerIds: string[] = [];
    for (const printer of printers) {
      const shouldPrint = checkOrderPrintable(order, printer);
      if (shouldPrint) printerIds.push(printer._id);
    }

    for (const payment of printablePayments) {
      if (payment.fiscale) window.printerJobs.delete(payment._id);
      if (printerIds.length === 0) continue;
      else
        jobs.push({
          id: payment._id.concat(order.status === "canceled" ? "CANC" : ""),
          orderId: order._id,
          orderStatus: order.status,
          status: PrintJobStatus.QUEUE,
          priority: 10, // TBD
          printerIds,
        });
    }

    return jobs;
  };

  const checkOrderPrintable = (order: Order, printer: Printer) => {
    const canShopPrint = printer.shops.includes(order.shop._id);
    if (!canShopPrint) return false;

    const deviceConfig = printer.devices.find((d) => d.uuid === deviceUUID);
    if (!deviceConfig) return false;

    const isOrderPaid = order.payed === "partial" || order.payed === "payed";
    if (!isOrderPaid) return false;

    const isStripeOrder = order.paymentAmount.stripe > 0 || order.paymentAmount.token > 0 || order.paymentAmount.postePay > 0;
    if (!isStripeOrder) return false;

    const canOrderStatusBePrinted = deviceConfig.autoPrint.orderStatus.includes(order.status as PrinterOrderStatus);
    if (!canOrderStatusBePrinted) return false;

    const canOrderTypeBePrinted = deviceConfig.autoPrint.ordersTypes.includes(order.orderType as PrinterOrderTypes);
    if (!canOrderTypeBePrinted) return false;

    return true;
  };

  useEffect(() => {
    if (!brand || !printers || !deviceUUID) return;

    for (const order of orders) {
      const jobs: PrintJob[] = getPrintJobs(order);
      for (const job of jobs) {
        const jobAlreadySeen = window.printerJobs.get(job.id) !== undefined;
        if (jobAlreadySeen) continue;

        window.printerJobs.set(job.id, job);
        prioQ.push(job.id);
      }
    }
  }, [orders]); // transformation of orders into printjobs

  useInterval(() => {
    const jobId = prioQ.pop();
    if (!jobId) return;

    const printJob = window.printerJobs.get(jobId);
    if (!printJob || printJob.status === PrintJobStatus.PRINTED || printJob.status === PrintJobStatus.PENDING) return;

    const orderOfPayment = printJob.rawOrderJson ? (JSON.parse(printJob.rawOrderJson) as Order) : orders.find((order) => order._id === printJob.orderId);
    if (!orderOfPayment) return;

    const paymentToPrint = orderOfPayment.payment?.find((payment) => payment._id === printJob.id);
    if (!paymentToPrint) return;

    const isDigitalPayment = paymentToPrint.paymentAmount.stripe > 0 || paymentToPrint.paymentAmount.token > 0 || paymentToPrint.paymentAmount.postePay > 0;
    if (!isDigitalPayment && !printJob.rawOrderJson) return; // WARN: ultra morb!!!

    const jobsPrinters = printers.filter((printer) => printJob.printerIds.includes(printer._id));
    printJob.status = PrintJobStatus.PENDING;

    for (const printer of jobsPrinters) {
      printPayment({
        order: orderOfPayment,
        payment: paymentToPrint,
        deviceUUID: deviceUUID!,
        fiscalPrinter: printer,
        successCallback: (res?: PrinterResponse) => {
          saveFiscale(
            printJob.orderStatus === "canceled" ? "annullo" : "commerciale",
            orderOfPayment,
            res!.receiptNumber,
            paymentToPrint._id,
            (fiscaleObject) => {
              printJob.status = PrintJobStatus.PRINTED;
              if (orderOfPayment.orderType !== "cashDesk") return;

              const printerIds: string[] = [];
              for (const printer of nonFiscalprinters) {
                if (printer.printFakeFiscalReceipt && printer.devices.findIndex((device) => device.uuid === deviceUUID) > -1) printerIds.push(printer._id);
              }

              if (printJob.courtesyReceipts && printerIds.length > 0 && fiscaleObject?.newFiscale) {
                for (let i = 0; i < printJob.courtesyReceipts; i++) {
                  const courtesyReceiptJob = {
                    id: `courtesy-${orderOfPayment._id}-${i}`,
                    orderId: orderOfPayment._id,
                    status: PrintJobStatus.QUEUE,
                    orderStatus: orderOfPayment.status,
                    priority: 100,
                    printerIds,
                    type: "receipt" as any,
                    fiscale: fiscaleObject.newFiscale,
                    rawOrderJson: printJob.rawOrderJson,
                    printFakeFiscalReceipt: true,
                  };

                  addNonFiscalPrintJob(courtesyReceiptJob);
                }
              }
            },
            true
          );
        },
        errorCallback: (err: PrinterError) => {
          const updatedJob = { ...printJob, status: PrintJobStatus.FAILED, priority: printJob.priority };
          addFiscalPrintJob(updatedJob);
        },
      });
    }
  }, printTimeoutMs); // feed to printer
}

export function useNonFiscalPrinterQueue({ orders, printTimeoutMs }: Params) {
  const { brand } = useBrand();
  const { deviceUUID, authenticated } = useContext(GlobalContext);
  const { isShopManager, shops: shopManagerShops } = checkShopManager();
  const { t } = useTranslate();
  const printers =
    getPrinters(brand?._id, isShopManager ? shopManagerShops.map((shop) => shop._id).join(",") : undefined, brand !== undefined).printers?.data.filter(
      (printer) => !printer.isFiscal
    ) || [];

    // ping printers
  useInterval(() => {
    printers.map(printer => {
        if(printer.devices.findIndex(device => device.uuid === deviceUUID) > -1) {
          if(authenticated) {
              request('get', `${printer.ssl ? 'https' : 'http'}://${printer.ip}/cgi-bin/epos/service.cgi`, undefined, undefined, true)
              .catch(() => {})
          }
        }
    })
}, 3000)

  printTimeoutMs = printTimeoutMs ?? 2000;
  if (typeof window === "undefined") return;

  if (window.printerJobs === undefined) window.printerJobs = new Map<string, PrintJob>();
  if (window.nonFiscalPrioQ === undefined)
    window.nonFiscalPrioQ = new Heap<string>((j1, j2) => (window.printerJobs.get(j2)?.priority || 0) - (window.printerJobs.get(j1)?.priority || 0));
  const prioQ = window.nonFiscalPrioQ;

  const getPrintJob = (order: Order): PrintJob => {
    const printerIds: string[] = [];
    for (const printer of printers) {
      const shouldPrint = checkOrderPrintable(order, printer);
      if (shouldPrint) printerIds.push(printer._id);
    }

    return {
      id: order._id.concat(order.orderOuting.length > 0 ? (order.orderOuting.length - 1).toString() : "0", order.status === "canceled" ? "CANC" : ""), // TODO: map to number
      orderId: order._id,
      status: PrintJobStatus.QUEUE,
      orderStatus: order.status,
      priority: 10,
      printerIds: printerIds,
    };
  };

  const checkOrderPrintable = (order: Order, printer: Printer) => {
    const orderAlreadyPrinted = order.printed.nonFiscal;
    if (orderAlreadyPrinted) return false;

    const canShopPrint = printer.shops.includes(order.shop._id);
    if (!canShopPrint) return false;

    const deviceConfig = printer.devices.find((d) => d.uuid === deviceUUID);
    if (!deviceConfig) return false;

    const canOrderStatusBePrinted = deviceConfig.autoPrint.orderStatus.includes(order.status as PrinterOrderStatus);
    if (!canOrderStatusBePrinted) return false;

    const canOrderTypeBePrinted = deviceConfig.autoPrint.ordersTypes.includes(order.orderType as PrinterOrderTypes);
    if (!canOrderTypeBePrinted) return false;

    const orderCartHasProducts = order.cart.some((item) => !item.isCover);
    if (!orderCartHasProducts) return false;

    const orderCategories = getOrderItemCategories(order);

    const categoriesToPrint = printer.categories.concat(printer.categoriesToReprint);
    const orderCartHasCategoriesToBePrinted = categoriesToPrint.some((category) => orderCategories.has(category)) || printer.categories.length === 0;
    if (!orderCartHasCategoriesToBePrinted) return false;

    const checkPrintNotPaidOrder =
      order.payed !== "payed"
        ? (deviceConfig.autoPrint.printNotPayedOrders && order.orderType !== "table" && order.shop.tables.paymentTime !== "before") ||
          (order.orderType === "table" && order.shop.tables.paymentTime === "after") ||
          order.orderType === "delivery"
        : (order.paymentAmount.stripe === 0 && deviceConfig.autoPrint.printNotPayedOrders) || order.paymentAmount.stripe > 0;

    if (!checkPrintNotPaidOrder) return false;

    return true;
  };

  useEffect(() => {
    if (!brand || !printers || !deviceUUID) return;

    for (const order of orders) {
      const job: PrintJob = getPrintJob(order);

      const jobAlreadySeen = window.printerJobs.get(job.id) !== undefined;
      if (jobAlreadySeen || job.printerIds.length === 0) continue;

      window.printerJobs.set(job.id, job);
      prioQ.push(job.id);
    }
  }, [orders]); // transformation of orders into printjobs

  useInterval(() => {
    const jobId = prioQ.pop();
    if (!jobId) return;

    const printJob = window.printerJobs.get(jobId);
    if (!printJob || printJob.status !== PrintJobStatus.QUEUE) return;

    const order = printJob.rawOrderJson ? (JSON.parse(printJob.rawOrderJson) as Order) : orders.find((order) => order._id === printJob.orderId);
    if (!order) return;

    const outingToPrint = order.orderType === "cashDesk" ? undefined : Number.parseInt(printJob.id.charAt(printJob.id.length - 1));
    const jobsPrinters = printers.filter((printer) => printJob.printerIds.includes(printer._id));
    printJob.status = PrintJobStatus.PENDING;

    // // ping printers
    // printers.map(printer => {
    //     if(printer.devices.findIndex(device => device.uuid === deviceUUID) > -1) {
    //         request('get', `https://${printer.ip}`, undefined, undefined, true)
    //         .catch(() => {})
    //     }
    // })

    for (const printer of jobsPrinters) {
      printOuting({
        order: order,
        printer: printer,
        deviceUUID: deviceUUID!,
        orderNumber: outingToPrint,
        autoPrint: order.orderType !== "cashDesk",
        type: printJob.id.startsWith("courtesy") ? "receipt" : "order",
        fiscale: printJob.fiscale,
        printFakeFiscalReceipt: printJob.printFakeFiscalReceipt,
        t: t,
        successCallback: () => {
          printJob.status = PrintJobStatus.PRINTED;
        },
        errorCallback: () => {
          printJob.status = PrintJobStatus.FAILED;
        },
      });
    }
  }, printTimeoutMs); // feed to printer
}
