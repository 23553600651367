import cn from 'classnames'
import { useState } from 'react'

import NotificationsLayout from 'components/notifications/notificationsLayout'
import Menu from 'components/menu'
import { IconButton } from '@easylivery/components'
import { Notifications } from '@mui/icons-material'

import { Order } from 'types'

type Props = {
    orders: Order[] | undefined
}


export default function NotificationMenu({ orders}: Props) {
    const [menuState, setMenuState] = useState<boolean>(false)
    const content = (
        <div className={cn("absolute bg-white rounded-xl shadow-lg flex-col right-0 top-2 z-50 border")}>
            <NotificationsLayout orders={orders} close={setMenuState}/>
        </div>
    )

    return(  
        <Menu content={content}>
            <div className={cn("flex flex-row justify-center rounded-full items-center w-8 h-8 bg-white border-[1px] cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75", {'border-primary': menuState}, {'border-gray-200': !menuState})}>
                <IconButton className="!bg-gray-100">
                    <Notifications/>
                </IconButton>
                {(orders && orders.length > 0) && <p className={cn("absolute ml-6 mb-6 bg-red-500  flex font-medium text-white rounded-full justify-center items-center text-xs", {'px-[0.45rem] py-[0.15rem]': orders.length < 10}, {'py-[0.2rem] px-[0.25rem]' : orders.length > 9})}>{orders.length}</p>}
            </div>
        </Menu>
    )
}