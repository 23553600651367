import cn from 'classnames'
import { useEffect, useState } from 'react'
import { notificationsElement } from 'utils/data'
import { getFromStorage, isClient, saveToStorage } from 'utils'
import { useTranslate } from 'utils/hooks'
import { getTime } from 'date-fns'

import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SingleNotification from './singleNotification'

import { Order } from 'types'

import { faVolumeSlash, faBell } from '@fortawesome/pro-regular-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

type Props = {
    orders: Order[] | undefined
    close: (state: boolean) => void 
}

export default function NotificationsLayout({orders, close}: Props){
    const { t } = useTranslate()
    const [volume, setVolume] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<'all'|'delivery'|'takeAway'|'table'|'express'|'canceled'>('all')
    const sortedOrders = orders?.sort((a, b) => getTime(new Date(b.created)) - getTime(new Date(a.created))) || []
    const ordersToShow = toShow()

    function toShow(){
        if(selectedTab !== 'all' && selectedTab !== 'delivery' && selectedTab !== 'takeAway' && selectedTab !== 'canceled'){
            return sortedOrders.filter(single => single.orderType === selectedTab).filter(single => single.status !== 'canceled')
        } else if(selectedTab === 'canceled'){
            return sortedOrders.filter(single => single.status === 'canceled')
        } else if(selectedTab === 'takeAway'){
            return sortedOrders.filter(single => single.orderType === "delivery" && single.deliveryInfo.homeDelivery === false).filter(single => single.status !== 'canceled')
        } else if(selectedTab === 'delivery'){
            return sortedOrders.filter(single => single.orderType === "delivery" && single.deliveryInfo.homeDelivery !== false).filter(single => single.status !== 'canceled')
        }  else 
            return sortedOrders
    }
    
    useEffect(() => {
        if(isClient) {
            (async () => {
                setVolume(await getFromStorage('notificationVolume') === 'true')
            })()
        }
    }, [isClient])

    useEffect(() => {
        (async () => {
            saveToStorage('notificationVolume', volume.toString())
        })()
    }, [volume])

    return (
        <>
            <div className="flex flex-row gap-1 md:gap-4 truncate items-center justify-center md:mx-5 md:mt-5">
                {notificationsElement.valueLabel.map(single => (
                    <Button noBorder noRounded className={cn("font-medium px-1 md:px-4 cursor-pointer focus:ring-0", {'border-primary text-primary border-b-[4px]': selectedTab === single.value })} onClick={() => {setSelectedTab(single.value as 'all'|'delivery'|'table'|'express'|'canceled');}}>
                        {single.label}
                    </Button>
                ))}
            </div>
            <div className="flex flex-row justify-end sm:mx-5 mt-3 items-center">
                <FontAwesomeIcon icon={volume ? faVolumeUp : faVolumeSlash} className={cn("cursor-pointer",{"text-green-500": volume}, {"text-orange-500": !volume})} onClick={() => setVolume(!volume)}/>
            </div>
            <div className="2sm:max-h-72 overflow-y-scroll">
                {ordersToShow.map(single => <SingleNotification single={single} close={close}/>)}
            </div>
            {ordersToShow.length === 0 && (
                <div className="m-10 flex flex-col items-center gap-5">
                    <div className="flex justify-center rounded-full items-center w-16 h-16 bg-primary">
                        <FontAwesomeIcon icon={faBell} className="text-white text-2xl"/>
                    </div>
                    <p className="font-medium text-center">{t('Non ci sono ordini da visualizzare')}</p>
                </div>
            )}
        </>
    )
}