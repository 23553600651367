export const theme = {
    typography: {
        fontFamily: "'IBM Plex Sans', 'Helvetica Neue', sans-serif",
        body1: {
            fontFamily: "'IBM Plex Sans', 'Helvetica Neue', sans-serif",
        },
        body2: {
            fontFamily: "'IBM Plex Sans', 'Helvetica Neue', sans-serif",
        }
    }
}