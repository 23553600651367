import { CashDeskCartV2 } from 'types';
import i18n from './index'
import translations from './translations'
import { getFromStorage, saveToStorage } from 'utils'

import { SupportedLanguages } from 'types'

export function changeLanguage(newLanguage: 'it' | 'en') {
    if (typeof window !== 'undefined') {
        i18n.changeLanguage(newLanguage)
        document.documentElement.setAttribute('lang', newLanguage)
    }
}

export async function getStorageLanguage() {
    return await getFromStorage('el-language')
}

export async function detectBrowserLanguage() {
    if (typeof window !== 'undefined' && !await getStorageLanguage()) {
        const supportedLangs = Object.keys(translations)
        let lang = (navigator.languages && navigator.languages[0]) || navigator.language || 'en'
        lang = lang.split('-')[0] || lang

        if (supportedLangs.indexOf(lang) === -1) {
            lang = 'en'
        }

        await saveToStorage('el-language', lang)
    }
}

export function getCurrentLanguageValue(otherLanguages: {[key in Exclude<SupportedLanguages, 'it'>]: any} | undefined, key: 'name' | 'description') {
    const currentOtherLanguage = otherLanguages ? otherLanguages[i18n.language as Exclude<SupportedLanguages, 'it'>] : null
    
    if(currentOtherLanguage && currentOtherLanguage[key] && currentOtherLanguage[key].length > 0 && currentOtherLanguage[key] !== 'NaN') {
        return currentOtherLanguage[key]
    }

    return null
}