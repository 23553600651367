import { setPrintedOrders } from 'api/orders'
import { dequal } from 'dequal'
import { addNotification, formatDate, generatePaymentAmountText, getCurrency, getProductItemQuantityArray, isCapacitor, numberFormatter } from 'utils'
import { allergensList, orderType } from 'utils/data'

import { getCleanQueryParams } from 'api/analytics'
import { Cart, CartConfiguration, CartConfigurationValue, CartItemWithQuantity, Category, Currencies, Fiscale, MergedSelledProducts, Mutate, Order, OrderTaxation, OrderV2, Payment, Printer, PrinterOrderStatus, PrinterOrderTypes, SelledProducts, Share, Shop } from 'types'


function usePrinter(printerSettings: Printer, t: Function, autoPrint?: boolean, deviceUUID?: string | null) {
    return new Promise<{printer: any, supportedOrders: PrinterOrderTypes[], supportedStatus: PrinterOrderStatus[], printNotPayedOrders: boolean, hideDetails: boolean, hideIngredients: boolean, hideAllergens: boolean}>(async (resolve, reject) => {
        const printerDevice = printerSettings.devices.find(device => device.uuid === deviceUUID)
        const { supportedOrders, supportedStatus, printNotPayedOrders, hideDetails, hideAllergens, hideIngredients } = {supportedOrders: printerDevice?.autoPrint.ordersTypes || ['delivery', 'takeAway', 'express', 'table', 'experience'] as PrinterOrderTypes[], hideDetails: printerDevice?.autoPrint.hideDetails || false, hideIngredients: printerDevice?.autoPrint.hideIngredients || false, hideAllergens: printerDevice?.autoPrint.hideAllergens || false, supportedStatus: printerDevice?.autoPrint.orderStatus || ['pending', 'confirmed', 'canceled'] as PrinterOrderStatus[],  printNotPayedOrders: printerDevice && autoPrint ? printerDevice.autoPrint.printNotPayedOrders : true}

        if(printerDevice) {
            // @ts-ignore
            if (window[`el_printer_${printerSettings._id}`] && window[`el_printer_${printerSettings._id}`].connectionObj.address_p !== '') {
                // @ts-ignore
                return resolve({printer: window[`el_printer_${printerSettings._id}`], supportedOrders, supportedStatus, printNotPayedOrders, hideDetails, hideAllergens, hideIngredients})
            } else if (printerSettings) {
                // @ts-ignore
                const ePosSdk: any = new epsonPrinter.ePOSDevice()
                ePosSdk.connect(printerSettings.ip, printerSettings.port, (data: string) => {
                    if (data === 'OK' || data === 'SSL_CONNECT_OK') {
                        ePosSdk.createDevice(printerSettings.password, ePosSdk.DEVICE_TYPE_PRINTER, { 'crypto': true, 'buffer': false }, (devobj: any, res: string) => {
                            if (res === 'OK') {
                                const printer = devobj
                                printer.timeout = 15000
                                printer.oncoveropen = () => alert('Chiudere il cassetto')
                                printer.onpapernearend = () => alert('La carta della stampante sta per finire')
                                printer.onpaperend = () => alert('Carta stampante esaurita')
                                printer.onerror = () => {
                                    addNotification(`${t("Si è verificato un errore, la stampante è disconnessa o bloccata")}`, 'danger')

                                    // @ts-ignore
                                    delete window[`el_printer_${printerSettings._id}`]
                                    return reject()
                                }
                                // @ts-ignore
                                window[`el_printer_${printerSettings._id}`] = printer
                                return resolve({printer, supportedOrders, supportedStatus, printNotPayedOrders, hideDetails, hideAllergens, hideIngredients})
                            } else {
                                return reject()
                            }
                        })
                    } else {
                        return reject()
                    }
                })
            } else {
                return reject()
            }   
        } else {
            return reject('NoDeviceEnabled')
        }
    })
}

function padLine(left: string, right: string) {
    const columns = 48
    const concatedLenght = left.length + right.length

    if (concatedLenght < columns) {
        return left + ' '.repeat(columns - concatedLenght) + right
    } else {
        let truncatedLeft = left.substring(0, left.length - ((concatedLenght - columns) + right.length)) + '...'
        return truncatedLeft + ' '.repeat(columns - (truncatedLeft.length + right.length)) + right
    }
}

type PrintOutingParams = {
    order: Order,
    printer: Printer,
    deviceUUID: string,
    orderNumber?: number,
    type: 'order' | 'receipt',
    autoPrint?: boolean,
    t: Function,
    fiscale?: OrderTaxation,
    printFakeFiscalReceipt?: boolean,
    successCallback: Function,
    errorCallback: Function
}

export function printOuting(params: PrintOutingParams) {
    epsonPrint([params.order], params.t, params.printer, params.orderNumber, undefined, params.type, params.fiscale, undefined, params.autoPrint, params.deviceUUID, undefined, undefined, undefined, params.successCallback, params.errorCallback, undefined, undefined, params.printFakeFiscalReceipt)
}

export function epsonPrint(orders: Order[] | OrderV2[], t: Function, currentPrinter?: Printer, orderNumber?: number, brandName?: string, type?: 'order' | 'receipt', fiscale?: OrderTaxation, annulloFiscale?: OrderTaxation, autoPrint?: boolean, deviceUUID?: string | null, mutate?: Mutate<Order> | Mutate<Order[]> | Mutate<{ order: Order }> | Mutate<{ order: OrderV2 }>, printFiscalLikeReceipt?: boolean, paymentId?: string, successCallback?: Function, errorCallback?: Function, receiptShares?: Share[], printBeforePaymentSubmit?: boolean, printFakeFiscalReceipt?: boolean) {
    if(isCapacitor) {
        orders.map((order) => {
            if((autoPrint ? !order.printed.nonFiscal : true) && order.shop && order.shop.printers) {
                (currentPrinter === undefined ? order.shop.printers.filter(printer => !printer.isFiscal && (printFiscalLikeReceipt ? printer.printFiscalLikeReceipt && (printFakeFiscalReceipt ? printer.printFakeFiscalReceipt : true) : true)) : [currentPrinter]).map(savedPrinter => {
                    usePrinter(savedPrinter, t, autoPrint, deviceUUID)
                    .then(({printer, supportedOrders, supportedStatus, printNotPayedOrders, hideAllergens, hideDetails, hideIngredients }) => {
                        const productsTextDimension = savedPrinter.productsTextDimension || 1
                        const customProductsTextDimension = savedPrinter.customProductsTextDimension || 1
                        const shopTextDimensions = savedPrinter.shopTextDimension || 1
                        const fixedOrderCart = order.orderType === 'table' && order.shop.tables.paymentTime === 'before' && isOrderV2(order) ? order.cart.filter(cartItem => cartItem.status !== 'waiting') : order.cart
                        orderNumber = order.orderType !== 'table' ? undefined : orderNumber
                        const orderCart = orderNumber !== undefined && type === 'order' ? (fixedOrderCart as unknown as Cart[]).filter(cartItem => cartItem.orderNumber === orderNumber) : fixedOrderCart
                        function subtotalDiscount() {
                            const itemsDiscount = (orderCart as unknown as Cart[]).reduce((previous, current) => previous + current.itemPromo, 0)
                            return order.promoPrice - itemsDiscount
                        }
                        
                        if(!autoPrint || supportedStatus.indexOf(order.status as unknown as PrinterOrderStatus) > -1 && (supportedOrders.indexOf(order.orderType as unknown as PrinterOrderTypes) > -1 || (supportedOrders.indexOf('delivery') > -1 && order.orderType === 'delivery' && !order.deliveryInfo.homeDelivery))) {
                            const printNotPayedOrdersExceptions = order.orderType === 'delivery' || (order.orderType === 'table' && order.shop.tables.paymentTime === 'after')
                            if(!autoPrint || (!printNotPayedOrders ? order.payed !== 'pending' || printNotPayedOrdersExceptions : true)) {
                                const currency = getCurrency(order.shop.currency as Currencies)
                                const ingredients = (orderCart as unknown as Cart[])?.map(cart => cart.item?.ingredients?.map(ingredient => ingredient.value)).reduce((a, b) => a.concat(b), []).filter((ingredient, index, self) => self.indexOf(ingredient) === index)
                                const allergens = (orderCart as unknown as Cart[])?.map(cart => cart.item?.allergens?.map(allergen => allergen)).reduce((a, b) => a.concat(b), []).filter((allergen, index, self) => self.indexOf(allergen) === index).map(allergen => allergensList.key[allergen])
                                const categoryGroupedCart: {[key: string]: (CartItemWithQuantity | null)[]} = {}
                                const allCategoryGroupedCart: {[key: string]: (CartItemWithQuantity | null)[]} = {}
                                getProductItemQuantityArray(JSON.parse(JSON.stringify(orderCart))).map(cartItem => categoryGroupedCart[(cartItem.item?.category as Category)?.name || t('Nessuna categoria')] = [...(categoryGroupedCart[(cartItem.item?.category as Category)?.name || t('Nessuna categoria')] || []), cartItem])
                                getProductItemQuantityArray(JSON.parse(JSON.stringify(orderCart))).map(cartItem => allCategoryGroupedCart[(cartItem.item?.category as Category)?.name || t('Nessuna categoria')] = [...(allCategoryGroupedCart[(cartItem.item?.category as Category)?.name || t('Nessuna categoria')] || []), cartItem])
                                if(!printFiscalLikeReceipt && savedPrinter.categories && savedPrinter.categories.length > 0) {
                                    Object.keys(categoryGroupedCart).map(categoryName => {
                                        categoryGroupedCart[categoryName].map((cartItem, cartItemIndex) => {
                                            if(cartItem?.item?.itemType === 'custom' && cartItem.cart.configuration && cartItem.cart.configuration.length > 0) {
                                                (cartItem.cart.configuration as CartConfiguration[]).map((cartConfiguration, cartConfigurationIndex) => {
                                                    if(categoryGroupedCart[categoryName][cartItemIndex] && categoryGroupedCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex] && categoryGroupedCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex]?.values) {
                                                        categoryGroupedCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values = cartConfiguration.values.filter((cartConfigurationValue, cartConfigurationValueIndex) => {
                                                            if(savedPrinter.splitCustomLinkedItems) {
                                                                if(cartConfigurationValue.linkedItem && savedPrinter.categories.indexOf((cartConfigurationValue.linkedItem?.category as Category)._id) > -1) {
                                                                    const itemToAggregateIndex = categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name]?.findIndex(itemToFind => itemToFind?.cart._id === cartItem.cart._id && itemToFind?.item?._id === cartConfigurationValue.linkedItem?._id)
                                                                    
                                                                    if(itemToAggregateIndex > -1 && categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]) {
                                                                        const configurationToAggregateIndex = categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration.findIndex(configurationToFind => configurationToFind.key === cartConfiguration.key)
                                                                        
                                                                        if(configurationToAggregateIndex > -1 && categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex] && categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]?.cart.configuration[configurationToAggregateIndex].values) {
                                                                            // @ts-ignore
                                                                            categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration[configurationToAggregateIndex].values.push({value: cartConfigurationValue.value})
                                                                        } else {
                                                                            // @ts-ignore
                                                                            categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration.push({key: cartConfiguration.key, values: [{value: cartConfigurationValue.value}]})
                                                                        }
                                                                    } else {
                                                                        // @ts-ignore
                                                                        categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name] = [...(categoryGroupedCart[(cartConfigurationValue.linkedItem.category as Category)?.name] || []), {quantity: 1, cart: {_id: cartItem.cart._id!, selectedItems: [], configuration: [{key: cartConfiguration.key, values: [{value: cartConfigurationValue.value}]}], item: cartConfigurationValue.linkedItem, itemName: cartConfigurationValue.linkedItem?.name}, item: cartConfigurationValue.linkedItem}]
                                                                    }
                                                                    
                                                                    if(categoryGroupedCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex]?.values) {
                                                                        // @ts-ignore
                                                                        categoryGroupedCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values[cartConfigurationValueIndex] = null
                                                                    }
                                                                    
                                                                    return true
                                                                } else if(!cartConfigurationValue.linkedItem && savedPrinter.categories.indexOf((cartItem?.item?.category as Category)._id) > -1) {
                                                                    return true
                                                                } else {
                                                                    return false
                                                                }
                                                            } else {
                                                                return (savedPrinter.categories.indexOf((cartItem?.item?.category as Category)._id) > -1 && !cartConfigurationValue.linkedItem) || (cartConfigurationValue.linkedItem && savedPrinter.categories.indexOf((cartConfigurationValue.linkedItem?.category as Category)._id) > -1)
                                                            }
                                                        })
                                                    }

                                                    if(categoryGroupedCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex]?.values) {
                                                        categoryGroupedCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values = (categoryGroupedCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values as CartConfigurationValue[]).filter(value => value !== null)
                                                    }
                                                })

                                                if(savedPrinter.splitCustomLinkedItems) {
                                                    if(savedPrinter.categories.indexOf((cartItem?.item?.category as Category)._id) === -1) {
                                                        categoryGroupedCart[categoryName][cartItemIndex] = null
                                                    }
                                                } else if((categoryGroupedCart[categoryName][cartItemIndex]?.cart.configuration as CartConfiguration[]).filter(cartConfiguration => cartConfiguration?.values && cartConfiguration.values.length > 0).length === 0) {
                                                    categoryGroupedCart[categoryName][cartItemIndex] = null
                                                }
                                            } else if(cartItem?.item && savedPrinter.categories.indexOf((cartItem?.item?.category as Category)._id) === -1) {
                                                categoryGroupedCart[categoryName][cartItemIndex] = null
                                            }
                                        })
                                    })
                                }
                                Object.keys(categoryGroupedCart).map(catToCheck => {
                                    categoryGroupedCart[catToCheck].map((cartItem, cartItemIndex) => {
                                        categoryGroupedCart[catToCheck].map((cartItemToFind, cartItemToFindIndex) => {
                                            if(cartItemIndex !== cartItemToFindIndex && cartItem && cartItemToFind && cartItem?.item?._id === cartItemToFind?.item?._id) {
                                                if((!cartItem.cart.configuration || cartItem.cart.configuration.length === 0) && (!cartItemToFind.cart.configuration || cartItemToFind.cart.configuration.length === 0)) {
                                                    categoryGroupedCart[catToCheck][cartItemIndex]!.quantity++
                                                    categoryGroupedCart[catToCheck][cartItemToFindIndex] = null
                                                } else if(cartItem.cart.configuration && cartItem.cart.configuration.length > 0 && cartItemToFind.cart.configuration && cartItemToFind.cart.configuration.length > 0) {
                                                    const clonedCartCartItemConfiguration = JSON.parse(JSON.stringify(cartItem.cart.configuration)) as CartConfiguration[]
                                                    clonedCartCartItemConfiguration.map(clonedCartCartItemConfigurationSingle => clonedCartCartItemConfigurationSingle ? delete clonedCartCartItemConfigurationSingle._id : null)
                                                    const clonedCartCartItemToFindConfiguration = JSON.parse(JSON.stringify(cartItemToFind.cart.configuration)) as CartConfiguration[]
                                                    clonedCartCartItemToFindConfiguration.map(clonedCartCartItemToFindConfigurationSingle => clonedCartCartItemToFindConfigurationSingle ? delete clonedCartCartItemToFindConfigurationSingle._id : null)

                                                    if(dequal(clonedCartCartItemConfiguration, clonedCartCartItemToFindConfiguration)) {
                                                        categoryGroupedCart[catToCheck][cartItemIndex]!.quantity++
                                                        categoryGroupedCart[catToCheck][cartItemToFindIndex] = null
                                                    }
                                                }
                                            }
                                        })
                                    })

                                    categoryGroupedCart[catToCheck] = categoryGroupedCart[catToCheck].filter(cartItem => cartItem !== null)
                                })
                                
                                Object.keys(allCategoryGroupedCart).map(catToCheck => {
                                    allCategoryGroupedCart[catToCheck].map((cartItem, cartItemIndex) => {
                                        allCategoryGroupedCart[catToCheck].map((cartItemToFind, cartItemToFindIndex) => {
                                            if(cartItemIndex !== cartItemToFindIndex && cartItem && cartItemToFind && cartItem?.item?._id === cartItemToFind?.item?._id) {
                                                if((!cartItem.cart.configuration || cartItem.cart.configuration.length === 0) && (!cartItemToFind.cart.configuration || cartItemToFind.cart.configuration.length === 0)) {
                                                    allCategoryGroupedCart[catToCheck][cartItemIndex]!.quantity++
                                                    allCategoryGroupedCart[catToCheck][cartItemToFindIndex] = null
                                                } else if(cartItem.cart.configuration && cartItem.cart.configuration.length > 0 && cartItemToFind.cart.configuration && cartItemToFind.cart.configuration.length > 0) {
                                                    const clonedCartCartItemConfiguration = JSON.parse(JSON.stringify(cartItem.cart.configuration)) as CartConfiguration[]
                                                    clonedCartCartItemConfiguration.map(clonedCartCartItemConfigurationSingle => clonedCartCartItemConfigurationSingle ? delete clonedCartCartItemConfigurationSingle._id : null)
                                                    const clonedCartCartItemToFindConfiguration = JSON.parse(JSON.stringify(cartItemToFind.cart.configuration)) as CartConfiguration[]
                                                    clonedCartCartItemToFindConfiguration.map(clonedCartCartItemToFindConfigurationSingle => clonedCartCartItemToFindConfigurationSingle ? delete clonedCartCartItemToFindConfigurationSingle._id : null)

                                                    if(dequal(clonedCartCartItemConfiguration, clonedCartCartItemToFindConfiguration)) {
                                                        allCategoryGroupedCart[catToCheck][cartItemIndex]!.quantity++
                                                        allCategoryGroupedCart[catToCheck][cartItemToFindIndex] = null
                                                    }
                                                }
                                            }
                                        })
                                    })
                                
                                    allCategoryGroupedCart[catToCheck] = allCategoryGroupedCart[catToCheck].filter(cartItem => cartItem !== null)
                                })

                                if(Object.keys(categoryGroupedCart).filter(categoryName => categoryGroupedCart[categoryName].length > 0).length > 0 || (printFakeFiscalReceipt && type === 'receipt')) {
                                    printer.addTextAlign(printer.ALIGN_CENTER)
                                    printer.brightness = 1.0
                                    // printer.halftone = printer.HALFTONE_ERROR_DIFFUSION
                                    printer.addTextSmooth(true)
            
                                    if (type === 'receipt') {
                                        console.log('in')
                                        printer.addFeedLine(1)
                                        if (order.shop.billing.denominazione !== undefined && order.shop.billing.denominazione !== '') {
                                            printer.addText(`${order?.shop.billing?.denominazione}\n`)
                                        }
                                        if ((order.shop.billing.indirizzo !== undefined && order.shop.billing.indirizzo !== '') || (order.shop.billing.numeroCivico !== undefined && order.shop.billing.numeroCivico !== '')) {
                                            printer.addText(`${!!order.shop.billing.indirizzo ? order?.shop.billing?.indirizzo : ''} ${!!order.shop.billing.numeroCivico ? ', ' + order?.shop.billing?.numeroCivico : ''}\n`)
                                        }
                                        if (order.shop.billing.cap !== undefined || order.shop.billing.comune !== undefined || order.shop.billing.provincia !== undefined) {
                                            printer.addText(`${!!order.shop.billing.cap ? order?.shop.billing?.cap + " - " : ''} ${!!order.shop.billing.comune ? order?.shop.billing?.comune : ''} ${!!order.shop.billing.provincia ? '(' + order?.shop.billing?.provincia + ')' : ''}\n`)
                                        }
                                        if (order.shop.billing.piva !== undefined && order.shop.billing.piva !== '') {
                                            printer.addText(`P.Iva/CF: ${order?.shop.billing?.piva}\n`)
                                        }
                                        printer.addFeedLine(1)
                                        if (fiscale) {
                                            printer.addText(`${t('DOCUMENTO COMMERCIALE')}\n`)
                                            if (annulloFiscale) {
                                                printer.addText(`${t('emesso per ANNULLAMENTO')}\n`)
                                                printer.addText(`${t('Documento di riferimento:')}\n`)
                                                printer.addText(`N. ${fiscale.progressivo}\n`)
                                            } else {
                                                printer.addText(`${t('di vendita o prestazione')}\n`)
                                            }
            
                                        } else {
                                            printer.addText(`Preconto\n`)
                                        }
                                        printer.addFeedLine(2)
                                        printer.addTextAlign(printer.ALIGN_LEFT)
                                        printer.addTextSize(1, 1)
                                        const currentPayment = !!paymentId ?
                                            (order.payment as unknown as Payment[]).find(singlePayment => singlePayment._id === paymentId) 
                                        : 
                                            !!order.payment ? order.payment.at(order.payment.length - 1) : undefined
                                        
                                        const paymentCart = (order.orderType === 'express'  || order.paymentMode === undefined || order.paymentMode === 'total' || (order.paymentMode === 'items' && printBeforePaymentSubmit)) ?
                                            orderCart
                                        :
                                            (orderCart as unknown as Cart[]).filter(cartItem => currentPayment?.cartItems?.find(itemId => itemId === cartItem._id))

                                        if (order?.orderType === 'table') {
                                            printer.addTextStyle(false, false, true, printer.COLOR_1)
                                            const coverCount = order.paymentMode === 'total' 
                                            ? 
                                                (isOrderV2(order) ?
                                                    order.saloonInfo.people
                                                :
                                                    order.deliveryInfo.tablePeople)
                                            : 
                                                (paymentCart as unknown as Cart[]).filter(cartItem => cartItem.isCover).length

                                            const coverPrice = isOrderV2(order) ? order.saloonInfo.servicePrice : order.deliveryPrice
                                            if (coverCount > 0 && coverPrice > 0) {
                                                printer.addText(`${padLine(t('Coperto') + " x " + coverCount, `${numberFormatter(coverPrice, undefined, true)}${currency}`)}\n`)
                                            }
                                        }
                                        
                                        if (order.paymentMode === 'share' && receiptShares === undefined) {
                                            currentPayment?.shares?.map((share, index) => {
                                                printer.addText(`${padLine("Quota " + `${index + 1}`, `${numberFormatter(share, undefined, true)}${currency}`)}\n`)
                                            })
                                        }

                                        if (receiptShares) {
                                            receiptShares.map((share, index) => {
                                                printer.addText(`${padLine("Quota " + `${index + 1}`, `${numberFormatter(share.price, undefined, true)}${currency}`)}\n`)
                                            })                                        }

                                        if (order.paymentMode === 'amount' && receiptShares === undefined) {
                                            printer.addText(`${padLine("Quota " + 1, `${numberFormatter(currentPayment?.price || order.price, undefined, true)}${currency}`)}\n`)
                                        }


                                        if (order.paymentMode !== 'share' && order.paymentMode !== 'amount' && receiptShares === undefined) {
                                            paymentCart.map((cartValue, index) => {
                                                const value = cartValue as Cart
                                                if (!value.isCover) {
                                                    printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                    printer.addText(`${padLine(value.itemName || value.item?.name, `${numberFormatter(value.itemPrice, undefined, true)}${currency}`)}\n`)
                                                    printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                    if (value.selectedItems.length > 0) {
                                                        value.selectedItems.map(selectedItem => {
                                                            printer.addText(`- ${selectedItem.itemName}\n`)
                                                        })
                                                    }
                                                    if (value.configuration.length > 0) {
                                                        value.configuration.map(singleConfiguration => {
                                                            if (singleConfiguration?.values && singleConfiguration?.values?.length > 0) {
                                                                printer.addText(`- ${singleConfiguration.key}: ${singleConfiguration?.values?.length > 1 ? (singleConfiguration?.values as CartConfigurationValue[])?.map(single => single.value).join(', ') : (singleConfiguration?.values as CartConfigurationValue[])[0] ? (singleConfiguration?.values as CartConfigurationValue[])[0]?.value : ''}\n`)
                                                            }
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                        if (order?.deliveryInfo.homeDelivery && order.deliveryPrice > 0) {
                                            printer.addText(`${padLine(t('Consegna'), `${numberFormatter(order.deliveryPrice, undefined, true)}${currency}`)}\n`)
                                        }
                                        if (order.servicePrice > 0) {
                                            printer.addText(`${padLine(t('Costo di servizio'), `${numberFormatter(order.servicePrice, undefined, true)}${currency}`)}\n`)
                                        }

                                        printer.addFeedLine(2)
                                        const paymentTotal = (order.paymentMode === 'total' || printBeforePaymentSubmit ? order.price + order.servicePrice : currentPayment?.price) || order.price + order.servicePrice
                                        const paymentTaxPrice = (order.paymentMode === 'total' || printBeforePaymentSubmit ? order.taxPrice : receiptShares === undefined ? currentPayment?.taxPrice : order.taxPrice) || order.taxPrice
                                        printer.addText(`${padLine(t('Totale complessivo'), `${numberFormatter(paymentTotal, undefined, true)}${currency}`)}\n`)
                                        printer.addText(`${padLine(t('di cui IVA'), `${numberFormatter(paymentTaxPrice, undefined, true)}${currency}`)}\n`)
                                        if ((!order.annulloFiscale || order.annulloFiscale.length === 0) && order.digitalAmount > 0) {
                                            printer.addText(`${padLine(t('Pagamento elettronico'), `${numberFormatter(order.digitalAmount, undefined, true)}${currency}`)}\n`)
                                        }
                                        if ((!order.annulloFiscale || order.annulloFiscale.length === 0) && order.cashAmount > 0) {
                                            printer.addText(`${padLine(t('Pagamento contante'), `${numberFormatter(order.cashAmount, undefined, true)}${currency}`)}\n`)
                                        }
                                        if (subtotalDiscount() > 0 && !order.fiscale) {
                                            printer.addText(`${padLine(t('Sconto a pagare'), `${numberFormatter(subtotalDiscount(), undefined, true)}${currency}`)}\n`)
                                        }
                                        if ((!order.annulloFiscale || order.annulloFiscale.length === 0) && fiscale?.dc.documentoCommerciale?.scontoAbbuono && parseFloat(fiscale?.dc.documentoCommerciale?.scontoAbbuono) > 0) {
                                            printer.addText(`${padLine(t('Sconto a pagare'), `${numberFormatter(parseFloat(fiscale.dc.documentoCommerciale.scontoAbbuono), undefined, true)}${currency}`)}\n`)
                                        }
                                        printer.addFeedLine(2)
                                        printer.addTextAlign(printer.ALIGN_CENTER)
                                        printer.addText(`${formatDate(annulloFiscale?.created || fiscale?.created || order.created, 'dd-MM-yyyy')} ${formatDate(annulloFiscale?.created || fiscale?.created || order.created, 'HH:mm')}`)
                                        if (order.fiscale && (annulloFiscale?.progressivo !== undefined || fiscale?.progressivo !== undefined)) {
                                            printer.addFeedUnit(2)
                                            printer.addText(`${t('Documento N.')} ${annulloFiscale ? annulloFiscale?.progressivo : fiscale?.progressivo}`)
                                        }
                                        printer.addFeedLine(2)
                                        
                                        if(order.code !== undefined && order.code !== null) {
                                            printer.addText(`${t('Codice ordine')}: ${order?.code.toUpperCase()}\n`)
                                        }

                                        if (order.progressiveDailyId !== undefined && order.progressiveDailyId !== null) {
                                            printer.addText(`${t('Progressivo ordine')}: ${Number(order?.progressiveDailyId || 0) + 1}\n`)
                                        }
                                        
                                        printer.addFeedLine(2)
                                    } else {
                                        // printer.addImage(context, 0, 0, 576, 107, printer.COLOR_1, printer.MODE_MONO)
                                        printer.addFeedUnit(20)
                                        if (order?.shop?.name || brandName) {
                                            printer.addTextStyle(false, false, true, printer.COLOR_1)
                                            printer.addTextSize(shopTextDimensions, shopTextDimensions)
                                            printer.addText(`${order?.shop?.name || brandName || ''}\n`)
                                            printer.addTextSize(1, 1)
                                            printer.addTextStyle(false, false, false, printer.COLOR_1)
                                        }
                                        printer.addFeedUnit(40)
                                        printer.addTextStyle(false, false, true, printer.COLOR_1)
                                        printer.addTextSize(4, 4)
                                        if(order.orderType === 'table') {
                                                const saloons = [...new Set((order as OrderV2).saloonInfo.saloonElement.map(element => element.saloon?.name)) as any]
                                                printer.addText(`${saloons.join(', ')}\n`)
                                            }
                                        printer.addText(order.orderType === 'table' ? (order as OrderV2).saloonInfo.saloonElement?.map(element => element?.name)?.join(', ') || (order as OrderV2).saloonInfo.saloonElementName : `${order.code?.toUpperCase()}\n`)
                                        printer.addTextSize(2, 2)
                                        if(order.pickupMode !== undefined) {
                                            printer.addFeedUnit(20)
                                            printer.addText(`${order.pickupMode}\n`)
                                        }
                                        if(order.status === 'canceled') {
                                            printer.addFeedUnit(20)
                                            printer.addText(`${t('ANNULLATO')}\n`)
                                        }
                                        printer.addFeedUnit(40)
                                        if (order.orderType === 'delivery' && order.status !== 'canceled') {
                                            printer.addText(order.deliveryInfo.mode === 'asap' ? t('Prima possibile') : `${t('Ora')} ${order.deliveryInfo.homeDelivery ? t('consegna') : t('asporto')}: ${formatDate(order.deliveryInfo?.date, 'dd/MM/yyyy - HH:mm')}`)
                                            printer.addText('\n')
                                        }
                                        printer.addTextStyle(false, false, false, printer.COLOR_1)
                                        printer.addTextSize(1, 1)
                                        printer.addTextAlign(printer.ALIGN_LEFT)
                                        printer.addText('------------------------------------------------\n')
                                        if (order.deliveryInfo?.address?.notes && order.deliveryInfo.address.notes !== 'undefined' && !hideDetails) {
                                            printer.addTextStyle(false, false, true, printer.COLOR_1)
                                            printer.addText(`${t('NOTE CONSEGNA')}\n`)
                                            printer.addTextStyle(false, false, false, printer.COLOR_1)
                                            printer.addText(`${order.deliveryInfo?.address?.notes}\n`)
                                            printer.addText('------------------------------------------------\n')
                                        }
                                        if (!hideDetails) {
                                            printer.addText(`${t('Creato')}: ${formatDate(order.created, 'HH:mm - E. dd MMM yy')}\n`)
                                            if ((order.deliveryInfo.orderUserInfo && order.deliveryInfo.orderUserInfo?.name?.length > 0 && order.deliveryInfo.orderUserInfo.surname?.length > 0) || order.user) {
                                                printer.addText(`${t('Cliente')}: ${order.deliveryInfo.orderUserInfo?.name || order.user?.name} ${order.deliveryInfo.orderUserInfo?.surname || order.user?.surname}\n`)
                                            }
                                            if (order.deliveryInfo.orderUserInfo?.phone || order.user?.phone || order.deliveryInfo?.address?.phone) {
                                                const phone = order.deliveryInfo.orderUserInfo?.phone || order.user?.phone || order.deliveryInfo?.address?.phone
                                                printer.addText(`${t('Telefono')}: ${phone}\n`)
                                            }
                                            if (order.orderType === 'delivery') {
                                                printer.addText(`${t('Consegna')}: ${order.deliveryInfo.homeDelivery ? t('domicilio') : t('asporto')}\n`)
                                            }
                                            printer.addText(`${t('Pagamento')}: ${generatePaymentAmountText(order.paymentAmount, t)}\n`)
                                            if (order.deliveryInfo.homeDelivery && order.deliveryInfo?.address) {
                                                printer.addText(`${t('Indirizzo')}: ${order.deliveryInfo?.address?.formatted}\n`)
                                            }
                                            if(order.qr && order.qr.notes && order.qr.notes.length > 0) {
                                                printer.addText(`${t('Posizione')}: ${order.qr.notes}\n`)
                                            }
                                            printer.addText('------------------------------------------------\n')
                                        }

                                        if(orderNumber !== undefined) {
                                            printer.addText(`${t('Uscita')} ${orderNumber + 1}\n`)
                                            printer.addText('------------------------------------------------\n')
                                        }

                                        if(!printFiscalLikeReceipt && savedPrinter.printRecapOnreprint) {
                                            printer.addFeedUnit(15)

                                            Object.keys(allCategoryGroupedCart).map((categoryName, index) => {
                                                if(allCategoryGroupedCart[categoryName].length > 0) {
                                                    printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                    printer.addText(`${categoryName}\n`)
                                                    printer.addTextStyle(false, false, false, printer.COLOR_1)

                                                    allCategoryGroupedCart[categoryName].map(cartItem => {
                                                        const element = cartItem as CartItemWithQuantity
                                                        printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                        printer.addText(`${element.quantity} ${element.cart.itemName || element.item?.name}\n`)
                                                        printer.addTextStyle(false, false, false, printer.COLOR_1)

                                                        if(element.cart.selectedItems.length > 0) {
                                                            element.cart.selectedItems.map(selectedItem => {printer.addText(`- ${selectedItem?.name || selectedItem.itemName}\n`)})
                                                        }

                                                        if(element.cart.configuration.length > 0) {
                                                            (element.cart.configuration as CartConfiguration[]).map((singleConfiguration: CartConfiguration) => {
                                                                if (singleConfiguration?.values && singleConfiguration?.values?.length > 0) {
                                                                    printer.addText(`${singleConfiguration.key}: ${singleConfiguration?.values?.length > 1 ? singleConfiguration?.values?.map(single => single.value).join(', ') : singleConfiguration.values[0] ? singleConfiguration.values[0]?.value : ''}\n`)
                                                                }
                                                            })
                                                        }

                                                        if(element.cart.itemNotes && element.cart.itemNotes.length > 0) {
                                                            printer.addText(`${t('Note')}: ${element.cart.itemNotes}\n`)
                                                        }
                                                    })

                                                    printer.addFeedUnit(15)
                                                }
                                            })

                                            printer.addText('------------------------------------------------\n')
                                        }

                                        printer.addFeedUnit(15)
                                        printer.addTextSize(productsTextDimension, productsTextDimension)
            
                                        Object.keys(categoryGroupedCart).map((categoryName, index) => {
                                            if(categoryGroupedCart[categoryName].length > 0) {
                                                printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                printer.addText(`${categoryName}\n`)
                                                printer.addTextStyle(false, false, false, printer.COLOR_1)

                                                categoryGroupedCart[categoryName].map(cartItem => {
                                                    const element = cartItem as CartItemWithQuantity
                                                    printer.addText(`${element.quantity} ${element.isCover ? t(element.cart.itemName || element.item.name) : element.cart.itemName || element.item?.name}\n`)

                                                    printer.addTextSize(customProductsTextDimension, customProductsTextDimension)
                                                    if(element.cart.selectedItems.length > 0) {
                                                        element.cart.selectedItems.map(selectedItem => {printer.addText(`- ${selectedItem?.name || selectedItem.itemName}\n`)})
                                                    }

                                                    if(element.cart.configuration.length > 0) {
                                                        (element.cart.configuration as CartConfiguration[]).map((singleConfiguration: CartConfiguration) => {
                                                            if (singleConfiguration?.values && singleConfiguration?.values?.length > 0) {
                                                                printer.addText(`${singleConfiguration.key}: ${singleConfiguration?.values?.length > 1 ? singleConfiguration?.values?.map(single => single.value).join(', ') : singleConfiguration.values[0]?.value}\n`)
                                                            }
                                                        })
                                                    }
                                                    printer.addTextSize(productsTextDimension, productsTextDimension)
                                                    
                                                    if(element.cart.itemNotes && element.cart.itemNotes.length > 0) {
                                                        printer.addText(`${t('Note')}: ${element.cart.itemNotes}\n`)
                                                    }
                                                })
                                                
                                                printer.addFeedUnit(20)
                                            }
                                        })

                                        printer.addTextSize(1, 1)
                                        
                                        if (order.notes && order.notes !== 'undefined') {
                                            printer.addText('------------------------------------------------\n')
                                            printer.addTextStyle(false, false, true, printer.COLOR_1)
                                            printer.addTextSize(productsTextDimension, productsTextDimension)
                                            printer.addText(`${t('NOTE')}\n`)
                                            printer.addTextStyle(false, false, false, printer.COLOR_1)
                                            printer.addText(`${order.notes}\n`)
                                            printer.addTextSize(1, 1)
                                        }
                                        if (!hideDetails) {
                                            printer.addText('------------------------------------------------\n')
                                            if (order.orderType !== 'table') {
                                                printer.addText(`${t('Numero prodotti')}: ${orderCart.length}\n`)
                                            }
                                            if (order.deliveryInfo.homeDelivery && order.deliveryPrice) {
                                                printer.addText(`${t('Costo consegna')}: ${numberFormatter(order.deliveryPrice, undefined, true)}${currency}\n`)
                                            }
                                            if (order.promo && order.promo.length > 0) {
                                                order.promo.map(single => printer.addText(`${t('Codice sconto')}: ${single?.name}\n`))
                                            }
            
                                            if (order.progressiveDailyId !== undefined && order.progressiveDailyId !== null) {
                                                printer.addText(`${t('Progressivo ordine')}: ${Number(order?.progressiveDailyId || 0) + 1}\n`)
                                            }
                                            if (isOrderV2(order) && order.orderType === 'table' && orderNumber !== undefined) {
                                                const outingTotal = (orderCart as unknown as Cart[]).filter(item => item.orderNumber === orderNumber).reduce((acc, currentItem) => acc + currentItem.itemPrice, 0)
                                                if (outingTotal !== order.price) {
                                                    printer.addText(`${t('Totale uscita')}: ${numberFormatter(outingTotal, undefined, true)}${currency}\n`)
                                                }
                                            }
                                            
                                            printer.addText(`${t('Totale')}: ${numberFormatter(order.orderType === 'express' ? order.price + order.servicePrice : order.price, undefined, true)}${currency}\n`)
                                        }
                                        printer.addFeedLine(2)

                                        if(!hideIngredients) {
                                            if (ingredients.filter(ingredient => ingredient !== undefined && ingredient !== null).length > 0) {
                                                printer.addText('------------------------------------------------\n')
                                                printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                printer.addText(`${t('INGREDIENTI')}\n`)
                                                printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                printer.addText(`${ingredients.join(', ')}\n`)
                                            }
                                        }
            
                                        if(!hideAllergens) {
                                            if (allergens.filter(allergen => allergen !== undefined && allergen !== null).length > 0) {
                                                printer.addText('------------------------------------------------\n')
                                                printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                printer.addText(`${t('ALLERGENI')}\n`)
                                                printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                printer.addText(`${allergens.join(', ')}\n`)
                                                printer.addFeedLine(1)
                                            }
                                        }
            
                                    }
                                    if(Object.keys(categoryGroupedCart).length > 0) {
                                        printer.addCut(printer.CUT_FEED)
                                        printer.send()
                                        if(successCallback) successCallback()
                                        setPrintedOrders('nonFiscal', [order._id], mutate)   
                                    }
                                }

                                if(savedPrinter.categoriesToReprint?.length > 0 && order.status !== 'canceled' && !printFiscalLikeReceipt && type !== 'receipt' && !printFakeFiscalReceipt) {
                                    savedPrinter.categoriesToReprint.map(category => {
                                        const categoryToReprintCart: {[key: string]: (CartItemWithQuantity | null)[]} = {}

                                        getProductItemQuantityArray(JSON.parse(JSON.stringify(orderCart))).map(cartItem => categoryToReprintCart[(cartItem.item?.category as Category)?.name || t('Nessuna categoria')] = [...(categoryToReprintCart[(cartItem.item?.category as Category)?.name || t('Nessuna categoria')] || []), cartItem])

                                        Object.keys(categoryToReprintCart).map(categoryName => {
                                            categoryToReprintCart[categoryName].map((cartItem, cartItemIndex) => {
                                                if(categoryName === t('Nessuna categoria')) {
                                                    categoryToReprintCart[categoryName][cartItemIndex] = null
                                                } else if(cartItem?.item?.itemType === 'custom' && cartItem.cart.configuration && cartItem.cart.configuration.length > 0) {
                                                    (cartItem.cart.configuration as CartConfiguration[]).map((cartConfiguration, cartConfigurationIndex) => {
                                                        if(categoryToReprintCart[categoryName][cartItemIndex] && categoryToReprintCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex] && categoryToReprintCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex]?.values) {
                                                            categoryToReprintCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values = cartConfiguration.values.filter((cartConfigurationValue, cartConfigurationValueIndex) => {
                                                                if(savedPrinter.splitCustomLinkedItems) {
                                                                    if(cartConfigurationValue.linkedItem && category === (cartConfigurationValue.linkedItem?.category as Category)._id) {
                                                                        const itemToAggregateIndex = categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name]?.findIndex(itemToFind => itemToFind?.cart._id === cartItem.cart._id && itemToFind?.item?._id === cartConfigurationValue.linkedItem?._id)
                                                                    
                                                                        if(itemToAggregateIndex > -1 && categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]) {
                                                                            const configurationToAggregateIndex = categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration.findIndex(configurationToFind => configurationToFind.key === cartConfiguration.key)

                                                                            if(configurationToAggregateIndex > -1 && categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex] && categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration[configurationToAggregateIndex]?.values) {
                                                                                // @ts-ignore
                                                                                categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration[configurationToAggregateIndex].values.push({value: cartConfigurationValue.value})
                                                                            } else {
                                                                                // @ts-ignore
                                                                                categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name][itemToAggregateIndex]!.cart.configuration.push({key: cartConfiguration.key, values: [{value: cartConfigurationValue.value}]})
                                                                            }
                                                                        } else {
                                                                            // @ts-ignore
                                                                            categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name] = [...(categoryToReprintCart[(cartConfigurationValue.linkedItem.category as Category)?.name] || []), {quantity: 1, cart: {_id: cartItem.cart._id!, selectedItems: [], configuration: [{key: cartConfiguration.key, values: [{value: cartConfigurationValue.value}]}], item: cartConfigurationValue.linkedItem, itemName: cartConfigurationValue.linkedItem?.name}, item: cartConfigurationValue.linkedItem}]
                                                                        }

                                                                        if(categoryToReprintCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex]?.values) {
                                                                            // @ts-ignore
                                                                            categoryToReprintCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values[cartConfigurationValueIndex] = null
                                                                        }

                                                                        return true
                                                                    } else if(!cartConfigurationValue.linkedItem && category === (cartItem?.item?.category as Category)._id) {
                                                                        return true
                                                                    } else {
                                                                        return false
                                                                    }
                                                                } else {
                                                                    return ((cartItem?.item?.category as Category)._id) === category || (cartConfigurationValue.linkedItem && (cartConfigurationValue.linkedItem?.category as Category)._id === category)
                                                                }
                                                            })
                                                        }
                                        
                                                        if(categoryToReprintCart[categoryName][cartItemIndex]?.cart.configuration[cartConfigurationIndex]?.values) {
                                                            categoryToReprintCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values = (categoryToReprintCart[categoryName][cartItemIndex]!.cart.configuration[cartConfigurationIndex].values as CartConfigurationValue[]).filter(value => value !== null)
                                                        }
                                                    })
                                        
                                                    if(savedPrinter.splitCustomLinkedItems) {
                                                        if((cartItem?.item?.category as Category)._id !== category) {
                                                            categoryToReprintCart[categoryName][cartItemIndex] = null
                                                        }
                                                    } else if((categoryToReprintCart[categoryName][cartItemIndex]?.cart.configuration as CartConfiguration[]).filter(cartConfiguration => cartConfiguration?.values && cartConfiguration.values.length > 0).length === 0) {
                                                        categoryToReprintCart[categoryName][cartItemIndex] = null
                                                    }
                                                } else if(cartItem?.item && (cartItem?.item?.category as Category)._id !== category) {
                                                    categoryToReprintCart[categoryName][cartItemIndex] = null
                                                }
                                            })
                                        })

                                        Object.keys(categoryToReprintCart).map(catToCheck => {
                                            categoryToReprintCart[catToCheck].map((cartItem, cartItemIndex) => {
                                                categoryToReprintCart[catToCheck].map((cartItemToFind, cartItemToFindIndex) => {
                                                    if(cartItemIndex !== cartItemToFindIndex && cartItem && cartItemToFind && cartItem?.item?._id === cartItemToFind?.item?._id) {
                                                        if((!cartItem.cart.configuration || cartItem.cart.configuration.length === 0) && (!cartItemToFind.cart.configuration || cartItemToFind.cart.configuration.length === 0)) {
                                                            categoryToReprintCart[catToCheck][cartItemIndex]!.quantity++
                                                            categoryToReprintCart[catToCheck][cartItemToFindIndex] = null
                                                        } else if(cartItem.cart.configuration && cartItem.cart.configuration.length > 0 && cartItemToFind.cart.configuration && cartItemToFind.cart.configuration.length > 0) {
                                                            const clonedCartCartItemConfiguration = JSON.parse(JSON.stringify(cartItem.cart.configuration)) as CartConfiguration[]
                                                            clonedCartCartItemConfiguration.map(clonedCartCartItemConfigurationSingle => clonedCartCartItemConfigurationSingle ? delete clonedCartCartItemConfigurationSingle._id : null)
                                                            const clonedCartCartItemToFindConfiguration = JSON.parse(JSON.stringify(cartItemToFind.cart.configuration)) as CartConfiguration[]
                                                            clonedCartCartItemToFindConfiguration.map(clonedCartCartItemToFindConfigurationSingle => clonedCartCartItemToFindConfigurationSingle ? delete clonedCartCartItemToFindConfigurationSingle._id : null)

                                                            if(dequal(clonedCartCartItemConfiguration, clonedCartCartItemToFindConfiguration)) {
                                                                categoryToReprintCart[catToCheck][cartItemIndex]!.quantity++
                                                                categoryToReprintCart[catToCheck][cartItemToFindIndex] = null
                                                            }
                                                        }
                                                    }
                                                })
                                            })

                                            categoryToReprintCart[catToCheck] = categoryToReprintCart[catToCheck].filter(cartItem => cartItem !== null)
                                        })

                                        if(Object.keys(categoryToReprintCart).filter(categoryName => categoryToReprintCart[categoryName].length > 0).length > 0) {
                                            printer.addTextAlign(printer.ALIGN_CENTER)
                                            printer.brightness = 1.0
                                            // printer.halftone = printer.HALFTONE_ERROR_DIFFUSION
                                            printer.addTextSmooth(true)
                                            
                                            // printer.addImage(context, 0, 0, 576, 107, printer.COLOR_1, printer.MODE_MONO)
                                            printer.addFeedUnit(20)
                                            if (order?.shop?.name || brandName) {
                                                printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                printer.addTextSize(shopTextDimensions, shopTextDimensions)
                                                printer.addText(`${order?.shop?.name || brandName || ''}\n`)
                                                printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                printer.addTextSize(1, 1)
                                            }
                                            printer.addFeedUnit(40)
                                            printer.addTextStyle(false, false, true, printer.COLOR_1)
                                            printer.addTextSize(4, 4)
                                            if(order.orderType === 'table') {
                                                const saloons = [...new Set((order as OrderV2).saloonInfo.saloonElement.map(element => element.saloon?.name)) as any]
                                                printer.addText(`${saloons.join(', ')}\n`)
                                            }
                                            printer.addText(order.orderType === 'table' ? (order as OrderV2).saloonInfo.saloonElement?.map(element => element?.name)?.join(', ') || (order as OrderV2).saloonInfo.saloonElementName : `${order.code?.toUpperCase()}\n`)
                                            printer.addTextSize(2, 2)
                                            if(order.pickupMode !== undefined) {
                                                printer.addFeedUnit(20)
                                                printer.addText(`${order.pickupMode}\n`)
                                            }
                                            if(order.status === 'canceled') {
                                                printer.addFeedUnit(20)
                                                printer.addText(`${t('ANNULLATO')}\n`)
                                            }
                                            printer.addFeedUnit(40)
                                            if (order.orderType === 'delivery' && order.status !== 'canceled') {
                                                printer.addText(order.deliveryInfo.mode === 'asap' ? t('Prima possibile') : `${t('Ora')} ${order.deliveryInfo.homeDelivery ? t('consegna') : t('asporto')}: ${formatDate(order.deliveryInfo?.date, 'dd/MM/yyyy - HH:mm')}`)
                                                printer.addText('\n')
                                            }
                                            printer.addTextStyle(false, false, false, printer.COLOR_1)
                                            printer.addTextSize(1, 1)
                                            printer.addTextAlign(printer.ALIGN_LEFT)
                                            printer.addText('------------------------------------------------\n')
                                            if (order.deliveryInfo?.address?.notes && order.deliveryInfo.address.notes !== 'undefined' && !hideDetails) {
                                                printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                printer.addText(`${t('NOTE CONSEGNA')}\n`)
                                                printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                printer.addText(`${order.deliveryInfo?.address?.notes}\n`)
                                                printer.addText('------------------------------------------------\n')
                                            }
                                            if (!hideDetails) {
                                                printer.addText(`${t('Creato')}: ${formatDate(order.created, 'HH:mm - E. dd MMM yy')}\n`)
                                                if ((order.deliveryInfo.orderUserInfo && order.deliveryInfo.orderUserInfo?.name?.length > 0 && order.deliveryInfo.orderUserInfo.surname?.length > 0) || order.user) {
                                                    printer.addText(`${t('Cliente')}: ${order.deliveryInfo.orderUserInfo?.name || order.user?.name} ${order.deliveryInfo.orderUserInfo?.surname || order.user?.surname}\n`)
                                                }
                                                if (order.deliveryInfo.orderUserInfo?.phone || order.user?.phone || order.deliveryInfo?.address?.phone) {
                                                    const phone = order.deliveryInfo.orderUserInfo?.phone || order.user?.phone || order.deliveryInfo?.address?.phone
                                                    printer.addText(`${t('Telefono')}: ${phone}\n`)
                                                }
                                                if (order.orderType === 'delivery') {
                                                    printer.addText(`${t('Consegna')}: ${order.deliveryInfo.homeDelivery ? t('domicilio') : t('asporto')}\n`)
                                                }
                                                printer.addText(`${t('Pagamento')}: ${generatePaymentAmountText(order.paymentAmount, t)}\n`)
                                                if (order.deliveryInfo.homeDelivery && order.deliveryInfo?.address) {
                                                    printer.addText(`${t('Indirizzo')}: ${order.deliveryInfo?.address?.formatted}\n`)
                                                }
                                                printer.addText('------------------------------------------------\n')
                                            }

                                            if(!printFiscalLikeReceipt && savedPrinter.printRecapOnreprint) {
                                                printer.addFeedUnit(15)
    
                                                Object.keys(allCategoryGroupedCart).map((categoryName, index) => {
                                                    if(allCategoryGroupedCart[categoryName].length > 0) {
                                                        printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                        printer.addText(`${categoryName}\n`)
                                                        printer.addTextStyle(false, false, false, printer.COLOR_1)
    
                                                        allCategoryGroupedCart[categoryName].map(cartItem => {
                                                            const element = cartItem as CartItemWithQuantity
                                                            printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                            printer.addText(`${element.quantity} ${element.cart.itemName || element.item?.name}\n`)
                                                            printer.addTextStyle(false, false, false, printer.COLOR_1)

                                                            if(element.cart.selectedItems.length > 0) {
                                                                element.cart.selectedItems.map(selectedItem => {printer.addText(`- ${selectedItem?.name || selectedItem.itemName}\n`)})
                                                            }
    
                                                            if(element.cart.configuration.length > 0) {
                                                                (element.cart.configuration as CartConfiguration[]).map((singleConfiguration: CartConfiguration) => {
                                                                    if (singleConfiguration?.values && singleConfiguration?.values?.length > 0) {
                                                                        printer.addText(`${singleConfiguration.key}: ${singleConfiguration?.values?.length > 1 ? singleConfiguration?.values?.map(single => single.value).join(', ') : singleConfiguration.values[0] ? singleConfiguration.values[0]?.value : ''}\n`)
                                                                    }
                                                                })
                                                            }
                                                        })
    
                                                        printer.addFeedUnit(15)
                                                    }
                                                })
    
                                                printer.addText('------------------------------------------------\n')
                                            }

                                            printer.addFeedUnit(15)
                                            printer.addTextSize(productsTextDimension, productsTextDimension)
                
                                            Object.keys(categoryToReprintCart).map((categoryName, index) => {
                                                if(categoryToReprintCart[categoryName].length > 0) {
                                                    printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                    printer.addText(`${categoryName}\n`)
                                                    printer.addTextStyle(false, false, false, printer.COLOR_1)

                                                    categoryToReprintCart[categoryName].map(cartItem => {
                                                        const element = cartItem as CartItemWithQuantity
                                                        printer.addText(`${element.quantity} ${element.cart.itemName || element.item?.name}\n`)

                                                        printer.addTextSize(customProductsTextDimension, customProductsTextDimension)
                                                        if(element.cart.selectedItems.length > 0) {
                                                            element.cart.selectedItems.map(selectedItem => {printer.addText(`- ${selectedItem?.name || selectedItem.itemName}\n`)})
                                                        }

                                                        if(element.cart.configuration.length > 0) {
                                                            (element.cart.configuration as CartConfiguration[]).map((singleConfiguration: CartConfiguration) => {
                                                                if (singleConfiguration?.values?.length > 0) {
                                                                    printer.addText(`${singleConfiguration.key}: ${singleConfiguration?.values?.length > 1 ? singleConfiguration?.values?.map(single => single.value).join(', ') : singleConfiguration.values[0] ? singleConfiguration.values[0]?.value : ''}\n`)
                                                                }
                                                            })
                                                        }
                                                        printer.addTextSize(productsTextDimension, productsTextDimension)

                                                        if(element.cart.itemNotes && element.cart.itemNotes.length > 0) {
                                                            printer.addText(`${t('Note')}: ${element.cart.itemNotes}\n`)
                                                        }
                                                    })
                                                    
                                                    printer.addFeedUnit(20)
                                                }
                                            })
                                            
                                            printer.addTextSize(1, 1)
                                            
                                            if (order.notes && order.notes !== 'undefined') {
                                                printer.addText('------------------------------------------------\n')
                                                printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                printer.addTextSize(productsTextDimension, productsTextDimension)
                                                printer.addText(`${t('NOTE')}\n`)
                                                printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                printer.addText(`${order.notes}\n`)
                                                printer.addTextSize(1, 1)
                                            }
                                            if (!hideDetails) {
                                                printer.addText('------------------------------------------------\n')
                                                if (order.orderType !== 'table') {
                                                    printer.addText(`${t('Numero prodotti')}: ${orderCart.length}\n`)
                                                }
                                                if (order.deliveryInfo.homeDelivery && order.deliveryPrice) {
                                                    printer.addText(`${t('Costo consegna')}: ${numberFormatter(order.deliveryPrice, undefined, true)}${currency}\n`)
                                                }
                                                if (order.promo && order.promo.length > 0) {
                                                    order.promo.map(single => printer.addText(`${t('Codice sconto')}: ${single?.name}\n`))
                                                }
                
                                                if (order.progressiveDailyId !== undefined && order.progressiveDailyId !== null) {
                                                    printer.addText(`${t('Progressivo ordine')}: ${Number(order?.progressiveDailyId || 0) + 1}\n`)
                                                }
                                                printer.addText(`${t('Totale')}: ${numberFormatter(order.price, undefined, true)}${currency}\n`)
                                            }
                                            printer.addFeedLine(2)
                
                                            if(!hideIngredients) {
                                                if (ingredients.filter(ingredient => ingredient !== undefined && ingredient !== null).length > 0) {
                                                    printer.addText('------------------------------------------------\n')
                                                    printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                    printer.addText(`${t('INGREDIENTI')}\n`)
                                                    printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                    printer.addText(`${ingredients.join(', ')}\n`)
                                                }
                                            }
                
                                            if(!hideAllergens) {
                                                if (allergens.filter(allergen => allergen !== undefined && allergen !== null).length > 0) {
                                                    printer.addText('------------------------------------------------\n')
                                                    printer.addTextStyle(false, false, true, printer.COLOR_1)
                                                    printer.addText(`${t('ALLERGENI')}\n`)
                                                    printer.addTextStyle(false, false, false, printer.COLOR_1)
                                                    printer.addText(`${allergens.join(', ')}\n`)
                                                    printer.addFeedLine(1)
                                                }
                                            }
                    
                                            if(Object.keys(categoryToReprintCart).length > 0) {
                                                printer.addCut(printer.CUT_FEED)
                                                printer.send()
                                            }   
                                        }
                                    })
                                }
                            }
                        }
                    })
                    .catch(err => {
                        if(errorCallback) errorCallback()

                        if(err !== 'NoDeviceEnabled' && err !== undefined && err !== 'undefined' && err !== null) {
                            addNotification(`${t("Si è verificato un errore durante la stampa dell'ordine")} ${order.code?.toUpperCase()} \n\n ${err}`, 'danger')
                        }
                    })
                })
            }
        })
    }
}

function isOrderV2(obj: any): obj is OrderV2 {
    return 'saloonInfo' in obj
}

export function epsonAnalyticsPrint(printer: Printer | undefined, t: Function, shops: Shop[], brandName: string, productsList: SelledProducts[], deviceUUID: string | null) {
    if(printer) {
        const { start, end, shopId } = getCleanQueryParams()
        const selectedShop = shops.find(shop => shop._id === shopId)
        const total = productsList.reduce((acc, curr) => acc + curr.untouchedRevenue, 0)
        const productsListGrouped = productsList.reduce((acc: MergedSelledProducts[], item) => {
            let category = item.category
            let currentCategory = acc.find(i => i.categoryName === category)
        
            if (currentCategory) {
                currentCategory.products.push(item)
            } else {
                acc.push({
                    categoryName: category,
                    products: [item]
                })
            }

            return acc
        }, [])

        usePrinter(printer, t, false, deviceUUID)
        .then(({printer}) => {
            printer.brightness = 1.0
            printer.addTextSmooth(true)
            
            printer.addTextAlign(printer.ALIGN_CENTER)
            printer.addTextSize(2, 2)
            printer.addText(`${selectedShop?.name || brandName}\n`)

            printer.addTextSize(1, 1)
            
            printer.addFeedLine(2)
            printer.addTextStyle(false, false, true, printer.COLOR_1)
            printer.addText('Statistiche\n')
            printer.addText(`dal: ${formatDate(start, 'dd-MM-yyyy HH:mm')}\n`)
            printer.addText(`al: ${formatDate(end, 'dd-MM-yyyy HH:mm')}\n`)

            printer.addFeedLine(2)
            printer.addTextAlign(printer.ALIGN_LEFT)
            printer.addTextStyle(false, false, false, printer.COLOR_1)
            productsListGrouped.map(category => {
                printer.addTextStyle(false, false, true, printer.COLOR_1)
                printer.addText(`${category.categoryName}\n`)
                printer.addTextStyle(false, false, false, printer.COLOR_1)
                
                category.products.map(product => {
                    printer.addText(`${padLine(`${product.count}x ${product.name}`, `${product.revenue}`)}\n`)
                })
                printer.addFeedLine(1)
            })

            printer.addFeedLine(2)
            printer.addTextStyle(false, false, true, printer.COLOR_1)
            printer.addText(padLine('Totale', `${numberFormatter(total, undefined, true)}${getCurrency(selectedShop?.currency)}`))
            printer.addTextStyle(false, false, false, printer.COLOR_1)
            
            printer.addFeedLine(2)
            printer.addCut(printer.CUT_FEED)
            printer.send()
        })
        .catch(err => {
            if(err !== 'NoDeviceEnabled' && err !== undefined && err !== 'undefined' && err !== null) {
                addNotification(`${t("Si è verificato un errore durante la stampa")}`, 'danger')
            }
        })
    }
}