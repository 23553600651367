import { createContext } from 'react'

import { CashDeskFiscalTypeV2, CurrentPaymentStateInterface } from 'types'

export const initialCurrentPayment = {
    amount: 0, 
    items: [], 
    fiscalType: "fiscal" as CashDeskFiscalTypeV2, 
    paymentId: undefined,
    paymentMethod: {
        cash: 0, 
        bancomat: 0, 
        creditCard: 0, 
        ticket: 0,
        sepa: 0,
        notCollected: 0,
        token: 0
    },
    fiscalData: {
        causal: undefined,
        courtesyReceipts: 0,
    },
    invoiceData: {
        company: null,
        causal: undefined,
        qrCodePay: false,
        piva: null
    },
    nonFiscalData: {
        causal: undefined,
        qrCodePay: false
    }
 }


const CurrentPaymentContext = createContext<CurrentPaymentStateInterface>({
    ...initialCurrentPayment,
    updateCurrentPayment: () => {return () => {/**/}}
})

export default CurrentPaymentContext