import usePortal from 'react-useportal'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslate } from 'utils/hooks'
import { colors } from 'styles/variables'
import { addNotification } from 'utils'

// @ts-ignore
const Toaster = dynamic(() => import('react-hot-toast').then(mod => mod.Toaster), {ssr: false})

export default function Toast() {
    const { Portal } = usePortal()
    const [isOnline, setIsOnline] = useState<boolean>(true)
    const [toastId, setToastId] = useState<string | null>(null)
    const { t } = useTranslate()
    
    useEffect(() => {
        window.addEventListener("offline", () => setIsOnline(false))
        window.addEventListener("online", () => setIsOnline(true))
    
        return () => {
          window.removeEventListener("offline", () => setIsOnline(false))
          window.removeEventListener("online", () => setIsOnline(true))
        }
    }, [])
    
    useEffect(() => {
        if(!isOnline && !toastId) {
            const savedToast = toast.loading(t('Connessione internet assente'), {duration: 1 * 24 * 60 * 60 * 1000, style: { backgroundColor: colors.red, color: '#fff' }})
            setToastId(savedToast)
        } else if(isOnline && toastId) {
            toast.dismiss(toastId)
            addNotification(t('Connessione internet ripristinata'), 'success')
            setToastId(null)

        }
    }, [toastId, isOnline])

    return(
        <Portal>
            <Toaster toastOptions={{duration: 5000, position: 'bottom-right'}} containerStyle={{bottom: 32, right: 32, zIndex: 9999}}/>
        </Portal>
    )
}