import { FormikErrors } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { MutatorCallback } from "swr";
import Heap from "heap-js";

export type WithChildren<T = {}> = T & {
  children?: React.ReactNode;
};

export type UserType = "merchant" | "shopManager" | "operator" | null;

export type GlobalContextState = {
  authenticated: boolean | null;
  userType: UserType;
  redirect: Redirect;
  id: string;
  email: string;
  name: string;
  surname: string;
  brands: Brand[];
  shops: Shop[];
  addons: Addon[];
  deviceUUID: string | null;
  operator?: OperatorStatus; // this is available if the user is of type operator
  allOrdersIsMutating: boolean;
  hideSidebar?: boolean;
  appUpdate: AppUpdate;
};

export type GlobalContextStateOptional = {
  authenticated?: boolean | null;
  userType?: UserType;
  redirect?: Redirect;
  id?: string;
  email?: string;
  name?: string;
  surname?: string;
  brands?: Brand[];
  shops?: Shop[];
  addons?: Addon[];
  deviceUUID?: string | null;
  operator?: OperatorStatus; // this is available if the user is of type operator
  allOrdersIsMutating?: boolean;
  hideSidebar?: boolean;
  appUpdate?: AppUpdate;
};

export interface GlobalContextStateInterface extends GlobalContextState {
  updateGlobalContext: (state: GlobalContextStateOptional) => void;
}

export type Redirect = string | null;

export interface Brand extends OtherLanguages<{ en: { name: string; desc: string; appDesc: string } }> {
  _id: string;
  name: string;
  logo?: string;
  horizontalLogo?: string;
  header?: string;
  appLayout: "list" | "grid";
  bid?: string;
  desc: string;
  created: string;
  appDesc: string;
  color: string;
  products?: string[];
  domain?: string[];
  invoiceToPay?: number;
  googleAnalytics: string[];
  facebookPixel: string[];
  owner: string;
  brandLocalization: SupportedLanguages;
  defaultTimezone: string;
  customNotification: CustomNotification;
  billing: {
    paymentMethod?: BrandBillingPaymentMethods;
    companyName: string;
    vat: string;
    fullAddress: string;
    uniqueCode: string;
    pec: string;
    card?: Card;
    bankAccount?: BankAccount;
    additionalShopCost: number;
    billingType: "percentage" | "orders";
  };
  qr?: QR;
  requiredLogin: boolean;
  requiredBirthday: boolean;
  requiredKyc: boolean;
  expressShowBottomBar: boolean;
}

export type BrandBillingPaymentMethods = "card" | "bank";

export interface SingleCustomNofitication extends OtherLanguages<{ en: { title: string; body: string } }> {
  title: string;
  body: string;
}

export type CustomNotification = {
  [key in
    | "confirmed"
    | "readyDelivery"
    | "readyTakeAway"
    | "onDelivery"
    | "deliveredDelivery"
    | "deliveredTakeAway"
    | "canceled"
    | "changeTime"
    | "tableConfirm"]: SingleCustomNofitication;
};

export type Currencies = "EUR" | "USD" | "GBP";

export type ReadableCurrencies = "EUR" | "USD" | "GBP";

export type AvailableModes = "delivery" | "takeAway";

export type AvailablePayments = "onDelivery" | "stripe" | "paypal";

export type AvailableTime = "scheduled" | "asap";

export type AvailableDays = "today" | "tomorrow" | "calendar";

export type TableBookingType = "seats" | "table";

export type ServiceCostOption = {
  name: string;
  price: number;
};

export type ShopPaymentOptions = "full" | "split-bill-equal" | "split-bill-custom" | "split-bill-items";

export type AppUpdate = {
  available: boolean;
  isCritical: boolean;
};

export interface Shop
  extends OtherLanguages<{
    en: { name: string; description: string; selfWithConfirmTitle: string; selfWithConfirmDescription: string };
  }> {
  _id: string;
  name: string;
  email: string;
  phone: string;
  brand: string;
  description: string;
  enableForItems: boolean;
  address: string;
  placeId: string;
  hourInterval: number;
  minPrice: number;
  tablePrice: number;
  currency: Currencies;
  availableCaps: string[];
  billing: {
    piva: string;
    denominazione: string;
    indirizzo: string;
    numeroCivico: string;
    cap: string;
    comune: string;
    provincia: string;
    nazione: string;
    fullAddress: string;
  };
  qr: QR;
  qrMenu: QR;
  open: {
    isOpen: boolean;
    week: Week[];
    closedDays: Date[];
  };
  openTable: {
    isOpen: boolean;
    bookingType: TableBookingType;
    week: TableWeek[];
  };
  undoTime: number;
  initialStatus: "pending" | "confirmed";
  tables: {
    enabled: boolean;
    enableAskBill: boolean;
    paymentOptions: ShopPaymentOptions[];
    enableTip: boolean;
    minCancelTime: number;
    avgBookDuration: number;
    aggregation: {
      enabled: boolean;
      time: number;
    };
    deliveryMode: "self" | "waiter";
    paymentTime: "before" | "after";
    smsNotification: boolean;
    minPrice: number;
    enableOrdering: boolean;
    paymentMethod: PaymentMethod[];
    enableStripeEmail: boolean;
    serviceCost: {
      enabled: boolean;
      price: number;
      options: ServiceCostOption[];
    };
  };
  express: {
    enabled: boolean;
    enableTip: boolean;
    deliveryMode: "self" | "waiter" | "selfWithConfirm";
    smsNotification: boolean;
    enablePickupMode: boolean;
    pickupModeValues: string[];
    minPrice: number;
    paymentMethod: PaymentMethod[];
    qrCheck: boolean;
    enableStripeEmail: boolean;
    hideFromBrandPage: boolean;
    enableUserNamePrompt: boolean;
    enableRequestEmail: boolean;
    showShopTakeawayName: boolean;
    serviceCost: {
      enabled: boolean;
      price: number;
    };
    orderTimer: {
      enabled: boolean;
      timer: number;
    };
    enableOrderTracking: boolean;
    textToShowInsteadOfTracking: string;
    enableOrderMonitorText: boolean;
    orderMonitorText: string;
    initialStatus: "confirmed" | "ready";
    selfWithConfirmTitle: string;
    selfWithConfirmDescription: string;
    disableDefaultOpenAccordion: boolean
  };
  printers: Printer[];
  applicationConf: {
    availableModes: AvailableModes[];
    availableTime: AvailableTime[];
    availablePayment: AvailablePayments[];
    availableTablePayment: AvailablePayments[];
    availableBooking: boolean;
    availableTable: boolean;
    availableDigitalMenu: boolean;
    availableDays: AvailableDays[];
    deliveryConf: DeliveryConf;
    takeAwayConf: TakeAwayConf;
  };
  shopType: "shop" | "ecommerce" | "pickup";
  deliveryMethod: DeliveryMethod;
  deliveryType: DeliveryType;
  capsDeliveryPrice?: DeliveryPrice[];
  useGlovoPrice: boolean;
  rangeDeliveryPrice?: {
    singlePrice?: number;
    singleRange?: number;
    singleRiderTime?: number;
    availableRanges?: DeliveryPrice[];
  };
  areaDeliveryPrice: AreaDeliveryPrice[];
  position?: {
    type: "Point";
    coordinates: [number, number];
  };
  addonFiscale?: AddonFiscale;
  cashDesk: {
    pickupModeValues: ShopPickupModeValue[];
    handleOrder: boolean;
    roundDiscount: boolean;
  };
}

export type ShopPickupModeValue = {
  _id?: string;
  value: string;
  handleOrder: boolean;
};

export interface ShopEditorType extends Shop {
  buttons: TaxButton[];
  categories: Category[];
  printerModal: {
    isOpen: boolean;
    selectedPrinter: string | null;
  };
  initial?: boolean;
}

export type AddonFiscale = {
  fiscale: Fiscale;
  brand: string;
  shop: string;
};

export type Fiscale = {
  cap: string;
  cf: string;
  comune: string;
  defAliquotaIVA: string;
  denominazione: string;
  indirizzo: string;
  nazione: string;
  numeroCivico: string;
  piva: string;
  provincia: string;
  pin: string;
  password: string;
  fullAddress: string;
  loginExpired: boolean;
};

export type AreaDeliveryPrice = {
  coordinates: number[][];
  minPrice?: number;
  price?: number;
  riderTime?: number;
  polygon?: google.maps.Polygon;
  hasListener?: boolean;
};

export type DeliveryMethod = "local" | "glovo" | "poony";

export type DeliveryType = "area" | "multipleRange" | "caps";

export type DeliveryPrice = {
  range: number;
  cap: string;
  price: number;
  minPrice: number;
  riderTime: number;
};

export type DeliveryConf = {
  availablePayment: AvailablePayments[];
  availableTime: AvailableTime[];
  availableDays: AvailableDays[];
  minPrice: number;
  calendarRange: number;
};

export type TakeAwayConf = {
  availablePayment: AvailablePayments[];
  availableTime: AvailableTime[];
  availableDays: AvailableDays[];
  minPrice: number;
  calendarRange: number;
};

export type WeekHours = {
  from: string;
  to: string;
  interval: number;
  maxOrders: number;
  selectedMode: ("delivery" | "takeAway" | "table" | "express")[];
  maxShow: number;
};

export type TableWeekHours = {
  from: string;
  to: string;
  freeTime?: number;
  discount?: number;
  maxSeats?: number;
};

export type Week = {
  _id?: string;
  enabled: boolean;
  hours: WeekHours[];
};

export type TableWeek = {
  _id?: string;
  enabled: boolean;
  hours: TableWeekHours[];
};

export type SupportedLanguages = "it" | "en";

export type OtherLanguages<T> = {
  otherLanguages: T;
};

export type SetErrors<T> = (errors: FormikErrors<T>) => void;

export type SetSubmitting = (isSubmitting: boolean) => void;

export type ApiErrors = {
  [key: string]: string;
};

export type RegisterData = {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  sellerRID: string;
};

export type LoginData = {
  email: string;
  password: string;
  remember: boolean;
};

export type LoginRes = {
  id: string;
  email: string;
  name: string;
  surname: string;
  userType: UserType;
  token?: string;
  operator?: OperatorStatus; // Only if user is of type operator
};

export type StatusRes = {
  id: string;
  email: string;
  name: string;
  surname: string;
  userType: UserType;
  operator?: OperatorStatus;
  error?: string;
};

export type ValueLabel<T = string | number> = { label: string | React.ReactNode; value?: T; disabled?: boolean };

export type CategoryType = "base" | "modular" | "custom" | "upselling";

export type FilterType = "delivery" | "takeAway" | "table" | "upselling" | "digitalMenu" | "express" | "cashDesk";

export type CategoryOpen = {
  enabled: boolean;
  from: string;
  to: string;
};

export interface Category extends OtherLanguages<{ en: { name: string } }> {
  _id: string;
  name: string;
  image?: string;
  categoryType: CategoryType[];
  open: CategoryOpen;
  items: Item[];
  position: number;
  groups: Group[];
}

export interface ComponentCategory extends OtherLanguages<{ en: { name: string } }> {
  _id: string;
  name: string;
  image?: string;
  categoryType: CategoryType[];
  open: CategoryOpen;
  components: Component[];
  position: number;
  groups: Group[];
}

export type ItemShop = {
  _id: string;
  quantity?: number;
  maxOrder?: number;
  price?: number | string;
};

export type ItemDelayUnit = "minutes" | "hours" | "days";

export type ItemDelay = {
  value: number;
  unit: ItemDelayUnit;
};

export type PaymentMethod = "stripe" | "onDelivery";

export type ItemType = "base" | "custom" | "modular-static" | "modular-flex";

export type warehouseProductType = "simpleProduct" | "billMaterials";

export type hoursTypeList = "delivery" | "takeAway" | "table" | "express";

export type itemComponent = {
  _id: string;
  quantity: number;
};

export type LinkedComponent = {
  _id: string;
  shouldScale: boolean;
  linked: boolean;
  componentName: string;
};

export type Visibility = {
  filterType: FilterType[];
  mode: "all" | "categories" | "items";
  categories: string[];
  items: string[];
  brandId: string;
};

export interface Item extends OtherLanguages<{ en: { name: string; description: string } }> {
  _id: string;
  itemName: string;
  name: string;
  category: Category | string;
  additionalCategories: string[];
  description: string;
  image?: string;
  price: number | undefined;
  externalRef: string;
  itemPrice: number | undefined;
  itemType: ItemType;
  availableConfigurations: ItemConfiguration[];
  integrations?: any;
  selectableItems: Selectable;
  shops: ItemShop[];
  filterType: FilterType[];
  delay: ItemDelay;
  initial?: boolean;
  copyProduct?: boolean;
  position: number;
  preorder?: Date;
  configurationShop?: string;
  allergens: Allergens[];
  ingredients: Ingredient[];
  newIngredient: NewIngredient;
  rawMaterialCategory?: string;
  rawMaterialCheck: boolean;
  adultCheck: boolean;
  isSoldOut?: boolean;
  fiscality: {
    cashDeskService: ItemTax;
    tableService: ItemTax;
    delivery: ItemTax;
    takeAway: ItemTax;
  };
  components: itemComponent[];
  productType?: warehouseProductType;
}

export type ComponentShop = {
  _id: string;
  quantity: number;
  unitPrice: number;
  additionPrice: number;
};

export type ComponentType = "base" | "complex";

export type loadType = "load" | "unload";

export interface Component extends OtherLanguages<{ en: { name: string; description: string } }> {
  _id: string;
  componentType: ComponentType;
  componentCategory: string;
  additionalComponentCategories: string[];
  position: number;
  image?: string;
  name: string;
  description: string;
  initial?: boolean;
  price: number | undefined;
  unit: string;
  allergens?: Allergens[];
  externalRef: string;
  components: LinkedComponent[];
}

export interface Load {
  _id: string;
  brand: string;
  component: string;
  shop: string;
  loadType: loadType;
  quantity: number;
  unitPrice: number;
  additionPrice: number;
  supplierId: string;
  ivaPrice?: number;
  iva?: string;
  cause?: string;
  notes?: string;
  barcode?: string;
  orderNumber: string;
  documentNumber: string;
  documentType: string;
  productionDate?: Date;
  expirationDate?: Date;
  lotNumber?: string;
  deliveryDate?: Date;
  deliveryHour?: string;
  deliveryTemperature?: number;
  deliveryConditions?: string;
  specificationsCompliance?: boolean;
  expireDateVerification?: boolean;
  deliveryConditionsVerification?: boolean;
  accepted?: boolean;
  rejectReason?: string;
  signature?: string;
}

export interface StockComponent {
  _id: string;
  brand: string;
  componentType: ComponentType;
  description: string;
  name: string;
  owner: string;
  unit: string;
  shop: {
    _id: string;
    quantity: string;
    unitPrice: string;
  }[];
}

export interface StockQuantity {
  quantity: number;
  unit: "kg" | "pz" | "l";
}

export interface Stock {
  components: StockComponent[];
  quantity: StockQuantity[];
}

export interface LoadForTable {
  _id: string;
  brand: string;
  component: {
    _id: string;
    name: string;
  };
  componentCategory: string;
  shop: {
    _id: string;
    name: string;
  };
  loadType: loadType;
  merchant: {
    _id: string;
    name: string;
    surname: string;
  };
  owner: string;
  quantity: number;
  unitPrice: number;
  productionDate?: Date;
  expirationDate?: Date;
  lotNumber?: string;
  deliveryDate?: Date;
  deliveryHour?: string;
  deliveryTemperature?: number;
  deliveryConditions?: string;
  specificationsCompliance?: boolean;
  expireDateVerification?: boolean;
  deliveryConditionsVerification?: boolean;
  accepted?: boolean;
  rejectReason?: string;
  signature?: string;
}

export interface Stock {
  _id: string;
  unit: string;
  componentType: "base" | "raw";
  lastUpdate: Date;
  name: string;
  brand: string;
  owner: string;
  price: number;
  componentCategory: string;
  shops: StockShop[];
}

export interface StockShop {
  quantity: number;
  unitPrice: number;
  _id: {
    currency: string;
    name: string;
    _id: string;
  };
}

export type ItemTax = "4" | "10" | "22" | "N4";

export interface NewIngredient extends OtherLanguages<{ en: { value: string } }> {
  value: string;
}

export interface Ingredient extends OtherLanguages<{ en: { value: string } }> {
  value: string;
}

export interface ItemConfiguration extends OtherLanguages<{ en: { name: string } }> {
  _id: string;
  name: string;
  possibleValuesAndPrice: PossibleValuesAndPrice[];
  limit: number;
  required: boolean;
  enabled: boolean;
  enabledShops: string[];
}

export interface PossibleValuesAndPrice extends OtherLanguages<{ en: { value: string } }> {
  _id: string;
  value: string;
  price?: number;
  limit?: number;
  enabled: boolean;
  enabledShops: string[];
  components: itemComponent[];
  linkedItem?: Item | string;
  handleLinkedQuantity?: boolean;
  linkedItemAdultCheck?: boolean;
}

export type SelectableItem = {
  _id: string;
  limit: number;
};

export type Selectable = {
  items: SelectableItem[];
  limit?: number;
  minPrice?: number;
};

export type LiveOrdersType = "delivery" | "takeaway" | "ecommerce" | "archive" | "express" | "cashdesk" | "all";

export type OrderStatus =
  | "pending"
  | "confirmed"
  | "ready"
  | "onDelivery"
  | "delivered"
  | "canceled"
  | "open"
  | "closed"
  | "payed";

export type DeliveryInfo = {
  homeDelivery: boolean;
  date: Date;
  address: Address;
  table: Table;
  watched: boolean;
  tableName: string;
  tablePeople: number;
  lastWatch: Date;
  mode: "scheduled" | "asap";
  orderUserInfo?: {
    email: string;
    name: string;
    surname: string;
    phone: string;
  };
};

export type PaymentType = "stripe" | "paypal" | "onDelivery";

export type ChannelType =
  | "cashDesk"
  | "ecommerce"
  | "delivery"
  | "takeaway"
  | "table"
  | "express"
  | "experience"
  | "notCollected"
  | "bancomat"
  | "creditCard"
  | "stripePos"
  | "ticket"
  | "cash"
  | "sepa"
  | "stripe"
  | "token";

export type ExperiencePaymentType = "cash" | "creditCard";

export type PaymentMode = "items" | "amount" | "total" | "share";

export type Rider = {
  _id: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  vehicle: RiderVehicles;
  shop: string;
  assign: RiderAssignType;
};

export type PaymentId = {
  paymentId: string;
};

export type OrderId = {
  orderId: string;
};

export type Payment = {
  _id: string;
  price: number;
  order: string;
  fiscale?: string;
  brand: string;
  paymentType: PaymentType;
  tip: number;
  paymentAmount: PaymentAmount;
  cartItems?: string[];
  taxPrice?: number;
  shares?: number[];
  status: "pending" | "payed" | "refunded";
};

export type OrderConsense = {
  profilingConsense: boolean;
  marketingConsense: boolean;
  thirdPartyMarketingConsense: boolean;
  profilingDate: Date;
  marketingDate: Date;
  thirdPartyMarketingDate: Date;
  phone?: string;
};

export type Order = {
  _id: string;
  brand: string;
  created: Date;
  shop: Shop;
  user: User;
  consenses: OrderConsense[];
  promo: Promo[];
  qr: QR;
  cart: Cart[];
  mode: "scheduled" | "asap";
  paymentType: PaymentType;
  paymentMode: PaymentMode;
  deliveryInfo: DeliveryInfo;
  deliveryMethod: DeliveryMethod;
  deliveryCode: string;
  deliveryPrice: number;
  status: OrderStatus;
  payed: "pending" | "payed" | "refunded" | "partial";
  payment?: Payment[];
  price: number;
  pricePayed: number;
  servicePrice: number;
  priceRefunded?: number;
  notes: string;
  code: string;
  orderOuting: {
    status: "ready" | "pending";
  }[];
  orderType: OrderType;
  rider: Rider | null;
  mutateFix?: number;
  taxPrice: number;
  fiscale: OrderTaxation[];
  annulloFiscale: OrderTaxation[];
  digitalAmount: number;
  cashAmount: number;
  promoPrice: number;
  promoValue: number;
  promoType: "percentage" | "fixed" | "team" | "percentagePlus" | "fixedPlus";
  progressiveDailyId?: string;
  name: string;
  surname: string;
  tip: number;
  pickupMode: string;
  printed: {
    nonFiscal: boolean;
    fiscal: boolean;
  };
  paymentAmount: PaymentAmount;
  saloonInfo?: SaloonInfo;
};

export type FiscaleType = "commerciale" | "annullo" | "nonFiscalInvoice";

export type OrderTaxation = {
  _id: string;
  brand: string;
  canceled: false;
  created: Date;
  documentType: FiscaleType;
  idtrx: string;
  order: string;
  pdfUrl: string;
  progressivo: string;
  shop: string;
  dc: {
    idtrx: string;
    documentoCommerciale: {
      scontoAbbuono: string;
      ammontareComplessivo: number;
      importoTotaleIva: number;
      resoAnnullo: {
        progressivo: string;
      };
    };
  };
};

export type OrderType = "delivery" | "table" | "cashDesk" | "express" | "experience";

export type User = {
  _id: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  created: Date;
};

export type UserRegistration = {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  shop?: string;
};

export type TeamMember = {
  _id: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  shop: string[];
  type?: TeamMeberType;
  vehicle?: RiderVehicles;
  assign?: RiderAssignType;
  orderStatus?: OperatorOrderStatus[];
  orderType?: OperatorOrderType[];
  permissions?: OperatorPermission[];
  services?: OperatorServices[];
  openCartByDefault?: boolean;
};

export type JwtQrForOperator = {
  token: string;
};

export type TokenListType = {
  brand: string;
  name: string;
  shop: string[];
  validFrom: string;
  validTo: string;
  valueOfOneToken: number;
  _id: string;
};

export type TeamMeberType = "manager" | "rider" | "waiter" | "operator";

export type RiderAssignType = "manual" | "auto";

export type Address = {
  latitude: number;
  longitude: number;
  formatted: string;
  phone: string;
  notes: string;
};

export type PromoType = "code" | "category" | "item" | "shipment";

export type DiscountType = "percentage" | "flat" | "shipment";

export type PromoFilterSelected = "none" | "all" | "item" | "category";

export type PromoRequirementSelected = "none" | "minimumAmount" | "minimumItems";

export type PromoStatus = "active" | "inactive";

export interface Promo extends OtherLanguages<{ en: { description: string } }> {
  _id: string;
  brandId: string;
  brand: string;
  promoType?: PromoType;
  name: string;
  description: string;
  shops: string[];
  discountType?: DiscountType;
  amount?: number;
  amountLimit?: number | string;
  filterSelected?: PromoFilterSelected;
  items: Item[];
  categories: Category[];
  requirementSelected?: PromoRequirementSelected;
  minimumAmount?: number;
  minimumItems?: number;
  maxUsagePerUser: number | undefined;
  maxUsageGlobal: number;
  dateFilterSelected: "none" | "period";
  startDate?: Date;
  endDate?: Date;
  banner: boolean;
  enabled: boolean;
  searchItems?: string;
  searchCategories?: string;
  code?: string;
  usageCount: number;
  orderType: string[];
}

export type CartConfiguration = {
  _id?: string;
  key: string;
  values: CartConfigurationValue[];
};

export type CartConfigurationValue = {
  _id: string;
  value: string;
  price: number;
  linkedItem?: Item;
};

export type Cart = {
  _id?: string;
  item: Item;
  itemName: string;
  itemDesc: string;
  itemPrice: number;
  itemPromo: number;
  itemNotes: string;
  itemPriceBeforePromo?: number;
  itemTax: ItemTax;
  itemTaxPrice: number;
  category: Category;
  configuration: CartItemConfiguration[] | CartConfiguration[];
  selectedItems: Item[];
  pickedUp: boolean;
  orderNumber: number;
  count: number;
  payed?: "payed" | "pending";
  isCover: boolean;
  payment?: string;
  status?: "waiting" | "confirmed" | "draft";
};

export interface CartWithRealIndex extends Cart {
  realIndex?: number;
}

export type CartToEdit = {
  _id?: string | undefined;
  item: string;
  itemName: string;
  itemDesc: string;
  itemPrice: number;
  itemPromo: number;
  itemTax: ItemTax;
  configuration: CartConfiguration[] | CartItemConfiguration[];
  selectedItems: Item[];
  orderNumber: number;
  isCover: boolean;
  status?: string;
};

export type CartToRemove = {
  _id: string;
  itemType: ItemType;
};

export type QR = {
  _id: string;
  brand: string;
  code: string;
  shop: string;
  table: string;
  qrType: "table" | "express" | null;
  notes?: string;
};

export type Table = {
  _id: string;
  shop: Shop | string;
  name: string;
  qr: QR;
  seats: {
    max: number | null;
    min: number | null;
  };
  created?: string;
  saloon?: string;
  bookingTable?: boolean;
  status?: "open" | "closed";
  expressCheckout?: boolean;
  orderTable?: boolean;
  enabled: boolean;
  color?: string;
};

export type EnabledTable = {
  orders: {
    _id: string;
    price: number;
    promoPrice: number;
    deliveryPrice: number;
    cart: Cart[];
    created: string;
    status: "open" | "closed";
    deliveryInfo: {
      date: string;
      tableName: string;
      tablePeople: number;
      table: Table;
    };
  }[];
};

export type tableCardState = "show" | "manage" | "in-progress";

export type Saloon = {
  _id: string;
  shop: Shop | string;
  name: string;
  indoor: boolean;
  enabled: boolean;
};

export type SaloonV2 = {
  elementCount: number;
  saloon: {
    _id: string;
    shop: Shop | string;
    name: string;
    enabled: boolean;
    viewPort?: {
      viewportTransform: [];
      zoom: number;
    };
  };
};

export type EnableTableDataNew = {
  _id: string;
  shop: Shop | string;
  tableId: string;
  people: number;
};

export type PaginatedData<T> = {
  data: T;
  hasNextPage: boolean;
  page: number;
  pages: number;
  total: number;
  paginationToken: string;
  mutateFix?: number;
};

export type Printer = {
  _id: string;
  ip: string;
  owner: string;
  brand: string;
  port: number | string;
  name: string;
  password: string;
  categories: string[];
  categoriesToReprint: string[];
  hideOrderDetails: boolean;
  printFiscalLikeReceipt: boolean;
  printNonFiscal: boolean;
  printNonFiscalAfterFiscal: boolean;
  splitCustomLinkedItems: boolean;
  printFakeFiscalReceipt: boolean;
  productsTextDimension: number;
  customProductsTextDimension: number;
  shopTextDimension: number;
  isFiscal: boolean;
  isETicketEnabled: boolean;
  shops: string[];
  printRecapOnreprint: boolean;
  devices: PrinterDevice[];
  departments: number[];
  supportDrawer: boolean;
  openDrawerAfterPayment: boolean;
  ssl: boolean;
};

export type PrinterDevice = {
  name: string;
  uuid: string;
  autoPrint: {
    ordersTypes: PrinterOrderTypes[];
    orderStatus: PrinterOrderStatus[];
    printNotPayedOrders: boolean;
    hideDetails: boolean;
    hideIngredients: boolean;
    hideAllergens: boolean;
  };
};

export type PrinterOrderTypes = "delivery" | "takeAway" | "express" | "table" | "experience";

export type PrinterOrderStatus = "pending" | "open" | "confirmed" | "ready" | "canceled";

export type FilterProperties =
  | "shopId"
  | "paymentType"
  | "from"
  | "to"
  | "status"
  | "type"
  | "datesAndHours"
  | "fiscal";

export type FilterStatus = {
  [key in FilterProperties]?: any;
};

export type TabItem = {
  name: string;
  children?: React.ReactNode;
  isActive?: boolean;
  url?: {
    href?: string;
    onClick?: Function;
  };
  hasError?: boolean;
};

export type Mutate<T> = (data?: MutatorCallback<T>, shouldRevalidate?: boolean) => void;

export type Booking = {
  _id: string;
  user: User;
  table?: Table;
  tableName?: string;
  shop: Shop;
  created: Date;
  from: Date;
  to: Date;
  people: number;
  status: BookingStatus;
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
};

export type BookingStatus = "pending" | "confirmed" | "canceled";

export type EnableTableData = {
  rid: string | null;
  shop: string | null;
  table: string | null;
  people?: number;
};

export type Notification = {
  title: string;
  body: string;
};

export type BrandImages = {
  logo: File | string | null | undefined;
  horizontalLogo: File | string | null | undefined;
  header: File | string | null | undefined;
};

export type BrandSetupState = {
  brand: Brand;
  card: Card;
  bankAccount: BankAccount;
};

export type Card = {
  brand: string;
  name: string;
  fourDigits: string;
  holder: string;
  cardNumber?: string;
  expireDate?: string;
  cvc?: string;
};

export type BrandSetupStep = "general" | "subscription";

export type CreditCardsType =
  | "American Express"
  | "cartes_bancaires"
  | "diners_club"
  | "discover"
  | "jcb"
  | "MasterCard"
  | "Visa"
  | "unionpay";

export type RiderVehicles = "bike" | "motorbike" | "car" | "scooter";

export const enum OperatorOrderType {
  OrderAndPay = "express",
  Delivery = "delivery",
  Takeaway = "takeAway",
  CashDesk = "cashDesk",
}

export const enum OperatorOrderStatus {
  Pending = "pending",
  Confirmed = "confirmed",
  Ready = "ready",
  Delivering = "delivering",
}

export const enum OperatorPermission {
  OrderRefund = "order.action.refund",
  OrderCancel = "order.action.cancel",
}

export const enum OperatorServices {
  Orders = "orders",
  CashDesk = "cashDesk",
}

export type TableProperties = {
  label: string;
  value?: string;
  className?: string;
  bodyClassName?: string;
};

export type MenuItems = {
  name?: string;
  url?: {
    externalHref?: string;
    href?: string;
    onClick?: Function;
  };
  icon?: IconProp;
  children?: JSX.Element;
  className?: string;
  iconClassName?: string;
  linkHoverClassName?: string;
} | null;

export type MenuOption = {
  label: string;
  icon: React.ReactNode;
  href: string;
  permission: UserType[];
  showOnlyWhen: string[];
  newFeature?: boolean;
  subPages: MenuSubPage[];
  exact?: boolean;
  isMobile?: boolean;
} | null;

export type MenuSubPage = {
  label: string;
  href: string;
  isExternal?: boolean;
} | null;

export type Invoice = {
  _id: string;
  created: Date;
  amount: number;
  exceedOrderCostDelivery: number;
  exceedOrderCostTable: number;
  status: "pending" | "payed" | "refunded" | "failed" | "error";
  vat: number;
  fic_pdf: string;
  includedOrdersCount: number;
  payedOrdersUnder300Count: number;
  payedOrdersOver300Count: number;
  tableOrdersCount: number;
  additionalShopCount: number;
};

export type CurrentCosts = {
  additionalShopCost: number;
  amount: number;
  baseFee: number;
  deliveryOrderCount: number;
  exceedOrderCostOver300: number;
  exceedOrderCostTable: number;
  exceedOrderCostUnder300: number;
  includedOrders: number;
  payedOrdersOver300Count: number;
  payedOrdersUnder300Count: number;
  tableOrderCount: number;
  expressOrderCount: number;
};

export type CurrentCostsKey = "amount" | "baseFee";

export type CurrentCostsKeyNew = "number" | "ordersPrice";

export type UpdateAnalytics = (type: "add" | "update" | "remove", key: AnalyticsProperties, data?: any) => void;

export type AnalyticsProperties =
  | "start"
  | "end"
  | "shopId"
  | "timeframe"
  | "brandId"
  | "productType"
  | "paymentType"
  | "transactionType"
  | "experiencePaymentType";

export type AnalyticsPropertiesV2 =
  | "dateFrom"
  | "dateTo"
  | "shopId"
  | "timespan"
  | "brandId"
  | "productType"
  | "paymentMethod"
  | "channel"
  | "shopIds"
  | "match.printer.objectId"
  | "granularity";

export type HallProperties = "shopId" | "indoor" | "saloonId";

export type HallPropertiesV2 = "shopId" | "saloonId" | "type";

export type StocksProperties = "start" | "end" | "shopId" | "brandId" | "componentType" | "timeframe";

export type CustomerProperties = "dateFrom" | "dateTo" | "timespan";

export type AnalyticsStatus = {
  [key in AnalyticsProperties]?: any;
};

export type AnalyticsStatusV2 = {
  [key in AnalyticsPropertiesV2]?: any;
};

export type CustomerStatus = {
  [key in CustomerProperties]?: any;
};

export type HallStatus = {
  [key in HallProperties]?: any;
};

export type HallStatusV2 = {
  [key in HallPropertiesV2]?: any;
};

export type StocksStatus = {
  [key in StocksProperties]?: any;
};

export type Timeframe = "today" | "yesterday" | "week" | "month" | "year" | "custom";

export type SelledProducts = {
  name: string;
  count: string;
  category: string;
  revenue: string;
  untouchedRevenue: number;
};

export type MergedSelledProducts = {
  categoryName: string;
  products: SelledProducts[];
};

export type HallTab = "new" | "in-progress" | "manage-saloon" | "archive";

export type AnalyticsTab = "products" | "revenue" | "orders" | "fiscality" | "tips" | "customers" | "experience";

export type AnalyticsTabV2 = "general" | "delivery" | "takeaway" | "order-pay" | "cashdesk" | "objects" | "fiscality";

export type SettingsTab = "account" | "billing" | "customization" | "integrations-apps";

export type AggregatedDataField = "deliveryInfo.homeDelivery" | "orderType" | "paymentType" | "category";

export type AggregatedDataCollectionName = "item" | "order" | "user";

export type BrandFunctionsPromise = {
  brands: Brand[];
  shops: Shop[];
};

export type GenericStatistics = {
  date: number;
  element: number;
};

export type ApiStatistics = {
  x: {
    hours?: number;
    day?: number;
    month: number;
    year: number;
  };
  y: number;
};

export type Margin = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export type ShopEditorTab =
  | "general"
  | "hours"
  | "delivery"
  | "takeaway"
  | "order-pay"
  | "bookings"
  | "taxation"
  | "other"
  | "halls"
  | "cashdesk"
  | "digital-menu"
  | "pay";

export type EventEditorTab = "general" | "associated-tickets";

export type StructureTab = "general" | "aree";

export type TicketEditorTab = "general" | "ticket-list";

export type Allergens =
  | "gluten"
  | "celery"
  | "treeNuts"
  | "fish"
  | "soy"
  | "sesame"
  | "peanuts"
  | "crustaceans"
  | "eggs"
  | "molluscs"
  | "milk"
  | "mustard"
  | "sulphurDioxide"
  | "lupin";

export type AllergensData = {
  value: Allergens;
  label: string;
  description: string;
};

export type Group = {
  _id: string;
  brand: string;
  category: string;
  name: string;
  items: string[];
  position: number;
};

export type MutateCategoriesGroups = {
  categories: Mutate<Category[]>;
  groups: Mutate<Group[]>;
};

export type MutateComponentCategoriesGroups = {
  categories: Mutate<ComponentCategory[]>;
  groups: Mutate<Group[]>;
};

export type ManualBooking = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  people: number | string;
  shop: string | number | null | undefined;
  table: string | null;
  date: Date | null;
  hour: string | null;
  from?: string;
  to?: string;
  tables: Table[];
  freeTime?: undefined;
  discount?: undefined;
};

export type BookingModal = {
  type: "manualBooking" | "detailsBooking" | "cancelBooking" | null;
  booking: Booking | null;
};

export type Taxation = {
  cf: string;
  password?: string;
  pin: string;
  piva: string;
  tipoIncarico: "incDiretto";
  denominazione: string;
  indirizzo: string;
  numeroCivico: string;
  cap: string;
  comune: string;
  provincia: string;
  nazione: "IT";
  defAliquotaIVA: "22";
};

export type CashKeyPadState = {
  currentPrice: number;
  quantity: number;
  totalPrice: number;
  mode: "sum" | "multiply";
};

export type CashKeyPadStateV2 = {
  sign: "-" | "X" | "%-" | "%+" | "+" | "/" | "";
  num: number | string;
  result: number | string;
};

export type TaxType = "Rep. 1" | "Rep. 2" | "Rep. 3" | "Esente Iva";

export type ValidTax = "4" | "10" | "22" | "N4";

export type CashDeskCart = {
  item?: Item;
  itemName: string;
  itemPrice: number;
  itemTax: ValidTax;
  itemTaxPrice: number;
  itemPromo: number;
  itemGift: boolean;
  quantity: number;
  discount: number;
  originalPrice: number;
  configuration?: CartItemConfiguration[];
};

export type CashDeskCartOptional = {
  item?: Item;
  itemName: string;
  itemPrice: number;
  itemTax: ValidTax;
  itemTaxPrice: number;
  itemPromo: number;
  itemGift: boolean;
  quantity?: number;
  discount?: number;
};

export type CashDeskMode = "items" | "cashDesk";

export type CashDeskContextState = {
  shopId: string | null;
  mode: CashDeskMode;
  cart: CashDeskCart[];
  cashAmount: number;
  digitalAmount: number;
  promoAmount: number;
  promoType?: CashDeskPromoType;
  discount: CashDeskDiscount;
  taxAmount: number;
  email: string | null;
  pickupMode: string | undefined;
  itemToConfigure: ItemToConfigure;
};

export type ItemToConfigure = {
  item: Item;
  configuration?: CartItemConfiguration[];
  isEditing: boolean;
} | null;

export type TableItemToAdd = {
  item: string;
  configuration: CartItemConfiguration[];
  selectedItems: string[];
};

export type CashDeskContextStateOptional = {
  shopId?: string | null;
  mode?: CashDeskMode;
  cart?: CashDeskCart[];
  cashAmount?: number;
  digitalAmount?: number;
  promoAmount?: number;
  promoType?: CashDeskPromoType;
  discount?: CashDeskDiscount;
  taxAmount?: number;
  email?: string | null;
  pickupMode?: string;
  itemToConfigure?: {
    item: Item;
    configuration?: CartItemConfiguration[];
    isEditing: boolean;
  } | null;
};

export type CartItemConfiguration = {
  key: string;
  values: string[];
  price: number;
};

export type CashDeskDiscount = {
  type: "fixed" | "percentage" | null;
  amount: number;
};

export interface CashDeskContextStateInterface extends CashDeskContextState {
  addToCart: (item: Item, configuration?: CartItemConfiguration[]) => void;
  updateCashDeskContext: (state: CashDeskContextStateOptional) => void;
}

export type GenerateReceiptApi = {
  pdf: string;
  progressivo: string;
  order: Order;
  created: Date;
};

export type CashDeskStatusState = {
  loading: boolean;
  progressivo: string;
  order: Order | null;
  pdf: string;
  error: boolean | null;
};

export type TableOrderStatusState = {
  loading: boolean;
  progressivo: string;
  order: Order | null;
  pdf: string;
  error: string | null;
};

export type CashDeskStatusStateOptional = {
  loading?: boolean;
  progressivo?: string;
  order?: Order | null;
  pdf?: string;
  error?: boolean | null;
};

export type NuovaStatus = {
  [key in NuvolaProperties]?: any;
};

export type NuvolaProperties = "from" | "to" | "shopId" | "code" | "timeframe" | "page";

export type NuovaKpi = {
  ordersNumber: number;
  ordersNumberNuvola: number;
  ordersResult: NuovaSingleKpi[];
  ordersNuvolaResult: NuovaSingleKpi[];
};

export type NuovaSingleKpi = {
  _id: OrderType;
  total: number;
};

export type GoogleMapsLibraries =
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization";

export type TaxButton = {
  name: string;
  tax: ValidTax;
  _id: string;
};

export type OrderButton = {
  name: string;
};

export type CashDeskOrderStats = {
  _id: string;
  "0%": number;
  "4%": number;
  "5%": number;
  "10%": number;
  "22%": number;
  totalCount: number;
  totalValue: number;
  tip: number;
};

export interface Banner extends OtherLanguages<{ en: { title: string; description: string } }> {
  _id: string;
  brand: string;
  brandId: string;
  enabled: boolean;
  title: string;
  description: string;
  shops: Shop[] | string[];
  bannerType: BannerTypes;
  item?: Item | string;
  category?: Category | string;
  url?: string;
  image: string;
  startDate?: Date;
  endDate?: Date;
}

export type BannerTypes = "base" | "item" | "category" | "url";

export type CashDeskPromoType = "fixed" | "percentage";

export type FilterOrderStatuses =
  | "pending"
  | "confirmed"
  | "ready"
  | "onDelivery"
  | "allCompleted"
  | "canceled"
  | "open";

export type FilterOrderType = "ecommerce" | "delivery" | "takeAway" | "table";

export type GeneratedShopHours = {
  label: string;
  value: string;
  freeTime?: number;
  discount?: number;
};

export type StripeAccountStates = "pending_requirements" | "completed";

export type StripeAccount = {
  _id: string;
  brand: string;
  country: string;
  created: Date;
  owner: string;
  email: string;
  state: StripeAccountStates;
  stripeId: string;
  details_submitted: boolean;
  charges_enabled: boolean;
  link?: {
    created: number;
    object: string;
    url: string;
  };
};

export type StripeAddonInfo = {
  accountId: string;
};

export type AddonName =
  | "apple"
  | "facebook"
  | "google"
  | "stripe"
  | "stripe-connect"
  | "stripe-backup"
  | "paypal"
  | "poket"
  | "glovo"
  | "poony"
  | "ipratico"
  | "digital-menu"
  | "fiscale"
  | "otp"
  | "warehouse"
  | "promo"
  | "notification"
  | "table-booking"
  | "table-order"
  | "delivery"
  | "order-pay"
  | "experience"
  | "aruba-invoice"
  | "billy"
  | "cashDesk";

export type Addon = {
  _id: string;
  merchant: string;
  brand: string;
  shop: string;
  name: AddonName;
  pub: string;
  priv: string;
  stripe?: StripeAddonInfo;
  expo: boolean;
  billy: BillyAddon
};

export type BillyAddon = {
  denominazione: string,
  indirizzo: string
  numeroCivico: string,
  cap: string
  comune: string,
  provincia: string,
  fullAddress: string,
  piva: string,
  codiceFiscale: string,
  id_utenza: string,
  login: {
      ae_user: string
      ae_password: string
      ae_pin: string
  }
}

export type Supplier = {
  _id: string;
  brand: string;
  type: "piva" | "private";
  name: string;
  email: string;
  iban: string;
  shops: string[];
  piva?: {
    _id: string;
    brand: string;
    uniqueCode?: string;
    pec?: string;
    codiceFiscale: string;
    denominazione: string;
    idIva: {
      codicePaese: string;
      partitaIva: string;
    };
    indirizzo: {
      indirizzo: string;
      cap: string;
      provincia: string;
      comune: string;
    };
  };
};

export type PIVAInfo = {
  _id: string;
  brandId: string;
  denominazione: string;
  codiceFiscale: string;
  idIva: {
    codicePaese: string;
    partitaIva: string;
  };
  indirizzo: {
    indirizzo: string;
    cap: string;
    comune: string;
    provincia: string;
  };
  codiceDestinatario?: string;
  pec?: string;
};

export type FilterMovementType = "loading" | "unloading" | undefined;

export type ComponentTypeKeys = "base" | "complex";

export type BankAccount = {
  holder: string;
  email: string;
  iban: string;
};

export type ModalState = {
  type: "newSaloon" | "newTable" | "editSaloon" | "editTable" | "deleteSaloon" | "deleteTable" | "openTable" | null;
  selected: Table | Saloon | null;
};

export type ModalStateOptional = Partial<ModalState>;

export type ModalStateTable = {
  type: "openTable" | null;
  selected: Table | null;
};

export type ModalStateTableOptional = Partial<ModalStateTable>;

export type VisibilityType = "all" | "categories" | "items";

export type LanguagesAvailable = "it" | "en";

export type PrinterResponse = {
  receiptNumber: string;
  receiptAmount: number;
};

export type PrinterError = {
  code: string;
  status: number;
};

export type AccountInfo = {
  language: SupportedLanguages;
  fuso: string;
  oldPassword?: string;
  newPassword?: string;
  newPasswordConfirm?: string;
  oldEmail?: string;
  email?: string;
};

export type MakeOptional<V, K extends keyof V> = Omit<V, K> & Partial<Pick<V, K>>;

export interface Operator {
  _id?: string;
  owner: string;
  brand: string;
  shop: string;
  user: string;
  orderStatus?: OperatorOrderStatus[];
  orderType?: OperatorOrderType[];
  permissions?: OperatorPermission[];
  services?: OperatorServices[];
  openCartByDefault?: boolean;
}

export type OperatorStatus = Required<Operator>;

export type FiscalPrinterDailyClose = {
  success: boolean;
  code: string;
  status: number;
  dailyAmount: string;
};

export type DailyClose = {
  _id: string;
  shops: string[];
  created: Date;
  brand: string;
  printer: string;
  amount: number;
};

export type CartItemWithQuantity = {
  quantity: number;
  item: Item;
  cart: Cart;
  isCover: boolean;
};

export type TaxButtons = {
  _id: string;
  shop: string;
  brand: string;
  owner: string;
  name: string;
  tax: ValidTax;
  __v: number;
};

export type ManageType = "editmap" | "map" | "card";

export type HallManagerMode = "cardMini" | "card" | "map";

export type HallManagerStatus = "empty" | "opened" | "booked";

export type ObjectsSaved = {
  _id: string;
  name: string;
  showChairs: boolean; // Opzionale in quanto potrebbe essere un accessorio
  seatsRange: {
    // Opzionale in quanto potrebbe essere un accessorio
    max: number;
    min: number;
  };
  express: {
    // Opzionale in quanto potrebbe essere un accessorio
    enabled: boolean;
  };
  enabled: boolean; // Opzionale in quanto potrebbe essere un accessorio

  booking: {
    // Opzionale in quanto potrebbe essere un accessorio
    enabled: boolean;
  };
  accessory: boolean;
  qr?: string; // Opzionale in quanto non Ã¨ detto che sia abilitato O&P
};

export type Elements = {
  owner: string;
  shop: string | null;
  brand: string;
  saloon: string | null;
  merged: [];
  elementType: string;
  left: number;
  top: number;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  angle: number;
};

export type SaloonElements = {
  _id: string;
  name: string;
  showChairs: boolean;
  seatsRange: {
    max: number;
    min: number;
  };
  express: {
    enabled: boolean;
  };
  enabled: boolean;

  booking: {
    enabled: boolean;
  };
  accessory: boolean;
  qr?: {
    code: string;
  };
  owner: string;
  shop: string | null;
  brand: string;
  saloon: {
    _id: string;
    name: string;
  };
  merged: [];
  elementType: string;
  left: number;
  top: number;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  status?: HallManagerStatus;
  orderId?: string;
};

export type HallManagerContextState = {
  shopId: string | null;
  saloonId: string | null;
  mode: HallManagerMode;
  saloons: SaloonV2[];
  filters: {
    name: string;
    covers: number;
    orderAndPay: boolean;
    status: HallManagerStatus[];
  };
};

export type HallManagerContextStateOptional = {
  shopId?: string | null;
  saloonId?: string | null;
  mode?: HallManagerMode;
  saloons?: SaloonV2[];
  filters?: {
    name?: string;
    covers?: number;
    orderAndPay?: boolean;
    status?: HallManagerStatus[];
  };
};

export interface HallManagerContextStateInterface extends HallManagerContextState {
  updateHallManagerContext: (state: HallManagerContextStateOptional) => void;
}

export type CashDeskCartV2 = {
  item?: Item;
  itemName: string;
  isCover?: boolean;
  itemPrice: number;
  itemTax: ValidTax;
  itemTaxPrice: number;
  itemPriceBeforePromo?: number;
  itemPromo: number;
  itemPromoValue: number;
  itemNotes: string;
  itemPromoType?: DiscountTypes;
  orderNumber?: number;
  status?: "waiting" | "draft" | "pending" | "confirmed";
  payed: "payed" | "partial" | "pending" | undefined;
  payment?: {
    _id?: string;
    status: "payed" | "partial" | "pending" | undefined;
    paymentAmount: PaymentAmount;
  };
  // se da select si seleziona Offerto, flaggare a true
  itemGift: boolean;
  discount: Omit<CashDeskDiscountV2, "notes">;
  configuration?: CartItemConfiguration[] | CartConfiguration[];
  notes: string;
  selected: boolean;
  _id?: string;
};

export type CashDeskModeV2 = "items" | "keyPad";
export type CashDeskPaymentModeV2 = "total" | "amount" | "items" | "share";

export type Share = {
  name: string;
  price: number;
  selected: boolean;
  isPayed: boolean;
  hasToBePayed: boolean;
};

export type CustomPayment = {
  name: string;
  price: number;
  selected: boolean;
  isPayed: boolean;
  hasToBePayed: boolean;
  taxPrice: number;
  taxValue: ValidTax;
  _id?: string;
};

export type CashDeskContextStateV2 = {
  shopId: string | null;
  mode: CashDeskModeV2;
  cart: CashDeskCartV2[];
  discount: CashDeskDiscountV2;
  pickupMode: string | undefined;
  itemToConfigure: ItemToConfigureV2;
  categoryId: string | null;
  draftOrders: Order[];
  notes: string;
  paymentMode: CashDeskPaymentModeV2;
  shares: Share[];
  customPayments: CustomPayment[];
  isPayingMode: boolean;
  isCartViewMode: boolean;
  tax: string;
  order: OrderV2 | null;
  orderNumber: number | undefined;
  orderOuting?: OrderOuting[];
  updateTableOrder?: boolean;
};

export type CashDeskFiscalTypeV2 = "fiscal" | "invoice" | "nonFiscal";

export type ItemToConfigureV2 = {
  item: Item;
  configuration?: CartItemConfigurationV2[];
  isEditing: boolean;
} | null;

export type CashDeskContextV2StateOptional = {
  shopId?: string | null;
  mode?: CashDeskModeV2;
  cart?: CashDeskCartV2[];
  discount?: CashDeskDiscountV2;
  pickupMode?: string | undefined;
  itemToConfigure?: ItemToConfigureV2;
  categoryId?: string | null;
  draftOrders?: Order[];
  notes?: string;
  paymentMode?: CashDeskPaymentModeV2;
  shares?: Share[];
  customPayments?: CustomPayment[];
  isPayingMode?: boolean;
  isCartViewMode?: boolean;
  tax?: string;
  order?: OrderV2 | null;
  orderNumber?: number | undefined;
  orderOuting?: OrderOuting[];
  updateTableOrder?: boolean;
};

export type CartItemConfigurationV2 = {
  key: string;
  values: string[];
  price: number;
};

export type CashDeskCartOptionalV2 = {
  item?: string;
  itemName: string;
  itemPrice: number;
  itemTax: ValidTax;
  itemTaxPrice: number;
  payed: "payed" | "partial" | "pending" | undefined;
  _id?: string;
  itemPromo?: number;
  itemNotes?: string;
  itemPromoType?: CashDeskDiscountV2["type"];
  configuration?: CartItemConfigurationV2[];
  itemGift: boolean;
  isCover?: boolean;
  quantity?: number;
  itemPriceBeforePromo?: number;
  itemPromoValue?: number;
};

export type DiscountTypes = "percentage" | "fixed" | "team" | "percentagePlus" | "fixedPlus" | "homage" | null;

export type CashDeskDiscountV2 = {
  type: DiscountTypes;
  amount: number;
  notes: string;
};

export type CurrentPaymentMethod = {
  cash: number;
  bancomat: number;
  creditCard: number;
  ticket: number;
  sepa: number;
  notCollected: number;
  token: number;
};

export type FiscalData = {
  causal: string | undefined;
  courtesyReceipts: number;
};

export type InvoiceData = {
  company: string | null;
  causal: string | undefined;
  qrCodePay: boolean;
  piva: Piva | null;
};

export type NonFiscalData = {
  causal: string | undefined;
  qrCodePay: boolean;
};

export type SelectedPaymentMethod = "cash" | "bancomat" | "creditCard" | "ticket";

export type CurrentPaymentContextState = {
  amount: number;
  items: CashDeskCartV2[];
  fiscalType: CashDeskFiscalTypeV2;
  selectedPaymentMethod?: SelectedPaymentMethod;
  paymentMethod: CurrentPaymentMethod;
  paymentId?: string;
  fiscalData: FiscalData;
  invoiceData: InvoiceData;
  nonFiscalData: NonFiscalData;
};

export type CurrentPaymentContextStateOptional = {
  amount?: number;
  items?: CashDeskCartV2[];
  fiscalType?: CashDeskFiscalTypeV2;
  selectedPaymentMethod?: SelectedPaymentMethod;
  paymentMethod?: CurrentPaymentMethod;
  paymentId?: string;
  fiscalData?: FiscalData;
  invoiceData?: InvoiceData;
  nonFiscalData?: NonFiscalData;
};

export interface CashDeskContextV2StateInterface extends CashDeskContextStateV2 {
  addToCart: (item: Item, configuration?: CartItemConfigurationV2[]) => void;
  updateCashDeskContext: (state: CashDeskContextV2StateOptional) => void;
}

export interface CurrentPaymentStateInterface extends CurrentPaymentContextState {
  updateCurrentPayment: (state: CurrentPaymentContextStateOptional) => void;
}

export type Events = {};

export type Experience = {
  from: string;
  to: string;
  brandId?: string;
  experienceId?: string;
  _id?: string;
  shops: string[];
  name: string;
  description: string;
  website: string;
  siaeCode: string;
  fromHour: string;
  toHour: string;
  maxPeople: number;
  initial?: boolean;
};

export type Structure = {
  _id?: string;
  name: string;
  description: string;
  brandId: string;
  experienceId: string;
  experience?: string;
  color?: string;
  areaList?: AreaListType[];
  initial?: boolean;
};

export type AreaListType = {
  _id?: string;
  areaId: string;
  name: string;
  description: string;
  color: string;
  type: string;
  shops: string[];
  maxPeople: number;
  itemsToAccess: [];
  structureId: string;
};

export type TicketType = {
  brand: string;
  name: string;
  description: string;
  price: number | undefined;
  itemType: "ticket";
  shops: string[];
  digitalItemInfo?: {
    endless: boolean;
    fromDate?: string;
    toDate?: string;
    maxUsage: number | undefined;
    burnOnExit: boolean;
  };
  digitalItem?: {
    endless: boolean;
    fromDate?: string;
    toDate?: string;
    maxUsage: number;
    burnOnExit: boolean;
  };
  experience?: string;
  _id?: string;
  fiscality?: {
    cashDeskService: string;
    tableService: string;
    delivery: string;
    takeAway: string;
  };
  initial?: boolean;
};

export type AccessoryBulk = {
  _id?: string;
  brand: string;
  brandId?: string;
  tag: string;
  amountToCreate: number | undefined;
  digitalItemsToAssign: string[];
};

export type TokenType = {
  _id?: string;
  brand: string;
  name: string;
  validFrom: string;
  validTo: string;
  valueOfOneToken: number | undefined;
  shops: string[];
  initial?: boolean;
};

export type Piva = {
  _id?: string;
  address: {
    street: string;
    streetNumber?: string;
    cap: string;
    city: string;
    province: string;
    formatted: string;
  };
  createdAt?: Date;
  fiscalCode?: string;
  country: string;
  vat?: string;
  lastUpdate?: Date;
  formatted?: string;
  pec?: string;
  uniqueCode?: string;
  name: string;
};

export type MapFoundVat = {
  id?: string;
  companyName: string;
  vat?: string;
  country: string;
  province: string;
  address: string;
  streetNumber?: string;
  city: string;
  zipCode: string;
  pec?: string;
  recipientCode?: string;
  formatted?: string;
};

export type SearchVatNumber = {
  piva: string;
  setSubmitting?: (isSubmitting: boolean) => void;
  setFoundVat?: React.Dispatch<React.SetStateAction<MapFoundVat | undefined>>;
  setPivaList?: React.Dispatch<React.SetStateAction<Piva[] | []>>;
  setStatus?: (status?: { isValid: boolean }) => void;
  setComboBoxHasChanged?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type HandleFavorite = {
  itemId: string;
  action: "add" | "remove";
  shopId: string;
};

export type OrderOuting = {
  status: "pending" | "ready" | "completed";
  phones?: string[];
  deviceTokens?: string[];
};

export type SaloonInfo = {
  people: number;
  actualOuting: number;
  saloonElement: SaloonElements[];
  saloonElementName: string;
  saloonOrderSent: string[];
  servicePrice: number;
  peopleNotEating?: number;
  saloonOrderTime?: Date;
  askBill?: boolean;
  oldMergedOrders?: any[];
};

export type Waiter = {
  _id: string;
};

export type PaymentV2 = {
  _id: string;
  price: number;
  order: string;
  status: "pending" | "processing" | "payed" | "refunded";
  paymentAmount: PaymentAmount;
  paymentMode: CashDeskPaymentModeV2;
  paymentId?: string;
  taxPrice: number;
  taxValue: ValidTax;
  shares: number[];
  items: CashDeskCartV2[];
  cartItems: string[];
  causal?: string;
  fiscale?: OrderTaxation;
  annulloFiscale?: OrderTaxation;
  printed: {
    fiscale: boolean;
    annulloFiscale: boolean;
  };
  customerInvoice?: string;
  courtesyReceipts: number;
};

export type OrderV2 = Omit<Order, "cart" | "status" | "payment"> & {
  _id: string;
  status:
    | "pending"
    | "confirmed"
    | "ready"
    | "onDelivery"
    | "delivered"
    | "canceled"
    | "open"
    | "closed"
    | "payed"
    | "draft";
  cart: CashDeskCartV2[];
  shareCount: number;
  promoNotes: string;
  promoValue: number;
  promoType: CashDeskDiscountV2["type"];
  orderOuting: OrderOuting[];
  saloonInfo: SaloonInfo;
  payment?: PaymentV2[];
  waiter: Waiter;
  shareTax?: string;
  created: string;
};

export type CashDeskCartV2UpdateOrder = Omit<CashDeskCartV2, "item"> & {
  item?: string;
};

export type PaymentAmount = {
  stripe: number;
  paypal: number;
  applePay: number;
  googlePay: number;
  postePay: number;
  cash: number;
  bancomat: number;
  creditCard: number;
  ticket: number;
  sepa: number;
  notCollected: number;
  token: number;
};

export type CreatePayment = {
  orderId: string;
  shopId: string;
  price: number;
  paymentAmount: PaymentAmount;
  paymentMode: CashDeskPaymentModeV2;
  customPayments?: CustomPayment[];
  shareCount?: number;
  shares?: number[];
  items?: CashDeskCartV2[];
  pivaInfo?: Piva;
  causal?: string;
  taxPrice: number;
  shareTax?: ValidTax;
  taxValue?: ValidTax;
  mutate?: () => void;
  deviceUUID?: string;
  printType?: "fiscal" | "invoice" | "nonFiscal";
  t: Function;
  courtesyReceipts?: number;
  printNonFiscal: boolean;
  billy?: {
    hasAddon: boolean
    addon: Addon | undefined
  }
};

export type UpdatePayment = Omit<CreatePayment, "taxPrice"> & {
  paymentId: string;
};

export type CashDeskV2OrderParams = {
  shopId: string;
  cart: CashDeskCartV2[];
  promoAmount: number;
  promoType: CashDeskDiscountV2["type"];
  promoNotes: string;
  notes: string;
  pickupMode?: string;
  mutate?: () => void;
  updateCashDeskContext?: (newState: CashDeskContextV2StateOptional) => void;
  t: Function;
};

export type UpdateCashDeskV2OrderParams = CashDeskV2OrderParams & {
  orderId: string;
  updateCashDeskContext?: (newState: CashDeskContextV2StateOptional) => void;
};

export type CausalCreateParams = {
  shopId: string;
  description: string;
  mutate?: () => void;
};

export type Causal = {
  _id: string;
  description: string;
  brand: string;
  owner: string;
  shop: string;
};

export type UpdateSaloonOrderParams = {
  orderId: string;
  cart: CashDeskCartV2UpdateOrder[];
  notes: string;
  people: number;
  promoAmount: number;
  promoType: DiscountTypes;
  servicePrice: number;
  orderOuting: OrderOuting[];
  waiterId: string;
  mutate?: () => void;
};

export type PaymentTypesKey =
  | "stripe"
  | "paypal"
  | "applePay"
  | "googlePay"
  | "cash"
  | "bancomat"
  | "creditCard"
  | "ticket"
  | "postePay";

export type PayedShares = {
  sharesNumber: number;
  sharePrice: number;
};

export type MultipleHeaderComponents = {
  middleComponents?: React.ReactNode | null;
  rightComponents?: React.ReactNode | null;
};

export type HeaderComponents = MultipleHeaderComponents | React.ReactNode;

export type SaveFiscaleRes = {
  newFiscale: OrderTaxation;
  order: Order | OrderV2;
};

export type OrdersContextState = {
  isPayMode: boolean;
};

export type OrdersContextStateOptional = {
  isPayMode?: boolean;
};

export interface OrdersContextStateInterface extends OrdersContextState {
  updateOrdersContext: (state: OrdersContextStateOptional) => void;
}

export type InvoiceStatus = "sent" | "delivered" | "accepted" | "refused";

export type InvoiceFormattedStatus = "Inviata" | "Consegnata" | "Accettata" | "Rifiutata";

export type Kpi = {
  _id: string;
  count: number;
};

export type PrinterId = string;

export enum PrintJobStatus {
  QUEUE = 0,
  PENDING,
  FAILED,
  PRINTED,
}

export type PrintJob = {
  id: string;
  orderId: string;
  status: PrintJobStatus;
  orderStatus: OrderStatus;
  priority: number;
  printerIds: string[];
  courtesyReceipts?: number;
  rawOrderJson?: string
  fiscale?: OrderTaxation
  type?: 'order' | 'receipt'
  printFakeFiscalReceipt?: boolean
};

export type PrintJobQueue = PrintJob[];

export type InvoiceDocument = {
  brand: string;
  canceled: boolean;
  created: string;
  documentType: "create" | "delete";
  order: string;
  payment: { _id: string; price: number };
  pivaInfo: Piva;
  progressive: number;
  shop: string;
  status: InvoiceStatus;
  year: string;
  _id: string;
};

export type CustomerInvoice = {
  kpi: Kpi[];
  result: {
    data: InvoiceDocument[];
    hasNextPage: boolean;
    page: number;
    pages: number;
    total: number;
  };
};

export type Closure = {
  amount: number;
  created: string;
  printerId: string;
  printerName: string;
  nReceipts?: number;
  netAmount?: number;
  vat0: number;
  vat4?: number;
  vat5?: number;
  vat10?: number;
  vat22?: number;
};

export type TransactionType = "recharge" | "rechargeNFC";

declare global {
  export interface Window {
    printerJobs: Map<string, PrintJob>;
    fiscalPrioQ: Heap<string>;
    nonFiscalPrioQ: Heap<string>;
  }
}

export type BillyAccount = {
  denominazione: string;
  indirizzo: string;
  numeroCivico: string;
  cap: string;
  comune: string;
  provincia: string;
  fullAddress: string;
  piva: string;
  login: {
      ae_user: string;
      ae_password: string;
      ae_pin: string;
  };
}