import React, { useContext, useState } from 'react'
import { useAddon, useBrand, useShops } from 'utils/hooks'
import { getMenuOptions } from 'utils/data'
import { fallbackImage, isElectron } from 'utils'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useTranslate } from 'utils/hooks'

import Link from 'components/link'
import { SidebarMenuLoader, SidebarBottomLoader } from 'components/loaders'
import { NewFeature } from 'components/badge'
import Select from 'components/field/select'

import GlobalContext from 'context'
import { MenuOption } from 'types'

import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { getAdminToken } from 'api/authentication'

type Props = {
    showLoader: boolean
}

export default function Sidebar({ showLoader }: Props) {
    const { userType, brands, appUpdate, updateGlobalContext } = useContext(GlobalContext)
    const { asPath } = useRouter()
    const { shops } = useShops()
    const { brand, id } = useBrand()
    const { t } = useTranslate()
    const [hideSidebar, setHideSidebar] = useState<boolean>(true)
    const hasAdminToken = getAdminToken()

    const updateSidebar = () => {
        setHideSidebar(oldHideSidebar => !oldHideSidebar)
        updateGlobalContext({ hideSidebar: !hideSidebar })
    }

    function brandOptions() {
        const options = brands.map((single, index) => ({
            value: single._id,
            label: (
                <Link href={`/brand/${index}`} key={index} className="flex items-center space-x-2 text-standard hover:text-standard focus:no-underline">
                    <img className="bg-gray-200 w-5 h-5 rounded-full flex-shrink-0" src={fallbackImage(single.logo)} alt={single.name} />
                    <span>{single.name}</span>
                </Link>
            )
        }))

        if (userType !== 'shopManager') {
            options.push({ value: 'new', label: <Link className="text-standard hover:text-standard font-medium flex items-center focus:no-underline" href={`/brand/${brands.length}/setup`}><div className="w-5 h-5 text-center rounded-full bg-gray-200 mr-2">+</div> {t('Nuovo brand')}</Link> })
        }

        return options
    }

    function allOptions() {
        return getMenuOptions(id, userType === 'shopManager', brand, shops).map((option, index) => option !== null && (!brand || canDisplayOption(option)) ? (
            <React.Fragment key={index}>
                <Link href={option.href} exact={option.exact} activeClassName="bg-gray-200 font-medium" className={cn('focus:no-underline group flex justify-between my-2 sm:my-0 text-standard hover:text-standard items-center p-1.5 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition', { '!mt-0': index === 0 })}>
                    <div className="flex items-center">
                        <div className="ml-0.5 mr-2 -mt-0.5">{option.icon}</div>
                        {!hideSidebar && t(option.label)}
                    </div>
                    {option.newFeature && !hideSidebar && <NewFeature />}
                </Link>
                {asPath.toString().indexOf(option.href) > -1 && option.subPages.length > 0 && !hideSidebar && (
                    <ul className="pl-5 space-y-1">
                        {option.subPages.map((subOption, subIndex) => subOption && (
                            <li key={subIndex}>
                                <Link activeClassName="bg-gray-200 font-medium" className="focus:no-underline group flex justify-between my-1 sm:my-0 text-standard hover:text-standard items-center p-1.5 py-1 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition" href={subOption.href}>{t(subOption.label)}</Link>
                            </li>
                        ))}
                    </ul>
                )}
            </React.Fragment>
        ) : null)
    }

    const brandSelect = (
        <Select position="up" className="bg-white" valueLabel={brand ? (
            <div className="flex items-center space-x-3">
                <img src={fallbackImage(brand.logo)} className="bg-gray-200 w-5 h-5 rounded-full flex-shrink-0" />
                <p className="truncate">{brand.name}</p>
            </div>
        ) : t('Nuovo brand')} overrideValue={brand?._id} disableFormik options={brandOptions()} />
    )

    function canDisplayOption(option: MenuOption) {
        const { showOnlyWhen, permission } = option!
        const optionsAllowed = shops.length > 0 ? ['shops'] : []

        if (useAddon('cashDesk').hasAddon) {
            optionsAllowed.push('cashDesk')
        }
        
        if(useAddon('table-order').hasAddon) {
            optionsAllowed.push('table-order')
        }

        return (option!.showOnlyWhen.length === 0 || optionsAllowed.filter(a => showOnlyWhen.indexOf(a) > -1).length === showOnlyWhen.length) && permission.indexOf(userType) > -1
    }

    return (
        <div className={cn('hidden 2sm:flex sm:flex-shrink-0 relative border-r', {
            '!pt-[50px]': !appUpdate.available && !hasAdminToken,
            '!pt-[78px]': (!appUpdate.available && hasAdminToken) || (appUpdate.available && !hasAdminToken),
            '!pt-[106px]': appUpdate.available && hasAdminToken
        })}>
            {!showLoader && (
                <div className="flex z-10 rounded-full border w-7 h-7 cursor-pointer transition-all ease-in-out items-center justify-center absolute -right-3 bg-primary bottom-40" onClick={() => updateSidebar()}>
                    {!hideSidebar ? <KeyboardArrowLeftOutlinedIcon className="text-white -ml-0.5"/> : <KeyboardArrowRightOutlinedIcon className="text-white ml-0.5"/>}
                </div>
            )}
            <div className={cn('flex flex-col', { 'w-[13rem]': !hideSidebar, 'w-[4.5rem]': hideSidebar })}>
                <div className="flex flex-col flex-grow py-5 px-4">
                    <div className="flex-1 flex flex-col">
                        <nav className="space-y-1">
                            {(!showLoader && id) ? allOptions() : <SidebarMenuLoader />}
                        </nav>
                    </div>
                </div>
                {!hideSidebar && (
                    <div className="px-4">
                        {!showLoader && userType === 'shopManager' && brands.length === 1 ? (
                            <div className="flex items-center space-x-3">
                                <img src={fallbackImage(brand.logo)} className="bg-gray-200 w-5 h-5 rounded-full flex-shrink-0" />
                                <p className="truncate">{brand.name}</p>
                            </div>
                        ) : brandSelect}
                    </div>
                )}
                <div className="flex-shrink-0 flex p-4">
                    <div className="w-full pt-4 border-t">
                        {!showLoader ? (
                            <div className="space-y-3">
                                <a href="https://help.easylivery.it" target={!isElectron ? '_blank' : undefined} className={cn('flex items-center space-x-3 w-full', { 'justify-center': hideSidebar })}>
                                    <BookOutlinedIcon className={cn({ 'mr-3': !hideSidebar, 'mr-1': hideSidebar })}/>
                                    {!hideSidebar && t('Guide')}
                                </a>
                                <Link href={`/brand/${id}/settings`} className={cn('flex items-center space-x-3 w-full', { 'justify-center': hideSidebar })}>
                                    <SettingsOutlinedIcon className={cn({ 'mr-3': !hideSidebar, 'mr-1': hideSidebar })}/>
                                    {!hideSidebar && t('Impostazioni')}
                                </Link>
                            </div>
                        ) : (
                            <SidebarBottomLoader />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}