import cn from 'classnames'
import { Crisp } from 'crisp-sdk-web'
import { IconButton } from '@easylivery/components'

import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'

export default function SupportChatMenu() {   
    const unreadMessages = window['$crisp'] !== undefined && window['$crisp'].get && typeof window['$crisp'].get === 'function' && Crisp.isCrispInjected() ? Crisp.chat.unreadCount() : 0

    function handleOpenCloseSupportChat() {
        if(Crisp.chat.isVisible()) {
            Crisp.chat.close()
        } else {
            Crisp.chat.open()
        }
    }
    
    return(  
        <div className="flex flex-row justify-center rounded-full items-center w-8 h-8 bg-white border-[1px] cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <IconButton className="!bg-gray-100" onClick={() => handleOpenCloseSupportChat()}>
                <HeadsetMicOutlinedIcon/>
            </IconButton>
            {(unreadMessages !== undefined && unreadMessages > 0) && <p className={cn("absolute ml-6 mb-6 bg-red-500  flex font-medium text-white rounded-full justify-center items-center text-xs", {'px-[0.45rem] py-[0.15rem]': unreadMessages < 10}, {'py-[0.2rem] px-[0.25rem]': unreadMessages > 9})}>{unreadMessages}</p>}
        </div>
    )
}