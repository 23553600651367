import { IconButton } from '@easylivery/components'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faCashRegister, faReceipt, faVolumeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Notifications } from '@mui/icons-material'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { getAdminToken, getAdminTokenExpire } from 'api/authentication'
import { getAllOrders } from 'api/orders'
import cn from 'classnames'
import Button from 'components/button'
import Link from 'components/link'
import MobileNotifications from 'components/notifications/mobileNotifications'
import GlobalContext from 'context'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { OperatorServices, Order } from 'types'
import { getFromStorage, isCapacitor, isClient, redirect, saveToStorage } from 'utils'
import { checkShopManager, useBrand, useCountdown, useOperator, useShops, useTranslate } from 'utils/hooks'
import MobileMenu from './_partials/mobileMenu'
import NotificationMenu from './_partials/notificationMenu'
import OperatorFilterShops from './_partials/operatorFilterShops'
import SupportChatMenu from './_partials/supportChatMenu'

type Props = {
    middleComponents?: React.ReactNode
    rightComponents?: React.ReactNode
    hasBackButton?: boolean
    backButtonUrl?: string
}

export default function Header({ middleComponents, rightComponents, backButtonUrl }: Props) {
    const { authenticated, userType, appUpdate } = useContext(GlobalContext)
    const isOperator = userType === "operator"
    const { id, brand } = useBrand()
    const { isShopManager } = checkShopManager()
    const { shops } = useShops()
    const [openorders, setOpenorders] = useState(false)
    const { orders, notWatchedOrders, isValidating } = getAllOrders(brand?._id, isShopManager || isOperator, shops, undefined, true)
    const router = useRouter()
    const isMobile = useMediaQuery({ query: '(max-width: 958px)' })
    const { t } = useTranslate()
    const operator = useOperator()
    const [volume, setVolume] = useState<boolean>(false)
    const hasLoginSignup = useMemo(() => ['/forgot-password', '/reset-password'].indexOf(router.pathname) > -1, [router.pathname])
    const showLoginSignup = useMemo(() => (authenticated === false && hasLoginSignup), [authenticated, hasLoginSignup])
    const hasAdminToken = getAdminToken()
    const hasAdminTokenExpire = hasAdminToken ? getAdminTokenExpire() : null
    const adminTokenCountdown = hasAdminTokenExpire ? useCountdown(new Date(Number(hasAdminTokenExpire))) : null


    function getOrdersForNotification(): Order[] {
        return _.union(orders.filter(order => order.orderType !== 'table' && !order.deliveryInfo.watched), orders.filter(order => order.orderType === 'table'))
    }
        
        useEffect(() => {
            if (isClient) {
                (async () => {
                    setVolume(await getFromStorage('notificationVolume') === 'true')
            })()
        }
    }, [isClient])

    useEffect(() => {
        (async () => {
            saveToStorage('notificationVolume', volume.toString())
        })()
    }, [volume])

    useEffect(() => {
        (async () => {
            const volumeIsEnabled = await getFromStorage('notificationVolume') === 'true'

            if (volumeIsEnabled && notWatchedOrders && notWatchedOrders.length > 0) {
                const notificationAudio = document.getElementById('notification-audio') as HTMLAudioElement | undefined
                if (notificationAudio) {
                    notificationAudio.play()
                }
            }
        })()
    }, [isValidating])

    return(
        <header className="fixed w-full left-0 z-50" id="header-container">
            {hasAdminToken && hasAdminTokenExpire && adminTokenCountdown && brand && (
                <div className="bg-orange-500 py-1 text-white px-4 2sm:px-6 text-center" id="banner-admin-token-countdown">
                    <p className="text-sm">{t(`Login come`)} <span className="font-medium">{brand.name}</span> / {t('scade tra')} {adminTokenCountdown.hours}:{adminTokenCountdown.minutes}:{adminTokenCountdown.seconds}</p>
                </div>
            )}
            {appUpdate.available && (
                <div className="bg-green-600 py-1 text-white px-4 2sm:px-6 text-center cursor-pointer" id="banner-app-update-available">
                    <p className="text-sm">{t(`Aggiornamento disponibile, clicca qui`)}</p>
                </div>
            )}
            <div className={cn('bg-white border-b px-4 2sm:px-6 min-h-[54px] flex items-center', { 'py-[7px]': authenticated && middleComponents === undefined, 'py-[15px] z-50': authenticated === null })}>
                <audio id="notification-audio" className="hidden" src={(isCapacitor ? '/audio' : 'https://static.easylivery.it') + '/notifications.mp3'}></audio>
                <div className={cn('flex items-center w-full', {'justify-between': showLoginSignup || (userType === 'operator' && authenticated)})}>
                    {showLoginSignup ? (
                        <Link href="/">
                            <img src="/images/logo.svg" alt="Easylivery" className="h-6" id="header-logo" key="header-logo" />
                        </Link>
                    ) : (
                        <img src="/images/logo.svg" alt="Easylivery" className="h-6" id="header-logo" key="header-logo" />
                    )}
                    {showLoginSignup && (
                        <div className="space-x-4">
                            <Link href="/">
                                <Button color="primary">Accedi</Button>
                            </Link>
                            <Link href="/signup">
                                <Button>Registrati</Button>
                            </Link>
                        </div>
                    )}
                    {backButtonUrl && (
                        <Link href={backButtonUrl}>
                            <IconButton size="small" sx={{ backgroundColor: "gray.light", marginLeft: "10px", borderRadius: "10px" }}>
                                <ArrowBackOutlinedIcon />
                            </IconButton>
                        </Link>
                    )}
                    {!isMobile && (
                        <>
                            {middleComponents !== undefined && (
                                <div className="w-full overflow-x-auto">
                                    {middleComponents}
                                </div>
                            )}

                            {userType !== 'operator' && authenticated && (
                                <div className={cn('hidden 2sm:flex items-center', { 'w-full justify-end': !middleComponents })}>
                                    {(!isMobile && brand) && (
                                        <>
                                            {rightComponents && rightComponents}
                                            <div className="pl-4">
                                                <NotificationMenu orders={getOrdersForNotification()} />
                                            </div>
                                            <div className="pl-5">
                                                <SupportChatMenu/>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {userType === "operator" && authenticated && (
                                <div className='flex flex-row items-center gap-x-[20px]'>
                                    <OperatorFilterShops />
                                    {operator && (
                                        <FontAwesomeIcon icon={volume ? faVolumeUp : faVolumeSlash} className={cn("cursor-pointer", { "text-green-500": volume }, { "text-orange-500": !volume })} onClick={() => setVolume(!volume)} />
                                    )}
                                    {operator?.services.includes(OperatorServices.CashDesk) && router.pathname !== "/brand/[id]/cashdesk/[[...index]]" && (
                                        <Button size='small' className='flex flex-row items-center' onClick={() => redirect(`/brand/${id}/cashdesk/receipt`)}>
                                            <FontAwesomeIcon icon={faCashRegister} className="mr-2" />
                                            {t("Cassa")}
                                        </Button>
                                    )}
                                    {operator?.services.includes(OperatorServices.Orders) && router.pathname !== "/brand/[id]/orders/simplified" && (
                                        <Button size='small' className='flex flex-row items-center' onClick={() => redirect(`/brand/${id}/orders/simplified`)}>
                                            <FontAwesomeIcon icon={faReceipt} className="mr-2" />
                                            {t("Ordini")}
                                        </Button>
                                    )}
                                    <Link href={`/brand/${id}/settings`}>
                                        <IconButton>
                                            <SettingsOutlinedIcon className="text-black"/>
                                        </IconButton>
                                    </Link>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className='flex flex-row'>
                    {authenticated && (
                        <div className='flex flex-row items-center justify-center space-x-2'>
                            {isMobile && (
                                <div className={cn("flex flex-row justify-center rounded-full items-center w-8 h-8 bg-white border-[1px] cursor-pointer", { 'border-primary': openorders }, { 'border-gray-400': !openorders }, { "hidden": userType === "operator" })}
                                    onClick={() => setOpenorders(!openorders)}>
                                    <IconButton className="!bg-gray-100">
                                        <Notifications/>
                                    </IconButton>
                                </div>
                            )}
                            {isMobile && userType !== "operator" && (
                                <MobileMenu />
                            )}
                            {openorders && <MobileNotifications orders={notWatchedOrders} onCloseRequest={() => setOpenorders(false)} />}
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}
