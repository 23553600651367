import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from './translations'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(initReactI18next).use(LanguageDetector).init({
    resources: translations,
    fallbackLng: 'it',
    interpolation: {
        escapeValue: false
    },
    detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'el-language'
    }
})

export default i18n