export default {
    'translation': {
        'Accedi': 'Accedi',
        'Accedi al tuo account': 'Accedi al tuo account',
        'Non hai un account?': 'Non hai un account?',
        'Registrati': 'Registrati',
        'Email': 'Email',
        'Password': 'Password',
        'Ricordami': 'Ricordami',
        'Password dimenticata?': 'Password dimenticata?',
        'Password dimenticata': 'Password dimenticata',
        'Recupera password': 'Recupera password',
        'Mail inviata': 'Mail inviata',
        "È stata inviata una mail con il link per reimpostare la password all'indirizzo": "È stata inviata una mail con il link per reimpostare la password all'indirizzo",
        "Inserisci di seguito l'indirizzo email con il quale sei registrato. Riceverai una mail con il link da aprire per impostare la nuova password.": "Inserisci di seguito l'indirizzo email con il quale sei registrato. Riceverai una mail con il link da aprire per impostare la nuova password.",
        'Invia richiesta': 'Invia richiesta',
        "Reimposta password": "Reimposta password",
        "Password reimpostata": "Password reimpostata",
        'La password è stata reimpostata correttamente': 'La password è stata reimpostata correttamente',
        'Inserisci di seguito la nuova password': 'Inserisci di seguito la nuova password',
        "Conferma password": "Conferma password",
        'Salva': 'Salva',
        'Entra a far ': 'Entra a far ',
        'parte di Easylivery': 'parte di Easylivery',
        'Nome': 'Nome',
        'Cognome': 'Cognome',
        'Codice venditore': 'Codice venditore',
        'opzionale': 'opzionale',
        'Registrandoti accetti i ': 'Registrandoti accetti i ',
        'termini del servizio': 'termini del servizio',
        'Hai già un account?': 'Hai già un account?',
        "Termini di servizio": "Termini di servizio",
        'Prodotti': 'Prodotti',
        'Fatturato': 'Fatturato',
        'Fiscale': 'Fiscale',
        'Ordini': 'Ordini',
        'Utenti': 'Utenti',
        'Statistiche': 'Statistiche',
        'Intervallo tempo': 'Intervallo tempo',
        "Intervallo date": "Intervallo date",
        "Negozio": "Negozio",
        "Metodo Pagamento": "Metodo Pagamento",
        'Tutti': 'Tutti',
        "Prenotazioni": "Prenotazioni",
        'Aggiungi prenotazione': 'Aggiungi prenotazione',
        'Confermate': 'Confermate',
        'Annullate': 'Annullate',
        'Nessuna prenotazione': 'Nessuna prenotazione',
        'persona': 'persona',
        'persone': 'persone',
        'Dettagli': 'Dettagli',
        'Annulla': 'Annulla',
        'Dettaglio': 'Dettaglio',
        'scontrino N.': 'scontrino N.',
        'preconto': 'preconto',
        "Torna indietro": "Torna indietro",
        'Cancellazione scontrino': 'Cancellazione scontrino',
        'Generazione scontrino': 'Generazione scontrino',
        'Si è verificato un errore': 'Si è verificato un errore',
        'durante la generazione': 'durante la generazione',
        'Chiudi': 'Chiudi',
        'Riprova': 'Riprova',
        'Stampa': 'Stampa',
        "Cassa": "Cassa",
        'Scontrino': 'Scontrino',
        'Archivio': 'Archivio',
        "Cerca": "Cerca",
        'Tastierino': 'Tastierino',
        'Delivery': 'Delivery',
        'Take away': 'Take away',
        'Tavolo': 'Tavolo',
        'Totale': 'Totale',
        'Data': 'Data',
        "Nessun ordine": "Nessun ordine",
        'Sconto': 'Sconto',
        'Attendi..': 'Attendi..',
        'A persona': 'A persona',
        'Pagato': 'Pagato',
        'Da pagare': 'Da pagare',
        'Paga': 'Paga',
        'Recap': 'Recap',
        'Sale esterne': 'Sale esterne',
        'Sale interne': 'Sale interne',
        "Nuovo Tavolo": "Nuovo Tavolo",
        'In Corso': 'In Corso',
        'Gestione Sala': 'Gestione Sala',
        'Nuova Sala': 'Nuova Sala',
        'Modifica Sala': 'Modifica Sala',
        'Tutte': 'Tutte',
        'Tutte le sale': 'Tutte le sale',
        "Cerca Tavolo": "Cerca Tavolo",
        'Aggiungi nuovo negozio': 'Aggiungi nuovo negozio',
        "Negozi": "Negozi",
        'Visualizza': 'Visualizza',
        'Modifica': 'Modifica',
        "Aggiungi membro team": "Aggiungi membro team",
        'Team': 'Team',
        "Vuoi dare l'accesso ad altri membri del team? Crea l'account e decidi a quale negozio può accedere e con che permessi": "Vuoi dare l'accesso ad altri membri del team? Crea l'account e decidi a quale negozio può accedere e con che permessi",
        'Aggiungi membro': 'Aggiungi membro',
        'Nome e cognome': 'Nome e cognome',
        'Tipologia': 'Tipologia',
        "Nessun membro del team": "Nessun membro del team",
        'Carico': 'Carico',
        'Scarico': 'Scarico',
        'Dettaglio fornitore': 'Dettaglio fornitore',
        'Modifica Fornitore': 'Modifica Fornitore',
        'Tipo': 'Tipo',
        'Prodotto': 'Prodotto',
        'Quantità': 'Quantità',
        'Store': 'Store',
        'Nessun Carico/Scarico': 'Nessun Carico/Scarico',
        'Magazzino': 'Magazzino',
        'Movimenti': 'Movimenti',
        'Lista fornitori': 'Lista fornitori',
        'Giacenze': 'Giacenze',
        'Aggiungi': 'Aggiungi',
        'Operatore': 'Operatore',
        'Nessun Movimento': 'Nessun Movimento',
        'Giacenza': 'Giacenza',
        "Filtra per prodotti": "Filtra per prodotti",
        'Prodotto Semplice': 'Prodotto Semplice',
        'Materia Prima': 'Materia Prima',
        "Ultimo aggiornamento": "Ultimo aggiornamento",
        'Valore': 'Valore',
        'Valore Totale': 'Valore Totale',
        'Nessuna Giacenza': 'Nessuna Giacenza',
        'Visualizza Fornitore': 'Visualizza Fornitore',
        "Cerca fornitore": "Cerca fornitore",
        'Nuovo Fornitore': 'Nuovo Fornitore',
        'Ragione Sociale': 'Ragione Sociale',
        'P.iva': 'P.iva',
        'Indirizzo Email': 'Indirizzo Email',
        'Nessun Fornitore': 'Nessun Fornitore',
        "Abilita tavolo": "Abilita tavolo",
        "Inserisci le informazioni richieste per associare un tavolo del ristorante ad un cliente.": "Inserisci le informazioni richieste per associare un tavolo del ristorante ad un cliente.",
        "Puoi inserire il codice cliente manualmente o scansionare il QR presente nell'applicazione.": "Puoi inserire il codice cliente manualmente o scansionare il QR presente nell'applicazione.",
        'Abilita': 'Abilita',
        'Abilita nuovo tavolo': 'Abilita nuovo tavolo',
        "Codice cliente": "Codice cliente",
        "Numero coperti": "Numero coperti",
        'Tavolo abilitato': 'Tavolo abilitato',
        "Puoi far accomodare il cliente al tavolo. L'applicazione nel telefono del cliente è abilitata all'ordinazione in autonomia.": "Puoi far accomodare il cliente al tavolo. L'applicazione nel telefono del cliente è abilitata all'ordinazione in autonomia.",
        'Gestione': 'Gestione',
        'Aggiungi, modifica ed elimina categorie, sottocategorie e prodotti': 'Aggiungi, modifica ed elimina categorie, sottocategorie e prodotti',
        'Gestisci prodotti': 'Gestisci prodotti',
        'Aggiungi carichi e scarichi, consulta la lista dei fornitori e le giacenze.': 'Aggiungi carichi e scarichi, consulta la lista dei fornitori e le giacenze.',
        'Gestisci magazzino': 'Gestisci magazzino',
        'Modifica informazioni come metodi di pagamento, delivery, tavoli e prenotazioni': 'Modifica informazioni come metodi di pagamento, delivery, tavoli e prenotazioni',
        'Gestisci negozi': 'Gestisci negozi',
        "Gestisci i membri del team e l'accesso a negozi e funzionalità": "Gestisci i membri del team e l'accesso a negozi e funzionalità",
        "Gestisci team": "Gestisci team",
        "Cerca prodotto": "Cerca prodotto",
        'Download prodotti': 'Download prodotti',
        'Import prodotti': 'Import prodotti',
        'Nessun prodotto trovato': 'Nessun prodotto trovato',
        'Aggiungi categoria': 'Aggiungi categoria',
        "Materie Prime": "Materie Prime",
        'Modifica banner': 'Modifica banner',
        'Crea banner': 'Crea banner',
        'negozio': 'negozio',
        'negozi': 'negozi',
        'Attivo': 'Attivo',
        'Disattivato': 'Disattivato',
        'Gestisci e crea banner da mostrare nella home page della tua app e del sito web': 'Gestisci e crea banner da mostrare nella home page della tua app e del sito web',
        'Stato': 'Stato',
        "Nessun banner": "Nessun banner",
        "Modifica promozione": "Modifica promozione",
        "Crea promozione": "Crea promozione",
        'Attiva': 'Attiva',
        'Disattiva': 'Disattiva',
        'Nessuno': 'Nessuno',
        "Promozioni": "Promozioni",
        "Gestisci e crea promozioni con codici sconto, sconti a categorie, prodotti oppure ordini con spese di spedizione gratuite per incrementare le vendite e fidelizzare i tuoi clienti.": "Gestisci e crea promozioni con codici sconto, sconti a categorie, prodotti oppure ordini con spese di spedizione gratuite per incrementare le vendite e fidelizzare i tuoi clienti.",
        'Utilizzato': 'Utilizzato',
        "Nessuna promozione": "Nessuna promozione",
        "Clienti": "Clienti",
        'Esporta': 'Esporta',
        'Data registrazione': 'Data registrazione',
        'Telefono': 'Telefono',
        "Nessun utente": "Nessun utente",
        "Marketing": "Marketing",
        'Gestisci promozioni': 'Gestisci promozioni',
        "Crea codici sconto, applica sconti a più prodotti oppure spedizioni gratuite per generare più vendite": "Crea codici sconto, applica sconti a più prodotti oppure spedizioni gratuite per generare più vendite",
        "Notifiche": "Notifiche",
        "Invia una notifica ai tuoi clienti per avvisarli di un'offerta o un nuovo prodotto!": "Invia una notifica ai tuoi clienti per avvisarli di un'offerta o un nuovo prodotto!",
        "Invia notifiche": "Invia notifiche",
        "Banner": "Banner",
        'Gestitci i banner da mostrare nella home page in app': 'Gestitci i banner da mostrare nella home page in app',
        "Gestisci banner": "Gestisci banner",
        "Stampa il QR Code o utilizza il link per far scaricare ai tuoi clienti l'app iOS o Android": "Stampa il QR Code o utilizza il link per far scaricare ai tuoi clienti l'app iOS o Android",
        'e per visitare il tuo sito web': 'e per visitare il tuo sito web',
        'Gestisci clienti': 'Gestisci clienti',
        'Ottieni QR Code e link': 'Ottieni QR Code e link',
        "Le notifiche ti permettono di attirare l'attenzione degli utenti ed invogliarli ad acquistare.": "Le notifiche ti permettono di attirare l'attenzione degli utenti ed invogliarli ad acquistare.",
        "Invia nuova notifica": "Invia nuova notifica",
        'Invia': 'Invia',
        "Titolo": "Titolo",
        "Nuovo prodotto": "Nuovo prodotto",
        "Descrizione": "Descrizione",
        "Scopri il nuovo prodotto nella nostra app": "Scopri il nuovo prodotto nella nostra app",
        "Inviata": "Inviata",
        'A breve gli utenti riceveranno la notifica': 'A breve gli utenti riceveranno la notifica',
        "QR Code e link universale": "QR Code e link universale",
        'Non importa che dispositivi abbiano i tuoi clienti.': 'Non importa che dispositivi abbiano i tuoi clienti.',
        "Il link universale o il QR Code li porteranno nei corretti store digitali (iOS e Android) per il download dell'applicazione.": "Il link universale o il QR Code li porteranno nei corretti store digitali (iOS e Android) per il download dell'applicazione.",
        'Inoltre hai a disposizione i vari link e QR Code per visitare il tuo sito web': 'Inoltre hai a disposizione i vari link e QR Code per visitare il tuo sito web',
        "Applicazione": "Applicazione",
        "Link universale": "Link universale",
        'Link universale copiato negli appunti': 'Link universale copiato negli appunti',
        'Scarica .png': 'Scarica .png',
        'Scarica .svg': 'Scarica .svg',
        "Sito web": "Sito web",
        "Link sito": "Link sito",
        'Link sito web copiato negli appunti': 'Link sito web copiato negli appunti',
        "Link menu": "Link menu",
        'Link menu copiato negli appunti': 'Link menu copiato negli appunti',
        "Link prenotazioni": "Link prenotazioni",
        'Link prenotazioni copiato negli appunti': 'Link prenotazioni copiato negli appunti',
        'QR code sito': 'QR code sito',
        'QR code menu': 'QR code menu',
        'Delivery/Takeaway': 'Delivery/Takeaway',
        'Ordina e Paga': 'Ordina e Paga',
        'Ecommerce': 'Ecommerce',
        'Non disponibile': 'Non disponibile',
        'Metodo pagamento': 'Metodo pagamento',
        'Ordini tavolo': 'Ordini tavolo',
        'Negozi aggiuntivi': 'Negozi aggiuntivi',
        'Costo negozi': 'Costo negozi',
        'Importo (IVA esc.)': 'Importo (IVA esc.)',
        'Importo (IVA inc.)': 'Importo (IVA inc.)',
        'Fattura': 'Fattura',
        'Scarica PDF': 'Scarica PDF',
        "Abbonamento": "Abbonamento",
        'Dati fatturazione': 'Dati fatturazione',
        'P. IVA:': 'P. IVA:',
        'Codice univoco:': 'Codice univoco:',
        'Pec:': 'Pec:',
        'Iban:': 'Iban:',
        'Nessun metodo di pagamento inserito': 'Nessun metodo di pagamento inserito',
        'Costo mese attuale': 'Costo mese attuale',
        'Storico fatture': 'Storico fatture',
        "Nessuna fattura disponibile": "Nessuna fattura disponibile",
        'Le modifiche ai dati di fatturazione sono state salvate con successo': 'Le modifiche ai dati di fatturazione sono state salvate con successo',
        "Modifica dati fatturazione": "Modifica dati fatturazione",
        'Il nuovo metodo di pagamento è stato aggiunto.': 'Il nuovo metodo di pagamento è stato aggiunto.',
        'Aggiungi metodo di pagamento': 'Aggiungi metodo di pagamento',
        'Modifica metodo di pagamento': 'Modifica metodo di pagamento',
        'Le impostazioni sono state salvate con successo!': 'Le impostazioni sono state salvate con successo!',
        'Personalizzazioni': 'Personalizzazioni',
        'Generali': 'Generali',
        "Notifiche - Personalizzazioni": "Notifiche - Personalizzazioni",
        "Ordine confermato": "Ordine confermato",
        "Ordine delivery pronto": "Ordine delivery pronto",
        "Ordine pronto": "Ordine pronto",
        "Il locale sta preparando il tuo ordine": "Il locale sta preparando il tuo ordine",
        "Il tuo ordine è stato preparato": "Il tuo ordine è stato preparato",
        "Ordine takeaway pronto": "Ordine takeaway pronto",
        "Il tuo ordine è pronto per il ritiro": "Il tuo ordine è pronto per il ritiro",
        "Ordine in consegna": "Ordine in consegna",
        "Il rider consegnerà presto il tuo ordine": "Il rider consegnerà presto il tuo ordine",
        "Ordine delivery consegnato": "Ordine delivery consegnato",
        "Ordine consegnato": "Ordine consegnato",
        "Il tuo ordine è stato consegnato": "Il tuo ordine è stato consegnato",
        "Ordine takeaway ritirato": "Ordine takeaway ritirato",
        "Ordine annullato": "Ordine annullato",
        "Orario ordine modificato": "Orario ordine modificato",
        "Ordine al tavolo in preparazione": "Ordine al tavolo in preparazione",
        "Ordine ritirato": "Ordine ritirato",
        "Il tuo ordine è stato ritirato": "Il tuo ordine è stato ritirato",
        "Ordine cancellato": "Ordine cancellato",
        "Siamo spiacenti, il tuo ordine è stato cancellato": "Siamo spiacenti, il tuo ordine è stato cancellato",
        "L'orario di consegna del tuo ordine è stato aggiornato": "L'orario di consegna del tuo ordine è stato aggiornato",
        "Il tuo ordine è stata confermato e verrà servito il prima possibile": "Il tuo ordine è stata confermato e verrà servito il prima possibile",
        "Altro": "Altro",
        "Gestisci i testi, le informazioni e il brand della tua attività": "Gestisci i testi, le informazioni e il brand della tua attività",
        "Vai alle personalizzazioni": "Vai alle personalizzazioni",
        "Integrazioni ed app": "Integrazioni ed app",
        'Aggiungi e gestisci le integrazioni con i gateway di pagamento': 'Aggiungi e gestisci le integrazioni con i gateway di pagamento',
        'Vai alle integrazioni': 'Vai alle integrazioni',
        "Fatturazione": "Fatturazione",
        'Analizza i costi di Easylivery, gestisci metodi di pagamento e dati di fatturazione': 'Analizza i costi di Easylivery, gestisci metodi di pagamento e dati di fatturazione',
        'Vai alla fatturazione': 'Vai alla fatturazione',
        'Aiutaci a migliorare Easylivery compilando rispondendo ad alcune semplici domande': 'Aiutaci a migliorare Easylivery compilando rispondendo ad alcune semplici domande',
        'Compila il feedback form': 'Compila il feedback form',
        "Attiva brand": "Attiva brand",
        "Pagina non trovata": "Pagina non trovata",
        'Torna alla home': 'Torna alla home',
        "Home": "Home",
        'Utenti registrati': 'Utenti registrati',
        'Scontrino medio': 'Scontrino medio',
        'ordine': 'ordine',
        'ordini': 'ordini',
        'da gestire': 'da gestire',
        'Sì': 'Sì',
        'No': 'No',
        'Inserisci la password per procedere': 'Inserisci la password per procedere',
        'Caricamento nuvola in corso...': 'Caricamento nuvola in corso...',
        "Chiusura": "Chiusura",
        'Doc. fiscali': 'Doc. fiscali',
        'Preconto': 'Preconto',
        'Preconti': 'Preconti',
        'Tot. fiscali': 'Tot. fiscali',
        'Tot. preconto': 'Tot. preconto',
        'Dettaglio ordine': 'Dettaglio ordine',
        'Crea negozio': 'Crea negozio',
        'QR Code/Link': 'QR Code/Link',
        'Seleziona almeno un opzione': 'Seleziona almeno un opzione',
        'Applica': 'Applica',
        'Reset': 'Reset',
        'Filtra': 'Filtra',
        "Tipo ordine": "Tipo ordine",
        'Nuovo brand': 'Nuovo brand',
        'Cambia brand': 'Cambia brand',
        'Logout': 'Logout',
        'Seleziona i coperti': 'Seleziona i coperti',
        'Oggi': 'Oggi',
        'Recap pagamenti': 'Recap pagamenti',
        'Rifiuta': 'Rifiuta',
        'Conferma': 'Conferma',
        'Segna come pronto': 'Segna come pronto',
        'Segna come ritirato': 'Segna come ritirato',
        "Seleziona rider": "Seleziona rider",
        'Segna in consegna': 'Segna in consegna',
        'Consegnato': 'Consegnato',
        'Pronto': 'Pronto',
        'Ritirato': 'Ritirato',
        'Annulla scontrino': 'Annulla scontrino',
        'consegna': 'consegna',
        'asporto': 'asporto',
        'Note:': 'Note:',
        'Note consegna': 'Note consegna',
        'Pagamento': 'Pagamento',
        'Al ritiro': 'Al ritiro',
        'Data e ora': 'Data e ora',
        'Modifica orario': 'Modifica orario',
        'Rider': 'Rider',
        'tel': 'tel',
        'Coperti': 'Coperti',
        'Modifica coperti': 'Modifica coperti',
        'sconto': 'sconto',
        'Mancia': 'Mancia',
        'Consegna': 'Consegna',
        'Coperto': 'Coperto',
        'Cover': 'Coperto',
        'Annulla ordine': 'Annulla ordine',
        'Seleziona un ordine': 'Seleziona un ordine',
        "Sei sicuro di voler annullare l'ordine": "Sei sicuro di voler annullare l'ordine",
        'Modifica orario ordine': 'Modifica orario ordine',
        'ritiro': 'ritiro',
        "Attualmente l'orario di": "Attualmente l'orario di",
        'è alle': 'è alle',
        'Nessuno orario alternativo disponibile': 'Nessuno orario alternativo disponibile',
        'Modifica coperti tavolo': 'Modifica coperti tavolo',
        'Dati cliente': 'Dati cliente',
        'Tipo ordine:': 'Tipo ordine:',
        'Asporto': 'Asporto',
        'Al tavolo': 'Al tavolo',
        'P.Iva/CF': 'P.Iva/CF',
        'Documento commerciale': 'Documento commerciale',
        'emesso per ANNULLAMENTO': 'emesso per ANNULLAMENTO',
        'di vendita o prestazione': 'di vendita o prestazione',
        'Documento di riferimento:': 'Documento di riferimento:',
        'Codici': 'Codici',
        'Codice': 'Codice',
        'Progressivo ordine': 'Progressivo ordine',
        'Totale complessivo': 'Totale complessivo',
        'di cui IVA': 'di cui IVA',
        'Pagamento elettronico': 'Pagamento elettronico',
        'Pagamento contante': 'Pagamento contante',
        'Sconto a pagare': 'Sconto a pagare',
        'Documento N.': 'Documento N.',
        'Ingredienti': 'Ingredienti',
        'Allergeni': 'Allergeni',
        'Iva': 'Iva',
        'Prezzo': 'Prezzo',
        'Aggiungi prodotto': 'Aggiungi prodotto',
        'Rimozione prodotto': 'Rimozione prodotto',
        'durante la rimozione del prodotto': 'durante la rimozione del prodotto',
        "Sei sicuro di voler rimuovere dall'ordine il prodotto": "Sei sicuro di voler rimuovere dall'ordine il prodotto",
        "Pagamento fallito": "Pagamento fallito",
        'Non è stato possibile elaborare il pagamento della fattura mensile di': 'Non è stato possibile elaborare il pagamento della fattura mensile di',
        'Per continuare ad usufruire dei serivzi Easylivery, ricordati di effettaure il pagamento al più presto.': 'Per continuare ad usufruire dei serivzi Easylivery, ricordati di effettaure il pagamento al più presto.',
        'Riprova pagamento': 'Riprova pagamento',
        'Nuovo tentativo di pagamento': 'Nuovo tentativo di pagamento',
        'Pagamento effettuato con successo': 'Pagamento effettuato con successo',
        'Incasso': 'Incasso',
        '0% IVA': '0% IVA',
        '4% IVA': '4% IVA',
        '5% IVA': '5% IVA',
        '10% IVA': '10% IVA',
        '22% IVA': '22% IVA',
        'Nessun ordine fiscale': 'Nessun ordine fiscale',
        'Totale ordini': 'Totale ordini',
        'Prodotti venduti': 'Prodotti venduti',
        'Prodotti più venduti': 'Prodotti più venduti',
        'Scarica report': 'Scarica report',
        'Nome prodotto': 'Nome prodotto',
        "Nessun prodotto venduto": "Nessun prodotto venduto",
        'Nessun dato sul fatturato': 'Nessun dato sul fatturato',
        'Totale utenti': 'Totale utenti',
        'Max': 'Max',
        'MAX': 'MAX',
        'Omaggio': 'Omaggio',
        'Contanti': 'Contanti',
        'Carta di credito/bancomat': 'Carta di credito/bancomat',
        'Generazione preconto': 'Generazione preconto',
        'Scontrino generato': 'Scontrino generato',
        'con successo': 'con successo',
        'Preconto generato': 'Preconto generato',
        'Genera scontrino': 'Genera scontrino',
        'Subtotale': 'Subtotale',
        'Rimuovi sconto': 'Rimuovi sconto',
        'Riepilogo': 'Riepilogo',
        'Aggiungi subtotale': 'Aggiungi subtotale',
        "Le credenziali per accedere all'Agenzia delle Entrate sono scadute, aggiornale al più presto per continuare ad effettuare scontrini": "Le credenziali per accedere all'Agenzia delle Entrate sono scadute, aggiornale al più presto per continuare ad effettuare scontrini",
        "Accesso Agenzia Entrate scaduto": "Accesso Agenzia Entrate scaduto",
        "Aggiorna le credenziali": "Aggiorna le credenziali",
        "Tasti reparti": "Tasti reparti",
        'Nuovo tasto': 'Nuovo tasto',
        'Aggiungi nuova opzione': 'Aggiungi nuova opzione',
        'Salva Modifiche': 'Salva Modifiche',
        'Vuoi chiudere il tavolo:': 'Vuoi chiudere il tavolo:',
        'Sei sicuro di voler chiudere questo tavolo?': 'Sei sicuro di voler chiudere questo tavolo?',
        'Aggiungi nuova Sala': 'Aggiungi nuova Sala',
        "Nome Sala": "Nome Sala",
        'Sala interna': 'Sala interna',
        'Sala esterna': 'Sala esterna',
        'Modifica Tavolo': 'Modifica Tavolo',
        'Aggiungi nuovo Tavolo': 'Aggiungi nuovo Tavolo',
        "Nome Tavolo": "Nome Tavolo",
        "Posti Minimi": "Posti Minimi",
        "Posti Massimi": "Posti Massimi",
        "Sala associata": "Sala associata",
        'Ordinazione al tavolo': 'Ordinazione al tavolo',
        'Prenotazione al tavolo': 'Prenotazione al tavolo',
        'Seleziona un colore per il tuo tavolo': 'Seleziona un colore per il tuo tavolo',
        'Scegli..': 'Scegli..',
        'Elimina sala': 'Elimina Sala',
        "L'eliminazione rimuoverà la sala ed i relativi dati.": "L'eliminazione rimuoverà la sala ed i relativi dati.",
        'Elimina Tavolo': 'Elimina Tavolo',
        "L'eliminazione rimuoverà il tavolo ed i relativi dati.": "L'eliminazione rimuoverà il tavolo ed i relativi dati.",
        "Non ci sono tavoli aperti in corso.": "Non ci sono tavoli aperti in corso.",
        'Caricamento in corso': 'Caricamento in corso',
        'Non ci sono tavoli da gestire.': 'Non ci sono tavoli da gestire.',
        'Il tavolo è stato CHIUSO, quindi non puoi aggiungere prodotti.': 'Il tavolo è stato CHIUSO, quindi non puoi aggiungere prodotti.',
        "Non puoi aggiungere nuovi prodotti all'ordine mentre sei in fase di pagamento.": "Non puoi aggiungere nuovi prodotti all'ordine mentre sei in fase di pagamento.",
        'Hai inserito erroneamente lo sconto': 'Hai inserito erroneamente lo sconto',
        'Sconto custom': 'Sconto custom',
        'Pagamento Preconto Tavolo': 'Pagamento Preconto Tavolo',
        'Preconto generato con successo!': 'Preconto generato con successo!',
        'Errore': 'Errore',
        'Totale restante': 'Totale restante',
        'Totale tavolo': 'Totale tavolo',
        'Nuova operazione': 'Nuova operazione',
        'Genera Scontrino': 'Genera Scontrino',
        'Stampa Preconto': 'Stampa Preconto',
        'Carta': 'Carta',
        'Prodotti che restano da pagare': 'Prodotti che restano da pagare',
        'Totale pagato': 'Totale pagato',
        'Scontrini': 'Scontrini',
        'Annulla Scontrino': 'Annulla Scontrino',
        "Non ci sono ancora preconti o scontrini da mostrare.": "Non ci sono ancora preconti o scontrini da mostrare.",
        'Totale Pagamento': 'Totale Pagamento',
        'Scontrino generato con successo!': 'Scontrino generato con successo!',
        'Stampa Scontrino': 'Stampa Scontrino',
        'Invia per email': 'Invia per email',
        "Email cliente": "Email cliente",
        'Svuota': 'Svuota',
        'Il cliente non ha ancora effettuato ordini.': 'Il cliente non ha ancora effettuato ordini.',
        'Comanda': 'Comanda',
        'Sconto di': 'Sconto di',
        'Mostra meno': 'Mostra meno',
        'Mostra altro': 'Mostra altro',
        'Le modifiche alla categoria sono state salvate': 'Le modifiche alla categoria sono state salvate',
        'Si è verificato un errore, riprova': 'Si è verificato un errore, riprova',
        'La categoria è stata creata': 'La categoria è stata creata',
        'Generale': 'Generale',
        'Sottocategorie': 'Sottocategorie',
        'Orari': 'Orari',
        'Visibilità': 'Visibilità',
        "Immagine": "Immagine",
        "Scegli l'orario in cui i prodotti di questa categoria saranno acquistabili dai tuoi clienti": "Scegli l'orario in cui i prodotti di questa categoria saranno acquistabili dai tuoi clienti",
        'oppure': 'oppure',
        "Dalle": "Dalle",
        "Alle": "Alle",
        "Abilitata": "Abilitata",
        "Scegli dove visualizzare la categoria": "Scegli dove visualizzare la categoria",
        'In home': 'In home',
        'Sezione upselling': 'Sezione upselling',
        "All'interno dei prodotti configurabili": "All'interno dei prodotti configurabili",
        'ID categoria:': 'ID categoria:',
        'Elimina categoria': 'Elimina categoria',
        'In questa sezione potrai creare le sottocategorie e aggiungere i prodotti': 'In questa sezione potrai creare le sottocategorie e aggiungere i prodotti',
        'Aggiungi nuova sottocategoria': 'Aggiungi nuova sottocategoria',
        'Nuova sottocategoria': 'Nuova sottocategoria',
        'ID gruppo:': 'ID gruppo:',
        'Nome sottocategoria': 'Nome sottocategoria',
        'Elimina sottocategoria': 'Elimina sottocategoria',
        'Seleziona prodotti': 'Seleziona prodotti',
        "Codice Prodotto": "Codice Prodotto",
        "Categorie aggiuntive": "Categorie aggiuntive",
        "Tipo di prodotto": "Tipo di prodotto",
        "Unità di Misura": "Unità di Misura",
        "Prezzo finale": "Prezzo finale",
        'Modifica prodotto': 'Modifica prodotto',
        'Aggiungi nuovo prodotto': 'Aggiungi nuovo prodotto',
        'Elimina prodotto': 'Elimina prodotto',
        'Nuova categoria': 'Nuova categoria',
        'In questa sezione potrai aggiungere gli ingredienti del tuo prodotto': 'In questa sezione potrai aggiungere gli ingredienti del tuo prodotto',
        "Nome ingrediente": "Nome ingrediente",
        'Nessun ingrediente': 'Nessun ingrediente',
        'Nessun valore': 'Nessun valore',
        'Crea e gestisci le opzioni per la configurazione del prodotto': 'Crea e gestisci le opzioni per la configurazione del prodotto',
        "Disponibilità negozi": "Disponibilità negozi",
        'Le modifiche sulla disponibilità vengono abilitate e disabilitate in base al negozio selezionato': 'Le modifiche sulla disponibilità vengono abilitate e disabilitate in base al negozio selezionato',
        'Nuova opzione': 'Nuova opzione',
        "Obbligatoria": "Obbligatoria",
        "Gusto": "Gusto",
        "Massimo selezionabile": "Massimo selezionabile",
        "opzioni": "opzioni",
        'Nuova opzione selezionabile': 'Nuova opzione selezionabile',
        'Elimina opzione': 'Elimina opzione',
        'Cioccolato': 'Cioccolato',
        'volta': 'volta',
        'volte': 'volte',
        'Seleziona tra le categorie un prodotto da duplicare, verranno copiate tutte le informazioni inserite': 'Seleziona tra le categorie un prodotto da duplicare, verranno copiate tutte le informazioni inserite',
        'Categorie': 'Categorie',
        'prodotto': 'prodotto',
        'prodotti': 'prodotti',
        'Copia': 'Copia',
        'Tempi': 'Tempi',
        'Tramite questa funzione potrai impostare un tempo di spedizione differente per un prodotto che ha dei tempi di preparazione maggiori rispetto agli altri': 'Tramite questa funzione potrai impostare un tempo di spedizione differente per un prodotto che ha dei tempi di preparazione maggiori rispetto agli altri',
        "(opzionale, non obbligatorio)": "(opzionale, non obbligatorio)",
        "Inserisci qui il valore": "Inserisci qui il valore",
        "Unità": "Unità",
        'Giorno': 'Giorno',
        'Giorni': 'Giorni',
        'Ora': 'Ora',
        'Ore': 'Ore',
        'Preordini': 'Preordini',
        'Tramite questa funzione potrai impostare una data di preordine': 'Tramite questa funzione potrai impostare una data di preordine',
        "Prodotto vietato ai minori di 18 anni": "Prodotto vietato ai minori di 18 anni",
        'Seleziona': 'Seleziona',
        'Seleziona Categoria della Materia Prima': 'Seleziona Categoria della Materia Prima',
        "Iva Cassa": "Iva Cassa",
        "Iva Tavolo": "Iva Tavolo",
        "Iva Delivery": "Iva Delivery",
        "Iva Take away": "Iva Take away",
        "Consenti al cliente di selezionare più prodotti ad esempio un secondo, un contorno e una bevanda.": "Consenti al cliente di selezionare più prodotti ad esempio un secondo, un contorno e una bevanda.",
        "Numero massimo di prodotti selezionabili": "Numero massimo di prodotti selezionabili",
        "Prezzo minimo da raggiungere": "Prezzo minimo da raggiungere",
        "Max. selezione": "Max. selezione",
        "Seleziona i negozi abilitati alla vendita del prodotto ed imposta prezzi diversi per negozio.": "Seleziona i negozi abilitati alla vendita del prodotto ed imposta prezzi diversi per negozio.",
        'Lasciando il campo "prezzo" vuoto verrà applicato il prezzo presente nella sezione "Generale"': 'Lasciando il campo "prezzo" vuoto verrà applicato il prezzo presente nella sezione "Generale"',
        '"Max. Ordinabile" indica il quantitativo massimo ordinabile da un cliente per ogni ordine': '"Max. Ordinabile" indica il quantitativo massimo ordinabile da un cliente per ogni ordine',
        'Abilitato': 'Abilitato',
        'Max. Ordinabile': 'Max. Ordinabile',
        'Illimitata': 'Illimitata',
        'Disabilitato': 'Disabilitato',
        'Illimitato': 'Illimitato',
        'Seleziona in che sezioni visualizzare il prodotto': 'Seleziona in che sezioni visualizzare il prodotto',
        'Upselling': 'Upselling',
        'In store': 'In store',
        'Menù digitale': 'Menù digitale',
        'Vuoi abilitare il magazzino?': 'Vuoi abilitare il magazzino?',
        'Associa la materia prima': 'Associa la materia prima',
        'In questa sezione potrai associare il prodotto semplice alla materia prima': 'In questa sezione potrai associare il prodotto semplice alla materia prima',
        "Aggiungi materia prima": "Aggiungi materia prima",
        'Nessuna materia prima trovata': 'Nessuna materia prima trovata',
        'Configurazione': 'Configurazione',
        'Modifica categoria': 'Modifica categoria',
        'Cambia posizione': 'Cambia posizione',
        "L'eliminazione rimuoverà l'intera categoria e i relativi prodotti associati a questa categoria": "L'eliminazione rimuoverà l'intera categoria e i relativi prodotti associati a questa categoria",
        "L'eliminazione rimuoverà il prodotto e i relativi dati": "L'eliminazione rimuoverà il prodotto e i relativi dati",
        'Generazione del file excel in corso': 'Generazione del file excel in corso',
        "File excel generato con successo, il download dovrebbe partire a breve": "File excel generato con successo, il download dovrebbe partire a breve",
        'Caricamento file import in corso': 'Caricamento file import in corso',
        "File caricato correttamente. L'importazione dei prodotti verrà completata a breve.": "File caricato correttamente. L'importazione dei prodotti verrà completata a breve.",
        "Utilizza questa funzione per importare nuovi prodotti o aggiornarne di esistenti in modo massivo. Il file da caricare dovrà essere dello stesso formato e con gli stessi dati del": "Utilizza questa funzione per importare nuovi prodotti o aggiornarne di esistenti in modo massivo. Il file da caricare dovrà essere dello stesso formato e con gli stessi dati del",
        'file che puoi scaricare da qui': 'file che puoi scaricare da qui',
        'Seleziona file': 'Seleziona file',
        'oppure trascinalo nel box': 'oppure trascinalo nel box',
        'Importa': 'Importa',
        'da ': 'da ',
        "Cambia posizione categorie": "Cambia posizione categorie",
        "Banner di natale": "Banner di natale",
        "Banner utilizzato per i prodotti di natale": "Banner utilizzato per i prodotti di natale",
        "Tipologia banner": "Tipologia banner",
        'categoria': 'categoria',
        "Link esterno": "Link esterno",
        "Immagine banner": "Immagine banner",
        "Data inizio": "Data inizio",
        "Data fine": "Data fine",
        "I clienti inseriranno questo codice al checkout per applicare la promozione": "I clienti inseriranno questo codice al checkout per applicare la promozione",
        "A cosa vuoi applicare la promozione?": "A cosa vuoi applicare la promozione?",
        'Applica a tutto': 'Applica a tutto',
        'Seleziona categorie': 'Seleziona categorie',
        "Cerca categorie": "Cerca categorie",
        'Nessuna categoria': 'Nessuna categoria',
        "Cerca prodotti": "Cerca prodotti",
        "Nome promozione": "Nome promozione",
        "Promozione pizze": "Promozione pizze",
        "Mostra banner in app": "Mostra banner in app",
        "Abilita promozione": "Abilita promozione",
        "Descrizione aggiuntiva promozione": "Descrizione aggiuntiva promozione",
        "Che tipo di promozione vuoi creare?": "Che tipo di promozione vuoi creare?",
        'Promozione con codice': 'Promozione con codice',
        'Sconti sulle categorie': 'Sconti sulle categorie',
        'Sconti sui prodotti': 'Sconti sui prodotti',
        'Consegna gratuita': 'Consegna gratuita',
        "Che tipo di sconto?": "Che tipo di sconto?",
        'Percentuale': 'Percentuale',
        'Importo fisso': 'Importo fisso',
        'Spedizione gratuita': 'Spedizione gratuita',
        'Importo': 'Importo',
        "Percentuale sconto": "Percentuale sconto",
        'Seleziona negozi': 'Seleziona negozi',
        "Regole di attivazione": "Regole di attivazione",
        'Importo minimo': 'Importo minimo',
        'Quantità minima prodotti': 'Quantità minima prodotti',
        "Limite di utilizzo per utente": "Limite di utilizzo per utente",
        "Download report": "Download report",
        'Sei sicuro di voler scaricare il report di': 'Sei sicuro di voler scaricare il report di',
        'File generato correttamente, il download dovrebbe partire a breve': 'File generato correttamente, il download dovrebbe partire a breve',
        'Scarica': 'Scarica',
        "Cerca codice ordine, nome, cognome, email e telefono": "Cerca codice ordine, nome, cognome, email e telefono",
        "Ragione sociale": "Ragione sociale",
        "Indirizzo e numero civico": "Indirizzo e numero civico",
        "Codice univoco": "Codice univoco",
        "Pec": "Pec",
        "Partita IVA": "Partita IVA",
        'Rid bancario': 'Rid bancario',
        "Nome intestatario": "Nome intestatario",
        "Iban": "Iban",
        'Carta di credito': 'Carta di credito',
        "Nome sulla carta": "Nome sulla carta",
        "Numero della carta": "Numero della carta",
        "Scadenza": "Scadenza",
        "CVC": "CVC",
        'Moduli nel tuo Abbonamento': 'Moduli nel tuo Abbonamento',
        'Seleziona le funzionalità da attivare nel tuo locale': 'Seleziona le funzionalità da attivare nel tuo locale',
        'Ordini al Tavolo': 'Ordini al Tavolo',
        "Cliccando su “Attiva account” accetti i": "Cliccando su “Attiva account” accetti i",
        "ed autorizzi Easylivery ad addebitarti automaticamente l'importo applicabile ogni mese fino alla cancellazione del servizio, che può avvenire dopo 3 mesi dall'attivazione.": "ed autorizzi Easylivery ad addebitarti automaticamente l'importo applicabile ogni mese fino alla cancellazione del servizio, che può avvenire dopo 3 mesi dall'attivazione.",
        'Attiva account': 'Attiva account',
        'Inserisci i dati di fatturazione. Serviranno per ricevere la fattura mensile con i costi del servizio.': 'Inserisci i dati di fatturazione. Serviranno per ricevere la fattura mensile con i costi del servizio.',
        "Inserisci il metodo di pagamento. Ci servirà per effettuare l'addebito mensile per i costi del servizio.": "Inserisci il metodo di pagamento. Ci servirà per effettuare l'addebito mensile per i costi del servizio.",
        'obbligatorio': 'obbligatorio',
        'Colore principale': 'Colore principale',
        "Nome attività": "Nome attività",
        "Descrizione attività": "Descrizione attività",
        'Lista': 'Lista',
        'Griglia': 'Griglia',
        "Layout": "Layout",
        'Anteprima visualizzazione logo e sfondo in app': 'Anteprima visualizzazione logo e sfondo in app',
        'Carica sfondo': 'Carica sfondo',
        'Carica logo': 'Carica logo',
        'Questo negozio consegna nella tua zona': 'Questo negozio consegna nella tua zona',
        'Avanti': 'Avanti',
        'Informazioni': 'Informazioni',
        'Fornisci i dettagli della tuà attività e della tua nuova applicazione': 'Fornisci i dettagli della tuà attività e della tua nuova applicazione',
        "Inserisci client ID, Secret e clicca sul bottone in basso per collegare il tuo account PayPal ad Easylivery ed iniziare a ricevere pagamenti": "Inserisci client ID, Secret e clicca sul bottone in basso per collegare il tuo account PayPal ad Easylivery ed iniziare a ricevere pagamenti",
        "Associa account a": "Associa account a",
        "Seleziona negozio": "Seleziona negozio",
        'Associa': 'Associa',
        "L'account è associato al brand": "L'account è associato al brand",
        "L'account è associato al negozio": "L'account è associato al negozio",
        "Aggiungi account": "Aggiungi account",
        "Inserisci la mail e clicca sul bottone in basso per collegare il tuo account Stripe ad Easylivery ed iniziare a ricevere pagamenti con carta": "Inserisci la mail e clicca sul bottone in basso per collegare il tuo account Stripe ad Easylivery ed iniziare a ricevere pagamenti con carta",
        'Collega': 'Collega',
        'Collegamento account in corso': 'Collegamento account in corso',
        'Completa configurazione': 'Completa configurazione',
        'In verifica': 'In verifica',
        'Accedi a Stripe': 'Accedi a Stripe',
        "Tavolo*": "Tavolo*",
        "Negozio*": "Negozio*",
        "Data*": "Data*",
        "Seleziona data": "Seleziona data",
        "Nessuna fascia disponibile": "Nessuna fascia disponibile",
        "Numero persone*": "Numero persone*",
        "Nome*": "Nome*",
        "Cognome*": "Cognome*",
        'Dettagli prenotazione': 'Dettagli prenotazione',
        'Annulla prenotazione': 'Annulla prenotazione',
        'Sei sicuro di voler annullare la prenotazione?': 'Sei sicuro di voler annullare la prenotazione?',
        'alle': 'alle',
        'Area': 'Area',
        'Ordine minimo': 'Ordine minimo',
        'Tempo arrivo rider': 'Tempo arrivo rider',
        "minuti": "minuti",
        'Nessuna area aggiunta': 'Nessuna area aggiunta',
        "Accetta ordini delivery": "Accetta ordini delivery",
        "Tempo massimo annullamento": "Tempo massimo annullamento",
        "Stato predefinito ordine": "Stato predefinito ordine",
        'Ricevi ordini dagli utenti in-app': 'Ricevi ordini dagli utenti in-app',
        'Consegna al più presto': 'Consegna al più presto',
        'Programmati': 'Programmati',
        'Programmati per': 'Programmati per',
        'Domani': 'Domani',
        "Pagamenti": "Pagamenti",
        'Accetta pagamenti': 'Accetta pagamenti',
        'Alla consegna': 'Alla consegna',
        "Contatta il nostro team per effettuare l'attivazione dei pagamenti con PayPal e carte di credito": "Contatta il nostro team per effettuare l'attivazione dei pagamenti con PayPal e carte di credito",
        "Impostazioni consegna": "Impostazioni consegna",
        'Raggio': 'Raggio',
        'Cap': 'Cap',
        'Aree di consegna': 'Aree di consegna',
        "Seleziona il tipo di gestione della consegna": "Seleziona il tipo di gestione della consegna",
        "Utilizza prezzo consegna calcolato da Glovo": "Utilizza prezzo consegna calcolato da Glovo",
        "Ordine minimo generale": "Ordine minimo generale",
        'Corriere': 'Corriere',
        'Servizio rider': 'Servizio rider',
        "Contatta il nostro team per effettuare l'attivazione del servizio rider Glovo o Poony": "Contatta il nostro team per effettuare l'attivazione del servizio rider Glovo o Poony",
        'Aggiungi cap': 'Aggiungi cap',
        'Aggiungi raggio': 'Aggiungi raggio',
        'Link copiato negli appunti': 'Link copiato negli appunti',
        "Impostazioni Ordina e Paga": "Impostazioni Ordina e Paga",
        "Tipo di consegna": "Tipo di consegna",
        "Notifica via SMS": "Notifica via SMS",
        "Abilita stato 'Pronto'": "Abilita stato 'Pronto'",
        "Abilita richiesta mancia": "Abilita richiesta mancia",
        "Abilita pulsanti 'Opzioni'": "Abilita pulsanti 'Opzioni'",
        "QR Code": "QR Code",
        "Link express": "Link express",
        "Impostazioni Tavolo": "Impostazioni Tavolo",
        "Abilita ordinazione cliente": "Abilita ordinazione cliente",
        "Costo coperto": "Costo coperto",
        "Timer di aggregazione comande": "Timer di aggregazione comande",
        "Durata Timer": "Durata Timer",
        "Abilita funzionalità prenotazioni": "Abilita funzionalità prenotazioni",
        "Accetta prenotazioni": "Accetta prenotazioni",
        "Tipologia gestione prenotazione": "Tipologia gestione prenotazione",
        "Tempo annullamento prenotazione": "Tempo annullamento prenotazione",
        "Fasce orarie": "Fasce orarie",
        "Max. coperti": "Max. coperti",
        "Liberare dopo": "Liberare dopo",
        'minuto': 'minuto',
        'Aggiungi orario': 'Aggiungi orario',
        'oppure scegli un': 'oppure scegli un',
        'giorno da cui copiare': 'giorno da cui copiare',
        'Nessun orario disponibile': 'Nessun orario disponibile',
        'Il negozio è attivo e sarà aperto negli orari di apertura impostati': 'Il negozio è attivo e sarà aperto negli orari di apertura impostati',
        'Il negozio è stato disattivato, clicca sul bottone per riattivarlo': 'Il negozio è stato disattivato, clicca sul bottone per riattivarlo',
        "Tipologia negozio": "Tipologia negozio",
        "Valuta": "Valuta",
        "Descrizione banner ecommerce": "Descrizione banner ecommerce",
        "Spedizione in 24/48h": "Spedizione in 24/48h",
        "Abilita menù digitale": "Abilita menù digitale",
        "Giorni e orari di apertura": "Giorni e orari di apertura",
        "Intervallo": "Intervallo",
        "Max. ordini": "Max. ordini",
        'Chiuso tutto il giorno': 'Chiuso tutto il giorno',
        "Stampante cassa": "Stampante cassa",
        "Indirizzo IP": "Indirizzo IP",
        "Nascondi dati ordine": "Nascondi dati ordine",
        'Aggiungi stampante': 'Aggiungi stampante',
        'Easylivery supporta le stampanti Epson serie TM con le ultime versioni firmware': 'Easylivery supporta le stampanti Epson serie TM con le ultime versioni firmware',
        "Ti suggeriamo l'acquisto di questa stampante": "Ti suggeriamo l'acquisto di questa stampante",
        "Abilita prodotti": "Abilita prodotti",
        'Abilita i prodotti per questo negozio': 'Abilita i prodotti per questo negozio',
        "Elimina negozio": "Elimina negozio",
        'Elimina definitivamente': 'Elimina definitivamente',
        'Verranno cancellate tutte le impostazioni compilate e verrà eliminato ogni dato associato ad esso': 'Verranno cancellate tutte le impostazioni compilate e verrà eliminato ogni dato associato ad esso',
        'Sei sicuro di voler eliminare il negozio': 'Sei sicuro di voler eliminare il negozio',
        "Verranno anche eliminati tutti i dati associati ad esso": "Verranno anche eliminati tutti i dati associati ad esso",
        "Accetta ordini take away": "Accetta ordini take away",
        'Ritiro il prima possibile': 'Ritiro il prima possibile',
        "Accessi Agenzia Entrate": "Accessi Agenzia Entrate",
        'Inserisci di seguito gli accessi che utilizzi per accedere al': 'Inserisci di seguito gli accessi che utilizzi per accedere al',
        "portale dell'Agenzia delle Entrate": "portale dell'Agenzia delle Entrate",
        "Codice fiscale/Codice Entratel": "Codice fiscale/Codice Entratel",
        "Codice PIN": "Codice PIN",
        "Denominazione": "Denominazione",
        'Fiscalità': 'Fiscalità',
        "Scannerizza codice QR": "Scannerizza codice QR",
        "Opzioni": "Opzioni",
        'Nuovo tasto opzione': 'Nuovo tasto opzione',
        'Elimina': 'Elimina',
        "Tipologia utente": "Tipologia utente",
        "Veicolo": "Veicolo",
        "Assegnazione del rider": "Assegnazione del rider",
        "Elimina utente": "Elimina utente",
        "Sei sicuro di voler eliminare definitivamente l'utente?": "Sei sicuro di voler eliminare definitivamente l'utente?",
        'Modifica fornitore': 'Modifica fornitore',
        'Aggiungi nuovo fornitore': 'Aggiungi nuovo fornitore',
        "Tipo cliente:": "Tipo cliente:",
        'P.IVA': 'P.IVA',
        'Privato': 'Privato',
        "Città": "Città",
        "CAP": "CAP",
        "Provincia": "Provincia",
        'Elimina il movimento inerente': 'Elimina il movimento inerente',
        "L'eliminazione rimuoverà il movimento e i relativi dati": "L'eliminazione rimuoverà il movimento e i relativi dati",
        'Elimina movimento': 'Elimina movimento',
        'Elimina il fornitore': 'Elimina il fornitore',
        "L'eliminazione rimuoverà il fornitore e i relativi dati": "L'eliminazione rimuoverà il fornitore e i relativi dati",
        'Elimina fornitore': 'Elimina fornitore',
        "Aggiungi nuovo movimento": "Aggiungi nuovo movimento",
        "Tipo di movimento": "Tipo di movimento",
        "Seleziona prodotto": "Seleziona prodotto",
        "Barcode": "Barcode",
        "Causale di carico": "Causale di carico",
        "Causale di scarico": "Causale di scarico",
        "Note": "Note",
        'Fornitore': 'Fornitore',
        "Seleziona fornitore": "Seleziona fornitore",
        "Numero ordine": "Numero ordine",
        "Tipo di documento": "Tipo di documento",
        "N. documento": "N. documento",
        "Costo Materia Prima": "Costo Materia Prima",
        "Seleziona il Negozio": "Seleziona il Negozio",
        "Imponibile": "Imponibile",
        "Associa un tavolo del ristorante al cliente che utilizza la tua applicazione o che ha prenotato.": "Associa un tavolo del ristorante al cliente che utilizza la tua applicazione o che ha prenotato.",
        '(Obbligatorio)': '(Obbligatorio)',
        'New': 'New',
        'foto': 'foto',
        'oppure trascina qui': 'oppure trascina qui',
        'La foto deve essere:': 'La foto deve essere:',
        'Ad alta risoluzione': 'Ad alta risoluzione',
        'Illuminata': 'Illuminata',
        'Centrata': 'Centrata',
        'Aiuto': 'Aiuto',
        'Indietro': 'Indietro',
        'Tutti i negozi': 'Tutti i negozi',
        'Proprietario': 'Proprietario',
        'In attesa': 'In attesa',
        'Confermato': 'Confermato',
        'Completato': 'Completato',
        'Aperto': 'Aperto',
        'Annullato': 'Annullato',
        'Confermati': 'Confermati',
        'Pronti': 'Pronti',
        'Consegnati': 'Consegnati',
        'Annullati': 'Annullati',
        'Completati': 'Completati',
        'Consegnati/Completati': 'Consegnati/Completati',
        'Euro (€)': 'Euro (€)',
        'Sterlina (£)': 'Sterlina (£)',
        'Dollaro ($)': 'Dollaro ($)',
        'Lunedì': 'Lunedì',
        'Martedì': 'Martedì',
        'Mercoledì': 'Mercoledì',
        'Giovedì': 'Giovedì',
        'Venerdì': 'Venerdì',
        'Sabato': 'Sabato',
        'Domenica': 'Domenica',
        'Feedback': 'Feedback',
        'Bicicletta': 'Bicicletta',
        'Scooter': 'Scooter',
        'Auto': 'Auto',
        'Monopattino': 'Monopattino',
        'Registrazione facile e veloce': 'Registrazione facile e veloce',
        'Per creare un account, inserisci nome, cognome e il tuo indirizzo email.': 'Per creare un account, inserisci nome, cognome e il tuo indirizzo email.',
        'Inizia a vendere online': 'Inizia a vendere online',
        'Con Easylivery la tua app sarà disponibile per il download su iOS & Android.': 'Con Easylivery la tua app sarà disponibile per il download su iOS & Android.',
        'Monitora la tua attività': 'Monitora la tua attività',
        "Gestisci gli ordini in un'unica dashboard, monitorali dalla preparazione alla consegna.": "Gestisci gli ordini in un'unica dashboard, monitorali dalla preparazione alla consegna.",
        'Aumenta le vendite': 'Aumenta le vendite',
        'Velocizza il tuo processo di ordinazione, crea e fidelizza il database dei tuoi clienti.': 'Velocizza il tuo processo di ordinazione, crea e fidelizza il database dei tuoi clienti.',
        'Normale': 'Normale',
        'Questo prodotto è semplice, senza configurazioni': 'Questo prodotto è semplice, senza configurazioni',
        'Configurabile': 'Configurabile',
        'Questo prodotto ha più opzioni come ingredienti, formati oppure opzioni varie': 'Questo prodotto ha più opzioni come ingredienti, formati oppure opzioni varie',
        'Lista prodotti con prezzo fisso': 'Lista prodotti con prezzo fisso',
        'Aggiungi più prodotti e crea un menu con un prezzo fisso': 'Aggiungi più prodotti e crea un menu con un prezzo fisso',
        'Lista prodotti con prezzo variabile': 'Lista prodotti con prezzo variabile',
        'Aggiungi più prodotti e crea un menu con un prezzo variabile in base ai prodotti aggiunti': 'Aggiungi più prodotti e crea un menu con un prezzo variabile in base ai prodotti aggiunti',
        'Copia prodotto': 'Copia prodotto',
        "Copia le informazioni da prodotto esistente velocizzando l'operazione di caricamento": "Copia le informazioni da prodotto esistente velocizzando l'operazione di caricamento",
        'Totale IVA escl.': 'Totale IVA escl.',
        'Base': 'Base',
        'Costo ordini': 'Costo ordini',
        'Numero Ordini': 'Numero Ordini',
        'Ieri': 'Ieri',
        'Ultima settimana': 'Ultima settimana',
        'Ultimo mese': 'Ultimo mese',
        'Ultimo anno': 'Ultimo anno',
        'Personalizzato': 'Personalizzato',
        'Punto vendita': 'Punto vendita',
        'Punto di ritiro': 'Punto di ritiro',
        'Codice sconto': 'Codice sconto',
        'Sconto categorie': 'Sconto categorie',
        'Sconto prodotti': 'Sconto prodotti',
        'Solo immagine': 'Solo immagine',
        'Ritiro': 'Ritiro',
        'Consegna al tavolo': 'Consegna al tavolo',
        'Pagamento anticipato': 'Pagamento anticipato',
        'Pagamento posticipato': 'Pagamento posticipato',
        'App iOS & Android': 'App iOS & Android',
        'Dashboard gestionale': 'Dashboard gestionale',
        'Notifiche e promozioni': 'Notifiche e promozioni',
        'Un punto vendita incluso': 'Un punto vendita incluso',
        'Costi server inclusi': 'Costi server inclusi',
        'Ordine al tavolo': 'Ordine al tavolo',
        'Punto vendita aggiuntivo': 'Punto vendita aggiuntivo',
        'Glutine': 'Glutine',
        'Sedano': 'Sedano',
        'Frutta a guscio': 'Frutta a guscio',
        'Pesce': 'Pesce',
        'Soia': 'Soia',
        'Sesamo': 'Sesamo',
        'Arachidi e derivati': 'Arachidi e derivati',
        'Crostacei': 'Crostacei',
        'Uova e derivati': 'Uova e derivati',
        'Molluschi': 'Molluschi',
        'Latte e derivati': 'Latte e derivati',
        'Senape': 'Senape',
        'Anidride solforosa e solfiti': 'Anidride solforosa e solfiti',
        'Lupini': 'Lupini',
        'Cereali, grano, segale, orzo, avena, farro, kamut, inclusi ibridati derivati': 'Cereali, grano, segale, orzo, avena, farro, kamut, inclusi ibridati derivati',
        "Sia in pezzi che all'interno di preparati per zuppe, salse e concentrati vegetali": "Sia in pezzi che all'interno di preparati per zuppe, salse e concentrati vegetali",
        'Mandorle, nocciole, noci comuni, noci di acagiù, noci pecan, anacardi e pistacchi': 'Mandorle, nocciole, noci comuni, noci di acagiù, noci pecan, anacardi e pistacchi',
        'Prodotti alimentari in cui è presente il pesce, anche se in piccole percentuali': 'Prodotti alimentari in cui è presente il pesce, anche se in piccole percentuali',
        'Prodotti dericati come: latte di soia, tofu, spaghetti di soia e simili': 'Prodotti dericati come: latte di soia, tofu, spaghetti di soia e simili',
        'Semi interi usati per il pane, farine anche se lo contengono in minima percentuale': 'Semi interi usati per il pane, farine anche se lo contengono in minima percentuale',
        'Snack confezionati, creme e condimenti in cui vi sia anche in piccole dosi': 'Snack confezionati, creme e condimenti in cui vi sia anche in piccole dosi',
        "Marini e d'acqua dolce: gamberi, scampi, aragoste, granchi e simili": "Marini e d'acqua dolce: gamberi, scampi, aragoste, granchi e simili",
        "Uova e prodotti che lo contengono come: maionese, emulsionanti, pasta all'uovo": "Uova e prodotti che lo contengono come: maionese, emulsionanti, pasta all'uovo",
        'Canestrello, cannolicchio, capasanta, cozza, ostrica, patella, vongola, tellina, ecc...': 'Canestrello, cannolicchio, capasanta, cozza, ostrica, patella, vongola, tellina, ecc...',
        'Ogni prodotto in cui viene usato il latte: yogurt, biscotti, torte, gelato e creme varie': 'Ogni prodotto in cui viene usato il latte: yogurt, biscotti, torte, gelato e creme varie',
        'Si può trovare nelle salse e nei condimenti, specie nella mostarda': 'Si può trovare nelle salse e nei condimenti, specie nella mostarda',
        "Cibi sott'aceto, sott'olio e in salamoia, marmellate, funghi secchi, conserve, ecc...": "Cibi sott'aceto, sott'olio e in salamoia, marmellate, funghi secchi, conserve, ecc...",
        'Presenti in cibi vegan sottoforma di: arrosti, salamini, farine e similari': 'Presenti in cibi vegan sottoforma di: arrosti, salamini, farine e similari',
        'Gestione coperti': 'Gestione coperti',
        'Gestione tavoli': 'Gestione tavoli',
        'Acquisto': 'Acquisto',
        'Reso': 'Reso',
        'Sostituzione': 'Sostituzione',
        'Produzione': 'Produzione',
        'Trasferimento': 'Trasferimento',
        'Chilogrammi': 'Chilogrammi',
        'Pezzi': 'Pezzi',
        'Litri': 'Litri',
        'Vendita': 'Vendita',
        'Uso interno': 'Uso interno',
        'Danneggiamento': 'Danneggiamento',
        'Furto': 'Furto',
        'Scarto': 'Scarto',
        'Reso a fornitore': 'Reso a fornitore',
        'Spostamento': 'Spostamento',
        'Esente Iva': 'Esente Iva',
        'Manager': 'Manager',
        'Cameriere': 'Cameriere',
        'Moto': 'Moto',
        'Macchina': 'Macchina',
        'Manuale': 'Manuale',
        'Automatica': 'Automatica',
        'Brand': 'Brand',
        'Distinta Base': 'Distinta Base',
        'App/Web': 'App/Web',
        'App e web - Marketing': 'App e web - Marketing',
        'QR Code e link': 'QR Code e link',
        'App': 'App',
        'e web': 'e web',
        'Gestisci notifiche, banner e qr code per app': 'Gestisci notifiche, banner e qr code per app',
        'Gestisci': 'Gestisci',
        'Nessun elemento': 'Nessun elemento',
        'Logo orizzontale': 'Logo orizzontale',
        'DOCUMENTO COMMERCIALE': 'DOCUMENTO COMMERCIALE',
        "Si è verificato un errore durante la stampa dell'ordine": "Si è verificato un errore durante la stampa dell'ordine",
        'Numero prodotti': 'Numero prodotti',
        'Prima possibile': 'Prima possibile',
        'Non ci sono ordini da visualizzare': 'Non ci sono ordini da visualizzare',
        'Nuovo ordine': 'Nuovo ordine',
        'Rimborsa': 'Rimborsa',
        'Rimborso': 'Rimborso',
        'Rimborsato': 'Rimborsato',
        'Totale Pagato': "Totale Pagato",
        'Lingua e area geografica': 'Lingua e area geografica',
        "Lingua": "Lingua",
        "Fuso orario": "Fuso orario",
        'Modifica Password': 'Modifica Password',
        'Inserisci la password attuale..': 'Inserisci la password attuale..',
        'Inserisci la nuova password..': 'Inserisci la nuova password..',
        'Conferma la nuova password..': 'Conferma la nuova password..',
        'Modifica E-mail': 'Modifica E-mail',
        'Inserisci la tua E-mail attuale..' : 'Inserisci la tua E-mail attuale..',
        'Inserisci la nuova E-mail..': 'Inserisci la nuova E-mail..',
        "Attenzione! Eliminando il tuo account, perderai l'accesso ai servizi dell'account Easylivery, tutti i tuoi dati e le configurazioni saranno eliminate definitivamente e non potranno essere più recuperati.": "Attenzione! Eliminando il tuo account, perderai l'accesso ai servizi dell'account Easylivery, tutti i tuoi dati e le configurazioni saranno eliminate definitivamente e non potranno essere più recuperati.",
        'Elimina Account': "Elimina Account",
        "Attenzione! Eliminando il tuo brand tutti i tuoi dati e le configurazioni saranno eliminate definitivamente e non potranno essere più recuperati.": "Attenzione! Eliminando il tuo brand tutti i tuoi dati e le configurazioni saranno eliminate definitivamente e non potranno essere più recuperati.",
        "Elimina Brand": "Elimina Brand",
        "Fino ad un massimo di": "Fino ad un massimo di",
        "Inserisci il codice di tracciamento fornito da Google Analytics": "Inserisci il codice di tracciamento fornito da Google Analytics",
        "Inserisci il codice di tracciamento fornito da Facebook": "Inserisci il codice di tracciamento fornito da Facebook",
        "Contattaci": "Contattaci",
        'Abilita o disabilita servizi temporaneamente': 'Abilita o disabilita servizi temporaneamente',
        "Max. da mostrare": "Max. da mostrare",
        "Inserisci uno o più codici di tracciamento forniti da Google Analytics": "Enter one or more tracking codes provided by Google Analytics",
        "Inserisci uno o più codici di tracciamento forniti da Facebook": "Enter one or more tracking codes provided by Facebook",
        'Codici Google Analytics salvati': 'Google Analytics codes saved',
        'Codici Facebook Pixel salvati': 'Facebook Pixel codes saved',
        'Da preparare': 'Da preparare',
        'Da ritirare': 'Da ritirare',
        'Da consegnare': 'Da consegnare',
        'Da consegnare/ritirare': 'Da consegnare/ritirare',
        "Stampa scontrino fiscale" : "Stampa scontrino fiscale",
        "Rimborso Parziale" : "Rimborso Parziale",
        "Seleziona Servizi" : "Seleziona Servizi",
        "Servizi" : "Servizi",
        "Tipologia ordini stampa automatica": "Tipologia ordini stampa automatica",
        "Tablet cassa": "Tablet cassa",
        "Stato ordini": "Stato ordini",
        "Dispositivi abilitati per la stampa": "Dispositivi abilitati per la stampa",
        "Pagine Schermi" : "Pagine Schermi",
        "Link Schermo Ordini" : "Link Schermo Ordini",
        "Link Schermo Qr" : "Link Schermo Qr",
        "Nascondi informazioni": "Nascondi informazioni",
        "Stampa automaticamente ordini non pagati": "Stampa automaticamente ordini non pagati",
        "Illimitati": "Illimitati",
        "ANNULLATO": "ANNULLATO",
        "Stampanti": "Stampanti",
        "Aggiungi o modifica le tue stampanti per le comande e gli scontrini": "Aggiungi o modifica le tue stampanti per le comande e gli scontrini",
        "Gestisci stampanti": "Gestisci stampanti",
        "Comande": "Comande",
        "Dispositivi": "Dispositivi",
        "Nessuna stampante": "Nessuna stampante",
        "Stampante salvata con successo": "Stampante salvata con successo",
        "Nessuna chiusura fiscale": "Nessuna chiusura fiscale",
        "Chiusure fiscali": "Chiusure fiscali",
        "Nuova chiusura fiscale": "Nuova chiusura fiscale",
        "Nuova chiusura": "Nuova chiusura",
        "Sei sicuro di voler effettuare una chiusura fiscale?": "Sei sicuro di voler effettuare una chiusura fiscale?",
        "Si è verificato un errore con la chiusura fiscale": "Si è verificato un errore con la chiusura fiscale",
        "Ristampa": "Ristampa",
        "Il tuo dispositivo non ha stampanti fiscali associate": "Il tuo dispositivo non ha stampanti fiscali associate",
        "Versione Easylivery": "Versione Easylivery",
        "Stampa preconto": "Stampa preconto",
        "Stampa preconti": "Stampa preconti",
        "Gestione ordine": "Gestione ordine",
        "Configurazioni obbligatorie mancanti": "Configurazioni obbligatorie mancanti",
        "Ristampa riepilogo": "Ristampa riepilogo",
        "Dividi prodotti configurabili": "Dividi prodotti configurabili",
        "Stati ordini stampa automatica": "Stati ordini stampa automatica",
        "Dimensione testo prodotti": "Dimensione testo prodotti",
        "Stampa comande dopo scontrino": "Stampa comande dopo scontrino",
        "Numero massimo": "Numero massimo",
        "Non gestire": "Non gestire",
        "Dimensione testo configurazioni prodotti": "Dimensione testo configurazioni prodotti",
        "Dati fiscali negozio": "Dati fiscali negozio",
        "Reparti": "Reparti",
        "Si è verificato un errore, la stampante è disconnessa o bloccata": "Si è verificato un errore, la stampante è disconnessa o bloccata",
        "Connessione internet ripristinata": "Connessione internet ripristinata",
        "Connessione internet assente": "Connessione internet assente",
        "Nascondi prodotti": "Nascondi prodotti",
        "Mostra prodotti": "Mostra prodotti",
        "Mostra lista prodotti ordini": "Mostra lista prodotti ordini",
        "Applicazioni": "Applicazioni",
        "Abilita email pagamento carta": "Abilita email pagamento carta",
        "Costo di servizio": "Costo di servizio",
        "Abilita costo di servizio": "Abilita costo di servizio",
        "Tipologia ordini": "Tipologia ordini",
        "Abilita massimo utilizzi": "Abilita massimo utilizzi",
        "Nessun prodotto": "Nessun prodotto",
        "Arrotonda sconti percentuali": "Arrotonda sconti percentuali",
        "Vassoio": "Vassoio",
        "Take Away": "Take Away",
        "Standard": "Standard",
        "Separato": "Separato",
        "Romana": "Romana",
        "Aggiungi note": "Aggiungi note",
        "Modifica aliquota IVA": "Modifica aliquota IVA",
        "Sconto o maggiorazione": "Sconto o maggiorazione",
        "Quote": "Quote",
        "Invio": "Invio",
        "Causale": "Causale",
        "Scontrino di cortesia": "Scontrino di cortesia",
        "Eventi": "Eventi",
        "Crea evento": "Crea evento",
        "Modifica evento": "Modifica evento",
        "Biglietti associati": "Biglietti associati",
        "Bancomat": "Bancomat",
        "Ticket": "Ticket",
        "Ricerca automaticamente la tua P.IVA": "Ricerca automaticamente la tua P.IVA",
        "Dati di fatturazione": "Dati di fatturazione",
        "Nazione": "Nazione",
        "Indirizzo": "Indirizzo",
        "Codice destinatario": "Codice destinatario",
        "Partita IVA non valida": "Partita IVA non valida",
        "Inserisci una mail valida": "Inserisci una mail valida",
        "Codice destinatario non valido": "Codice destinatario non valido",
        "Codice fiscale non valido": "Codice fiscale non valido",
        "Dati salvati con successo": "Dati salvati con successo",
        "Piva trovata": "P.iva trovata",
        "Nessuna PIVA trovata, procedi manualmente": "Nessuna P.IVA trovata, procedi manualmente",
        "Associa le licenze di Spiagge.it ai tuoi negozi": "Associa le licenze di Spiagge.it ai tuoi negozi",
        "Licenza": "Licenza",
        "Licenza 1": "Licenza 1",
        "Licenza spiaggia associata correttamente": "Licenza spiaggia associata correttamente",
        "Licenza spiaggia": "Licenza spiaggia",
        "associata a": "associata a",
        "Licenza spiaggia eliminata correttamente": "Licenza spiaggia eliminata correttamente",
        "Nessuna licenza disponibile": "Nessuna licenza disponibile",
        "Abilita QR Code per attivare pay": "Abilita QR Code per attivare pay",
        "Pasto dipendente": "Pasto dipendente",
        "Sconto assoluto": "Sconto assoluto",
        "Nuovo": "Nuovo",
        "Prossima uscita": "Prossima uscita",
        "Invia comanda": "Invia comanda",
        "Nessun preferito": "Nessun preferito",
        "Cassetto": "Cassetto",
        "Costi di servizio": "Costi di servizio",
        "Abilita pagamento Stripe con email": "Abilita pagamento Stripe con email",
        "Costo di servizio base": "Costo di servizio base",
        "Abilita costi di servizio": "Abilita costi di servizio",
        "Seleziona causale": "Seleziona causale",
        "Aggiungi quantità": "Aggiungi quantità",
        "Seleziona sconto o maggiorazione": "Seleziona sconto o maggiorazione",
        "Inserisci qui l’importo": "Inserisci qui l’importo",
        "Scrivi qui le note": "Scrivi qui le note",
        "Salda": "Salda",
        "Unito": "Unito",
        "Nessuna unione": "Nessuna unione",
        "Nuova": "Nuova",
        "Aggiungi dispositivo": "Aggiungi dispositivo",
        "Tutti gli eventi": "Tutti gli eventi",
        "Gestisci eventi": "Gestisci eventi",
        "Gestisci biglietti": "Gestisci biglietti",
        "Gestisci accessori": "Gestisci accessori",
        "Gestisci strutture": "Gestisci strutture",
        "Gestisci token": "Gestisci token",
        "Siti": "Siti",
        "Gestisci siti": "Gestisci siti",
        "Aggiungi, modifica ed elimina eventi": "Aggiungi, modifica ed elimina eventi",
        "Aggiungi, modifica ed elimina biglietti": "Aggiungi, modifica ed elimina biglietti",
        "Aggiungi, modifica ed elimina accessori": "Aggiungi, modifica ed elimina accessori",
        "Aggiungi, modifica ed elimina strutture ed aree": "Aggiungi, modifica ed elimina strutture ed aree",
        "Aggiungi, modifica ed elimina token": "Aggiungi, modifica ed elimina token",
        "Aggiungi, modifica ed elimina siti": "Aggiungi, modifica ed elimina siti",
        "Abilita stampa non fiscale": "Abilita stampa non fiscale",
        "Mostra opzioni": "Mostra opzioni",
        "Modifica le impostazioni generali del tuo account Easylivery": "Modifica le impostazioni generali del tuo account Easylivery",
        "IP Internet": "IP Internet",
        "UUID dispositivo": "UUID dispositivo",
        "Aggiornamento disponibile, clicca qui": "Aggiornamento disponibile, clicca qui",
        "Nessuna configurazione abilitata": "Nessuna configurazione abilitata",
        "Visibilità prodotti": "Visibilità prodotti",
        "Rider mancante": "Rider mancante",
        "Guide": "Guide",
        "Chiudi applicazione": "Chiudi applicazione",
        "Impostazioni sviluppatore": "Impostazioni sviluppatore",
        "Schermo intero": "Schermo intero",
        "Non puoi richiedere la fatturazione utilizzando metodi di pagamento diversi": "Non puoi richiedere la fatturazione utilizzando metodi di pagamento diversi",
        "Gestisci le tue fatture": "Gestisci le tue fatture",
        "Gestisci fatturazione": "Gestisci fatturazione",
        "Cerchi la spiegazione dettaglita di una funzionalità o hai bisogno di assistenza?": "Cerchi la spiegazione dettaglita di una funzionalità o hai bisogno di assistenza?",
        "App e sito web": "App e sito web",
        "Nota di credito": "Nota di credito",
        "Unisci": "Unisci",
        "Attiva Tavolo": "Attiva Tavolo",
        "Uscita": "Uscita",
        "Seleziona tavoli da unire": "Seleziona tavoli da unire",
        "Tavoli uniti": "Tavoli uniti",
        "Scarica QR": "Scarica QR",
        "Scarica qr code": "Scarica qr code",
        "Scarica selezionati": "Scarica selezionati",
        "Nessun tavolo con qr attivo": "Nessun tavolo con qr attivo",
        "Aggiungere il nome all'ultimo tavolo aggiunto": "Aggiungere il nome all'ultimo tavolo aggiunto",
        "Aggiungere il nome a tutti i tavoli": "Aggiungere il nome a tutti i tavoli",
        "Deve essere presente almeno una sala": "Deve essere presente almeno una sala",
        "Max 10 caratteri": "Max 10 caratteri",
        "Mostra": "Mostra",
        "Seleziona costo di servizio": "Seleziona costo di servizio",
        "Seleziona operatore": "Seleziona operatore",
        "Seleziona coperti": "Seleziona coperti",
        "Numero coperti diverso da numero ospiti": "Numero coperti diverso da numero ospiti",
        "Coperti che non consumano": "Coperti che non consumano",
        "Non hai ancora creato nessun tavolo!": "Non hai ancora creato nessun tavolo!",
        "Clicca qui per creare la tua sala": "Clicca qui per creare la tua sala",
        "Elementi spiaggia": "Elementi spiaggia",
        "Spiaggia": "Spiaggia",
        "Ristorante, bar, club": "Ristorante, bar, club",
        "Mappa": "Mappa",
        "Unisci tavoli": "Unisci tavoli",
        "Disunisci": "Disunisci",
        "Occupati": "Occupati",
        "Liberi": "Liberi",
        "Tavoli liberi": "Tavoli liberi",
        "Tavoli occupati": "Tavoli occupati",
        "Abilita verifica QR code": "Abilita verifica QR code",
        "Prodotti migliori": "Prodotti migliori",
        "Ricavo": "Ricavo",
        "Margine": "Margine",
        "Top prodotti venduti": "Top prodotti venduti",
        "Fatturato per canale": "Fatturato per canale",
        "Migliori promozioni": "Migliori promozioni",
        "Clienti registrati": "Clienti registrati",
        "Migliori store": "Migliori store",
        "Prodotti peggiori": "Prodotti peggiori",
        "Categorie migliori": "Categorie migliori",
        "Categorie peggiori": "Categorie peggiori",
        "Migliori clienti": "Migliori clienti",
        "Import da piattaforme": "Import da piattaforme",
        "Link non valido": "Link non valido",
        "Mostra tracking ordine la cliente": "Mostra tracking ordine la cliente",
        "Visualizza ordine dal nostro schermo": "Visualizza ordine dal nostro schermo",
        "Abilita testo in alto a destra": "Abilita testo in alto a destra",
        "Testo monitor ordini": "Testo monitor ordini",
        "Cerca codice ordine": "Cerca codice ordine",
        "Iscritto": "Iscritto",
        "Non iscritto": "Non iscritto",
        "Prodotto cancellato": "Prodotto cancellato",
        "Categoria cancellata": "Categoria cancellata",
        "Negozio cancellato":  "Negozio cancellato",
        "Promozione cancellata": "Promozione cancellata",
        "Cliente cancellato": "Cliente cancellato",
        "Oggetto cancellato": "Oggetto cancellato",
        "NOME PRODOTTO": "NOME PRODOTTO",
        "NOME CATEGORIA": "NOME CATEGORIA",
        "NOME NEGOZIO": "NOME NEGOZIO",
        "NOME PROMOZIONE": "NOME PROMOZIONE",
        "NOME CLIENTE": "NOME CLIENTE",
        "NOME OGGETTO": "NOME OGGETTO",
        "NOME": "NOME",
        "Richiesta conto ordine": "Richiesta conto ordine",
        "Paga al tavolo": "Paga al tavolo",
        "Sei sicuro di voler scaricare le chiusure": "Sei sicuro di voler scaricare le chiusure",
        "Non fiscale": "Non fiscale"
    }
}
