import { ApiErrors } from 'types'

const errors: ApiErrors = {
    Generic: 'Si è verificato un errore, riprova',
    AuthFail: 'Email o password errata',
    EmailRegistered: 'Email già utilizzata',
    Wait: `Hai già effettuato un tentativo, riprova tra un'ora`,
    PasswordInsecure: 'Password non sicura, usa 6 o più caratteri',
    WrongPassword: 'Password vecchia errata',
    NoSeller: 'Nessun venditore trovato con il codice inserito',
    Already: 'Il nome della promozione è già stato utilizzato',
    AlreadyOpen: 'Il tavolo è già stato aperto, prova ad aggiornare la pagina.'
}

export default function mapError(err: { error: string } | string) {
    let newError = errors.Generic

    if (typeof err === 'object') {
        if (err.error && err.error !== 'Generic' && errors[err.error]) {
            newError = errors[err.error]
        }
    } else {
        newError = errors[err]
    }

    return newError
}