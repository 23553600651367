import useSWR from 'swr'
import { addNotification, getQueryParams, isProduction, redirect, transformToQueryParams } from 'utils'
import request from './request'
import Router from 'next/router'

import { Parser } from "xml2js"
import { ParsedQuery } from 'query-string'
import { DailyClose, GlobalContextStateOptional, Mutate, PaginatedData, PrintJob, PrintJobStatus, Printer } from 'types'
import { getBrandAndShopAndAddons } from './authentication'
import Heap from 'heap-js'

export function getPrinters(brandId: string, shop?: string, shouldDoRequest = true) {
    const params = {shop, ...getQueryParams() as ParsedQuery}
    const { data, error, mutate } =  useSWR<PaginatedData<Printer[]>, {error: string}>(() => shouldDoRequest ? `/printer/${brandId}/my${transformToQueryParams('string', params)}` : null)
    
    return {printers: data, isLoading: !data && !error, error, mutate}
}

export function getSinglePrinter(printerId: string, shouldDoRequest?: boolean) {
    const { data, error, mutate } = useSWR<Printer, { error: string }>(() => shouldDoRequest ? `/printer/info/${printerId}` : null)

    return { printer: data as Printer, isLoading: !data && !error, error, mutate }
}

export function createPrinter(printer: Printer, brandIndex: string, setSubmitting: (isSubmitting: boolean) => void, mutate?: Mutate<Printer>, updateGlobalContext?: (state: GlobalContextStateOptional) => void) {
    if(mutate) {
        mutate(() => printer, false)
    }
    
    request('post', '/printer/create', {...printer, brandId: printer.brand, departments: printer.departments.filter(singleDepartment => typeof singleDepartment === 'number'), brand: undefined})
    .then(() => {
        addNotification('Stampante salvata con successo', 'success')

        if(updateGlobalContext) {
            getBrandAndShopAndAddons(updateGlobalContext)
        }
        
        redirect(`/brand/${brandIndex}/manage/printers`)
    })
    .catch(() => addNotification('Si è verificato un errore, riprova', 'danger'))
    .finally(() => {
        if(mutate) {
            mutate()
        }

        setSubmitting(false)
    })
}

export function editPrinter(printer: Printer, brandIndex: string, setSubmitting: (isSubmitting: boolean) => void, mutate?: Mutate<Printer>, updateGlobalContext?: (state: GlobalContextStateOptional) => void) {
    if(mutate) {
        mutate(() => printer, false)
    }
    
    request('post', '/printer/edit', {...printer, printerId: printer._id, departments: printer.departments.filter(singleDepartment => typeof singleDepartment === 'number'), _id: undefined, brandId: printer.brand, brand: undefined})
    .then(() => {
        addNotification('Stampante salvata con successo', 'success')
     
        if(updateGlobalContext) {
            getBrandAndShopAndAddons(updateGlobalContext)
        }
        
        redirect(`/brand/${brandIndex}/manage/printers`)
    })
    .catch(() => addNotification('Si è verificato un errore, riprova', 'danger'))
    .finally(() => {
        if(mutate) {
            mutate()
        }

        setSubmitting(false)
    })
}

export function deletePrinter(printerId: string, brandId: string, updateGlobalContext?: (state: GlobalContextStateOptional) => void) {
    const { push } = Router
    
    request('post', '/printer/delete', {printerId})
    .then(() => {
        push(`/brand/${brandId}/manage/printers`)

        if(updateGlobalContext) {
            getBrandAndShopAndAddons(updateGlobalContext)
        }
    })
    .catch(() => addNotification('Si è verificato un errore, riprova', 'danger'))
}

export function getPrinterDailyCloses(brandId: string, printerId: string, shop?: string, to?: string) {
    const params = {shop, ...getQueryParams() as ParsedQuery, to, ...getQueryParams() as ParsedQuery}
    const { data, error, mutate } =  useSWR<PaginatedData<DailyClose[]>, {error: string}>(`/fiscale/${brandId}/daily-close-list/${printerId}${transformToQueryParams('string', params)}`)
    return {dailyCloses: data, isLoading: !data && !error, error, mutate}
}

export function savePrinterDailyClose(brandId: string, printerId: string, amount: number, setStatus: React.Dispatch<React.SetStateAction<"error" | "done" | "loading" | "confirm" | null>>, mutate: Mutate<PaginatedData<DailyClose[]>>) {
    request('post', '/fiscale/daily-close', {brandId, printerId, amount})
    .then(() => setStatus('done'))
    .catch(() => setStatus('error'))
    .finally(() => mutate())
}

export function addFiscalPrintJob(printJob: PrintJob) {
    if (window.printerJobs === undefined) window.printerJobs = new Map<string, PrintJob>()
    if (window.fiscalPrioQ === undefined) window.fiscalPrioQ = new Heap<string>((j1, j2) => (window.printerJobs.get(j2)?.priority || 0) - (window.printerJobs.get(j1)?.priority || 0))

    window.printerJobs.set(printJob.id, printJob)
    window.fiscalPrioQ.push(printJob.id)
}

export function addNonFiscalPrintJob(printJob: PrintJob) {
    if (window.printerJobs === undefined) window.printerJobs = new Map<string, PrintJob>()
    if (window.nonFiscalPrioQ === undefined) window.nonFiscalPrioQ = new Heap<string>((j1, j2) => (window.printerJobs.get(j2)?.priority || 0) - (window.printerJobs.get(j1)?.priority || 0))
    
    window.printerJobs.set(printJob.id, printJob)
    window.nonFiscalPrioQ.push(printJob.id)
}
