import request from 'api/request'
import Router from 'next/router'
import { addNotification, removeFromStorage, getFromStorage, isProduction, saveToStorage, setSentryExtras, isCapacitor, isDevelopment, isElectron } from 'utils'
import mapError from 'utils/mapError'
import { removeNotificationToken } from 'utils/notifications'
import { initialGlobalContextState } from 'context'
import Cookies from 'universal-cookie'
import { Device } from '@capacitor/device'
import { detectBrowserLanguage, getStorageLanguage } from 'utils/language/utils'

import { GlobalContextStateOptional, Shop, Brand, StatusRes, Redirect, LoginData, LoginRes, RegisterData, Addon, OperatorServices,  } from 'types'

export function getAdminToken() {
    return new Cookies().get('elAdminToken')
}

export function getAdminTokenExpire() {
    return new Cookies().get('elAdminTokenExpire')
}

export async function getToken() {
    const adminCookie = isProduction ? getAdminToken() : undefined

    return adminCookie || await getFromStorage('token')
}

export async function setToken(token: string) {
    await saveToStorage('token', token)
}

export async function revokeToken() {
    new Cookies().remove('elAdminToken', {domain: 'easylivery.it', path: '/'})
    await removeFromStorage('token')
}

const notAutheticatedRoutes = ['/', '/signup', '/forgot-password', '/reset-password']

export function checkAuthenticationAndGetData() {
    return new Promise<GlobalContextStateOptional>(async (resolve, reject) => {
        let redirect: Redirect = 'done'
        const deviceId = isCapacitor ? (await Device.getId()) : null
        // @ts-ignore
        const deviceUUID = deviceId ? deviceId.uuid || deviceId.identifier : null
        const token = await getToken()

        await detectBrowserLanguage()

        if (typeof window !== 'undefined') {
            document.documentElement.setAttribute('lang', await getStorageLanguage() || 'en')
        }

        if(token) {
            Promise.all([request<StatusRes>('get', '/authentication/status'), getBrandAndShopAndAddons()])
            .then(async ([status, brandAndShop]) => {
                if(status.error) {
                    await revokeToken()
                    await removeNotificationToken()
                    redirect = notAutheticatedRoutes.indexOf(Router.pathname) > -1 ? 'done' : '/'
                    return reject({authenticated: false, redirect})
                } else {
                    redirect = notAutheticatedRoutes.indexOf(Router.pathname) > -1 ? '/brand/0/home/' : 'done'

                    if(brandAndShop.brands.length === 0 && Router.pathname !== '/brand/[id]/setup') {
                        redirect = '/brand/0/setup/general'
                    } else if(brandAndShop.shops.length === 0 && Router.pathname !== '/brand/[id]/manage/shops/create') {
                        redirect = `/brand/0/manage/shops/create`
                    }
                    
                    if(status.userType === "operator"){
                        const validRoutes: string[] = []
                        const hasOperatorPermission = (permission : OperatorServices) => status.operator?.services && status.operator?.services.includes(permission)

                        if(hasOperatorPermission(OperatorServices.Orders)){
                            validRoutes.push('/brand/[id]/orders/simplified')
                        }
                        if(hasOperatorPermission(OperatorServices.CashDesk)){
                            validRoutes.push('/brand/[id]/cashdesk/[[...index]]')
                            validRoutes.push('/brand/[id]/cashdesk/archive')
                        }

                        if(!validRoutes.includes(Router.pathname)){
                            if(hasOperatorPermission(OperatorServices.Orders)){
                                redirect =  '/brand/0/orders/simplified'
                            } else if(hasOperatorPermission(OperatorServices.CashDesk)){
                                redirect = '/brand/0/cashdesk/receipt'
                            }
                        }
                    } else {
                        delete status.operator
                    }

                    return resolve({authenticated: true, deviceUUID, ...status, ...brandAndShop, redirect})
                }
            })
            .catch(async () => {
                await revokeToken()
                await removeNotificationToken()
                redirect = notAutheticatedRoutes.indexOf(Router.pathname) > -1 ? 'done' : '/'
                return reject({authenticated: false, redirect})
            })
        } else {
            await removeNotificationToken()
            redirect = notAutheticatedRoutes.indexOf(Router.pathname) > -1 ? 'done' : '/'
            return reject({authenticated: false, redirect})
        }
    })
}

export function getBrandAndShopAndAddons(updateGlobalContext?: (state: GlobalContextStateOptional) => void) {
    const hasGlobalContext = updateGlobalContext !== undefined
    
    return new Promise<{brands: Brand[], shops: Shop[]}>((resolve, reject) => {
        Promise.all([request<Brand[]>('get', '/brand/my'), request<Addon[]>('get', '/addon/my')])
        .then(([brands, addons]) => {
            const brandAndShopsAndAddons = {brands, addons, shops: []}
            if(brands.length > 0) {
                request<Shop[]>('get', `/shop/my`)
                .then(shops => hasGlobalContext ? updateGlobalContext({...brandAndShopsAndAddons, shops}) : resolve({...brandAndShopsAndAddons, shops}))
                .catch(() => hasGlobalContext ? updateGlobalContext(brandAndShopsAndAddons) : resolve(brandAndShopsAndAddons))
            } else {
                hasGlobalContext ? updateGlobalContext(brandAndShopsAndAddons) : resolve(brandAndShopsAndAddons)
            }
        })
        .catch(() => reject())
    })
}

export function login(data: LoginData) {
    return new Promise<GlobalContextStateOptional>((resolve, reject) => {
        request<LoginRes>('post', '/authentication/login-admin', {...data, remember: data.remember ? 'yes' : 'no'})
        .then(async authData => {
            await setToken(authData.token!)
            delete authData.token
            const deviceId = isCapacitor ? (await Device.getId()) : null
            // @ts-ignore
            const deviceUUID = deviceId ? deviceId.uuid || deviceId.identifier : null
            
            getBrandAndShopAndAddons()
            .then(async brandAndShop => {
                let redirect = '/brand/0/home/'
                if(brandAndShop.brands.length === 0) {
                    redirect = '/brand/0/setup/general'
                }

                if(authData.userType === "operator"){
                    const hasOperatorPermission = (permission : OperatorServices) => authData.operator?.services && authData.operator?.services.includes(permission)

                    if(hasOperatorPermission(OperatorServices.Orders)){
                        redirect =  '/brand/0/orders/simplified'
                    }else if(hasOperatorPermission(OperatorServices.CashDesk)){
                        redirect = '/brand/0/cashdesk/receipt'
                    }
                } else {
                    delete authData.operator
                }
                    
                return resolve({authenticated: true, deviceUUID, ...authData, ...brandAndShop, redirect})
            })
            .catch(async () => resolve({authenticated: true, deviceUUID, ...authData, redirect: '/brand/0/setup/general'}))
        })
        .catch(err => {
            addNotification(mapError(err), 'danger')
            return reject()
        })
    })
}

export async function logout(updateGlobalContext: (state: GlobalContextStateOptional) => void) {
    await revokeToken()
    await removeNotificationToken()
    setSentryExtras({})
    updateGlobalContext({...initialGlobalContextState, authenticated: false, redirect: '/'})   

    if(isElectron) {
        // @ts-ignore
        window.electron.disableFullScreen()
    }
}

export function signupMerchant(data: RegisterData) {
    return new Promise<{_id: string}>((resolve, reject) => {
        request<{token: string; _id: string}>('post', '/authentication/create-merchant', data)
        .then(async ({token, _id}) => {
            await setToken(token)
            return resolve({_id})
        })
        .catch(err => {
            addNotification(mapError(err), 'danger')
            return reject()
        })
    })
}