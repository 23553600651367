export const colors = {
    primary: '#0090d5',
    secondary: '#1e2d55',
    border: '#E5E7EB',
    borderSecondary: '#cecece',
    black: '#4a4a4a',
    red: '#ef4444'
}

export const border = '1px solid #e5e7eb'

export const borderRadius = '.5rem'