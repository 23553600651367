import cn from 'classnames'
import { useContext } from 'react'
import { isAndroidOrIos, isCapacitor, isIOSDevice } from 'utils'
import { checkShopManager, useBrand, useFiscalPrinterQueue, useNonFiscalPrinterQueue, useOperator, useShops } from 'utils/hooks'

import { ContainerLoader } from 'components/loaders'
import Sidebar from 'components/sidebar'
import AppNavBar from './appNavBar'
import Header from './header'

import GlobalContext from 'context'
import { getAllOrders } from 'api/orders'

type Props = {
    showLoader: boolean
    children: React.ReactNode
}

export default function Layout({ showLoader, children }: Props) {
    const { authenticated, userType } = useContext(GlobalContext)
    const operator = useOperator()
    const deviceIsIOS = isIOSDevice()
    const isOperator = userType === "operator"
    const { brand } = useBrand()
    const { isShopManager } = checkShopManager()
    const { shops } = useShops()
    const { orders } = getAllOrders(brand?._id, isShopManager || isOperator, shops, undefined, true)
    useFiscalPrinterQueue({ orders })
    useNonFiscalPrinterQueue({ orders })

    return (
        <div>
            {showLoader && <Header/>}
            <div className={cn('layout-container flex h-screen', {'flex-col': authenticated && isAndroidOrIos && !operator, 'prevent-select': isCapacitor})}>
                {(authenticated || showLoader) && userType !== "operator" && !isAndroidOrIos && <Sidebar showLoader={showLoader} />}
                <div className={cn('flex-1 overflow-auto focus:outline-none', { 'bg-gray-light': authenticated || showLoader })} id="layout-wrapper">
                    {showLoader ? (
                        <ContainerLoader />
                    ) : children}
                </div>
                {!showLoader && authenticated && !operator && isAndroidOrIos && <AppNavBar/>}
            </div>
            <style jsx>{`
                .layout-container {height: ${deviceIsIOS ? '-webkit-fill-available' : undefined};}
            `}</style>
        </div>
    )
}